import { CFFunctionType } from "./enums.model";

export class CFFunctionNode {
    name: string;
    baseValue: string;
    preview: string;
    sqlServerValue: string;
    mySqlValue: string;
    description: string;
    acceptedTypes: string[];
    functionType: CFFunctionType;
    level: number;
    expandable: boolean;
}

