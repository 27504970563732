<h2 mat-dialog-title [ngClass]="isMenu() ? 'title-menu' : isRetail() ? 'title-retail': isWelcome()?'title-welcome':''">{{'title.modify' | translate}}</h2>
<mat-dialog-content class="mat-typography" [formGroup]="formGroup">
  <mat-form-field class="form-element">
    <mat-label>{{'dialog.name' | translate}}</mat-label>
    <input matInput placeholder="{{'fact.interrogation-name' | translate}}" formControlName="name" required (blur)="validatorService.validateName(formGroup.controls['name'].value, formGroup)" maxlength="50">
    @if ((!formGroup.controls['name'].valid && formGroup.controls['name'].touched) || formGroup.controls['name'].errors) {
      <mat-error>
        {{ formGroup.controls['name'].errors['message'] }}
      </mat-error>
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button class="btn-light" mat-dialog-close>{{'dialog.close' | translate}}</button>
  <button mat-raised-button class="btn-dark" mat-dialog-close (click)="close()"
  [disabled]="!formGroup.valid" >{{'dialog.proceed' | translate}}</button>
</mat-dialog-actions>
