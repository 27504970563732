<mat-toolbar class="mat-elevation-z1" [ngClass]="isMenu() ? 'product-container-menu' : isRetail() ? 'product-container-retail': isWelcome()?'product-container-welcome':''">
  @if (user) {
    <button mat-icon-button id="menu" (click)="onSidenavToggle()">
      <mat-icon style="color: white; margin-left: -10px; margin-right: 5px;">menu</mat-icon>
    </button>
  }
  <span>
    @if (isMenu()) {
      <img class="logo" src="assets/images/Menu.svg">
    }
    @else if (isRetail()) {
      <img class="logo" src="assets/images/Retail.svg">
    }
    @else if (isWelcome()) {
      <img class="logo" src="assets/images/Welcome.svg">
    }
    <span class="product-name"> {{ name }} </span>
  </span>
  <!--<span class="example-spacer"></span>-->
  <!--<mat-select #langSelect class="lang-select" [(value)]="selectedLang"
  (selectionChange)="changeLang(langSelect.value)" >
  <mat-option class="text_uppercase" *ngFor="let lang of translate.getLangs()" [value]="lang" >
    {{ lang }}
  </mat-option>
</mat-select>-->
<!--<button mat-icon-button class="logout" (click)="authService.logout()">
<mat-icon>exit_to_app</mat-icon>
</button>-->

</mat-toolbar>