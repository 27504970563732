import { Component, Input } from "@angular/core";
import { BtnDialogConfig } from "src/app/models/dialog-box.model";

@Component({
  selector: "app-message-dialog",
  templateUrl: "./message-dialog.component.html",
  styleUrls: ["./message-dialog.component.scss"]
})
export class MessageDialogComponent {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() message: string;
  @Input() showTextContainer: boolean;
  @Input() btn1: BtnDialogConfig;
  @Input() btn2: BtnDialogConfig;

  constructor(

  ) {

  }
}
