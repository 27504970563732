<div class="item-container" [ngClass]="{'active': isActive(), 'open': expanded == true && state == true}">
  <div [ngStyle]="{'padding-left': (depth * 12) + 'px', 'width': '80%'}" (click)="onItemSelected(item, depth)"
       [ngClass]="{
    'active': item.route ? router.isActive(item.route, routeParams): false,
    'menu-active': isMenuActive == true, 'expanded': expanded == true && state == true
    }"
    class="flex-row flex-align-items-center padding-10">
    @if (depth==0) {
      <div class="mat-button-menu" (click)="onIconSelected(item)" >
        <span class="material-icons color-white" matTooltip="{{item.displayName}}">{{item.iconName}}</span>
        <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" ></div><!--[ngStyle]="{'top': getTop(item)}"-->
      </div>
    }
    @if (depth!=0) {
      <span
        [ngClass]="isMenuIcon(item)? 'dot-icon-menu' : isRetailIcon(item) ? 'dot-icon-retail': isWelcomeIcon(item)?'dot-icon-welcome':''"
      class="material-icons" matTooltip="{{item.displayName}}">{{item.iconName}}</span>
    }
    <div class="expandable-item" (click)="onIconSelected(item)">
      @if (!isMobile && !isTablet) {
        <span [@animateText]="state ? 'show' : 'hide'"
          [ngClass]="{'item-name' : depth == 0, 'item-name-small': depth != 0 }" matTooltip="{{item.hintName}}"
        >{{item.displayName}}</span>
      }

      @if (isMobile || isTablet) {
        <span [@animateText]="state ? 'show' : 'hide'"
          [ngClass]="{'item-name' : depth == 0, 'item-name-small': depth != 0 }"
        >{{item.displayName}}</span>
      }
      @if (item.children && item.children.length) {
        <span class="material-icons color-white dropdown-icon" [@animateText]="state ? 'show' : 'hide'"
          [@indicatorRotate]="expanded == true && state == true ? 'expanded': 'collapsed'"
        >arrow_drop_down</span>
      }
    </div>
  </div>
  @if (item.canAdd && !item.disabled && !isMobile && !isTablet && item.id != 0) {
    <div (click)="onAddSelected(item)"
      [style.display]="state?'flex': 'none'">
      <div
        [ngClass]="isMenu() ? 'add-container-menu' : isRetail() ? 'add-container-retail': isWelcome()?'add-container-welcome':''">
        <span id="circle-add" class="material-icons color-white" [@animateText]="state ? 'show' : 'hide'">add</span>
      </div>
    </div>
  }
</div>
@if (expanded && state && !isMobile && !isTablet) {
  <div class="children-items"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    #todoList="cdkDropList"
    [cdkDropListData]="item.children">
    <!--Dashboard, Report, Indagine-->
    @if (item.id == -1) {
      @for (child of item.children; track child) {
        <app-menu-list-item cdkDrag [item]="child" [depth]="depth+2" [state]="state">
        </app-menu-list-item>
      }
    }
    <!--Controllo Gestione-->
    @if (item.id != -1) {
      @for (child of item.children; track child) {
        <app-menu-list-item [item]="child" [depth]="depth+2" [state]="state">
        </app-menu-list-item>
      }
    }
  </div>
}
@if (expanded && state && (isMobile || isTablet)) {
  <div class="children-items">
    @for (child of item.children; track child) {
      <app-menu-list-item [item]="child" [depth]="depth+2" [state]="state">
      </app-menu-list-item>
    }
  </div>
}

<mat-menu #menu="matMenu" class="side-panel" [overlapTrigger]="true" [ngStyle]="{'max-height': height}">
  <div class="menu-title">
    <span class="item-name-menu">{{item.displayName}}</span>
    @if (item.canAdd && !item.disabled && !isMobile && !isTablet && item.id != 0) {
      <div
        [ngClass]="isMenu() ? 'new-btn-menu-menu' : isRetail() ? 'new-btn-menu-retail': isWelcome()?'new-btn-menu-welcome':''"
        (click)="onItemSelected(item, 1)">
        <span class="material-icons color-white">add</span>
        <span class="new-name">{{item.addString}}</span>
      </div>
    }
  </div>
  <div class="side-panel-list" [ngStyle]="{'max-height': height}">
    @for (child of item.children; track child) {
      <app-menu-list-item [item]="child" [depth]="1" [state]="true">
      </app-menu-list-item>
    }
  </div>
</mat-menu>


