<!--<div style="visibility: hidden; position: fixed; max-height: 48px !important;" [style.left]="contextMenuPosition.x"
[style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu" >
</div>
<mat-menu #contextMenu="matMenu" class="dimension-menu">
  <ng-template matMenuContent let-item="item">
    <div mat-menu-item (click)="changeJoin($event)">
      <mat-checkbox class="custom-checkbox"
        [checked]="isRightJoin()"
      >{{'dialog.all-values' | translate}}</mat-checkbox>
    </div>
  </ng-template>
</mat-menu>-->
<div class="flex-row flex-fill">
  @if (view=='tree') {
    <h2 mat-dialog-title style="width: 100%;">{{'dialog.select-fields' | translate}}
      <span class="material-icons" matTooltip="{{fact.Title}}"
      [ngClass]="configService.isMenu() ? 'description-icon-menu' : configService.isRetail() ? 'description-icon-retail': configService.isWelcome()?'description-icon-welcome':''">info</span>
      <span style="flex: 1 1 auto;"></span>
      @if (histYears && !isSurvey) {
<button mat-mini-fab class="historicized-years" [ngStyle]="historicizedYear != translate.instant('dialog.delete-selection') && historicizedYear != undefined?
        {'background-color':'#21c795'} : {'background-color': '#2D3E50'}"
          matTooltip="{{'dialog.historicized-years' | translate}} {{historicizedYear}}"
          (click)="openHistoricizedYears()">
          <mat-icon>date_range</mat-icon>
        </button>
      }
    </h2>
  }
  <!--<p style="margin: 5px 0;">test</p>-->
</div>
<!--<h2 mat-dialog-title *ngIf="view=='filter'">{{'dialog.set-filters' | translate}}</h2>-->
<mat-dialog-content class="full-width fill" style="overflow: hidden;">
  <div class="scroll-container-up" [style.display]="view=='filter'?'none': 'flex'">
    <mat-form-field>
      <input matInput #dataSourceName placeholder="{{'dialog.ds-name' | translate}}"
        (change)="changeDataSourceName($event)" [(ngModel)]="dsName">
        @if (dsName == null) {
          <mat-error>
            {{'dialog.not-correct-name' | translate}}
          </mat-error>
        }
      </mat-form-field>
      <!--<div >-->
      @if (view=='tree') {
        <mat-form-field class="form-element">
          <mat-label>{{'dw.ds-available' | translate}}</mat-label>
          <mat-select placeholder="{{'dw.ds-copied' | translate}}" (selectionChange)="dsChanged($event)">
            @for (ds of dsAvailables; track ds.Id) {
              <mat-option [value]="ds">
                {{ds.Name}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      <!--<button mat-mini-fab color="warn">
      <mat-icon>delete</mat-icon>
    </button>-->
  <!--</div>-->
  @if (view=='tree') {
    <mat-form-field>
      <input matInput placeholder="{{'dialog.search' | translate}}" (input)="filterChanged($event.target)">
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  }
</div>
<div class="flex-row fill" [style.display]="view=='filter'?'none': 'flex'">
        <div class="scroll-container" [style.display]="view=='filter'?'none': 'flex'" [ngStyle]="(canReorder || isNotReport) && selectedToReorder.length > 0 ?
        {'width':'50%'} : {'width': '100%'}"><!---->
    @if (isSurvey && proVersion) {
      <button mat-raised-button class="btn-dark" style="width: fit-content; margin-top: 1%;"
        (click)="openCalculatedFieldForm()">{{'calculated-fields.btn-text' |
        translate}}
        <span class="material-icons" [ngStyle]="{'color': '#ffffff'}">functions</span>
      </button>
    }
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"
      [style.display]="view=='filter'?'none': 'block'" style="max-height: 40vh;">

      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node custom-checkbox"
          [checked]="checklistSelection.isSelected(node)"
          (change)="todoLeafItemSelectionToggle($event, node)">
        {{node.title}}</mat-checkbox>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
        ><!--(contextmenu)="onContextMenu($event, node)"-->
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)" [disabled]="node.isMeasure"
          [indeterminate]="descendantsPartiallySelected(node)" class="custom-checkbox"
        (change)="todoItemSelectionToggle(node)">{{node.title}}</mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </div>
  @if ((canReorder || isNotReport) && selectedToReorder.length > 0) {
    <div style="width: 50%;" class="scroll-container" [style.display]="view=='filter'?'none': 'flex'"
      >
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        @for (s of selectedToReorder; track s) {
          <div class="example-box" cdkDrag
            (cdkDragDropped)="dragDropped($event)" (cdkDragReleased)="dragReleased($event)">
            <div style="display: inline-flex;">
              @if (isSurvey && s.isCalculatedField) {
                <span class="material-icons" style="color: #2D3E50; font-size: 20px !important;"
                >functions</span>
              }
              {{s.preview}}
            </div>
                        <div class="flex-row" [ngStyle]="isSurvey && s.isCalculatedField?
                    {'width': '18%', 'align-items': 'center', 'justify-content': 'space-between', 'padding-right' : '8%'} :
                    {'width': '10%', 'align-items': 'center', 'justify-content': 'space-between'}">
              @if (!isNotReport) {
                <mat-checkbox [checked]="s.grouped" class="custom-checkbox"
                  (change)="changeGrouped(s)"
                matTooltip="{{'dialog.hint-checkbox' | translate}}"></mat-checkbox>
              }
              @if (isSurvey && s.isCalculatedField) {
                <span class="material-icons" (click)="modifyCF(s)"
                  style="color: #2D3E50; font-size: 20px !important; cursor: pointer;"
                matTooltip="{{'calculated-fields.modify-cf' | translate}}">mode</span>
              }
              <img src="assets/images/arrows.svg" alt="arrows" style="height: 20px;">
              <!-- padding-right: 2%; -->
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
@if (isNotSmart()) {
  <div [style.display]="view=='tree'?'none': 'block'"> <!--*ngIf="isNotSmart()"-->
    <mat-tab-group #matGroup mat-stretch-tabs>
      <mat-tab label="{{'dialog.filters' | translate}}">
        <app-custom-filter-builder #filterBuilder [items]="checklistSelection.selected"
        (error)="setFilterError($event)"></app-custom-filter-builder>
      </mat-tab>
      @if (canReorder) {
        <mat-tab label="{{'dialog.request-filters' | translate}}">
          <app-custom-filter-builder #filterRequest [items]="checklistSelection.selected"
          (error)="setFilterRequestError($event)"></app-custom-filter-builder>
        </mat-tab>
      }
    </mat-tab-group>
    <!--<mat-tab-group #matGroup mat-stretch-tabs *ngIf="!canReorder">
    <mat-tab label="1">
      <app-custom-filter-builder #filterBuilder [items]="checklistSelection.selected"
      (error)="setFilterError($event)"></app-custom-filter-builder>
    </mat-tab>
  </mat-tab-group>-->
  <!--<app-filter-builder #filterBuilder [items]="checklistSelection.selected" (error)="setFilterError($event)">
</app-filter-builder>-->
</div>
}
</mat-dialog-content>
<mat-dialog-actions align="end" style="width: 100%;">
  @if (view=='filter') {
    <button mat-raised-button class="btn-light" (click)="goToPanel('tree')">{{'dialog.back' |
    translate}}</button>
  }
  <button mat-raised-button mat-dialog-close class="btn-light">{{'dialog.close' | translate}}</button>
  @if (view=='tree' && isNotSmart()) {
    <button mat-raised-button type="button" (click)="goToPanel('filter')" [disabled]="!isValidDataSource()"
    class="btn-dark">{{'dialog.filter' | translate}}</button>
  }
  <!--<button mat-raised-button *ngIf="!canReorder && filterBuilder && isNotSmart()"
  mat-dialog-close (click)="{name: dataSourceName.value, selected: checklistSelection.selected, fact: fact.Name, filters: {dw: filterBuilder.query, query: filterBuilder.realQuery}, historicizedYear: historicizedYear, dimensionOptions: dimensionOptions}"
  type="button" class="btn-dark" [disabled]="!isValidDataSource()">{{'dialog.proceed' |
translate}}</button>
<button mat-raised-button *ngIf="canReorder && filterBuilder && filterRequest && isNotSmart()"
  mat-dialog-close (click)="{name: dataSourceName.value, selected: selectedToReorder, fact: fact.Name, filters: {dw: filterBuilder.query, query: filterBuilder.realQuery}, requestedFilters: {dw: filterRequest.query, query: filterRequest.realQuery}, historicizedYear: historicizedYear, dimensionOptions: dimensionOptions}"
  type="button" [disabled]="!isValidDataSource()" class="btn-dark">{{'dialog.proceed' |
translate}}</button>-->
<button mat-raised-button
  [mat-dialog-close]="closeDialog(dataSourceName.value)"
  type="button" [disabled]="!isValidDataSource()" class="btn-dark">{{'dialog.proceed' |
translate}}</button>
</mat-dialog-actions>
<!--filters: {dw: filterBuilder.query, query: filterBuilder.realQuery}-->
