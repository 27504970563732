import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import {environment} from "../../environments/environment";
import {User} from "../models/user";
import {DialogService} from "../dialogs/dialog.service";
import {ConfigService} from "./config.service";
import { DwApiService } from './dw-mart-api.service';
import { LoginRQ } from '../models/web-api-models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public userSubject: BehaviorSubject<User>;
    public licenseSubject: BehaviorSubject<boolean>;
    public groupOptionsSubject: BehaviorSubject<boolean>;
    public user: Observable<User>;
    public license: Observable<boolean>;
    public gropOptions: Observable<boolean>;
    errorMsg: string;

    constructor(
        private router: Router,
        private dwService: DwApiService,
        private cookieService: CookieService,
        private dialogService: DialogService,
        private configService: ConfigService
    ) {
        this.userSubject = new BehaviorSubject<User>(null);
        this.licenseSubject = new BehaviorSubject<boolean>(null);
        this.groupOptionsSubject = new BehaviorSubject<boolean>(null);
        this.user = this.userSubject.asObservable();
        this.license = this.licenseSubject.asObservable();
        this.gropOptions = this.groupOptionsSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    public get gOptionsValue(): Boolean {
        return this.groupOptionsSubject.value;
    }

    async login(rq: LoginRQ, keepLoggedIn: boolean) {
        let body = new LoginRQ(rq.Username, rq.Password);
        let user = await firstValueFrom(this.dwService.login(body));
        this.userSubject.next(user);
        this.configService.setUser(user, keepLoggedIn);
        return user;
    }

    logout() {
        sessionStorage.clear();
        this.cookieService.deleteAll('/');
        this.stopRefreshTokenTimer();
        this.userSubject.next(null);
        this.licenseSubject = new BehaviorSubject<boolean>(null);
        this.groupOptionsSubject = new BehaviorSubject<boolean>(null);
        this.user = this.userSubject.asObservable();
        this.license = this.licenseSubject.asObservable();
        this.gropOptions = this.groupOptionsSubject.asObservable();
        this.router.navigate(['/login']);
    }

    logoutWithoutRedirect() {
        sessionStorage.clear();
        this.cookieService.deleteAll('/');
        this.stopRefreshTokenTimer();
        this.userSubject.next(null);
        this.licenseSubject = new BehaviorSubject<boolean>(null);
        this.groupOptionsSubject = new BehaviorSubject<boolean>(null);
        this.user = this.userSubject.asObservable();
        this.license = this.licenseSubject.asObservable();
        this.gropOptions = this.groupOptionsSubject.asObservable();
    }

    goToLogin() {
        this.stopRefreshTokenTimer();
        this.userSubject.next(null);
        this.licenseSubject = new BehaviorSubject<boolean>(null);
        this.groupOptionsSubject = new BehaviorSubject<boolean>(null);
        this.user = this.userSubject.asObservable();
        this.license = this.licenseSubject.asObservable();
        this.gropOptions = this.groupOptionsSubject.asObservable();
        this.router.navigate(['/login']);
    }

    refreshToken() {
        //let refreshToken = /*this.cookieService.get('refreshToken') ? this.cookieService.get('refreshToken') : */this.configService.getRefreshToken();//this.cookieService.get('refreshToken');
        let body = { RefreshToken: this.configService.getRefreshToken() ?? '' };
        let token = this.dwService.refreshToken(body);
        /*this.userSubject.next(user);
        this.configService.setUser(user);*/
        this.startRefreshTokenTimer();
        return token;
        /*return this.http.post<any>(`${environment.urlApi}apida/users/refresh-token`, { Token: refreshToken ? refreshToken : '' })
            .pipe(map((user) => {
                //this.licenseSubject.next(true);
                this.userSubject.next(user);
                this.configService.setUser(user);
                this.startRefreshTokenTimer();
                return user;
            }, catchError((err) => {
                this.dialogService.openErrorDialog(err, true);
                this.userSubject.next(null);
                return of([]);
            })));*/
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const token = JSON.parse(atob(this.userValue.Token.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(token.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
