import { Component, Input } from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-recipient-input',
  templateUrl: './recipient-input.component.html',
  styleUrl: './recipient-input.component.scss'
})
export class RecipientInputComponent {

  @Input({ required: true }) model: any[];

  readonly addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.validateEmail(value)) {
      this.model.push(value);
      event.chipInput!.clear();
    }
  }

  remove(item): void {
    const index = this.model.indexOf(item);
    this.model.splice(index, 1);
  }

  edit(item, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(item);
      return;
    }

    const index = this.model.indexOf(item);
    this.model[index] = value;
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
}
