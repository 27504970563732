import { ErrorHandler, LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TopNavComponent } from "./menu/top-nav/top-nav.component";
import { MenuListItemComponent } from "./menu/menu-list-item/menu-list-item.component";
import { MenuListComponent } from "./menu/menu-list/menu-list.component";
import { EtlComponent } from "./etl/etl/etl.component";
import { UserPanelComponent } from "./user-panel/user-panel/user-panel.component";
import { MaterialModule } from "./material/material.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { GlobalErrorHandler } from "../interceptors/global-error.handler";
import { xhrAuthInterceptor } from "../interceptors/xhr-auth.interceptor";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from "@angular/common";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { LoginComponent } from "./login/login.component";
import { RefreshDialogComponent } from "./dialogs/refresh-dialog/refresh-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MessageDialogComponent } from "./dialogs/message-dialog/message-dialog.component";
import { SpinnerComponent } from "./dialogs/spinner/spinner.component";
import { LoadingDialogComponent } from "./dialogs/loading-dialog/loading-dialog.component";
import { EtlErrorDialogComponent } from "./dialogs/etl-error-dialog/etl-error-dialog.component";
import { AddEtlDialogComponent } from "./etl/add-etl-dialog/add-etl-dialog.component";
import { ErrorDialogComponent } from "./dialogs/error-dialog/error-dialog.component";
import { DeactivateDialogComponent } from "./dialogs/deactivate-dialog/deactivate-dialog.component";
import { AllPermissionDialogComponent } from './user-panel/all-permission-dialog/all-permission-dialog.component';
import { ScheduledOperationComponent } from './scheduled-operation/scheduled-operation/scheduled-operation.component';
import { ReuseStrategy } from "../straregies/reuse-strategy";
import {
  DatasourceCreationDialogComponent
} from "./dialogs/datasource-creation-dialog/datasource-creation-dialog.component";
import {
  CustomFilterBuilderComponent
} from "./dialogs/datasource-creation-dialog/custom-filter-builder/custom-filter-builder.component";
import {
  HistoricizeYearsDialogComponent
} from "./dialogs/datasource-creation-dialog/historicized-years-dialog/historicized-years-dialog.component";
import {
  CalculatedFieldFormComponent
} from "./dialogs/datasource-creation-dialog/calculated-field-form/calculated-field-form.component";
import {
  ModifyInterrogationTitleComponent
} from "./dialogs/modify-interrogation-title/modify-interrogation-title.component";
import {
  InterrogationDescriptionComponent
} from "./dialogs/interrogation-description/interrogation-description.component";
import { FactSelectionDialogComponent } from "./dialogs/fact-selection-dialog/fact-selection-dialog.component";
import { AlterDatasourceComponent } from "./dialogs/alter-datasource/alter-datasource.component";
import { UploadDefaultComponent } from "./dialogs/upload-default/upload-default.component";
import { UploadDialogComponent } from "./dialogs/upload-dialog/upload-dialog.component";
import { RecipientDialogComponent } from "./dialogs/recipient-dialog/recipient-dialog.component";
import { FilePickerDirective } from "./dialogs/upload-dialog/file-picker-directive";
import { SelectedFilesTableComponent } from "./dialogs/upload-dialog/selected-files-table/selected-files-table-component";
import { DxFilterBuilderModule, DxTagBoxModule } from "devextreme-angular";
import { Router, RouteReuseStrategy } from "@angular/router";
import { SharedModule } from './shared/shared.module';
import * as Sentry from "@sentry/angular";
import { EmailServerConfigComponent } from './user-panel/email-server-config/email-server-config.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import '@angular/common/locales/global/it';
import {DateRangeDialogComponent} from "./dialogs/date-range-dialog/date-range-dialog.component";
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import {RequestFilterDialogComponent} from "./dialogs/request-filter-dialog/request-filter-dialog.component";
import {
  FiltersOnRequestComponent
} from "./dialogs/request-filter-dialog/filters-on-request/filters-on-request.component";
import { MatFormFieldModule } from '@angular/material/form-field';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    TopNavComponent,
    MenuListItemComponent,
    MenuListComponent,
    EtlComponent,
    UserPanelComponent,
    LoginComponent,
    RefreshDialogComponent,
    MessageDialogComponent,
    SpinnerComponent,
    LoadingDialogComponent,
    EtlErrorDialogComponent,
    AddEtlDialogComponent,
    ErrorDialogComponent,
    DeactivateDialogComponent,
    AllPermissionDialogComponent,
    ScheduledOperationComponent,
    DatasourceCreationDialogComponent,
    CustomFilterBuilderComponent,
    HistoricizeYearsDialogComponent,
    CalculatedFieldFormComponent,
    ModifyInterrogationTitleComponent,
    InterrogationDescriptionComponent,
    FactSelectionDialogComponent,
    AlterDatasourceComponent,
    UploadDefaultComponent,
    UploadDialogComponent,
    RecipientDialogComponent,
    FilePickerDirective,
    SelectedFilesTableComponent,
    EmailServerConfigComponent,
    DateRangeDialogComponent,
    RequestFilterDialogComponent,
    FiltersOnRequestComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DragDropModule,
    NgxMaterialTimepickerModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatDialogModule,
    TranslateModule,
    DxFilterBuilderModule,
    DxTagBoxModule,
    SharedModule,
    MatFormFieldModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    provideHttpClient(withInterceptors([xhrAuthInterceptor])),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RouteReuseStrategy, useClass: ReuseStrategy },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
