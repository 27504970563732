import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {NavService} from '../nav.service';
import {AuthenticationService} from "../../services/authentication.service";
import {ProductType} from "../../models/enums.model";
import {User} from "../../models/user";
import {ConfigService} from "../../services/config.service";

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {

  user: User;
  public sideNavState: boolean = false;
  name: string = " Analytics";

  constructor(
    public navService: NavService,
    public authService: AuthenticationService,
    public translate: TranslateService,
    private configService: ConfigService) {
    this.authService.user.subscribe(x => this.user = x);
    this.navService.sideNavState$.subscribe( res => {  this.sideNavState = res; });
   }

  ngOnInit() {
  }

  isMenu() {
    return this.configService.configInstances.getProductType() == ProductType.Menu;
  }

  isRetail() {
    return this.configService.configInstances.getProductType() == ProductType.Retail;
  }

  isWelcome() {
    return this.configService.configInstances.getProductType() == ProductType.Welcome;
  }

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;
    this.navService.sideNavState$.next(this.sideNavState);
  }
}
