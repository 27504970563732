import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import {CommonModule} from "@angular/common";
import {DialogService} from "../../dialogs/dialog.service";
import {
  ETLRunning,
  ETLState,
  ETLType,
  FTMaintenance,
  InterrogationType,
  LicenseType,
  MessageType, ProductType,
  ProductTypeStyle,
  RecurrenceType
} from '../../models/enums.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataMartInfo, EtlInfo } from '../../models/etlInfo';
import {MatSelect } from '@angular/material/select';
import { ThemePalette } from '@angular/material/core';
import { DwApiService } from '../../services/dw-mart-api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { UpdateMenuService } from '../../services/update-menu.service';
import { CookieService } from 'ngx-cookie-service';
import {MatRadioChange} from '@angular/material/radio';
import { default as p } from "../../../assets/pass-manifest.json";
import { Validator } from '../../utils/validator';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs';
import { AddEtlDialogComponent } from '../add-etl-dialog/add-etl-dialog.component';
import { UploadFolderDialogComponent } from '../upload-folder-dialog/upload-folder-dialog.component';
import {ConfigService} from "../../services/config.service";
import {CopyInterrogationRQ, GetConfigDataRQ, GetUserInterrogationsRQ} from "../../models/web-api-models";

interface Language {
  displayName: string;
  value: string;
}

@Component({
  selector: 'app-etl',
  templateUrl: './etl.component.html',
  styleUrl: './etl.component.scss'
})
export class EtlComponent {

  hasChanges: boolean = false;
  licenseType: string = '';
  isETLInfoLoaded = false;
  selectedEtl: string = '';
  selectedSource: string = '';
  selectedDataMarts: string = '';
  onLineYears: string = '';
  ftCount: number = 0;
  lastExecution: string = '';
  nextExecution: string = '';
  storicalYears: string = '';
  state: string = '';
  recurrenceType: string = '';
  etl: EtlInfo[] = [];
  etlList: string[] = [];
  factList: DataMartInfo[] = [];

  hour1: string = '';
  hour2: string = '';
  hour3: string = '';

  manualStartDate: Date;
  manualStartTime: string = '00:00';
  selectedYear: string = '';

  hasLog: boolean = false;
  hasError: boolean = false;
  hoursSelectionVisible: boolean = false;
  manualStartTimeVisible: boolean = false;
  maintenanceParamsVisible: boolean = false;

  manualStartRunning: boolean = false;
  maintenanceRunning: boolean = false;


  public get messageType(): typeof MessageType {
    return MessageType;
  }

  public get runningType(): typeof ETLRunning {
    return ETLRunning;
  }

  private etlContent: MatSelect;
  @ViewChild('etl') set content(content: MatSelect) {
    if (content) { // initially setter gets called with undefined
      this.etlContent = content;
    }
  }

  planningOptions: string[] = [];//= ['Manual', 'RealTime', 'Hourly'];
  manualStart: string;
  manualStartOptions: string[] = [];// = ['Incrementale', 'Incrementale dalla data', 'Totale', 'Totale dalla data (DT totale, FT inc.)'];
  maintenance: string;
  maintenanceOptions: string[] = [];// = ['Cancella anno', 'Storicizza anno', 'Rigenera anno', 'Reset totale DW'];
  etlState: string[] = [];
  color: ThemePalette = 'primary';
  checked = false;

  etlRunning: any;
  exportRunning: any;
  canDoMaintenance: boolean = false;
  selectedFact: string = this.translate.instant('dialog.all-selection');
  init: boolean = true;
  isNewAvailable: boolean = true;

  availableLanguages: Language[] = [];
  selectedLanguage: Language;
  selectedLanguageBKP: Language;
  hasDefaultChangedLang: boolean = false;
  hasNotDefaultChangedLang: boolean = false
  version: string = "";
  NotShareDT: boolean = false;
  IsMenuAvailable: boolean = false;
  AreInterrogationsAvailable: boolean = false;
  IsMenuAvailableChange: boolean = false;
  constructor(
    private api: DwApiService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private router: Router,
    private authenticationService: AuthenticationService,
    private updateMenu: UpdateMenuService,
    private cookieService: CookieService,
    private configService: ConfigService
  ) {
    //this.translate.use('it');
    //this.dialogService.hideDialog();
    this.licenseType = configService.configInstances.getLicense() == LicenseType.Full ? this.translate.instant('license.full') :
      configService.configInstances.getLicense() == LicenseType.FullDemo ? this.translate.instant('license.demonstrative') : this.translate.instant('license.basic');
    this.planningOptions = [
      this.translate.instant('etl.planning-opt.manual'),
      this.translate.instant('etl.planning-opt.real-time'),
      this.translate.instant('etl.planning-opt.hourly')];
    this.manualStartOptions = [
      this.translate.instant('etl.manual-start-opt.incremental'),
      this.translate.instant('etl.manual-start-opt.incremental-from-date'),
      this.translate.instant('etl.manual-start-opt.total'),
      this.translate.instant('etl.manual-start-opt.total-from-date')];
    this.maintenanceOptions = [
      this.translate.instant('etl.maintenance-opt.delete-year'),
      this.translate.instant('etl.maintenance-opt.historicize-year'),
      this.translate.instant('etl.maintenance-opt.regenerate-year'),
      this.translate.instant('etl.maintenance-opt.reset-dw')];
    this.etlState = [
      this.translate.instant('etl.state-enum.not-executed'),
      this.translate.instant('etl.state-enum.in-progress'),
      this.translate.instant('etl.state-enum.success'),
      this.translate.instant('etl.state-enum.error'),
      this.translate.instant('etl.state-enum.success-warning')
    ]
  }

  isMenu() {
    return this.configService.configInstances.getProductType() == ProductType.Menu;
  }

  isRetail() {
    return this.configService.configInstances.getProductType() == ProductType.Retail;
  }

  isWelcome() {
    return this.configService.configInstances.getProductType() == ProductType.Welcome;
  }

  getLog() {
    this.api.getLog().subscribe((da: any) => {
      let message = da;
      if (!message)
        message = 'dialog.no-log';

      this.dialogService.openMessageDialog(null, null, message, true);
    });
  }

  DTSharedClick(e) {
    this.NotShareDT = e.checked;
    this.hasChanges = true;
  }

  IsMenuAvailableClick(e) {
    this.IsMenuAvailable = e.checked;
    this.IsMenuAvailableChange = true;
    this.hasChanges = true;
  }

  ngOnInit(): void {
    let group = this.configService.configInstances.getGroupName();
    var body = {group: group, type: 0};
    this.api.getGroupOptionsEtl(body).subscribe((data: any) => {
      this.canDoMaintenance = this.configService.configInstances.getEtlWrite();
      if (this.canDoMaintenance)
        this.selectedFact = this.translate.instant('dialog.all-selection');
      if (this.configService.configInstances.getLicense() == LicenseType.FullDemo)
        this.isNewAvailable = false;
    });
    this.getETLInfo();
    this.translate.onLangChange.subscribe(
      (langChangeEvent: LangChangeEvent) => {
        // TODO: Add language change event logic
        this.planningOptions = [
          this.translate.instant('etl.planning-opt.manual'),
          this.translate.instant('etl.planning-opt.real-time'),
          this.translate.instant('etl.planning-opt.hourly')];
        this.manualStartOptions = [
          this.translate.instant('etl.manual-start-opt.incremental'),
          this.translate.instant('etl.manual-start-opt.incremental-from-date'),
          this.translate.instant('etl.manual-start-opt.total'),
          this.translate.instant('etl.manual-start-opt.total-from-date')];
        this.maintenanceOptions = [
          this.translate.instant('etl.maintenance-opt.delete-year'),
          this.translate.instant('etl.maintenance-opt.historicize-year'),
          this.translate.instant('etl.maintenance-opt.regenerate-year'),
          this.translate.instant('etl.maintenance-opt.reset-dw')];
        this.etlState = [
          this.translate.instant('etl.state-enum.not-executed'),
          this.translate.instant('etl.state-enum.in-progress'),
          this.translate.instant('etl.state-enum.success'),
          this.translate.instant('etl.state-enum.error'),
          this.translate.instant('etl.state-enum.success-warning')];
      });
  }

  canDeactivate() {
    if (this.hasChanges && this.authenticationService.userValue) {
      return this.dialogService.getDialogRefDeactivate(this.translate.instant('dialog.go-away')).pipe(map(result => {
        if (!result) this.dialogService.hideDialog();
        return result === true;
      }));
    } else return true;

  }

  addNewEtl() {
    const dialogConfig = new MatDialogConfig();
    /*dialogConfig.data = {
    };*/
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AddEtlDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(addEtlRes => {
      if (addEtlRes) {
        //console.log(addEtlRes);
        //DAEnumProductType productType, DAEnumSourceType sourceType, DAEnumRecurrenceType recurrenceType, string etlName, bool active, string sourceConnection, string hour1, string hour2, string hour3
        /*
        {productType: prodType, sourceType: sType, recurrenceType: recType,
            etlName: formGroup.get('name').value, active: active, user: formGroup.get('user').value,
            password: formGroup.get('password').value, server: formGroup.get('server').value,
            database: formGroup.get('database').value, hour1: hour1, hou2: hour2, hour3: hour3}*/
        var body;
        if (addEtlRes.recurrenceType == RecurrenceType.Hourly) {
          body = {
            productType: addEtlRes.productType,
            sourceType: addEtlRes.sourceType,
            recurrenceType: addEtlRes.recurrenceType,
            etlName: addEtlRes.etlName,
            sourceName: addEtlRes.sourceName,
            active: addEtlRes.active,
            isReplica: addEtlRes.isReplica,
            user: addEtlRes.user,
            password: addEtlRes.password,
            server: addEtlRes.server,
            database: addEtlRes.database,
            hour1: addEtlRes.hour1,
            hour2: addEtlRes.hour2,
            hour3: addEtlRes.hour3
          }
        } else {
          body = {
            productType: addEtlRes.productType,
            sourceType: addEtlRes.sourceType,
            recurrenceType: addEtlRes.recurrenceType,
            etlName: addEtlRes.etlName,
            sourceName: addEtlRes.sourceName,
            active: addEtlRes.active,
            isReplica: addEtlRes.isReplica,
            user: addEtlRes.user,
            password: addEtlRes.password,
            server: addEtlRes.server,
            database: addEtlRes.database
          }
        }

        this.api.addETL(body).subscribe((res) => {
          if (res) {
            //etl aggiunto
            this.dialogService.openMessageDialog(this.translate.instant('add-etl.add.title'),
              this.translate.instant('add-etl.add.subtitle'),
              this.translate.instant('add-etl.add.message'));
            this.getETLInfo();
          } else {
            //etl non aggiunto
            this.dialogService.openErrorDialog(this.translate.instant('add-etl.add.error'), true)
          }
        }, (err) => {
          throw err;
        });
      }
    });
  }

  toggleActive(e) {
    this.hasChanges = true;
    this.checked = e.checked;
  }

  getETLInfo(index?: number, checkError?: boolean) {
    this.dialogService.openLoadingDialog(false);
    this.isETLInfoLoaded = false;
    this.etl = [];
    this.etlList = [];
    this.api.getETLInfo().subscribe((etlInfo: any) => {
      for (let i in etlInfo) {
        this.etl.push(new EtlInfo(
          etlInfo[i].EtlName,
          etlInfo[i].Info.Active == 'True',
          Number(etlInfo[i].Info.LastDimAnalyze),
          etlInfo[i].Info.LastError,
          //this.parseDate(etlInfo[i].Info.lastExecution),
          (etlInfo[i].Info.LastExecution),
          etlInfo[i].Info.LastLog,
          //this.parseDate(etlInfo[i].Info.nextExecution),
          (etlInfo[i].Info.NextExecution),
          etlInfo[i].Info.Source,
          this.etlState[etlInfo[i].Info.State == ETLState.NotExecuted ? ETLState.NotExecuted :
            etlInfo[i].Info.State == ETLState.InProgress ? ETLState.InProgress :
              etlInfo[i].Info.State == ETLState.Success ? ETLState.Success - 1 :
                etlInfo[i].Info.State == ETLState.Error ? ETLState.Error - 1 : ETLState.SuccessWithWarning - 1],//etlInfo[i].Info.state,
          etlInfo[i].FactList,
          /*Number(etlInfo[i].Info.maxYearOnline),
          Number(etlInfo[i].Info.minYearOnline),*/
          etlInfo[i].Info.OnlineYears.substring(1, etlInfo[i].Info.OnlineYears.length - 1),
          Number(etlInfo[i].Info.FtCount),
          etlInfo[i].StoricalYears,
          this.planningOptions[etlInfo[i].Info.RecurrenceType],
          etlInfo[i].Info.Hour1.substring(0, etlInfo[i].Info.Hour1.length - 3),
          etlInfo[i].Info.Hour2.substring(0, etlInfo[i].Info.Hour2.length - 3),
          etlInfo[i].Info.Hour3.substring(0, etlInfo[i].Info.Hour3.length - 3),
          etlInfo[i].Info.Language,
          etlInfo[i].NotShareDT,
          etlInfo[i].IsMenuAvailable,
          etlInfo[i].Info.AreInterrogationsAvailables == 'True'
        ));
        this.NotShareDT = etlInfo[i].NotShareDt;
        this.IsMenuAvailable = etlInfo[i].IsMenuAvailable;
        this.AreInterrogationsAvailable = etlInfo[i].Info.AreInterrogationsAvailables == 'True';
        this.version = etlInfo[i].Version + "-" + p.version;
        this.etlList.push(etlInfo[i].EtlName);
        if (Number(i) == etlInfo.length - 1) {
          /*if (this.selectedEtl) {
            this.etl = this.etl.filter(e => e.name = this.selectedEtl);
          }*/
          this.configure(this.etl, this.etlList, index, checkError);
        }
        let languages = JSON.parse(etlInfo[i].Info.AvailableLanguage);
        this.availableLanguages = [];
        languages.forEach(l => {
          this.availableLanguages.push({displayName: this.translate.instant('language.' + l), value: l})
        });
      }
      setTimeout(() => {
        this.dialogService.hideDialog();
        this.isETLInfoLoaded = true;
        if ((this.hasDefaultChangedLang || this.IsMenuAvailableChange) && this.AreInterrogationsAvailable) {
          var firtsConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.reload-default-interrogations'));
          firtsConfirm.afterClosed().subscribe((res) => {
            if (res) {
              this.dialogService.openLoadingDialog(false);
              this.api.updateDefaultInterrogations({}).subscribe((res) => {
                if (this.hasDefaultChangedLang) this.hasDefaultChangedLang = false;
                if (this.IsMenuAvailableChange) this.IsMenuAvailableChange = false;
                this.dialogService.hideDialog();
                window.location.reload();
              }, (err) => {
                this.dialogService.hideDialog();
                throw err;
              });
            }
          });
        }
      }, 1000);
    });
  }

  configure(etl: EtlInfo[], etlList: string[], index?: number, checkError?: boolean) {
    //this.etl = etl;
    //this.etlList = etlList;
    if (!index) index = 0;
    this.selectedEtl = etlList[index];
    this.selectedSource = etl[index].source;
    this.selectedDataMarts = etl[index].factList.map(f => f.Title).toString().split(',').join('\n');
    this.factList = [];
    this.factList.push({ Name: this.translate.instant('dialog.all-selection'), Title: this.translate.instant('dialog.all-selection') });
    etl[index].factList.forEach(f => this.factList.push(f));
    this.checked = etl[index].active;
    this.onLineYears = '';
    /*if (etl[index].minYearOnline > 0) {
      for (let i = etl[index].minYearOnline; i <= etl[index].maxYearOnline; i++) {
        this.onLineYears = this.onLineYears.concat(i + ', ');
      }
    } else this.onLineYears = etl[index].maxYearOnline.toString();*/
    this.onLineYears = etl[index].onlineYears;
    this.ftCount = etl[index].ftCount;
    this.storicalYears = etl[index].storicalYears.toString() ? etl[index].storicalYears.toString() : this.translate.instant('etl.message.no-historicized-year');;
    this.lastExecution = Validator.isValidDate(this.parseDate(etl[index].lastExecution)) ? etl[index].lastExecution/*.toTimeString()*/ : this.translate.instant('etl.message.not-valid-date');;
    this.nextExecution = Validator.isValidDate(this.parseDate(etl[index].nextExecution)) ? etl[index].nextExecution/*.toTimeString()*/ : this.translate.instant('etl.message.not-valid-date');;
    this.state = etl[index].state;
    this.recurrenceType = etl[index].recurrenceType;
    this.hasLog = etl[index].lastLog ? true : false;
    this.hasError = etl[index].lastError ? true : false;
    this.hoursSelectionVisible = etl[index].recurrenceType == this.planningOptions[2];
    this.hour1 = etl[index].hour1;
    this.hour2 = etl[index].hour2;
    this.hour3 = etl[index].hour3;
    this.selectedLanguage = etl[index].language != '' ?
      { displayName: this.translate.instant('language.' + etl[index].language), value: etl[index].language } :
      { displayName: this.translate.instant('language.eng'), value: 'eng' };
    this.selectedLanguageBKP = etl[index].language != '' ?
      { displayName: this.translate.instant('language.' + etl[index].language), value: etl[index].language } :
      { displayName: this.translate.instant('language.eng'), value: 'eng' };
    if (checkError && this.hasError) this.dialogService.openEtlErrorDialog(this.translate.instant('etl.etl-error'), etl[index].lastError, this.translate.instant('etl.etl-error-message'));
    this.ref.detectChanges();
  }

  languageChanged(e) {
    let oldLanguage = this.selectedLanguageBKP;
    let defaultETL = this.etl.find(etl => etl.name == "Default");
    if (this.selectedEtl == "Default") {
      if (defaultETL.language != e.value) {
        this.hasChanges = true;
        this.hasDefaultChangedLang = true;
        this.selectedLanguage = { displayName: this.translate.instant('language.' + e.value), value: e.value };
        this.selectedLanguageBKP = { displayName: this.translate.instant('language.' + e.value), value: e.value };
      } else {
        this.hasChanges = false;
        this.hasDefaultChangedLang = false;
        this.selectedLanguage = { displayName: oldLanguage.displayName, value: oldLanguage.value };
        this.selectedLanguageBKP = { displayName: oldLanguage.displayName, value: oldLanguage.value };
        //e.source.value = this.selectedLanguage;
      }

    } else {
      if (defaultETL.language != e.value) {
        var firtsConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.change-lang-notdefault'));
        firtsConfirm.afterClosed().subscribe((res) => {
          if (res) {
            this.hasChanges = true;
            this.selectedLanguage = { displayName: this.translate.instant('language.' + e.value), value: e.value };
            this.selectedLanguageBKP = { displayName: this.translate.instant('language.' + e.value), value: e.value };
          } else {
            this.hasChanges = false;
            this.selectedLanguage = { displayName: oldLanguage.displayName, value: oldLanguage.value };
            this.selectedLanguageBKP = { displayName: oldLanguage.displayName, value: oldLanguage.value };
            //e.source.value = this.selectedLanguage;
          }
        });
      } else {
        let currentETL = this.etl.find(etl => etl.name == this.selectedEtl);
        if (currentETL.language != e.value) {
          this.hasChanges = true;
          this.selectedLanguage = { displayName: this.translate.instant('language.' + e.value), value: e.value };
          this.selectedLanguageBKP = { displayName: this.translate.instant('language.' + e.value), value: e.value };
        } else {
          this.hasChanges = false;
          this.selectedLanguage = { displayName: oldLanguage.displayName, value: oldLanguage.value };
          this.selectedLanguageBKP = { displayName: oldLanguage.displayName, value: oldLanguage.value };
        }
      }
    }
  }

  parseDate(date: string) {
    if (date) {
      let d: string = date.split(' ')[0];
      let t: string = date.split(' ')[1];
      let day: string = d.split('/')[0];
      let month: string = d.split('/')[1];
      let year: string = d.split('/')[2];
      let newDate: Date = new Date();
      let hours: string = t.split(':')[0];
      let minutes: string = t.split(':')[1];
      newDate.setFullYear(Number(year));
      newDate.setMonth(Number(month) - 1);
      newDate.setDate(Number(day));
      newDate.setHours(Number(hours));
      newDate.setMinutes(Number(minutes));
      return newDate;
    }
    return null;
  }

  etlChanged(e) {
    //qui devo cambiare la factList e il resto
    //console.log(e);
    let etl = this.etl.find(etl => etl.name == e.value);
    this.selectedEtl = e.value;
    this.factList = [];
    this.factList.push({ Name: this.translate.instant('dialog.all-selection'), Title: this.translate.instant('dialog.all-selection') });
    etl.factList.forEach(f => this.factList.push(f));
    this.selectedSource = etl.source;
    this.selectedDataMarts = etl.toString().split(',').join('\n');
    this.selectedDataMarts = etl.factList.map(f => f.Title).toString().split(',').join('\n');
    this.checked = etl.active;
    this.hour1 = etl.hour1;
    this.hour2 = etl.hour2;
    this.hour3 = etl.hour3;
    this.onLineYears = '';
    this.NotShareDT = etl.NotShareDT;
    /*for (let i = etl.minYearOnline; i <= etl.maxYearOnline; i++) {
      this.onLineYears = this.onLineYears.concat(i + ', ');
    }*/
    this.onLineYears = etl.onlineYears;
    //this.onLineYears = this.onLineYears.substring(0, this.onLineYears.length - 2);
    this.ftCount = etl.ftCount;
    this.storicalYears = etl.storicalYears.toString() ? etl.storicalYears.toString() : this.translate.instant('etl.message.no-historicized-year');
    this.lastExecution = Validator.isValidDate(this.parseDate(etl.lastExecution)) ? etl.lastExecution/*.toTimeString()*/ : this.translate.instant('etl.message.not-valid-date');
    this.nextExecution = Validator.isValidDate(this.parseDate(etl.nextExecution)) ? etl.nextExecution/*.toTimeString()*/ : this.translate.instant('etl.message.not-valid-date');
    this.state = etl.state;
    this.recurrenceType = etl.recurrenceType;
    this.hasLog = etl.lastLog ? true : false;
    this.hasError = etl.lastError ? true : false;
    this.selectedLanguage = etl.language != '' ?
      { displayName: this.translate.instant('language.' + etl.language), value: etl.language } :
      { displayName: this.translate.instant('language.eng'), value: 'eng' };
    this.selectedLanguageBKP = etl.language != '' ?
      { displayName: this.translate.instant('language.' + etl.language), value: etl.language } :
      { displayName: this.translate.instant('language.eng'), value: 'eng' };
    this.ref.detectChanges();
  }

  planningRadioChange(event: MatRadioChange) {
    this.hasChanges = true;
    this.recurrenceType = event.value;
    this.hoursSelectionVisible = event.value == this.planningOptions[2];
    if (this.hoursSelectionVisible) {
      this.hour1 = '00:00';
      this.hour2 = '00:00';
      this.hour3 = '00:00';
    }
  }

  indexOf(value) {
    return this.planningOptions.indexOf(value);
  }

  maintenanceRadioChange(event: MatRadioChange) {
    this.maintenanceParamsVisible = event.value != this.maintenanceOptions[3];
  }

  manualStartRadioChange(event: MatRadioChange) {
    this.manualStartTimeVisible = event.value == this.manualStartOptions[1] || event.value == this.manualStartOptions[3];
  }

  startInterval(running: ETLRunning) {
    running == ETLRunning.ManualStart ? this.manualStartRunning = true : this.maintenanceRunning = true;
    this.etlRunning = setInterval(async () => {
      let params: HttpParams = new HttpParams();
      params = params.append('etlName', this.selectedEtl);
      this.api.getETLState(params).subscribe((data: any) => {
          if (((running == ETLRunning.Reset || running == ETLRunning.Maintenance || running == ETLRunning.ManualStart) && data.state == ETLState.NotExecuted) || (running != ETLRunning.Reset && (data.state == ETLState.Success || data.state == ETLState.SuccessWithWarning || data.state == ETLState.Error))) {
            /*if (running == ETLRunning.Reset) {
              setTimeout(() => {
                this.stopInterval(running);
              }, 3000);
            } else */
            this.stopInterval(running);
          }
        },
        (err) => {
          throw err;
        })
    }, 10000)
  }

  stopInterval(running: ETLRunning) {
    running == ETLRunning.ManualStart ? this.manualStartRunning = false : this.maintenanceRunning = false;
    this.manualStart = '';
    this.maintenance = '';
    this.manualStartTimeVisible = false;
    this.maintenanceParamsVisible = false;
    clearInterval(this.etlRunning);
    let ref = this.dialogService.getRef();
    ref.close();
    //if (this.dialogService.opened) this.dialogService.opened = false;
    this.getETLInfo(undefined, true);
  }

  manualStartClick() {
    //console.log(this.manualStart);
    let body: any;
    switch (this.manualStart) {
      case this.translate.instant('etl.manual-start-opt.incremental'):
        body = {
          etlName: this.selectedEtl,
          etlType: ETLType.Incremental
        };
        this.api.startETLExecution(body).subscribe(() => {
            this.startInterval(ETLRunning.ManualStart);
            this.dialogService.openDialogWithStop(this.translate.instant('etl.manual-start-in-progress'), true, this.translate.instant('etl.in-progress'));
            let ref = this.dialogService.getRef();
            ref.afterClosed().subscribe(res => {
              if (res) {
                //this.dialogService.opened = false;
                this.stopEtl(this.runningType.ManualStart);
              }
            });
          },
          (err) => {
            throw "manualStart" + err;
          });
        break;
      case this.translate.instant('etl.manual-start-opt.incremental-from-date'):
        if (this.manualStartDate) {
          if (this.manualStartTime) {
            let h: string = this.manualStartTime.split(':')[0];
            let m: string = this.manualStartTime.split(':')[1];
            this.manualStartDate.setHours(Number(h)/* + +2*/);
            this.manualStartDate.setMinutes(Number(m) + Math.abs(this.manualStartDate.getTimezoneOffset()));
          } else {
            this.manualStartDate.setHours(this.manualStartDate.getMinutes() + Math.abs(this.manualStartDate.getTimezoneOffset()));
          }
          body = {
            etlName: this.selectedEtl,
            etlType: ETLType.IncrementalFromDate,
            fromDate: this.manualStartDate.toISOString()
          };
          this.api.startETLExecution(body).subscribe(() => {
              this.startInterval(ETLRunning.ManualStart);
              this.dialogService.openDialogWithStop(this.translate.instant('etl.manual-start-in-progress'), true, this.translate.instant('etl.in-progress'));
              let ref = this.dialogService.getRef();
              ref.afterClosed().subscribe(res => {
                if (res) {
                  //this.dialogService.opened = false;
                  this.stopEtl(this.runningType.ManualStart);
                }
              });
            },
            (err) => {
              throw "manualStart" + err;
            });
        } else {
          let msg = this.translate.instant('etl.message.insert-date');
          //throw new Error(msg);
          this.dialogService.openErrorDialog(msg, true);
        }
        break;
      case this.translate.instant('etl.manual-start-opt.total'):
        body = {
          etlName: this.selectedEtl,
          etlType: ETLType.Total
        };
        this.api.startETLExecution(body).subscribe(() => {
            this.startInterval(ETLRunning.ManualStart);
            this.dialogService.openDialogWithStop(this.translate.instant('etl.manual-start-in-progress'), true, this.translate.instant('etl.in-progress'));
            let ref = this.dialogService.getRef();
            ref.afterClosed().subscribe(res => {
              if (res) {
                //this.dialogService.opened = false;
                this.stopEtl(this.runningType.ManualStart);
              }
            });
          },
          (err) => {
            throw "manualStart" + err;
          });
        break;
      case this.translate.instant('etl.manual-start-opt.total-from-date'):
        if (this.manualStartDate) {
          if (this.manualStartTime) {
            let h: string = this.manualStartTime.split(':')[0];
            let m: string = this.manualStartTime.split(':')[1];
            this.manualStartDate.setHours(Number(h)/* + +2*/);
            this.manualStartDate.setMinutes(Number(m) + Math.abs(this.manualStartDate.getTimezoneOffset()));
          } else {
            this.manualStartDate.setHours(this.manualStartDate.getMinutes() + Math.abs(this.manualStartDate.getTimezoneOffset()));
          }
          body = {
            etlName: this.selectedEtl,
            etlType: ETLType.TotalFromDate,
            fromDate: this.manualStartDate.toISOString()
          };
          this.api.startETLExecution(body).subscribe(() => {
              this.startInterval(ETLRunning.ManualStart);
              this.dialogService.openDialogWithStop(this.translate.instant('etl.manual-start-in-progress'), true, this.translate.instant('etl.in-progress'));
              let ref = this.dialogService.getRef();
              ref.afterClosed().subscribe(res => {
                if (res) {
                  //this.dialogService.opened = false;
                  this.stopEtl(this.runningType.ManualStart);
                }
              });
            },
            (err) => {
              throw "manualStart" + err;
            });
        } else {
          let msg = this.translate.instant('etl.message.insert-date');
          //throw new Error(msg);
          this.dialogService.openErrorDialog(msg, true);
          //throw new Error(this.translate.instant('etl.message.insert-date'));
        }
        break;
    }
  }

  changeFactMaintenance(fact) {
    //console.log(fact);
    this.selectedFact = fact;
  }

  maintenanceStart() {
    //console.log(this.maintenance);
    let body: any;
    switch (this.maintenance) {
      case this.translate.instant('etl.maintenance-opt.delete-year'):
        if (this.selectedYear && Validator.isYearValue(this.selectedYear)) {
          var firtsConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.delete-year-confirm1'));
          firtsConfirm.afterClosed().subscribe((res) => {
            if (res) {
              var secondConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.delete-year-confirm2'));
              secondConfirm.afterClosed().subscribe((res) => {
                if (res) {

                  body = {
                    ftMaintenance: FTMaintenance.DeleteYear,
                    etlName: this.selectedEtl,
                    year: this.selectedYear,
                    fact: this.selectedFact == this.translate.instant('dialog.all-selection') ? '' : this.selectedFact
                  }
                  this.startInterval(ETLRunning.Maintenance);
                  this.dialogService.openDialogWithStop(this.translate.instant('etl.maintenance-in-progress'), true, this.translate.instant('etl.in-progress'));
                  let ref = this.dialogService.getRef();
                  ref.afterClosed().subscribe(res => {
                    if (res) {
                      //this.dialogService.opened = false;
                      this.stopEtl(this.runningType.Maintenance);
                    }
                  });
                  this.api.ftMaintenance(body).subscribe((res) => {
                    //console.log("cancella anno");

                  });

                }
              });
            }
          });
        } else {
          let msg = this.translate.instant('etl.message.insert-year');
          //throw new Error(msg);
          this.dialogService.openErrorDialog(msg, true);
          //throw new Error(this.translate.instant('etl.message.regenerate-year'));
        }
        break;
      case this.translate.instant('etl.maintenance-opt.historicize-year'):
        if (this.selectedYear && Validator.isYearValue(this.selectedYear)) {
          body = {
            ftMaintenance: FTMaintenance.HistoricizesYear,
            etlName: this.selectedEtl,
            year: this.selectedYear,
            fact: this.selectedFact == this.translate.instant('dialog.all-selection') ? '' : this.selectedFact
          }
          this.startInterval(ETLRunning.Maintenance);
          this.dialogService.openDialogWithStop(this.translate.instant('etl.maintenance-in-progress'), true, this.translate.instant('etl.in-progress'));
          let ref = this.dialogService.getRef();
          ref.afterClosed().subscribe(res => {
            if (res) {
              //this.dialogService.opened = false;
              this.stopEtl(this.runningType.Maintenance);
            }
          });
          this.api.ftMaintenance(body).subscribe((res) => {
            //console.log("storicizza anno");

          });
        } else {
          let msg = this.translate.instant('etl.message.insert-year');
          //throw new Error(msg);
          this.dialogService.openErrorDialog(msg, true);
          //throw Error(this.translate.instant('etl.message.insert-year'));
        }
        break;
      case this.translate.instant('etl.maintenance-opt.regenerate-year'):
        if (this.selectedYear && Validator.isYearValue(this.selectedYear)) {
          body = {
            etlName: this.selectedEtl,
            etlType: ETLType.YearRigeneration,
            fromDate: this.getYearRigeneration(this.selectedYear),
            fact: this.selectedFact == this.translate.instant('dialog.all-selection') ? '' : this.selectedFact
          };
          this.startInterval(ETLRunning.Maintenance);
          this.dialogService.openDialogWithStop(this.translate.instant('etl.maintenance-in-progress'), true, this.translate.instant('etl.in-progress'));
          let ref = this.dialogService.getRef();
          ref.afterClosed().subscribe(res => {
            if (res) {
              //this.dialogService.opened = false;
              this.stopEtl(this.runningType.Maintenance);
            }
          });
          this.api.startETLExecution(body).subscribe((res) => {
            //console.log("genera anno");

          });
        } else {
          let msg = this.translate.instant('etl.message.insert-year');
          //throw new Error(msg);
          this.dialogService.openErrorDialog(msg, true);
          //throw new Error(this.translate.instant('etl.message.regenerate-year'));
        }
        break;
      case this.translate.instant('etl.maintenance-opt.reset-dw'):
        var firtsConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.reset-dw-confirm1'));
        firtsConfirm.afterClosed().subscribe((res) => {
          if (res) {
            var secondConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.reset-dw-confirm2'));
            secondConfirm.afterClosed().subscribe((res) => {
              if (res) {
                var thirdConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.reset-dw-confirm3'));
                thirdConfirm.afterClosed().subscribe((res) => {
                  body = {
                    etlName: this.selectedEtl
                  }
                  this.startInterval(ETLRunning.Reset);
                  this.dialogService.openDialogWithStop(this.translate.instant('etl.maintenance-in-progress'), true, this.translate.instant('etl.in-progress'));
                  let ref = this.dialogService.getRef();
                  ref.afterClosed().subscribe(res => {
                    if (res) {
                      //this.dialogService.opened = false;
                      this.stopEtl(this.runningType.Reset);
                    }
                  });
                  this.api.resetDW(body).subscribe((data) => {
                      //console.log("reset");

                    },
                    (err) => {
                      throw err;
                    });
                });
              }
            });
          }
        });
        break;

    }
  }

  getYearRigeneration(year: string) {
    let newDate = new Date();
    newDate.setFullYear(Number(year));
    newDate.setMonth(0);
    newDate.setDate(1);
    return newDate;
  }

  saveEtl(reload: boolean) {
    /*
      this.translate.instant('etl.planning-opt.manual'),
      this.translate.instant('etl.planning-opt.real-time'),
      this.translate.instant('etl.planning-opt.hourly')];
    */
    let recType = this.recurrenceType == this.translate.instant('etl.planning-opt.hourly') ?
      RecurrenceType.Hourly : (this.recurrenceType == this.translate.instant('etl.planning-opt.manual') ? RecurrenceType.Manual : RecurrenceType.RealTime);
    var body;
    if (recType == RecurrenceType.Hourly) {
      body = {
        etlName: this.selectedEtl,
        isMenuAvailable: this.IsMenuAvailable,
        active: this.checked,
        recurrenceType: recType,
        notsharedt: this.NotShareDT,
        language: this.selectedLanguage.value,
        hour1: this.hour1,//this.timeToMs(this.hour1Tick),
        hour2: this.hour2,//this.timeToMs(this.hour2Tick),
        hour3: this.hour3//this.timeToMs(this.hour3Tick)
      }
    } else {
      body = {
        etlName: this.selectedEtl,
        isMenuAvailable: this.IsMenuAvailable,
        active: this.checked,
        recurrenceType: recType,
        notsharedt: this.NotShareDT,
        language: this.selectedLanguage.value
      }
    }

    this.api.updateETL(body).subscribe((data: any) => {
        if (reload) {
          this.getETLInfo(this.etl.indexOf(this.etl.find(etl => etl.name == this.selectedEtl)));
          this.hasChanges = false;
          this.configService.configInstances.setIsMenuAvailable(this.IsMenuAvailable);
          /*if (this.cookieService.check('group')) {
            this.cookieService.delete('isMenuAvailable');
            this.cookieService.set('isMenuAvailable', this.IsMenuAvailable.toString(), new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), '/');
          }*/
        }
      },
      (err) => {
        throw err;
      });
  }

  stopEtl(running: ETLRunning) {
    this.api.stopETLExecution({etlName: this.selectedEtl}).subscribe(() => {
      //console.log("Etl stopped");
      this.stopInterval(running);
      //this.getETLInfo();
    });
  }

  msToTime(duration: string) {
    if (duration) {
      let milliseconds = ((parseInt(duration) % 1000) / 100),
        seconds = Math.floor((parseInt(duration) / 1000) % 60),
        minutes = Math.floor((parseInt(duration) / (1000 * 60)) % 60),
        hours = Math.floor((parseInt(duration) / (1000 * 60 * 60)) % 24);

      let hoursStr = (hours < 10) ? "0" + hours : hours;
      let minutesStr = (minutes < 10) ? "0" + minutes : minutes;
      let secondsStr = (seconds < 10) ? "0" + seconds : seconds;
      //return hoursStr + ":" + minutesStr + ":" + secondsStr + "." + milliseconds;
      return hoursStr + ":" + minutesStr;
    } else return '';
  }

  timeToMs(time: string) {
    //var time = "34:26";
    if (time) {
      var timeParts = time.split(":");
      //console.log((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
      return (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000);
    } else return '';
  }

  getDialogRef(type: MessageType, m: string) {
    let message: string = type == MessageType.Log ? this.etl.find(etl => etl.name == this.selectedEtl).lastLog.split('\n').join('\n') :
      (type == MessageType.Error ? this.etl.find(etl => etl.name == this.selectedEtl).lastError : m);

    return this.dialogService.openMessageDialog(null, null, message);
  }

  openEtlDialog(type: MessageType) {
    let info = type == MessageType.Log ? 0 : (type == MessageType.Error ? 1 : -1);
    this.api.getUpdatedLog({ etlName: this.selectedEtl, type: info }).subscribe((res: any) => {
      let message = res.message;
      let dialogRef = this.dialogService.openMessageDialog(null, null, message, type == this.messageType.Log);
    }, (err) => { throw err; })

  }

  isEtlActive(etl) {
    return this.etl.find(e => e.name == etl).active;
  }

  hour1Changed(e) {
    this.hasChanges = true;
    this.hour1 = e;
  }
  hour2Changed(e) {
    this.hasChanges = true;
    this.hour2 = e;
  }
  hour3Changed(e) {
    this.hasChanges = true;
    this.hour3 = e;
  }

  exportConfig() {
    var firtsConfirm = this.getDialogRef(MessageType.Confirm, 'etl.message.export-msg');
    firtsConfirm.afterClosed().subscribe((res) => {
      if (res) {
        let group = this.configService.configInstances.getGroupName();
        var body: GetUserInterrogationsRQ = {Group: group};
        this.api.getUserInterrogations(body).subscribe((data: any) => {
          this.dialogService.openLoadingDialog(false);
          let dashboardEnd = false;
          let reportEnd = false;
          let surveyEnd = false;
          const zip = new JSZip();
          let dashboards = zip.folder("Dashboards");
          for (let d in data.Dashboards) {
            let name = data.Dashboards[d].Name;
            var body: CopyInterrogationRQ = {
              Id: data.Dashboards[d].Id,
              Type: InterrogationType.Dashboard
            };
            this.api.exportInterrogationJson(body)
              .pipe(catchError(err => {
                throw err;
              }))
              .subscribe((res: any) => {
                var blob = JSON.stringify(res);
                dashboards.file(name + '.json', blob);
                if (data.Dashboards.length - 1 == Number(d)) {
                  dashboardEnd = true;
                }
              });
          }
          let reports = zip.folder("Reports");
          for (let r in data.Reports) {
            let name = data.Reports[r].Name;
            var body: CopyInterrogationRQ = {
              Id: data.Reports[r].Id,
              Type: InterrogationType.Report
            };
            this.api.exportInterrogationJson(body)
              .pipe(catchError(err => {
                throw err;
              }))
              .subscribe((res: any) => {
                var blob = JSON.stringify(res);
                reports.file(name + '.json', blob);
                if (data.Reports.length - 1 == Number(r)) {
                  reportEnd = true;
                }
              });
          }
          let surveys = zip.folder("Surveys");
          for (let s in data.Surveys) {
            let name = data.Surveys[s].Name;
            var body: CopyInterrogationRQ = {
              Id: data.Surveys[s].Id,
              Type: InterrogationType.Smart
            };
            this.api.exportInterrogationJson(body).subscribe({
              next: (res: any) => {
                //var blob = new Blob([res], { type: res.type });
                var blob = JSON.stringify(res);
                surveys.file(name + '.json', blob);
                if (data.Surveys.length - 1 == Number(s)) {
                  surveyEnd = true;
                }
              }, error: (err) => {
                throw err;
              }
            });
          }

          this.exportRunning = setInterval(async () => {
            if (dashboardEnd && reportEnd && surveyEnd) {
              zip.generateAsync({type: "blob"})
                .then(function (content) {
                  // see FileSaver.js
                  saveAs(content, "AnalisiDati.zip");
                });
              clearInterval(this.exportRunning);
              this.dialogService.hideDialog();
            }
          }, 10000)


        });
      }

    });
  }

  importConfig() {
    var firtsConfirm = this.getDialogRef(MessageType.Confirm, this.translate.instant('etl.message.import-msg'));
    firtsConfirm.afterClosed().subscribe((res) => {
      if(res) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(UploadFolderDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.dialogService.openLoadingDialog(false);
            let dashboards = res.files.filter(i => i.content.Type == InterrogationType.Dashboard);
            dashboards = dashboards.map(d => d.content);
            let reports = res.files.filter(i => i.content.Type == InterrogationType.Report);
            reports = reports.map(d => d.content);
            let surveys = res.files.filter(i => i.content.Type == InterrogationType.Smart);
            surveys = surveys.map(d => d.content);
            let deleteOnImport = res.deleteOnImport;
            let bodyConfig = new GetConfigDataRQ;
            console.log("Import Config");
            bodyConfig.dashboards = JSON.stringify(dashboards);
            bodyConfig.reports = JSON.stringify(reports);
            bodyConfig.surveys = JSON.stringify(surveys);
            bodyConfig.deleteOnImport = deleteOnImport;
          
            /*let body = {
              dashboards: JSON.stringify(dashboards),
              reports: JSON.stringify(reports),
              surveys: JSON.stringify(surveys),
              deleteOnImport: deleteOnImport
            }*/

            this.api.importConfig(bodyConfig).subscribe(res => {
              this.updateMenu.onItemAdded$.next('title-dashboard');
              this.dialogService.hideDialog();
            }, (err) => {
              this.dialogService.hideDialog();
              console.log(err);
              throw err;
            })
          }
        });
      }

  });
  }
}
