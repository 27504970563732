import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {EtlComponent} from "./etl/etl/etl.component";
import {authGuard} from "../guards/auth-guard";
import {UserPanelComponent} from "./user-panel/user-panel/user-panel.component";
import {LoginComponent} from "./login/login.component";
import { ScheduledOperationComponent } from './scheduled-operation/scheduled-operation/scheduled-operation.component';
import {SmartSurveysResolver} from "../resolvers/smart-surveys-resolver";
import {DashboardsResolver} from "../resolvers/dashboards-resolver";

const routes: Routes = [
  //{ path: '', redirectTo: '', pathMatch: 'full', canActivate: [authGuard]},
  { path: 'etl',  pathMatch: 'full', component: EtlComponent, canActivate: [authGuard], runGuardsAndResolvers: "always"},
  { path: 'survey',  loadChildren: () => import('./smart-survey/smart-survey.module').then(mod => mod.SmartSurveyModule), canActivate: [authGuard], resolve: { user: SmartSurveysResolver }, runGuardsAndResolvers: 'always'  },
  { path: 'survey/fact/:fact',  loadChildren: () => import('./smart-survey/smart-survey.module').then(mod => mod.SmartSurveyModule), canActivate: [authGuard],  runGuardsAndResolvers: 'always' },
  { path: 'survey/:id', loadChildren: () => import('./smart-survey/smart-survey.module').then(mod => mod.SmartSurveyModule), canActivate: [authGuard], runGuardsAndResolvers: 'always'},
  { path: 'user-panel', pathMatch: 'full', component: UserPanelComponent, canActivate: [authGuard] },
  { path: 'schedule', pathMatch: 'full', component: ScheduledOperationComponent, canActivate: [authGuard] },
  { path: 'schedule/:id', pathMatch: 'full', component: ScheduledOperationComponent, canActivate: [authGuard] },
  { path: 'controllo-gestione', loadChildren: () => import('./controllo-gestione/controllo-gestione.module').then(mod => mod.ControlloGestioneModule), canActivate: [authGuard], runGuardsAndResolvers: 'always'},
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule), canActivate: [authGuard], runGuardsAndResolvers: 'always'  },
  { path: 'dashboard/:id', loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule), canActivate: [authGuard], runGuardsAndResolvers: 'always'},
  { path: 'viewer',  loadChildren: () => import('./report-viewer/report-viewer.module').then(mod => mod.ReportViewerModule), canActivate: [authGuard], runGuardsAndResolvers: 'always'},
  { path: 'viewer/:id',  loadChildren: () => import('./report-viewer/report-viewer.module').then(mod => mod.ReportViewerModule), canActivate: [authGuard], runGuardsAndResolvers: 'always'},
  { path: 'report',  loadChildren: () => import('./report-designer/report-designer.module').then(mod => mod.ReportDesignerModule), canActivate: [authGuard], runGuardsAndResolvers: 'always'},
  { path: 'report/:id',  loadChildren: () => import('./report-designer/report-designer.module').then(mod => mod.ReportDesignerModule), canActivate: [authGuard], runGuardsAndResolvers: 'always' },

  { path: 'login', pathMatch: 'full', component: LoginComponent},
  { path: '**', redirectTo: 'user-panel' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
