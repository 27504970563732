import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ActionType, ImportType, ImportTypeMapping, InterrogationType} from '../../models/enums.model';
import {firstValueFrom} from "rxjs";
import {DwApiService} from "../../services/dw-mart-api.service";
import {DataSource} from "../../models/dialogs-model";

@Component({
  selector: 'app-alter-datasource',
  templateUrl: './alter-datasource.component.html',
  styleUrls: ['../../utils/shared-form-layout.scss']
})
export class AlterDatasourceComponent implements OnInit {

  @Input() interrogationId: string;
  @Input() actionType: ActionType;
  @Input() interrogationType: InterrogationType;
  formGroup: FormGroup;
  datasources: DataSource[];
  importTypes: ImportType[] = Object.values(ImportType).filter(value => typeof value === 'number');
  importTypesMap: typeof ImportTypeMapping = ImportTypeMapping;
  selected: any;
  isReady: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
     @Inject(MAT_DIALOG_DATA) data,
     public translate: TranslateService,
     public dialog: MatDialog,
     private api: DwApiService) {
    //this.actionType = data.actionType;
  }

  async ngOnInit() {
    if(this.actionType == ActionType.DataSource) {
      this.datasources =  await firstValueFrom(this.api.getAvailableDS(this.interrogationId));
      if(this.datasources.length == 1) this.selected = this.datasources[0];
    }
    this.createForm();
  }

  getValue(d: any) {
    return this.actionType == ActionType.Import? ImportTypeMapping[d] : d.Name;
  }

  createForm() {
    if (this.actionType == ActionType.DataSource) {
      this.formGroup = this.formBuilder.group({
        'datasource': this.selected? [this.selected, Validators.required] : [null, Validators.required],
        'importTypes': [null, null]
      });
    } else if (this.actionType == ActionType.Import) {
      this.formGroup = this.formBuilder.group({
        'datasource': [null, null],
        'importTypes': [null, Validators.required]
      });
    }

    this.isReady = true;
  }

  close(): DataSource | ImportType {
    return this.actionType == ActionType.DataSource? this.formGroup.controls['datasource'].value : this.formGroup.controls['importTypes'].value;
  }

  protected readonly ActionType = ActionType;
}

