@if (!data.title) {
  <h1 mat-dialog-title>{{'dialog.error-occurred' | translate}}</h1>
}
@if (data.title) {
  <h1 mat-dialog-title>{{data.title | translate}}</h1>
}
<div mat-dialog-content>
  @if (data.status) {
    <p>
      <b>Status Code: {{data.status}}</b>
    </p>
  }
  @if (data.error) {
    <p>
      {{'dialog.error-msg' | translate}}
    </p>
  }
  <p [ngClass]="{'error-message': data.error}">
    {{data?.message | translate}}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close class="btn-dark">
    {{'dialog.close' | translate}}
  </button>
</div>