import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {AuthenticationService} from "../app/services/authentication.service";
import {JwtService} from "../app/services/jwt.service";
import {DwApiService} from "../app/services/dw-mart-api.service";
import {ConfigService} from "../app/services/config.service";

export function appInitializer(authenticationService: AuthenticationService, cookieService: CookieService, jwtService: JwtService, api: DwApiService, configService: ConfigService, router: Router) {
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  let viewOnly = url.searchParams.get('viewOnly');
  if(viewOnly == null) {
    /*if(cookieService.get('viewOnly'))
      viewOnly = cookieService.get('viewOnly');
    else if(sessionStorage.getItem('viewOnly'))
      viewOnly = sessionStorage.getItem('viewOnly');*/
    var instances = configService.configInstances;
  if (instances.getViewOnly())
    viewOnly = instances.getViewOnly().toString();
  else viewOnly = 'false';
  }
  if (configService.getToken() ||
    token || sessionStorage.getItem('skipRefresh')) {
    return () => new Promise((resolve, reject) => {
      if (token || sessionStorage.getItem('skipRefresh')) {
        jwtService.setUser(token, cookieService.get('currentLang')).subscribe({ next:(data) => {
            api.initialize().subscribe({next: (res) => {

                instances.setViewOnly(viewOnly === 'true');
                instances.setSession(res.Session);
                instances.setProductType(res.ProductType);
                instances.setLicense(res.License);
                instances.setIsMenuAvailable(res.IsMenuAvailable);
                instances.setAdditionalModules(res.AdditionalModules);
                instances.setGroupOptions(res.GroupOptions);
                instances.setFtCount(res.FtCount);
                instances.setOnlineYears(res.OnlineYears);

                /*onst expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
                if (cookieService.check('group')) {
                  cookieService.delete('viewOnly');
                  cookieService.set('viewOnly', viewOnly.toString(), expires, '/');
                  cookieService.delete('productType');
                  cookieService.set('productType', res.ProductType.toString(), expires, '/');
                  cookieService.delete('licenseInfo');
                  cookieService.set('licenseInfo', res.License.toString(), expires, '/');
                  cookieService.delete('isMenuAvailable');
                  cookieService.set('isMenuAvailable', res.IsMenuAvailable.toString(), expires, '/');
                  cookieService.delete('additionalModules');
                  cookieService.set('additionalModules', res.AdditionalModules.toString(), expires, '/');
                  cookieService.delete('homepage');
                  cookieService.set('homepage', res.GroupOptions.Homepage.toString(), expires, '/');
                  cookieService.delete('canCreate');
                  cookieService.set('canCreate', res.GroupOptions.CanCreate.toString(), expires, '/');
                  cookieService.delete('etlRead');
                  cookieService.set('etlRead', res.GroupOptions.ETLRead.toString(), expires, '/');
                  cookieService.delete('canAccessSchOp');
                  cookieService.set('canAccessSchOp', res.GroupOptions.CanAccessSchOp.toString(), expires, '/');
                }*/
                authenticationService.licenseSubject.next(true);
                authenticationService.groupOptionsSubject.next(true);
                api.addUser({id: authenticationService.userValue.Id, name: authenticationService.userValue.Name, group: authenticationService.userValue.Group}).subscribe({
                  next: (d) => {
                    url.pathname? router.navigate([url.pathname]) : router.navigate(['/']);
                  }
                });
              }, error: (err) => {
                authenticationService.groupOptionsSubject.next(false);
                authenticationService.licenseSubject.next(false);
                throw err;
              }});
          }, error:(err) => { throw err; }})
          .add(() => resolve);
      } else {
        authenticationService.refreshToken()
          .subscribe({next:
              (data) => {
                console.log('after refresh');

                api.initialize().subscribe({next: (res) => {
                    instances.setViewOnly(false);
                    instances.setSession(res.Session);
                    instances.setProductType(res.ProductType);
                    instances.setLicense(res.License);
                    instances.setIsMenuAvailable(res.IsMenuAvailable);
                    instances.setAdditionalModules(res.AdditionalModules);
                    instances.setGroupOptions(res.GroupOptions);
                    instances.setFtCount(res.FtCount);
                    instances.setFtCount(res.OnlineYears);

                    /*const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
                    if (cookieService.check('group')) {
                      cookieService.delete('viewOnly');
                      cookieService.set('viewOnly', viewOnly.toString(), expires, '/');
                      cookieService.delete('productType');
                      cookieService.set('productType', res.ProductType.toString(), expires, '/');
                      cookieService.delete('licenseInfo');
                      cookieService.set('licenseInfo', res.License.toString(), expires, '/');
                      cookieService.delete('isMenuAvailable');
                      cookieService.set('isMenuAvailable', res.IsMenuAvailable.toString(), expires, '/');
                      cookieService.delete('additionalModules');
                      cookieService.set('additionalModules', res.AdditionalModules.toString(), expires, '/');
                      cookieService.delete('homepage');
                      cookieService.set('homepage', res.GroupOptions.Homepage.toString(), expires, '/');
                      cookieService.delete('canCreate');
                      cookieService.set('canCreate', res.GroupOptions.CanCreate.toString(), expires, '/');
                      cookieService.delete('etlRead');
                      cookieService.set('etlRead', res.GroupOptions.ETLRead.toString(), expires, '/');
                      cookieService.delete('canAccessSchOp');
                      cookieService.set('canAccessSchOp', res.GroupOptions.CanAccessSchOp.toString(), expires, '/');
                    }*/
                    authenticationService.licenseSubject.next(true);
                    authenticationService.groupOptionsSubject.next(true);
                    api.addUser({id: authenticationService.userValue.Id, name: authenticationService.userValue.Name, group: authenticationService.userValue.Group}).subscribe((d) => {
                      url.pathname? router.navigate([url.pathname]) : router.navigate(['/']);
                    });
                  }, error: (err) => {
                    authenticationService.groupOptionsSubject.next(false);
                    authenticationService.licenseSubject.next(false);
                    throw err;
                  }});
              }, error: (err) => {
              console.log('error 1')
              alert(err);
              throw err;
            }})
          .add(() => resolve);
      }
    }).catch((err) => {
      console.log('error 2');
      alert(err);
      throw err; });
  } else {
    return () => new Promise((resolve, reject) => {
      resolve(false);
    });
  }
}

