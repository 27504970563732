import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {InterrogationType} from "../../models/enums.model";
import {firstValueFrom} from "rxjs";
import {DwApiService} from "../../services/dw-mart-api.service";
import {Validator} from "../../utils/validator";

@Component({
  selector: 'app-upload-default',
  templateUrl: './upload-default.component.html',
  styleUrls: ['./upload-default.component.scss']
})
export class UploadDefaultComponent implements OnInit {

  displayedColumns: string[] = ['select', 'name', 'description'];
  dataSource = new MatTableDataSource<any>();
  selected = undefined;
  empty: boolean = false;
  @Input() interrogationType: InterrogationType;
  constructor(//@Inject(MAT_DIALOG_DATA) data,
              private api: DwApiService) {
    //this.interrogationType = data.interrogationType;
  }

  async ngOnInit() {
    let res = await firstValueFrom(this.api.getDefaultToImport(this.interrogationType));
    let arr = Validator.transform(res);
    this.dataSource = arr;
    this.empty = arr.length == 0;

  }

  onChange(event, row) {
    this.selected = row;
  }

  close() {

  }

}
