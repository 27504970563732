<mat-form-field class="w-100">
  <mat-label>{{ 'schedule.recipient' | translate }}*</mat-label>
  <mat-chip-grid #chipGrid>
    @for (item of model; track item) {
      <mat-chip-row
        (removed)="remove(item)"
        [editable]="true"
        (edited)="edit(item, $event)"
      >
        {{item}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    }
    <input
      [placeholder]="'schedule.recipientPlaceholder' | translate"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
</mat-form-field>