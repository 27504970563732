import {animate, state, style, transition, trigger} from '@angular/animations';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {IsActiveMatchOptions, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import devices from 'devextreme/core/devices';
import {Subscription} from 'rxjs';
import {NavItem} from '../nav-item';
import {NavService} from '../nav.service';
import {animateText} from "../../../animations/animation";
import {DwApiService} from "../../services/dw-mart-api.service";
import {LicenseType, ProductType} from "../../models/enums.model";
import {DialogService} from "../../dialogs/dialog.service";
import {FilteredMenuService} from "../../services/filtered-menu.service";
import {UpdateMenuService} from "../../services/update-menu.service";
import {ConfigService} from "../../services/config.service";
import { GetOrderListRQ } from 'src/app/models/web-api-models';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [animateText,
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit, AfterViewInit {
  routeParams: IsActiveMatchOptions = {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'};
  onItemAdded: Subscription;
  expanded: boolean;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() state: boolean;
  //@Output() updateList: EventEmitter<any> = new EventEmitter<any>();
  isMenuActive: boolean = false;
  visibility: string = 'always';
  height: string = 'auto';
  screenHeight: number = 0;
  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(
    public navService: NavService,
    public router: Router,
    public translate: TranslateService,
    public filterMenuService: FilteredMenuService,
    private api: DwApiService,
    private dialogService: DialogService,
    private updateMenuService: UpdateMenuService,
    private configService: ConfigService) {
      this.isMobile = devices.current().deviceType === 'phone';
      this.isTablet = devices.current().deviceType === 'tablet';
      if (this.depth === undefined) {
        this.depth = 0;
      }
      if (this.filterMenuService.currentState) this.expanded = this.filterMenuService.currentState;
        this.filterMenuService.onFilterApplied$.subscribe(e => {
      });
      this.onItemAdded = this.updateMenuService.onItemAdded$.subscribe(e => {
        this.isMobile = devices.current().deviceType === 'phone';
        this.isTablet = devices.current().deviceType === 'tablet';
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenHeight = event.target.innerHeight;
  }

  isMenu() {
    return this.configService.configInstances.getProductType() == ProductType.Menu;
  }

  isRetail() {
    return this.configService.configInstances.getProductType() == ProductType.Retail;
  }

  isWelcome() {
    return this.configService.configInstances.getProductType() == ProductType.Welcome;
  }
  isMenuIcon(item) {
    return item.productType == ProductType.Menu;
  }

  isRetailIcon(item) {
    return item.productType == ProductType.Retail;
  }

  isWelcomeIcon(item) {
    return item.productType == ProductType.Welcome;
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = this.item.route == 'report' ? url.indexOf(`/viewer`) === 0 || url.indexOf('/report') === 0 : url.indexOf(`/${this.item.route}`) === 0;
        if (this.filterMenuService.currentState) this.expanded = this.filterMenuService.currentState;
        //questo per renderla sempre chiusa
        //this.expanded = false;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  isActive() {
    return this.router.isActive(this.item.route, this.routeParams) ||
      this.router.isActive(this.item.secondaryRoute, this.routeParams) ||
      this.router.isActive(this.item.thirdRoute, this.routeParams);
  }

  ngAfterViewInit() {
    this.screenHeight = window.innerHeight;
  }

  onItemSelected(item: NavItem, depth) {
    if (depth == 0 && item.children && this.state == false) {

      this.height = 'auto';
      let h = 0;
      if (item.displayName == this.translate.instant('dashboard.route-title')) {
        h = 40;
      } else if (item.displayName == this.translate.instant('report.route-title')) {
        h = 84;
      } else if (item.displayName == this.translate.instant('survey.route-title')){
        h = 128;
      } else {
        h = 172;
      }
      if (Number(this.height) > (this.screenHeight - h - 175) || this.height == 'auto') {
        //this.height = '350px';
        this.height = this.screenHeight - h - 175 + 'px';
      }
      this.matMenuTrigger.openMenu();
      this.matMenuTrigger.closeMenu();
      this.matMenuTrigger.openMenu();
      this.isMenuActive = true;
      this.matMenuTrigger.menuClosed.subscribe(e => this.isMenuActive = false);
    } else if (depth == 1 && item.children && this.state == false) {
      //quando apro nuova dashboard/report dal menu chiuso
      this.dialogService.openLoadingDialog(false);
      this.router.navigate([item.route]);
    } else if (!item.disabled && !item.canAdd && !item.children) {
      this.dialogService.openLoadingDialog(false);
      this.router.navigate([item.route]);
      if (this.isMobile) this.navService.sideNavState$.next(false);
    }
  }
  onAddSelected(item: NavItem) {
    if (!item.disabled && item.canAdd) {
      if(this.configService.configInstances.getLicense() == LicenseType.Basic) {
        if(item.displayName == this.translate.instant('dashboard.route-title')) {
          this.dialogService.openErrorDialog('commercial-messages.dashboard-new', true, undefined, 'survey.notice');
        } else if(item.displayName == this.translate.instant('report.route-title')) {
          this.dialogService.openErrorDialog('commercial-messages.report-new', true, undefined, 'survey.notice');
        } else if(item.displayName == this.translate.instant('survey.route-title')) {
          this.dialogService.openErrorDialog('commercial-messages.smart-survey-new', true, undefined, 'survey.notice');
        }
      } else {
        this.dialogService.openLoadingDialog(false);
        this.router.navigate([item.route]);
      }
      //this.itemActive.emit(item);
    }
  }
  onIconSelected(item: NavItem) {
    //this.updateList.emit();
    if (item.children && this.state)
      this.expanded = !this.expanded;
  }

  drop(event: CdkDragDrop<any[], any>) {
    if(!this.isMobile && !this.isTablet) {
    const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    this.dialogService.getDialogRefDeactivate(this.translate.instant('dialog.change-order')).subscribe(res => {
      if (res) {
        moveItemInArray(this.item.children, event.previousIndex, event.currentIndex);
        // fare chiamata per aggiornare l'ordine sul db
        // riscrivere l'ordine dentro children
        let order = 0;
        this.item.children.forEach(c => c.order = order += 1);
        //console.log(this.item.children);
        let orderList:  GetOrderListRQ[] = [];
        this.item.children.forEach(c => orderList.push({ id: c.id, order: c.order }));
        //console.log(orderList);
        this.api.updateOrder(orderList/*{ orderList: JSON.stringify(orderList) }*/).subscribe((res) => {

        }, (err) => { throw err });
      }
    });
  }
  }

  getTop(item: NavItem) {
    if(item.displayName == this.translate.instant('dashboard.route-title')) {
      return '0px';
    } else if(item.displayName == this.translate.instant('report.route-title')) {
      return '62px';
    } else if(item.displayName == this.translate.instant('survey.route-title')) {
      return '124px';
    }
    return '186px';
  }

}
