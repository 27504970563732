@if (user && isDemo) {
  <div [ngClass]="watermarkClass()">{{demoText}}</div>
}
@if (isMobile && user) {
  <app-top-nav></app-top-nav>
}
<mat-sidenav-container autosize class="sidenav-container" (backdropClick)="close('backdrop')"
  [ngClass]="{'sidenav-container': onSideNavChange == undefined || !user || !isMobile, 'sidenav-container-mobile': (onSideNavChange != undefined && isMobile) || (onSideNavChange == undefined && isMobile && user)}">
  @if (user) {
    <mat-sidenav  [mode]="mode" [opened]="(onSideNavChange || !isMobile) && !isViewOnly" class="sidenav">
      <app-menu-list (logoutEvent)="clearInterval()"></app-menu-list>
    </mat-sidenav>
  }
  <mat-sidenav-content
                       [@onMainContentChange]="
  onSideNavChange == undefined ? 'notvisible':
    (onSideNavChange == true && !isMobile && !isViewOnly ?'open':
      (onSideNavChange == true && isMobile? 'close':
      onSideNavChange == true && isViewOnly? 'notvisible' :
        (onSideNavChange == false && (isMobile || isViewOnly)? 'notvisible' :'close')
      )
    )">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

