import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {InterrogationDescription, InterrogationName} from "../../models/dialogs-model";

@Component({
  selector: 'app-interrogation-description',
  templateUrl: './interrogation-description.component.html',
  styleUrls: ['../../utils/shared-form-layout.scss']
})
export class InterrogationDescriptionComponent implements OnInit {

  formGroup: FormGroup;
  @Input() description: string = '';
  isModified: boolean = false;

  constructor(private formBuilder: FormBuilder, /*@Inject(MAT_DIALOG_DATA) data,*/ private translate: TranslateService) {
    //this.description = data.description;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'description': this.description.length > 0? [''+this.description+'', Validators.required] : [null, null],
    });
    this.formGroup.controls['description'].setValidators(this.descriptionValidator());
    this.onChanges();
  }

  onChanges(): void {
    this.formGroup.valueChanges.subscribe(val => {
      this.isModified = true;
    });
  }

  descriptionValidator(): ValidatorFn {
    let validateExpression = (): ValidationErrors => {
      let description: string = this.formGroup.controls['description'].value;
      if(description == '' || description == null || description.length > 200) {
        this.formGroup.controls['description'].setErrors({ message: this.translate.instant('dialog.max-char-descr-msg') });
        return { message: this.translate.instant('dialog.max-char-descr-msg') };
      } else  {
        this.formGroup.controls['description'].setErrors(null);
        return null;
      }
    }
    return validateExpression;
  }

  close(): InterrogationDescription {
    let ret = new InterrogationDescription();
    ret.Description =  this.formGroup.get('description').value;
    return ret;
  }
}
