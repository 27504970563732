import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDrawerMode } from "@angular/material/sidenav";
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { TranslateService } from "@ngx-translate/core";
import dayjs from "dayjs";
import 'dayjs/locale/it';
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import utc from 'dayjs/plugin/utc';
import weekOfYear from "dayjs/plugin/weekOfYear";
import devices from 'devextreme/core/devices';
import { loadMessages, locale } from "devextreme/localization";
import itMessages from "devextreme/localization/messages/it.json";
import { catchError, Subscription } from "rxjs";
import { onMainContentChange } from "../animations/animation";
import { NavService } from "./menu/nav.service";
import { LicenseType, ProductType } from "./models/enums.model";
import { User } from "./models/user";
import { UpdateUserRQ } from './models/web-api-models';
import { AuthenticationService } from "./services/authentication.service";
import { ConfigService } from "./services/config.service";
import { DwApiService } from "./services/dw-mart-api.service";
import themes from "devextreme/ui/themes";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [onMainContentChange]
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

  user: User;
  public onSideNavChange: boolean = undefined;
  theme: string = '';
  isMenu: boolean = false;
  isRetail: boolean = false;
  isWelcome: boolean = false;
  isViewOnly: boolean = true;
  isMobile: boolean = false;
  mode: MatDrawerMode;
  opened: boolean = false;
  demoText: string = "";
  interval: any;
  userSub: Subscription;

  public screenWidth: number = 0;
  public screenHeight: number = 0;

  isDemo: boolean = false;
  elem: any;

  updateUserInterval: any;
  constructor(
    private translateService: TranslateService,
    private navService: NavService,
    private authService: AuthenticationService,
    private api: DwApiService,
    private router: Router,
    private configService: ConfigService
  ) {
    dayjs.extend(localeData);
    dayjs.extend(duration);
    dayjs.extend(weekOfYear);
    dayjs.extend(utc);
    dayjs.locale('it');
    this.translateService.addLangs(['it', 'en']);
    this.translateService.use('it');
    loadMessages(itMessages);
    locale(navigator.language);

    this.isMobile = devices.current().deviceType === 'phone';//this.deviceService.isMobile();
    this.mode = this.isMobile ? 'over' : 'side';
    this.elem = document.documentElement;


    this.navService.sideNavState$.subscribe(res => {
      this.onSideNavChange = res;
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url.split('/')[1] == 'viewer' && this.configService.configInstances.getLicense() == LicenseType.FullDemo) {
          this.isDemo = false;
        } else if (this.configService.configInstances.getLicense() == LicenseType.FullDemo) {
          this.isDemo = true;
        }
      }
    });
  }

  ngOnInit() {
    this.userSub = this.authService.user.subscribe({
      next: x => {
        if (x) {
          this.authService.license.subscribe({
            next: y => {
              if (y) {
                this.isDemo = this.configService.configInstances.getLicense() == LicenseType.FullDemo
                this.start(x);
              }
            },
            error: (err) => {
              this.user = null;
              this.onSideNavChange = undefined;
            }
          });
        } else {
          this.user = null;
          this.onSideNavChange = undefined;
          clearInterval(this.updateUserInterval);
        }
      }, error: (err) => {
        console.log('err');
        this.user = null;
        this.onSideNavChange = undefined;
      }
    });
  }

  start(x) {
    this.authService.gropOptions.subscribe({
      next:
        (g) => {
          if (g) {
            this.opened = !this.isMobile;
            this.mode = this.isMobile ? 'over' : 'side';
            this.user = x;

            this.isMenu = this.configService.configInstances.getProductType() == ProductType.Menu;
            this.isRetail = this.configService.configInstances.getProductType() == ProductType.Retail;
            this.isWelcome = this.configService.configInstances.getProductType() == ProductType.Welcome;
            this.isViewOnly = this.configService.configInstances.getViewOnly();
            /*if (this.cookieService.check('productType')) {
              this.isMenu = this.configService.getProductType() == ProductType.Menu;
              this.isRetail = this.configService.getProductType() == ProductType.Retail;
              this.isWelcome = this.configService.getProductType() == ProductType.Welcome;
              this.isViewOnly = this.configService.getViewOnly();
              this.configService.setProductType(Number(this.cookieService.get('productType')) as ProductType);
            }
            else {
              this.isMenu = Number(sessionStorage.getItem('productType')) == ProductType.Menu;
              this.isRetail = Number(sessionStorage.getItem('productType')) == ProductType.Retail;
              this.isWelcome = Number(sessionStorage.getItem('productType')) == ProductType.Welcome;
              this.isViewOnly = sessionStorage.getItem('viewOnly') === 'true';
            }*/


            if (this.isMenu) {
              this.demoText = "MENU ANALYTICS DEMO";
              const temp1 = document.createElement('link');
              const temp2 = document.createElement('link');
              const temp3 = document.createElement('link');
              const temp4 = document.createElement('link');
              /*temp1.innerHTML = '<link id="m1" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/menu/dx.menu-theme.css">';
              temp2.innerHTML = '<link id="m2" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/menu/dx-analytics.menu-theme.css" />';
              temp3.innerHTML = '<link id="m3" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/menu/dx-dashboard.menu-theme.css" />';
              temp4.innerHTML = '<link id="m3" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/menu/dx-dashboard.menu-theme.css" />';
              temp4.innerHTML = '<link id="m4" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/menu/reporting.menu3.custom.css" />';*/
              const head = document.head;
              while (temp1.firstChild) {
                head.appendChild(temp1.firstChild);
              }
              while (temp2.firstChild) {
                head.appendChild(temp2.firstChild);
              }
              while (temp3.firstChild) {
                head.appendChild(temp3.firstChild);
              }
              while (temp4.firstChild) {
                head.appendChild(temp4.firstChild);
              }
              this.theme = 'menu-theme';
              //document.getElementById('themeAnalytics').setAttribute('href', 'assets/css/custom-themes/menu/dx-analytics.' + this.theme + '.css');
              //document.getElementById('themeDashboard').setAttribute('href', 'assets/css/custom-themes/menu/dx-dashboard.' + this.theme + '.css');
              //document.getElementById('themeReport').setAttribute('href', 'assets/css/custom-themes/menu/reporting.menu3.custom.css');
              //themes.current('generic.menu');
            } else if (this.isRetail) {
              this.demoText = "RETAIL ANALYTICS DEMO";
              const temp1 = document.createElement('link');
              const temp2 = document.createElement('link');
              const temp3 = document.createElement('link');
              const temp4 = document.createElement('link');
              /*temp1.innerHTML = '<link id="r1" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/retail/dx.retail-theme.css" />';
              temp2.innerHTML = '<link id="r2" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/retail/dx-analytics.retail-theme.css" />';
              temp3.innerHTML = '<link id="r3" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/retail/dx-dashboard.retail-theme.css" />';
              temp4.innerHTML = '<link id="r4" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/retail/reporting.retail3.custom.css" />';*/
              const head = document.head;
              while (temp1.firstChild) {
                head.appendChild(temp1.firstChild);
              }
              while (temp2.firstChild) {
                head.appendChild(temp2.firstChild);
              }
              while (temp3.firstChild) {
                head.appendChild(temp3.firstChild);
              }
              while (temp4.firstChild) {
                head.appendChild(temp4.firstChild);
              }
              this.theme = 'retail-theme';
              //document.getElementById('themeAnalytics').setAttribute('href', 'assets/css/custom-themes/retail/dx-analytics.' + this.theme + '.css');
              //document.getElementById('themeDashboard').setAttribute('href', 'assets/css/custom-themes/retail/dx-dashboard.' + this.theme + '.css');
              //document.getElementById('themeReport').setAttribute('href', 'assets/css/custom-themes/retail/reporting.retail3.custom.css');
              //themes.current('generic.greenmist');
              //themes.current('generic.retail');
            } else if (this.isWelcome) {
              this.demoText = "WELCOME ANALYTICS DEMO";
              const temp1 = document.createElement('link');
              const temp2 = document.createElement('link');
              const temp3 = document.createElement('link');
              const temp4 = document.createElement('link');
              /*temp1.innerHTML = '<link id="w1" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/welcome/dx-analytics.welcome-theme.css" />';
              temp2.innerHTML = '<link id="w2" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/welcome/dx.welcome-theme.css" />';
              temp3.innerHTML = '<link id="w3" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/welcome/dx-dashboard.welcome-theme.css" />';
              temp4.innerHTML = '<link id="w4" rel="stylesheet" type="text/css" href="../assets/css/custom-themes/welcome/reporting.welcome3.custom.css" />';*/
              const head = document.head;
              while (temp1.firstChild) {
                head.appendChild(temp1.firstChild);
              }
              while (temp2.firstChild) {
                head.appendChild(temp2.firstChild);
              }
              while (temp3.firstChild) {
                head.appendChild(temp3.firstChild);
              }
              while (temp4.firstChild) {
                head.appendChild(temp4.firstChild);
              }
              this.theme = 'welcome-theme';
              //document.getElementById('themeAnalytics').setAttribute('href', 'assets/css/custom-themes/welcome/dx-analytics.' + this.theme + '.css');
              //document.getElementById('themeDashboard').setAttribute('href', 'assets/css/custom-themes/welcome/dx-dashboard.' + this.theme + '.css');
              //document.getElementById('themeReport').setAttribute('href', 'assets/css/custom-themes/welcome/reporting.welcome3.custom.css');
              //themes.current('generic.softblue');
              //themes.current('generic.welcome');
            }
            this.updateUserInterval = setInterval(() => this.updateUser(), 45000);
          }

        },
      error: (err) => {
        this.authService.groupOptionsSubject.next(false);
        //console.log("Errore addAllGroupOptions in app.component.ts");
        throw err;
      }
    });
  }

  private updateUser() {
    if (this.authService.userValue) {
      const user = this.authService.userValue;
      this.api.updateUser(new UpdateUserRQ(user.Id, user.Group, user.Name))
        .pipe(catchError(err => {
          if (err instanceof HttpErrorResponse && err.status == (environment.production ? 0 : 500))
            this.clearInterval();

          throw err;
        })
      ).subscribe();
    }
  }


  clearInterval() {
    clearInterval(this.updateUserInterval);
    //this.router.navigate(['/login']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
    this.screenHeight = event.target.innerHeight;
  }

  close(reason: string) {
    if (this.isMobile) {
      this.navService.sideNavState$.next(false);
    }
  }

  watermarkClass() {
    let cssClass = '';
    if (this.isMenu)
      cssClass = 'watermark-menu';
    else if (this.isRetail)
      cssClass = 'watermark-retail';
    else if (this.isWelcome)
      cssClass = 'watermark-welcome';
    //if (this.deviceService.isMobile()) cssClass = cssClass + '-mobile';
    //else if (this.deviceService.isTablet()) cssClass = cssClass + '-tablet';
    return cssClass;
  }

  ngAfterViewInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    clearInterval(this.updateUserInterval);
    this.userSub.unsubscribe();
    if (this.authService.userValue && this.configService.configInstances.getSession()) {
      let user = new User();
      user.Id = this.authService.userValue.Id;
      user.Name = this.authService.userValue.Name;
      user.Group = this.authService.userValue.Group;
      this.api.removeUser(user).subscribe();
    }
  }

}
