<div class="snackbar-error">
  <!--<div>{{'snackbar.message' | translate}}</div>-->
  @if (names.length == 1) {
    <div [translate]="'snackbar.message'" [translateParams]="{names: etlNames}"></div>
  }
  @if (names.length > 1) {
    <div [translate]="'snackbar.message-multi'" [translateParams]="{names: etlNames}"></div>
  }
  <!--<p> (</p>
  <div *ngFor="let n of names">{{n }} </div>
  <p>) </p>-->
  @if (canRead) {
    <div class="viewError">
    <button mat-raised-button (click)="goToEtl()" style="color: orange;
    background: transparent;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-weight: bold;
    font-size: 15px;">
        {{'snackbar.check' | translate}}
        <!--<mat-icon>launch</mat-icon>-->
      </button>
    </div>
  }
  <div class="dismiss">

    <button mat-raised-button (click)="sbRef.dismiss()" style="color: red;
    background: transparent;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-weight: bold;
    font-size: 15px;">
      {{'dialog.close' | translate}}
      <!--<mat-icon>Dismiss</mat-icon>-->
    </button>
  </div>
</div>