import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationService} from "../app/services/authentication.service";
import {DwApiService} from "../app/services/dw-mart-api.service";
import {appInitializer} from '../initializers/app-initializer';
import {JwtService} from '../app/services/jwt.service';
import {ConfigService} from "../app/services/config.service";
import {User} from "../app/models/user";
import {LicenseType} from "../app/models/enums.model";

export const authGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {

  let router: Router = inject(Router);
  let authenticateService: AuthenticationService = inject(AuthenticationService);
  let api: DwApiService = inject(DwApiService);
  let cookieService: CookieService = inject(CookieService);
  let jwtService: JwtService = inject(JwtService);
  let configService: ConfigService = inject(ConfigService);

  var user: User = authenticateService.userValue;

  if (!user) {
    await appInitializer(authenticateService, cookieService, jwtService, api, configService, router)();
    user = authenticateService.userValue;
  }
  if (user) {
    const gOptions = authenticateService.gOptionsValue;
    if (gOptions) {
      let etlRead = configService.configInstances.getEtlRead();
      let homepage = configService.configInstances.getHomepage();
      let licenseInfo = configService.configInstances.getLicense();
      let canCreate = configService.configInstances.getCanCreate();
      let canAccessSchOp = configService.configInstances.getCanAccessSchOp();
      let isNotFullLicense = !(licenseInfo == LicenseType.Full || licenseInfo == LicenseType.FullDemo);
      if ((state.url == '/dashboard' && (/*sessionStorage.getItem('device') === 'phone' || sessionStorage.getItem('device') === 'tablet' ||*/ isNotFullLicense || !canCreate)) ||
        (state.url == '/report' && (/*sessionStorage.getItem('device') === 'phone' || sessionStorage.getItem('device') === 'tablet' ||*/ isNotFullLicense || !canCreate)) ||
        (state.url.startsWith('/report/') && (/*sessionStorage.getItem('device') === 'phone' || sessionStorage.getItem('device') === 'tablet' ||*/ isNotFullLicense)) ||
        (state.url == '/survey' && (/*sessionStorage.getItem('device') === 'phone' || sessionStorage.getItem('device') === 'tablet' ||*/ isNotFullLicense || !canCreate)) ||
        (state.url == '/etl' && !etlRead) ||
        (state.url == '/schedule' && !canAccessSchOp && isNotFullLicense)) {
        if (homepage != null) {
          router.navigate([homepage]);
          return true;
        } else {
          router.navigate(['/user-panel']);
          return true;
        }
        //res(false);
      } else if (state.url == '\/') {
        if (homepage != null) {
          router.navigate([homepage]);
          return true;
        } else {
          router.navigate(['/user-panel']);
          return false;
        }
      } else {
        return true;
      }
    } else return false;
  } else {
    // not logged in so redirect to login page with the return url
    if (!state.url.includes('login')) {
      router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    } else return true;
  }
}
