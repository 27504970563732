import {FieldType, FunctionType} from "./enums.model";

const EMPTY_STRING: string =  '';
export class FieldNode {
  name: string;
  title: string;
  tableTitle: string;
  parentTitle?: string;
  parentName?: string;
  level: number;
  isMeasure: boolean;
  isEnum: boolean;
  type: string;
  expandable: boolean;
  functionType?: FunctionType;
  fieldType?: FieldType;
  fk?: string;
  preview: string;
  grouped: boolean = true;
  format: string;
  isCalculatedField: boolean;
  sqlExpression: string;
  sqlServerExpression: string;
  mySqlExpression: string;

  static setCFFieldNode(name: string, sqlExpression: string, sqlServerExpression: string, mySqlExpression: string, functionType: FunctionType) : FieldNode {
      let cfNode: FieldNode = new FieldNode();
      cfNode.name = name;
      cfNode.title = name;
      cfNode.level = 1;
      cfNode.tableTitle = EMPTY_STRING;
      cfNode.parentName = EMPTY_STRING;
      cfNode.parentTitle = EMPTY_STRING;
      cfNode.expandable = false;
      cfNode.isMeasure = functionType != FunctionType.None;
      cfNode.isEnum = false;
      cfNode.preview = name;
      cfNode.functionType = functionType != FunctionType.None? functionType : undefined;
      cfNode.fieldType = FieldType.Calculated;
      cfNode.type = functionType == FunctionType.None? 'string' : 'int';
      cfNode.grouped = true;
      cfNode.format = EMPTY_STRING;
      cfNode.isCalculatedField = true;
      cfNode.sqlExpression = sqlExpression;
      cfNode.sqlServerExpression = sqlServerExpression;
      cfNode.mySqlExpression = mySqlExpression;
    return cfNode;
  }
}

export class IndexedFieldNode {
  Field: FieldNode;
  Index: number;
}
