@if (title) {
  <h1 mat-dialog-title class="title">{{ title | translate }}</h1>
}
<div mat-dialog-content>
  @if (subtitle) {
    <p>
      {{ subtitle | translate }}
    </p>
  }
  @if (message) {
    <p class="message" [ngClass]="{'error-message': showTextContainer}">
      {{message | translate}}
    </p>
  }
</div>
<div mat-dialog-actions align="end">
  @if (!btn1 && !btn2) {
    <button mat-raised-button mat-dialog-close class="btn-light">{{ 'dialog.close' | translate }}</button>
    <button mat-raised-button [mat-dialog-close]="true" class="btn-dark">{{ 'dialog.ok' | translate }}</button>
  } @else {
    @if (btn2) {
      <button mat-raised-button [mat-dialog-close]="btn2.Action()" class="btn-light">{{ btn2.Text | translate }}</button>
    }
    @if (btn1) {
      <button mat-raised-button [mat-dialog-close]="btn1.Action()" class="btn-dark">{{ btn1.Text | translate }}</button>
    }
  }
</div>