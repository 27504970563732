//types
//['int', 'float', 'decimal']

import { TranslateService } from "@ngx-translate/core";
import { CFFunctionType } from "./enums.model";

//['varchar', 'char']
export class CFFunction {
    name: string;
    baseValue: string;
    preview: string;
    sqlServerValue: string;
    mySqlValue: string;
    description: string;
    acceptedTypes: string[];
    functionType: CFFunctionType;
    children?: CFFunction[];

    constructor(name: string, baseValue?: string, preview?: string, sqlServerValue?: string, mySqlValue?: string, description?: string, acceptedTypes?: string[], functionType?: CFFunctionType, children?: CFFunction[]) {

        this.name = name;
        if(baseValue) this.baseValue = baseValue;
        if(preview) this.preview = preview;
        if(sqlServerValue) this.sqlServerValue = sqlServerValue;
        if(mySqlValue) this.mySqlValue = mySqlValue;
        if(description) this.description = description;
        if(acceptedTypes) this.acceptedTypes = acceptedTypes;
        if(functionType) this.functionType = functionType;
        if(children) this.children = children;
    }

    static getDefaultFunctions(translate: TranslateService) : CFFunction[] {
        let functions: CFFunction[] = [];

        //funzioni di aggregazione
        let aggrFunctions: CFFunction = new CFFunction(translate.instant('calculated-fields.functions.aggr.title'));
        let aggrChildren: CFFunction[] = [];
        let sum: CFFunction = new CFFunction("Sum", "Sum()", "Sum(Metrica)", "Sum()", "Sum()", translate.instant('calculated-fields.functions.aggr.sum'), ['int', 'float', 'decimal'], CFFunctionType.Aggregate);
        let avg: CFFunction = new CFFunction("Avg", "Avg()", "Avg(Metrica)", "Avg()", "Avg()", translate.instant('calculated-fields.functions.aggr.avg'), ['int', 'float', 'decimal'], CFFunctionType.Aggregate);
        let count: CFFunction = new CFFunction("Count", "Count()", "Count(Metrica)", "Count()", "Count()", translate.instant('calculated-fields.functions.aggr.count'), ['int', 'float', 'decimal'], CFFunctionType.Aggregate);
        let max: CFFunction = new CFFunction("Max", "Max()", "Max(Metrica)", "Max()", "Max()", translate.instant('calculated-fields.functions.aggr.max'), ['int', 'float', 'decimal'], CFFunctionType.Aggregate);
        let min: CFFunction = new CFFunction("Min", "Min()", "Min(Metrica)", "Min()", "Min()", translate.instant('calculated-fields.functions.aggr.min'), ['int', 'float', 'decimal'], CFFunctionType.Aggregate);
        let countdistinct: CFFunction = new CFFunction("CountDistinct", "CountDistinct()", "CountDistinct(Metrica)", "Count( distinct )", "Count( distinct )", translate.instant('calculated-fields.functions.aggr.countdistinct'), ['int', 'float', 'decimal'], CFFunctionType.Aggregate);
        aggrChildren.push(sum, avg, count, max, min, countdistinct);
        aggrFunctions.functionType = CFFunctionType.Aggregate;
        aggrFunctions.children = aggrChildren;

        //funzioni logiche
        let logicFunctions: CFFunction = new CFFunction(translate.instant('calculated-fields.functions.logic.title'));
        let logicChildren: CFFunction[] = [];
        let iif: CFFunction = new CFFunction("Iif", "Iif(, ,)", "Iif(Espressione, ParteVera, ParteFalsa)", "Iif(, ,)", "Iif(, ,)", translate.instant('calculated-fields.functions.logic.iif'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Logic);
        let isNull: CFFunction = new CFFunction("IsNull", "IsNull()", "IsNull(Valore)", "IsNull()", "IsNull()", translate.instant('calculated-fields.functions.logic.isnull'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Logic);
        let isNullOrEmpty: CFFunction = new CFFunction("IsNullOrEmpty", "IsNullOrEmpty()", "IsNullOrEmpty(Stringa)", "IsNullOrEmpty()", "IsNullOrEmpty()", translate.instant('calculated-fields.functions.logic.isnullorempty'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Logic);
        logicChildren.push(iif, isNull, isNullOrEmpty);
        logicFunctions.functionType = CFFunctionType.Logic;
        logicFunctions.children = logicChildren;

        //funzioni matematiche
        let mathFunctions: CFFunction = new CFFunction(translate.instant('calculated-fields.functions.math.title'));
        let mathChildren: CFFunction[] = [];
        let abs: CFFunction = new CFFunction("Abs", "Abs()", "Abs(Valore)", "ABS()", "Abs()", translate.instant('calculated-fields.functions.math.abs'), ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let sqr: CFFunction = new CFFunction("Sqr", "Sqr()", "Sqr(Valore)", "SQRT()", "SQRT()", translate.instant('calculated-fields.functions.math.sqr'), ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let cos: CFFunction = new CFFunction("Cos", "Cos()", "Cos(Valore)", "COS()", "COS()", translate.instant('calculated-fields.functions.math.cos'), ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let sin: CFFunction = new CFFunction("Sin", "Sin()", "Sin(Valore)", "SIN()", "SIN()", translate.instant('calculated-fields.functions.math.sin'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let atn: CFFunction = new CFFunction("Atn", "Atan()", "Atn(Valore)", "ATAN()", "ATAN()", translate.instant('calculated-fields.functions.math.atn'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let exp: CFFunction = new CFFunction("Exp", "Exp()", "Exp(Valore)", "EXP()", "EXP()", translate.instant('calculated-fields.functions.math.exp'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let log_naturale: CFFunction = new CFFunction("Log naturale", "Log()", "Log(Valore)", "LOG()", "LOG()", translate.instant('calculated-fields.functions.math.log-nat'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let log_base: CFFunction = new CFFunction("Log", "Log(,)", "Log(Valore, Base)", "LOG(,)", "LOG(,)", translate.instant('calculated-fields.functions.math.log-base'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let tan: CFFunction = new CFFunction("Tan", "Tan()", "Tan(Valore)", "TAN()", "TAN()", translate.instant('calculated-fields.functions.math.tan'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let power: CFFunction = new CFFunction("Power", "Power(,)", "Power(Valore, Potenza)", "POWER(,)", "POWER(,)", translate.instant('calculated-fields.functions.math.power'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let sign: CFFunction = new CFFunction("Sign", "Sign()", "Sign(Valore)", "SING()", "SING()", translate.instant('calculated-fields.functions.math.sign'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        let round: CFFunction = new CFFunction("Round Intero", "Round()", "Round(Valore)", "ROUND()", "ROUND()", translate.instant('calculated-fields.functions.math.round'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        let round_decimal: CFFunction = new CFFunction("Round", "Round(,)", "Round(Valore, Precisione)", "ROUND(,)", "ROUND(,)", translate.instant('calculated-fields.functions.math.round-decimal'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        let ceiling: CFFunction = new CFFunction("Ceiling", "Ceiling()", "Ceiling(Valore)", "CEILING()", "CEILING()", translate.instant('calculated-fields.functions.math.ceiling'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        let floor: CFFunction = new CFFunction("Floor", "Floor()", "Floor(Valore)", "FLOOR()", "FLOOR()", translate.instant('calculated-fields.functions.math.floor'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let acos: CFFunction = new CFFunction("Acos", "Acos()", "Acos(Valore)", "ACOS()", "ACOS()", translate.instant('calculated-fields.functions.math.acos'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let asin: CFFunction = new CFFunction("Asin", "Asin()", "Asin(Valore)", "ASIN()", "ASIN()", translate.instant('calculated-fields.functions.math.asin'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let cosh: CFFunction = new CFFunction("Cosh", "Cosh()", "C()", "", translate.instant('calculated-fields.functions.logic.isnullorempty'),  ['int', 'float', 'decimal']);
        //let log10: CFFunction = new CFFunction("Log10", "Log10()", "Log10(Valore)", "LOG10()", "LOG10()", translate.instant('calculated-fields.functions.math.log10'),  ['int', 'float', 'decimal'], CFFunctionType.Math);
        //let sinh: CFFunction = new CFFunction("Sinh", "Sinh()", "Sinh()", "", translate.instant('calculated-fields.functions.logic.isnullorempty'),  ['int', 'float', 'decimal']);
        //let tanh: CFFunction = new CFFunction("Tanh", "Tanh()", "Tanh()", "", translate.instant('calculated-fields.functions.logic.isnullorempty'),  ['int', 'float', 'decimal']);
        mathChildren.push(abs, /*sqr, cos, sin, atn, exp, log_naturale, log_base, tan, power, sign,*/ round, round_decimal, ceiling, floor/*, acos, asin, log10*/);
        mathFunctions.functionType = CFFunctionType.Math;
        mathFunctions.children = mathChildren;

        //funzioni stringa
        let stringFunctions: CFFunction = new CFFunction(translate.instant('calculated-fields.functions.string.title'));
        let stringChildren: CFFunction[] = [];
        let trim: CFFunction = new CFFunction("Trim", "Trim()", "Trim(Stringa)", "TRIM()", "TRIM()", translate.instant('calculated-fields.functions.string.trim'), ['varchar', 'char'], CFFunctionType.String);
        let length: CFFunction = new CFFunction("Len", "Len()", "Len(Valore)", "LEN()", "LENGTH()", translate.instant('calculated-fields.functions.string.len'), ['varchar', 'char'], CFFunctionType.String);
        let substringlength: CFFunction = new CFFunction("Substring", "Substring(, ,)", "Substring(Stringa, PosizioneIniziale, Lunghezza)", "SUBSTRING(, ,)", "SUBSTRING(, ,)", translate.instant('calculated-fields.functions.string.substring-length'), ['varchar', 'char'], CFFunctionType.String);
        //let substring: CFFunction = new CFFunction("Substring", "Substring(,)", "Substring(Stringa, PosizioneIniziale)", "SUBSTRING(,)", "SUBSTRING(,)", translate.instant('calculated-fields.functions.string.substring'), ['varchar', 'char'], CFFunctionType.String);
        let upper: CFFunction = new CFFunction("Upper", "Upper()", "Upper(Stringa)", "UPPER()", "UPPER()", translate.instant('calculated-fields.functions.string.upper'), ['varchar', 'char'], CFFunctionType.String);
        let lower: CFFunction = new CFFunction("Lower", "Lower()", "Lower(Stringa)", "LOWER()", "LOWER()", translate.instant('calculated-fields.functions.string.lower'), ['varchar', 'char'], CFFunctionType.String);
        let concat: CFFunction = new CFFunction("Concat", "Concat(,)", "Concat(Stringa1, ... , StringaN)", "CONCAT(,)", "CONCAT(,)", translate.instant('calculated-fields.functions.string.concat'), ['varchar', 'char'], CFFunctionType.String);
        let ascii: CFFunction = new CFFunction("Ascii", "Ascii()", "Ascii(Stringa)", "ASCII()", "ASCII()", translate.instant('calculated-fields.functions.string.ascii'), ['varchar', 'char'], CFFunctionType.String);
        //let char: CFFunction = new CFFunction("Char", "Char()", "Char(Numero)", "CHAR()", "CHAR()", translate.instant('calculated-fields.functions.string.char'), ['int', 'float', 'decimal'], CFFunctionType.String);
        let replace: CFFunction = new CFFunction("Replace", "Replace(, ,)", "Replace(Stringa1, SottoStringa1, Stringa2)", "REPLACE()", "REPLACE()", translate.instant('calculated-fields.functions.string.replace'), ['varchar', 'char'], CFFunctionType.String);
        let reverse: CFFunction = new CFFunction("Reverse", "Reverse()", "Reverse(Stringa)", "REVERSE()", "REVERSE()", translate.instant('calculated-fields.functions.string.reverse'), ['varchar', 'char'], CFFunctionType.String);
        let charindex: CFFunction = new CFFunction("CharIndex", "CharIndex(,)", "CharIndex(Stringa1, Stringa2)", "CHARINDEX(,)", "LOCATE(,)", translate.instant('calculated-fields.functions.string.char-index'), ['varchar', 'char'], CFFunctionType.String);
        let charindexindex: CFFunction = new CFFunction("CharIndex (StartIndex)", "CharIndex(, ,)", "CharIndex(Stringa1, Stringa2, IndiceInizioRicerca)", "CHARINDEX(, ,)", "LOCATE(, ,)", translate.instant('calculated-fields.functions.string.char-index-index'), ['varchar', 'char'], CFFunctionType.String);
        stringChildren.push(trim, length, substringlength/*, substring*/, upper, lower, concat, ascii/*, char*/, replace, reverse/*, charindex, charindexindex*/);
        stringFunctions.functionType = CFFunctionType.String;
        stringFunctions.children = stringChildren;

        //operatori
        let operators: CFFunction = new CFFunction(translate.instant('calculated-fields.functions.operator.title'));
        let operatorChildren: CFFunction[] = [];
        let plus: CFFunction = new CFFunction("+", "+", "+", "+", "+", translate.instant('calculated-fields.functions.operator.plus'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let minus: CFFunction = new CFFunction("-", "-", "-", "-", "-", translate.instant('calculated-fields.functions.operator.minus'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let mult: CFFunction = new CFFunction("*", "*", "*", "*", "*", translate.instant('calculated-fields.functions.operator.mult'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let div: CFFunction = new CFFunction("/", "/", "/", "/", "/", translate.instant('calculated-fields.functions.operator.div'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let mod: CFFunction = new CFFunction("%", "%", "%", "%", "%", translate.instant('calculated-fields.functions.operator.mod'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let eq: CFFunction = new CFFunction("==", "==", "==", "=", "=", translate.instant('calculated-fields.functions.operator.eq'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let noteq: CFFunction = new CFFunction("!=", "!=", "!=", "<>", "<>", translate.instant('calculated-fields.functions.operator.noteq'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let less: CFFunction = new CFFunction("<", "<", "<", "<", "<", translate.instant('calculated-fields.functions.operator.less'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let lesseq: CFFunction = new CFFunction("<=", "<=", "<=", "<=", "<=", translate.instant('calculated-fields.functions.operator.lesseq'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let moreeq: CFFunction = new CFFunction(">=", ">=", ">=", ">=", ">=", translate.instant('calculated-fields.functions.operator.moreeq'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let more: CFFunction = new CFFunction(">", ">", ">", ">", ">", translate.instant('calculated-fields.functions.operator.more'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let in_: CFFunction = new CFFunction("In", "In(,)", "In (,,,)", "In(,)", "In(,)", translate.instant('calculated-fields.functions.operator.in'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let like: CFFunction = new CFFunction("Like", "Like", "Like", "Like", "Like", translate.instant('calculated-fields.functions.operator.like'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let between: CFFunction = new CFFunction("Between", "Between", "Between (,)", "Between", "Between", translate.instant('calculated-fields.functions.operator.between'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let and: CFFunction = new CFFunction("And", "And", "And", "&&", "&&", translate.instant('calculated-fields.functions.operator.and'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let or: CFFunction = new CFFunction("Or", "Or", "Or", "||", "||", translate.instant('calculated-fields.functions.operator.or'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        let not: CFFunction = new CFFunction("Not", "Not", "Not", "!", "!", translate.instant('calculated-fields.functions.operator.not'), ['int', 'float', 'decimal', 'varchar', 'char'], CFFunctionType.Operator);
        operatorChildren.push(plus, minus, mult, div, mod, eq, noteq, less, lesseq, moreeq, more, in_, like, between, and, or, not);
        operators.functionType = CFFunctionType.Operator;
        operators.children = operatorChildren;

        let dateFunctions: CFFunction = new CFFunction(translate.instant('calculated-fields.functions.date.title'));
        let dateChildren: CFFunction[] = [];
        let getdate: CFFunction = new CFFunction("GetDate", "GetDate()", "GetDate(Data)", "GetDate()", "CONVERT(, getdate(), 5)", translate.instant('calculated-fields.functions.date.getdate'), ['date', 'datetime']);
        let getsecond: CFFunction = new CFFunction("GetSecond", "GetSecond()", "GetSecond(Data)", "SECOND()", "DATEPART(second,)", translate.instant('calculated-fields.functions.date.getsecond'), ['date', 'datetime']);
        let getminute: CFFunction = new CFFunction("GetMinute", "GetMinute()", "GetMinute(Data)", "MINUTE()", "DATEPART(minute,)", translate.instant('calculated-fields.functions.date.getminute'), ['date', 'datetime']);
        let gethour: CFFunction = new CFFunction("GetHour", "GetHour()", "GetHour(Data)", "HOUR()", "DATEPART(hour,)", translate.instant('calculated-fields.functions.date.gethour'), ['date', 'datetime']);
        let getday: CFFunction = new CFFunction("GetDay", "GetDay()", "GetDay(Data)", "DAY()", "DATEPART(day,)", translate.instant('calculated-fields.functions.date.getday'), ['date', 'datetime']);
        let getmonth: CFFunction = new CFFunction("GetMonth", "GetMonth()", "GetMonth(Data)", "MONTH()", "DATEPART(month,)", translate.instant('calculated-fields.functions.date.getmonth'), ['date', 'datetime']);
        let getyear: CFFunction = new CFFunction("GetYear", "GetYear()", "GetYear(Data)", "YEAR()", "DATEPART(year,)", translate.instant('calculated-fields.functions.date.getyear'), ['date', 'datetime']);
        let getdayofweek: CFFunction = new CFFunction("GetDayOfWeek", "GetDayOfWeek()", "GetDayOfWeek(Data)", "DAYOFWEEK()", "DATEPART(DW,created)", translate.instant('calculated-fields.functions.date.getdayofweek'), ['date', 'datetime']);
        let getdayofyear: CFFunction = new CFFunction("GetDayOfYear", "GetDayOfYear()", "GetDayOfYear(Data)", "DAYOFYEAR()", "DATEPART(DY,created)", translate.instant('calculated-fields.functions.date.getdayofyear'), ['date', 'datetime']);
        dateChildren.push(getdate, getsecond, getminute, gethour, getday, getmonth, getyear, getdayofweek, getdayofyear);
        dateFunctions.functionType = CFFunctionType.Date;
        dateFunctions.children = dateChildren;
        functions.push(aggrFunctions, logicFunctions, mathFunctions, stringFunctions, operators, dateFunctions);
        return functions;
    }
}

