<mat-dialog-content class="mat-typography" [formGroup]="formGroup">
  <mat-form-field class="form-element">
    <mat-label>{{'dialog.description' | translate}}</mat-label>
    <textarea style="min-height: 150px; font-size: 15px;" matInput placeholder="Inserisci una descrizione" formControlName="description" required></textarea>
    @if ((!formGroup.controls['description'].valid && formGroup.controls['description'].touched) || formGroup.controls['description'].errors) {
      <mat-error>
        {{ formGroup.controls['description'].errors['message'] }}
      </mat-error>
    }
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close class="form-element-button btn-light">{{'dialog.close' | translate}}</button>
  <button mat-raised-button [mat-dialog-close]="close()" color="primary" type="submit"
  [disabled]="!formGroup.valid || !isModified" class="form-element-button btn-dark">{{'dialog.modify' | translate}}</button>
</mat-dialog-actions>
