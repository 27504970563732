<div id="websiteContainer">
  <div id="wrapperLogin" class="wrapperLogin no-gutter d-flex flex-column justify-content-center align-items-center ">
    <div id="pannelloLogin" class="containerComp pannelloLogin no-gutter">
      <div id="row_1" class="row">
        <div id="colonnaSx" class="colonna">
          <div id="wrapperImmagine">
            <img src="assets/images/Passepartout-w.svg" alt="Passepartout - Accesso gestionale" title="Passepartout - Accesso gestionale">
          </div>
          <div id="wrapperSlogan">
            <p class="slogan1">{{'login.access-message' | translate}}</p>
            <p class="slogan2">{{'login.access-submessage' | translate}}</p>
          </div>
        </div>
        <div id="colonnaDx" class="colonna">
          <div id="wrapperImmagineMobile">
            <img src="assets/images/Passepartout-w.svg" alt="Passepartout - Accesso gestionale" title="Passepartout - Accesso gestionale">
          </div>
          <!--<div id="wrapperColonna">
          <div id="container_form" class="component floated ">
            <div class="myLeftCtn"> -->
              <div class="myLeftCtn flex-column flex-align-items-center">
                <mat-form-field class="form-element">
                  <mat-label>{{'login.username' | translate}}</mat-label>
                  <input #username="ngModel" matInput [placeholder]="'login.username' | translate" (keydown.enter)="onSubmit()"
                    [(ngModel)]="model.Username" required>
                    @if ((username.invalid && username.touched)) {
                      <mat-error>
                        {{'login.error-username' | translate}}
                      </mat-error>
                    }
                  </mat-form-field>
                  <mat-form-field class="form-element">
                    <mat-label>{{'login.password' | translate}}</mat-label>
                    <input #password="ngModel" type="password" matInput (keydown.enter)="onSubmit()"
                      [placeholder]="'login.password' | translate" [(ngModel)]="model.Password" required>
                      @if ((password.invalid && password.touched)) {
                        <mat-error>
                          {{'login.error-password' | translate}}
                        </mat-error>
                      }
                    </mat-form-field>
                    <mat-checkbox class="custom-checkbox" [(ngModel)]="keepLoggedIn">{{'login.keepLoggedIn' | translate}}</mat-checkbox>
                    <button mat-raised-button class="btn-dark btn-login" (click)="onSubmit()" [disabled]="isLoading">{{'login.login' | translate}}</button>
                  </div>
                <!--</div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div id="footer">
        <!--<div id="container_testoPiede" class="containerComp component floated ">
        © 2020 Passepartout s.p.a. - World Trade Center - Via Consiglio dei Sessanta 99, 47891 Dogana Repubblica di San Marino - Tel. 0549 978011 - Numero Verde 800 414243 Codice Operatore Economico SM03473 - Iscrizione Registro Società n° 6210 del 6 agosto 2010 - Capitale Sociale € 2.800.000 i.v.<br>
      </div>-->
    </div>
  </div>
</div>
