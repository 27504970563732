import {JoinType} from "./enums.model";

export class DimensionOption {
    title: string;
    name: string;
    fk: string;
    joinType: JoinType;

    constructor(title: string, name: string, fk?: string, joinType?: JoinType) {
        this.title = title;
        this.name = name;
        if(fk) this.fk = fk;
        if(joinType) this.joinType = joinType;
        else this.joinType = JoinType.Left;
    }
}
