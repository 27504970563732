<form #ngForm="ngForm">
  <div class="server-config">
    <span class="title" [class]="'product-' + productTypeName">{{ 'schedule.serverConfig' | translate }}</span>
    <div class="mb-20">
      <span class="mr-2">{{ 'schedule.serverType' | translate }}</span>
      <mat-button-toggle-group name="ServerType" [(ngModel)]="model.ServerType"
        [hideSingleSelectionIndicator]="true">
        <mat-button-toggle class="server-toggle" [value]="emailServerType.Smtp">{{ 'schedule.smtp' | translate }}</mat-button-toggle>
        <mat-button-toggle class="server-toggle" [value]="emailServerType.Google">{{ 'schedule.google' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    @if (model.ServerType == emailServerType.Smtp) {
      <mat-form-field class="form-field">
        <mat-label>{{ 'schedule.sender' | translate }}</mat-label>
        <input type="text" matInput name="Sender" [(ngModel)]="model.Sender" required>
      </mat-form-field>
      <app-recipient-input [model]="model.Recipient"></app-recipient-input>
      <mat-form-field class="form-field">
        <mat-label>{{ 'schedule.timeout' | translate }}</mat-label>
        <input type="text" matInput name="Timeout" [(ngModel)]="model.Timeout" required>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>{{ 'schedule.serverSMTP' | translate }}</mat-label>
        <input type="text" matInput name="ServerSMTP" [(ngModel)]="model.ServerSmtp" required>
      </mat-form-field>
      <mat-checkbox name="Ssl" [(ngModel)]="model.Ssl">{{ 'schedule.ssl' | translate }}</mat-checkbox>
      <mat-checkbox name="DefaultCredentials" class="mb-20" [(ngModel)]="model.DefaultCredentials">{{ 'schedule.defaultCredentials' | translate }}</mat-checkbox>
      @if (!model.DefaultCredentials) {
        <mat-form-field class="form-field">
          <mat-label>{{ 'schedule.user' | translate }}</mat-label>
          <input type="text" matInput name="User" [(ngModel)]="model.User" required>
        </mat-form-field>
        <mat-form-field class="form-field">
          <mat-label>{{ 'schedule.password' | translate }}</mat-label>
          <input type="password" matInput name="Password" [(ngModel)]="model.Password" required>
        </mat-form-field>
      }
    }

    @if (model.ServerType == emailServerType.Google) {
      <div class="flex-row mb-20">
        <button class="gsi-material-button mr-2" (click)="openGoogleLogin()">
          <div class="gsi-material-button-state"></div>
          <div class="gsi-material-button-content-wrapper">
            <div class="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span class="gsi-material-button-contents">Sign in with Google</span>
          </div>
        </button>
        <span class="flex-row">
          {{ 'schedule.status' | translate }}:
          @if (isGoogleTokenOk) {
            <mat-icon class="check-icon ml-2">check_circle</mat-icon>
          } @else {
            <mat-icon class="cancel-icon ml-2">cancel</mat-icon>
          }
        </span>
      </div>
      <app-recipient-input [model]="model.Recipient"></app-recipient-input>
    }


    <div class="action-container">
      <button mat-raised-button class="btn-light" mat-dialog-close>{{ 'schedule.cancel' | translate }}</button>
      <button mat-raised-button class="btn-light" (click)="sendTestEmail()" [disabled]="!isFormValid()">{{ 'schedule.test' | translate }}</button>
      <button mat-raised-button class="btn-dark" (click)="save()" [disabled]="!isFormValid()">{{ 'schedule.save' | translate }}</button>
    </div>
  </div>
</form>