import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-refresh-dialog",
  templateUrl: "./refresh-dialog.component.html",
  styleUrls: ["./refresh-dialog.component.scss"]
})
export class RefreshDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; subtitle: string; message: string; }, private translate: TranslateService
  ) {
  }
}
