@if (isReady) {
  <div
  [ngClass]="{
    'sidenav_container_opened_pro' : sideNavState == true && !isMobile && isPro() ,
    'sidenav_container_opened' : sideNavState == true && !isMobile,
    'sidenav_container_closed': sideNavState == false && !isMobile,
    'sidenav_container_closed_menu': sideNavState == undefined,
    'sidenav_container_opened_mobile': sideNavState == true && isMobile,
    'sidenav_container_closed_mobile': sideNavState == false && isMobile
  }"
    [@onSideNavChange]="sideNavState ? 'open' : 'close'">
    <div class="menu-container">
      @if (!isMobile) {
        <div
          [ngClass]="isMenu() ? 'product-container-menu' : isRetail() ? 'product-container-retail': isWelcome()?'product-container-welcome':''"
          (click)="goToHomepage()">
          <div class="product-container">
            @if (!sideNavState) {
              <img class="logo logo-small" src="assets/images/Logo.svg">
            }
            @if (sideNavState && isMenu()) {
              <img class="logo" src="assets/images/Menu.svg">
            }
            @if (sideNavState && isRetail()) {
              <img class="logo" src="assets/images/Retail.svg">
            }
            @if (sideNavState && isWelcome()) {
              <img class="logo" src="assets/images/Welcome.svg">
            }
            <span class="product-name" [@animateText]="sideNavState ? 'show' : 'hide'"> {{ name }} </span>
          </div>
        </div>
      }
      <div class="central-section" [formGroup]="formGroup">
        <!--<mat-form-field id="menuSearch" *ngIf="sideNavState" formControlName="search" ngDefaultControl>
        <mat-label style="color: white;">{{'dialog.search' | translate}}</mat-label>
        <input id="input" matInput (input)="filterChanged($event)" [readonly]="readonly" (click)="toggleEdit()">
        <mat-icon matPrefix style="color: white;">search</mat-icon>
      </mat-form-field>-->
      @for (item of navItems; track item) {
        @if (item.id != 0) {
          <app-menu-list-item [item]="item" [state]="sideNavState"></app-menu-list-item>
        }
      }
    </div>
  </div>
  <span style="flex: 1 1 auto;"></span>
  <mat-divider></mat-divider>
  <div class="final-section">
    <div class="logout-div">
      <div class="logout-group">
        <button mat-icon-button (click)="logout()">
          <span class="material-icons logout-icon" matTooltip="{{'login.logout' | translate}}">logout</span>
        </button>
        <span class="item-name" [@animateText]="sideNavState ? 'show' : 'hide'">{{'login.logout' | translate}}</span>
      </div>
      @if (sideNavState && !isMobile) {
        <button mat-icon-button class="btn-menu-open" (click)="onSidenavToggle()">
          <span class="material-icons side-open">arrow_back_ios</span>
        </button>
      }
    </div>
    @if (!sideNavState) {
      <div class="button-container">
        @if (!sideNavState) {
          <button mat-icon-button class="btn-menu" (click)="onSidenavToggle()">
            <span class="material-icons side-open">arrow_forward_ios</span>
          </button>
        }
      </div>
    }
  </div>
</div>
}
