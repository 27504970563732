export enum MessageType {
  Log = 0,
  Error = 1,
  Confirm = 2
}
export enum FieldType {
  NotDefined = -1,
  Simple = 0,
  Key = 1,
  ForeignKey = 2,
  CompanyKey = 3,
  SourceId = 4,
  Company = 5,
  Metric = 6,
  Calculated = 7
}

export enum AdditionalModules
{
  None = 0,
  ManagementControl = 1
}

export enum DateRangeType {
  CustomRange = 0,
  Today = 1,
  Yesterday = 2,
  ThisWeek = 3,
  Last7Days = 4,
  Last30Days = 5,
  ThisMonth = 6,
  LastMonth = 7,
  ThisYear = 8,
  LastYear = 9,
  ThisWeekLastYear = 10,
  Last7DaysLastYear = 11,
  Last30DaysLastYear = 12,
  ThisMonthLastYear = 13,
  ThisYearLastYear = 14,
  TodayLastYear = 15,
  CustomPeriodLastYear = 16,
  CustomPeriodLastLastYear = 17,
  YesterdayLastYear = 18
}

export enum DaysOfWeek {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6
}

export const DaysOfWeekMapping: Record<DaysOfWeek, string> = {
  [DaysOfWeek.Monday]: "Lunedì",
  [DaysOfWeek.Tuesday]: "Martedì",
  [DaysOfWeek.Wednesday]: "Mercoledì",
  [DaysOfWeek.Thursday]: "Giovedì",
  [DaysOfWeek.Friday]: "Venerdì",
  [DaysOfWeek.Saturday]: "Sabato",
  [DaysOfWeek.Sunday]: "Domenica"
};

export enum DocumentType
{
  PDF,
  XLSX
}

export enum ETLState {
  NotExecuted = 0,
  InProgress = 1,
  Success = 3,
  Error = 4,
  SuccessWithWarning = 5
}

export enum ETLType {
  /// <summary>
  /// FT & DT Incremental automatic from last execution
  /// Use: default for scheduled execution
  /// </summary>
  Incremental = 0,
  /// <summary>
  /// FT & DT total from the beginning
  /// Use: first total ETL
  /// </summary>
  Total = 1,
  /// <summary>
  /// FT & DT Incremental from specific date/time
  /// Use: repeat an ETL from a specific date/time
  /// </summary>
  IncrementalFromDate = 2,
  /// <summary>
  /// DT total from the beginning + FT Incremental from specific date/time
  /// Use: for an empty DW, import all DT data but only few years FT
  /// </summary>
  TotalFromDate = 3,
  /// <summary>
  /// Only FT ETL for specified year,
  /// regenerate a Year for all FT of the specified ETL
  /// </summary>
  YearRigeneration = 4
}

export enum FTMaintenance
{
  /// <summary>
  /// Cancella un anno dal DW (data documento)
  /// </summary>
  DeleteYear = 1,
  /// <summary>
  /// Storicizza su tabella separata un anno del DW  (data documento)
  /// </summary>
  HistoricizesYear = 2,
}

export enum FunctionType
{
  Sum = 0,
  Avg = 1,
  Count = 2,
  Max = 3,
  Min = 4,
  CountDistinct = 5,
  None = -1

}

export enum InterrogationType {
  NotDefined = -1,
  Dashboard = 0,
  Report = 1,
  Smart = 2
}

export enum ActionType {
  DataSource = 0,
  Import = 1
}

export enum JoinType {
  NotDefined = 0,
  Left = 1,
  Right = 2
}

export enum ProductTypeStyle
{
  Menu = 1,
  Retail = 2,
  Welcome = 3
  //Mexal = 2
}

export enum ProductType
{
  Welcome = 0,
  Menu= 1,
  Retail = 2
  //Mexal = 2
}

export enum RecurrenceType {
  Manual = 0,
  RealTime = 1,
  Hourly = 2
}

export enum ScheduledOperationType {
  Dashboard = 0,
  Report = 1,
  Survey = 2
}

export enum ImportType {
  Default= 0,
  Custom = 1
}
export const ImportTypeMapping: Record<ImportType, string> = {
  [ImportType.Default]: "Predefiniti",
  [ImportType.Custom]: "Personali",
}

export const ScheduledOperationTypeMapping: Record<ScheduledOperationType, string> = {
  [ScheduledOperationType.Dashboard]: "Dashboard",
  [ScheduledOperationType.Report]: "Report",
  [ScheduledOperationType.Survey]: "Indagine rapida"
}

export enum ScheduleMonthlyPeriod {
  Start = 1,
  End = 2
}

export const ScheduleMonthlyPeriodMapping: Record<ScheduleMonthlyPeriod, string> = {
  [ScheduleMonthlyPeriod.Start]: "Inizio mese",
  [ScheduleMonthlyPeriod.End]: "Fine mese"
}

export enum SchedulerRecurrenceType {
  One = 0,
  EveryHourMinute = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4
}

export enum SourceType
{
  MySQL = 0,
  SqlServer = 1,
  SQLite = 2
}

export enum TimeLimitType {
  NotDefined = 0,
  Day = 1,
  Month = 2,
  Year = 3
}
export const TimeLimitTypeMapping: Record<TimeLimitType, string> = {
  [TimeLimitType.NotDefined]: "",
  [TimeLimitType.Day]: "Giorno",
  [TimeLimitType.Month]: "Mese",
  [TimeLimitType.Year]: "Anno",
}
export enum SortingType {
  Asc, Desc
}

export enum FilterValueType {
  Number, String, Date
}

export enum CriteriaOperator {
  Equal = '$f = $v',
  NotEqual = '$f != $v',
  Greater = '$f > $v',
  GreaterEqual = '$f >= $v',
  Lower = '$f < $v',
  LowerEqual = '$f <= $v',
  In = '$f In $v',
  Between = '$f Between ($v)',
  Contains = 'Contains($f, $v)',
  NotContains = 'Not Contains($f, $v)',
  EndsWith = 'EndsWith($f, $v)',
  StartsWith = 'StartsWith($f, $v)',
  IsNullOrEmpty = 'IsNullOrEmpty($f) = True',
  IsNotNullOrEmpty = 'IsNullOrEmpty($f) = False',
  IsNull = 'IsNull($f) = True',
  IsNotNull = 'IsNull($f) = False',
  IsTrue = '$f = True',
  IsFalse = '$f = False',
  NotEqualEnum = 'Not($f = $v)'
}

export enum TipoRecuperaAnagrafica {
  Anagrafiche = 0,
  Cliente = 1,
  Agenzia = 2,
  Azienda = 3,
  ClientiComponenti = 4,
  AgenziaAzienda = 5,
  Piatti = 6,
  Pacchetto = 8,
  Gruppo = 9,
  ContiAperti = 10,
  Fornitori = 11,
  Dipendenti = 12,
  Banche = 13,
  Casse = 14,
  Conto = 17,
  Articoli = 18,
  ComponenteXPrenotazione = 19,
  ArticoliXPrenotazione = 20,
  Menu = 21,
  Comuni = 22,
  BookingRules = 23,
  Richieste = 24,
  Prenotazioni = 25,
  PrenotazioniMaster = 26
}

export enum ETLRunning {
  ManualStart = 0,
  Maintenance = 1,
  Reset = 2
}

export enum LicenseType {
  Disabled = 0,
  Basic = 0,
  Full = 1,
  FullDemo = 2
}

export enum CFFunctionType {
  Aggregate = 1,
  Logic = 2,
  Math = 3,
  String = 4,
  Operator = 5,
  Date = 6
}

export enum EmailServerType {
  Smtp = 1,
  Google = 2
}

export enum LogType {
  Generic = 0,
  Api = 1
}

export enum DateRangeDialogResType {
  Update=  0,
  Reset = 1
}
