@if (newInterrogation) {
  <span mat-dialog-title class="title-container">
    {{'dialog.insert-data' | translate}}
    <button mat-mini-fab class="import-button" [matTooltip]="'dashboard.import' | translate" [mat-dialog-close]="close(true)">
      <mat-icon>note_add</mat-icon>
    </button>
  </span>
}
<mat-dialog-content class="mat-typography" [formGroup]="formGroup">
  @if (newInterrogation) {
    <mat-form-field class="form-element">
      <mat-label>{{'dialog.name' | translate}}</mat-label>
      <input matInput placeholder="{{'fact.interrogation-name' | translate}}" formControlName="name" maxlength="50" required (blur)="validatorService.validateName(formGroup.controls['name'].value, formGroup)">
      @if ((!formGroup.controls['name'].valid && formGroup.controls['name'].touched) || formGroup.controls['name'].errors) {
        <mat-error>
          {{ formGroup.controls['name'].errors['message'] }}
        </mat-error>
      }
    </mat-form-field>
  }
  @if (newInterrogation) {
    <mat-form-field class="form-element">
      <mat-label>{{'dialog.description' | translate}}</mat-label>
      <textarea matInput [placeholder]="'fact.interrogation-description' | translate"
      formControlName="description" maxlength="200" cdkTextareaAutosize cdkAutosizeMinRows="6" cdkAutosizeMaxRows="6"></textarea>
      @if ((!formGroup.controls['description'].valid && formGroup.controls['description'].touched) || formGroup.controls['description'].errors) {
        <mat-error>
          {{ formGroup.controls['description'].errors['message'] }}
        </mat-error>
      }
    </mat-form-field>
  }
  <mat-form-field class="form-element">
    <mat-label>{{'dialog.interested-fact' | translate}}</mat-label>
    <mat-select placeholder="{{'fact.interrogation-fact' | translate}}" formControlName="fact" required>
      @for (f of facts; track f.Name) {
        <mat-option [value]="f">
          {{f.Preview}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button class="btn-light" mat-dialog-close>{{'dialog.close' | translate}}</button>
  <button mat-raised-button class="btn-dark"
    [mat-dialog-close]="close(false)"
  [disabled]="!formGroup.valid">{{'dialog.proceed' | translate}}</button>
</mat-dialog-actions>
