<div class="user-container"><!--*ngIf="isReady"-->
  <div class="user-nested-container">
    <div class="user-subnested-container flex-row">
      <div class="left flex-column">
        <mat-card class="users-card mat-users-background">
          <mat-card-title class="flex-row"
            [ngClass]="configService.isMenu() ? 'card-title-menu' : configService.isRetail() ? 'card-title-retail': configService.isWelcome()?'card-title-welcome':''">
            {{'user-panel.route-title' | translate}}
            @if (!isBasic && canModifyPermissions) {
              <button mat-mini-fab class="settings-button" [matTooltip]="'permission.title' | translate"
                [disabled]="!canAccess" (click)="openPermissionDialog()">
                <mat-icon class="settings-icon">settings</mat-icon>
              </button>
            }
          </mat-card-title>
          <mat-card-content>
            <div class="flex-column flex-align-center">
              <mat-selection-list [multiple]="false" #gNames class="groups-list" [hideSingleSelectionIndicator]="true"
                (selectionChange)="onGroupSelection($event, gNames.selectedOptions.selected)">

                @for (g of groups; track g.GroupOptions.Id) {
                    <mat-list-option [selected]="g.GroupOptions.Id == currentModel.Id" [disabled]="g.GroupOptions.Name != userGroup && !canAccess"
                      [value]="g" style="min-width: 10vw;">
                      {{ g.GroupOptions.Name }}
                    </mat-list-option>
                  }
                            </mat-selection-list>
                        </div>
                    </mat-card-content>
                </mat-card>
                <!--Aggiungere gruppi-->
                <div class="flex-row div-expansion-panel">
                    <mat-accordion class="example-headers-align" multi>
                        <mat-expansion-panel class="expansion-panel">
                            <mat-expansion-panel-header>
                            <mat-panel-title><mat-icon class="icon-add-group"> group_add</mat-icon>{{'user-panel.add-group' | translate}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field class="form-field-add-group">
                            <mat-label>{{'user-panel.group-name' | translate}}</mat-label>
                            <input matInput [(ngModel)]="groupName"/>
                            </mat-form-field>
                            <div>
                                <button mat-raised-button class="btn-dark" (click)="addGroup()">{{'user-panel.add' | translate}}</button>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
            
            <div class="flex-column gap-10 right">
                <p class="selected-group">{{ currentModel.Name }}</p>
                <mat-card class="mat-custom-background">
                    <mat-card-content>
                        <p>{{'user-panel.homepage-title' | translate}}</p>
                        <mat-form-field class="w-100">
                            <mat-label>{{'user-panel.homepage' | translate}}</mat-label>
                            <mat-select #etl [(ngModel)]="currentModel.Homepage" [disabled]="!canAccess" [hideSingleSelectionIndicator]="true"
                                (selectionChange)="homepageChanged($event)">
                                @for (e of availableInterrogations; track e.id) {
                                    <mat-option [value]="e.id">
                                      {{e.displayName | translate }}
                                    </mat-option>
                                  }
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                </mat-card>

        <mat-card class="mat-custom-background" style="overflow: auto;">
          <mat-card-content class="flex-column">
            @if (!isBasic) {
              <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="currentModel.CanCreate" [disabled]="!canAccess"
                (change)="checkBoxClickActivate($event)">
              {{'user-panel.can-create' | translate}}</mat-checkbox>
            }
            <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="currentModel.EtlRead" [disabled]="!canAccess"
              (change)="updateGroups()">
            {{'user-panel.options-access' | translate}}</mat-checkbox>
            <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="currentModel.EtlWrite" [disabled]="!canAccess"
              (change)="updateGroups()">
            {{'user-panel.options-modification' | translate}}</mat-checkbox>
            <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="currentModel.TypeFilterActive" [disabled]="!canAccess"
              (change)="updateGroups()">
            {{'user-panel.document-type-filter' | translate}}</mat-checkbox>
            <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="currentModel.TabletMode" [disabled]="!canAccess && isDesktop"
              (change)="checkBoxClickActivate($event)">
            {{'user-panel.tablet-mode' | translate}}</mat-checkbox>
            <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="currentModel.CanAccessSchOp" [disabled]="!canAccess"
              (change)="updateGroups()">
            {{'user-panel.sch-op-access' | translate}}</mat-checkbox>
            <mat-checkbox #emailCheckbox class="custom-checkbox checkbox-opt" [(ngModel)]="currentModel.CanAccessEmailConfig" [disabled]="!canAccess"
              (change)="updateGroups()">
              {{'user-panel.export-survey-conf-access' | translate}}
              @if (!isBasic && canModifyPermissions) {
                <button mat-mini-fab class="settings-button" [matTooltip]="'user-panel.config-mail' | translate"
                  [disabled]="!currentModel.CanAccessEmailConfig || !emailCheckbox.checked" (click)="openEmailConfig()">
                  <mat-icon class="settings-icon">settings</mat-icon>
                </button>
              }
            </mat-checkbox>
          </mat-card-content>
        </mat-card>

        <mat-card class="mat-custom-background">
          <mat-card-content>
            <p>{{'user-panel.filter' | translate}}</p>
            <mat-form-field class="w-100">
              <mat-label>{{'user-panel.company-filter' | translate}}</mat-label>
              <mat-select [value]="selectedCompany" (selectionChange)="companyChanged($event)" [disabled]="!canAccess" multiple>
                @for (e of availableCompanies; track e) {
                  <mat-option [value]="e.Value">
                    {{e.Name | translate}}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </mat-card-content>
        </mat-card>

      </div>

    </div>

        <div class="save-button-container">
            <button mat-raised-button class="btn-dark" [class.spinner]="onSaving" (click)="saveGroupOptions(true)"
                [disabled]="!hasChanges || onSaving">{{'dialog.save' | translate}}</button>
            <button mat-raised-button class="btn-dark deletegroup" [disabled]="isDeleteDisabled" (click)="deleteGroup()">{{'user-panel.delete-group' | translate}}</button>
        </div>

  </div>

</div>
