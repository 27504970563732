import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { DeactivateDialogComponent } from "./deactivate-dialog/deactivate-dialog.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { EtlErrorDialogComponent } from "./etl-error-dialog/etl-error-dialog.component";
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { RefreshDialogComponent } from "./refresh-dialog/refresh-dialog.component";
import {LoadingDialogComponent} from "./loading-dialog/loading-dialog.component";
import {MessageType} from "../models/enums.model";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { BtnDialogConfig } from "../models/dialog-box.model";

@Injectable({ providedIn: 'root' })
export class DialogService {
  private opened = false;
  private dialogRef: MatDialogRef<any>;
  private readonly SNACKBAR_TIME = 4000;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackbar: MatSnackBar
  ) { }

  openErrorDialog(message: string, error: boolean, status?: number, title?: string) {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      data: { message, status, title },
      maxHeight: "100%",
      width: "540px",
      maxWidth: "100%",
      disableClose: true,
      hasBackdrop: true
    });

    return dialogRef;
  }

  openRefreshDialog(message: string): void {
    if (!this.opened) {
      this.opened = true;
      const dialogRef = this.dialog.open(RefreshDialogComponent, {
        data: { message },
        maxHeight: "100%",
        width: "540px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });

      dialogRef.afterClosed().subscribe((res) => {
        this.opened = false;
        if (res) window.location.reload();
      });
    }
  }

  openMessageDialog(title: string, subtitle: string, message: string, showTextContainer: boolean = false, btn1?: BtnDialogConfig, btn2?: BtnDialogConfig) {
    this.dialogRef = this.dialog.open(MessageDialogComponent, {
      maxHeight: "90vh",
      minWidth: "400px",
      maxWidth: "90vw",
      disableClose: true,
      hasBackdrop: true
    });

    const componentInstance = this.dialogRef.componentInstance as MessageDialogComponent;
    componentInstance.title = title;
    componentInstance.subtitle = subtitle;
    componentInstance.message = message;
    componentInstance.showTextContainer = showTextContainer;
    componentInstance.btn1 = btn1;
    componentInstance.btn2 = btn2;

    return this.dialogRef;
  }

  getDialogRefDeactivate(message: string) {
    let type = MessageType.Confirm;
    const dialogRef = this.dialog.open(DeactivateDialogComponent, {
      data: { message, type },
      maxHeight: "100%",
      //width: "540px",
      maxWidth: "100%",
      disableClose: true,
      hasBackdrop: true
    });
    return dialogRef.afterClosed();
  }

  openEtlErrorDialog(message: string, error: string, subtitle?: string) {
    if (!this.opened) {
      this.opened = true;
      const dialogRef = this.dialog.open(EtlErrorDialogComponent, {
        data: { message, error, subtitle },
        maxHeight: "100%",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });

      dialogRef.afterClosed().subscribe((res) => {
        this.opened = false;

      });
    }
  }

  openLoadingDialog(canStop: boolean): void {
    if (!this.opened) {
      this.opened = true;
      this.dialogRef = this.dialog.open(LoadingDialogComponent, {
        data: { message: 'dialog.loading-msg', canStop: canStop },
        maxHeight: "100%",
        width: "400px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }

  openDialogWithStop(message: string, canStop: boolean, subtitle?: string): void {
    if (!this.opened) {
      this.opened = true;
      this.dialogRef = this.dialog.open(LoadingDialogComponent, {
        data: { message: message, canStop: canStop, subtitle: subtitle },
        maxHeight: "100%",
        width: "400px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });
    }
  }

  openDialogWithHoCapito(message: string, canStop: boolean, hoCapito: boolean, subtitle?: string): void {
    if (!this.opened) {
      this.opened = true;
      this.dialogRef = this.dialog.open(LoadingDialogComponent, {
        data: { message: message, canStop: canStop, hoCapito: hoCapito, subtitle: subtitle },
        maxHeight: "100%",
        width: "400px",
        maxWidth: "100%",
        disableClose: true,
        hasBackdrop: true
      });
    }
  }

  getRef() {
    return this.dialogRef;
  }

  hideDialog() {
    if (this.dialogRef)
      this.dialogRef.close();
  }

  openSnackbar(msg: string, btnText?: string) {
    let config = new MatSnackBarConfig();
    config.duration = this.SNACKBAR_TIME;
    this.snackbar.open(
      this.translateService.instant(msg),
      btnText ? this.translateService.instant(btnText) : this.translateService.instant('snackbar.close'),
      config
    );
  }

  openModalComponent(component: any, config?: MatDialogConfig) {
    if (!config)
      config = new MatDialogConfig();

    config.disableClose = true;
    
    //check del panelClass se è già esistente non lo vado a sovrascrivere
    if (!config.panelClass)
      config.panelClass = 'std-dialog';
    
    this.dialogRef = this.dialog.open(component, config);
    return this.dialogRef;
  }
}
