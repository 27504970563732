import { Component } from '@angular/core';

@Component({
  selector: 'app-add-etl-dialog',
  templateUrl: './add-etl-dialog.component.html',
  styleUrl: './add-etl-dialog.component.scss'
})
export class AddEtlDialogComponent {

}
