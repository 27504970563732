@if (isReady) {
  <div class="container">
    <mat-dialog-content class="mat-typography" [formGroup]="formGroup" >
      <mat-form-field class="form-element">
        @if (actionType==ActionType.Import) {
          <mat-label>{{'upload.type' | translate}}</mat-label>
        }
        @if (actionType==ActionType.DataSource) {
          <mat-label>{{'dialog.datasource' | translate}}</mat-label>
        }
        @if (actionType == ActionType.DataSource) {
          <mat-select formControlName="datasource" required [(value)]="selected">
            @for (d of datasources; track d.Id) {
              <mat-option [value]="d">
                {{d.Name}}
              </mat-option>
            }
          </mat-select>
        }
        @if (actionType == ActionType.Import) {
          <mat-select formControlName="importTypes" required>
            @for (key of importTypes; track key) {
              <mat-option [value]="key">
                {{importTypesMap[key]}}
              </mat-option>
            }
          </mat-select>
        }
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close class="form-element-button btn-light">{{'dialog.close' | translate}}</button>
      <button mat-raised-button [mat-dialog-close]="close()" type="submit"
      [disabled]="!formGroup.valid" class="form-element-button btn-dark">{{'dialog.proceed' | translate}}</button>
    </mat-dialog-actions>
  </div>
}
