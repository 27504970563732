import {ProductType} from './enums.model';

export class FactInfo {
  Name: string;
  Title: string;
  HistoricizedYears: number[] = [];
  ProductTypes: ProductType[] = [];


  constructor(name: string, title: string, historicizedYears?: number[], productTypes?: ProductType[]) {
    this.Name = name;
    this.Title = title;
    if(historicizedYears) this.HistoricizedYears = historicizedYears;
    if(productTypes) this.ProductTypes = productTypes;
  }
}
