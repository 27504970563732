import { InterrogationType } from "./enums.model";

export class Permission {
    name: string;
    read: boolean;
    write: boolean;
    readCompany: boolean;
    disabled?: boolean;

    constructor(
        name: string,
        read: boolean,
        write: boolean,
        readCompany: boolean,
        disabled?: boolean
    ) {
        this.name = name;
        this.read = read;
        this.write = write;
        this.readCompany = readCompany;
        if (disabled) this.disabled = disabled;
    }
}

export interface GroupPermissionDTO {
    Id: number;
    Name: string;
    ReadIds: number[];
    WriteIds: number[];
}

