<mat-card class="selection" style="border-radius: none !important;">
  <mat-card-title class="flex-row">
    <div [ngClass]="configService.isMenu() ? 'option-title-menu' : configService.isRetail() ? 'option-title-retail': configService.isWelcome()?'option-title-welcome':''">
      Configurazione destinatari email
    </div>
    <!--<button mat-mini-fab
    class="historicized-years"
    style="background-color: #2D3E50; height: 30px; width: 30px;"
    matTooltip="{{'permission.title' | translate}} "
    (click)="openPermissionDialog()">
    <mat-icon style=" height: 8px; width: 8px; margin-top: -3vh; margin-right: 1.5vh;">settings</mat-icon>
  </button>-->
</mat-card-title>
<mat-card-content>
  @if (recipient) {
    <div [formGroup]="formGroupConf">
      <div class="flex-row gap-10">
        <mat-form-field appearance="fill" class="white-back-sched content-item">
          <mat-label>Destinatario\i (separati da un ;)</mat-label>
          <textarea  matInput  formControlName="recipient" required></textarea><!--[(value)]="recipient"-->
        </mat-form-field>
      </div>
    </div>
  }
  <!--<div class="flex-row gap-10" style="justify-content: space-around;" >
  <mat-checkbox class="example-margin custom-checkbox" [checked]="documentType == documentTypeEn.PDF" (change)="documentTypeChange(documentTypeEn.PDF)">PDF</mat-checkbox>
  <mat-checkbox class="example-margin custom-checkbox" [checked]="documentType == documentTypeEn.XLSX" (change)="documentTypeChange(documentTypeEn.XLSX)">XLSX</mat-checkbox>
</div>-->
</mat-card-content>
<mat-card-actions align="end" >
  <button mat-raised-button class="btn-dark" [disabled]="!formGroupConf.valid"
  mat-dialog-close (click)="close()">Salva</button>
  <button mat-raised-button class="btn-light" style="height: 100%;" mat-dialog-close
  >Chiudi</button>
</mat-card-actions>
</mat-card>
