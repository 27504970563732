import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilteredMenuService {

  onFilterApplied$ = new Subject<any>();
  currentState: boolean;

  constructor() { }

  setState(res: boolean) {
    this.currentState = res;
  }
}
