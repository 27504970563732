import { Component, OnInit, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-custom-snackbar",
  templateUrl: "./custom.snackbar.component.html",
  styleUrls: ["./custom.snackbar.component.scss"]
})
export class CustomSnackbarComponent implements OnInit {
  public canRead: boolean = false;
  public names: string[] = [];
  public etlNames: string = '(';
  constructor(
    public sbRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private router: Router,
    private translate: TranslateService
  ) {
    this.canRead = data.canRead;
    this.names = data.names;
    data.names.forEach(n => {
      this.etlNames += n + ',';
    });
    this.etlNames = this.etlNames.substr(0, this.etlNames.length-1);
    this.etlNames = this.etlNames + ')';
  }
  ngOnInit() {}

  goToEtl() {
    this.router.navigate(['etl']);
    this.sbRef.dismiss();
  }
}
