<div class="flex-row flex-fill">
  <h2 mat-dialog-title style="width: 100%;">{{'calculated-fields.title' | translate}}
  </h2>
  <!--<p style="margin: 5px 0;">test</p>-->
</div>
<mat-dialog-content class="mat-typography" style="overflow: hidden;" fxFill>
  <div class="scroll-container-up flex-column">
    <mat-form-field >
      <mat-label>{{'calculated-fields.cfname' | translate}}</mat-label>
      <input matInput #calculatedFieldName placeholder="{{'calculated-fields.cfname' | translate}}"
        (input)="changeCalculatedFieldName($event)" [(ngModel)]="cfName">
        <!--<mat-error *ngIf="cfName == null">
        {{'dialog.not-correct-name' | translate}}
      </mat-error>-->
      @if (cfName == null) {
        <span
          class="error">{{'dialog.not-correct-name' | translate}}
        </span>
      }
    </mat-form-field>
  </div>
  <div class="flex-row flex-fill" [style.margin-top]="'2%'">
    <div class="scroll-container flex-column" [style.display]="'flex'" [style.margin-right]="'1%'" [ngStyle]="{'width':'50%'}">
      <mat-form-field class="search-field">
        <input matInput placeholder="{{'dialog.search' | translate}}" (input)="filterChanged($event.target)">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"
        style="height: 30vh; overflow: auto;">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <p class="node-class" (dblclick)="insertField(node)">{{node.title}}</p>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <span>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.title}}
          </span>
        </mat-tree-node>
      </mat-tree>
    </div>
    <div style="width: 50%; margin-left: 1%;" class="scroll-container flex-column" >
      <mat-form-field class="search-field">
        <input matInput placeholder="{{'dialog.search' | translate}}" (input)="functionsFilterChanged($event.target)">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
      <mat-tree [dataSource]="functionsDataSource" [treeControl]="functionsTreeControl"
        style="height: 30vh; overflow: auto;">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
          <p class="node-class" (dblclick)="insertFunction(node)">{{node.preview}}</p>
          <span class="material-icons" matTooltip="{{node.description}}"
            [ngClass]="isMenu() ? 'description-icon-menu-function' : isRetail() ? 'description-icon-retail-function': isWelcome()?'description-icon-welcome-function':''"
          >info</span>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
            <mat-icon class="mat-icon-rtl-mirror">
              {{functionsTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          {{node.name}}
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
  <div class="flex-column flex-fill" [style.margin-top]="'2%'">
    <mat-form-field class="form-element" style="width: 95%;">
      <mat-label>{{'calculated-fields.functions.aggr.title' | translate}}
      </mat-label>
      <mat-select placeholder="{{'calculated-fields.functions.aggr.title' | translate}}" (selectionChange)="aggrFunctionChange($event)" [(value)]="aggrFuntionSelected">
        @for (f of functionTypes; track f) {
          <mat-option [value]="f">
            {{f.name}}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <span class="material-icons" matTooltip="{{'calculated-fields.functions.aggr.description' | translate}}" style="align-self: end; display: flex !important;"
      [ngClass]="isMenu() ? 'aggr-icon-menu-function' : isRetail() ? 'aggr-icon-retail-function': isWelcome()?'aggr-icon-welcome-function':''"
    >info</span>
    <mat-form-field class="example-full-width-text-area">
      <mat-label>{{'calculated-fields.label' | translate}}</mat-label>
      <textarea matInput class="text-area" [value]="expression" (input)="onChangeExpression($event.target)"></textarea>
      <!--<mat-error *ngIf="expressionError">
      {{'dialog.not-correct-name' | translate}}
    </mat-error>-->
    @if (expressionError == true) {
      <span
        class="error">{{cfFunctionErr}}
      </span>
    }
  </mat-form-field>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="width: 100%;">
  <button mat-raised-button mat-dialog-close class="btn-light">{{'dialog.close' | translate}}</button>
  <button mat-raised-button [mat-dialog-close]="{name: cfName, expression: expression, sqlServerExpression: sqlServerExpression, mySqlExpression: mySqlExpression, functionType: aggrFuntionSelected}" class="btn-dark" [disabled]="isDisabled()">{{'dialog.proceed' | translate}}</button>
</mat-dialog-actions>
<!--filters: {dw: filterBuilder.query, query: filterBuilder.realQuery}-->
