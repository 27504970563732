import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-folder-dialog',
  templateUrl: './upload-folder-dialog.component.html',
  styleUrl: './upload-folder-dialog.component.scss'
})
export class UploadFolderDialogComponent {

}
