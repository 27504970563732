import {Injectable} from "@angular/core";
import {GroupOptions} from "../models/groupoptions";
import {User} from "../models/user";
import {AdditionalModules, LicenseType, ProductType, ProductTypeStyle} from "../models/enums.model";
import {FactInfo} from "../models/factInfo";
import {CookieService} from "ngx-cookie-service";

export class ConfigInstances {
  public groupOptions:  GroupOptions = new GroupOptions();
  public homepage: number;
  public ftCount: number;
  public onlineYears: number;
  public license: LicenseType;
  public productType: ProductType;
  public session: number;
  public isMenuAvailable: boolean;
  public additionalModules: AdditionalModules;
  public facts: FactInfo[];
  public viewOnly: boolean;
  public groupName: string;
  public groupId: number;
  public administrator: boolean;
  public etlRead: boolean;
  public etlWrite: boolean;
  public canCreate: boolean;
  public canAccessSchOp: boolean;
  public canAccessEmailConfig: boolean;

  setGroupOptions: (groupOptions: GroupOptions) => void = (groupOptions: GroupOptions) => { this.groupOptions = groupOptions; }
  setHomepage: (homepage: number) => void = (homepage: number) => {
    let currGroupOptions = !this.groupOptions? this.groupOptions : null;
    if(currGroupOptions) {
      currGroupOptions.Homepage = homepage;
      this.groupOptions = currGroupOptions;
    }
  }
  setFtCount: (ftCount: number) => void = (ftCount: number) => { this.ftCount = ftCount; }
  setOnlineYears: (onlineYears: number) => void = (onlineYears: number) => { this.onlineYears = onlineYears; }
  setLicense: (license: LicenseType) => void = (license: LicenseType) => { this.license = license; }
  setProductType: (productType: ProductType) => void = (productType: ProductType) => { this.productType = productType; }
  setSession: (session: number) => void = (session: number) => { this.session = session; }
  setIsMenuAvailable: (isMenuAvailable: boolean) => void = (isMenuAvailable: boolean) => { this.isMenuAvailable = isMenuAvailable; }
  setAdditionalModules: (additionalModules: AdditionalModules) => void = (additionalModules: AdditionalModules) => { this.additionalModules = additionalModules; }
  setFacts: (facts: FactInfo[]) => void = (facts: FactInfo[]) => { this.facts = facts; }
  setViewOnly: (viewOnly: boolean) => void = (viewOnly: boolean) => { this.viewOnly = viewOnly;}

  getGroupName: () => string = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.Name : null; }
  getGroupId: () => number = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.Id : null; }
  isAdministrator: () => boolean = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.Name == 'administrator' : false; }
  getEtlRead: () => boolean = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.EtlRead : null; }
  getEtlWrite: () => boolean = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.EtlWrite : null; }
  getHomepage: () => number = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.Homepage : null; }
  getCanCreate: () => boolean = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.CanCreate : null; }
  getCanAccessSchOp: () => boolean = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.CanAccessSchOp : null; }
  getCanAccessEmailConfig: () => boolean = () => { return this.groupOptions && this.groupOptions != null ? this.groupOptions.CanAccessEmailConfig : null; }
  getFtCount: () => number = () => { return this.ftCount; }
  getOnlineYears: () => number = () => { return this.onlineYears; }
  getLicense: () => LicenseType = () => { return this.license as LicenseType; }
  getProductType: () => ProductType = () => { return this.productType as ProductType; }
  getSession: () => number = () => { return this.session; }
  getIsMenuAvailable: () => boolean = () => { return this.isMenuAvailable === true; }
  getAdditionalModules: () => AdditionalModules = () => { return this.additionalModules as AdditionalModules; }
  getViewOnly: () => boolean = () => { return this.viewOnly === true; }
  getFacts: () => FactInfo[] = () => { return this.facts; }

}

@Injectable({ providedIn: 'root' })
export class ConfigService {

  constructor( private cookieService: CookieService ) {}

  configInstances = new ConfigInstances();

  setUser = (user: User, keepLogged?: boolean) => {
    if (sessionStorage.getItem('token')) {
      sessionStorage.clear();
    }
    let currentLang: string = this.cookieService.get('currentLang');
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('currentLang', currentLang ? currentLang : 'it');
    sessionStorage.setItem('skipRefresh', '1');
    if (keepLogged) {
      this.cookieService.deleteAll('/');
      const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
      this.cookieService.set('id', user.Id.toString(), expires, '/');
      this.cookieService.set('company', user.Company, expires, '/');
      this.cookieService.set('group', user.Group, expires, '/');
      this.cookieService.set('lang', user.Lang, expires, '/');
      this.cookieService.set('name', user.Name, expires, '/');
      this.cookieService.set('refreshToken', user.RefreshToken, expires, '/');
      this.cookieService.set('token', user.Token, expires, '/');
      this.cookieService.set('currentLang', currentLang ? currentLang : 'it', expires, '/');
    }
  }

  //questi salvati nei cookies
  getUser: () => User = () => { return sessionStorage.getItem('user') && sessionStorage.getItem('user') != ''? /*plainToClass(User, */JSON.parse(sessionStorage.getItem('user')) : null;}
  getUserId: () => number = () => {return sessionStorage.getItem('user') && sessionStorage.getItem('user') != ''? /*plainToInstance(User, */JSON.parse(sessionStorage.getItem('user')).Id : null; }
  getToken: () => string = () => { return sessionStorage.getItem('user') && sessionStorage.getItem('user') != ''? /*plainToInstance(User,*/ JSON.parse(sessionStorage.getItem('user')).Token : null;}
  getLang: () => string = () => { return sessionStorage.getItem('user') && sessionStorage.getItem('user') != ''? /*plainToInstance(User,*/ JSON.parse(sessionStorage.getItem('user')).Lang : null;}
  getRefreshToken: () => string = () => { return sessionStorage.getItem('user') && sessionStorage.getItem('user') != ''? /*plainToInstance(User,*/ JSON.parse(sessionStorage.getItem('user')).RefreshToken : null; }
  //---------------------------

  isMenu: () => boolean = () => { return this.configInstances.getProductType() == ProductType.Menu; }
  isRetail: () => boolean = () => { return this.configInstances.getProductType() == ProductType.Retail; }
  isWelcome: () => boolean = () => { return this.configInstances.getProductType() == ProductType.Welcome; }

  isAdministratorGroup: () => boolean = () => { return this.configInstances.getGroupName() == 'administrator' };

  get productTypeName() {
    return ProductTypeStyle[this.configInstances.getProductType()].toLowerCase();
  }
}
