import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from "../services/authentication.service";
import { DwApiService } from "../services/dw-mart-api.service";
import { DialogService } from "../dialogs/dialog.service";
import { ConfigService } from "../services/config.service";
import {InitializeRS, LoginRQ} from '../models/web-api-models';
import { catchError, pipe } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: LoginRQ = new LoginRQ();
  keepLoggedIn: boolean;
  returnUrl: string;
  theme: string = '';
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private api: DwApiService,
    private cookieService: CookieService,
    private dialogService: DialogService,
    public translate: TranslateService,
    private configService: ConfigService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.userValue) {
      this.router.navigate(['']);
    }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  async onSubmit() {
    if (!this.model.Username || !this.model.Password)
      return;

    this.isLoading = true;
    this.dialogService.openLoadingDialog(false);
    let user = await this.authenticationService
      .login(this.model, this.keepLoggedIn)
      .catch(e => {
        this.isLoading = false;
        if (e instanceof HttpErrorResponse) {
          if (e.status == 401) {
            throw new Error("login.error");
          } else {
            throw e;
          }
        }
      });

    this.api.initialize()
      .pipe(catchError(x => { this.isLoading = false; throw x; }))
      .subscribe({
        next: (res) => {
          this.configService.configInstances.setViewOnly(false);
          this.configService.configInstances.setSession(res.Session);
          this.configService.configInstances.setProductType(res.ProductType);
          this.configService.configInstances.setLicense(res.License);
          this.configService.configInstances.setIsMenuAvailable(res.IsMenuAvailable);
          this.configService.configInstances.setAdditionalModules(res.AdditionalModules);
          this.configService.configInstances.setGroupOptions(res.GroupOptions);
          this.configService.configInstances.setFtCount(res.FtCount);
          this.configService.configInstances.setOnlineYears(res.OnlineYears);
          this.configService.configInstances.setFacts(res.Facts)
          this.authenticationService.licenseSubject.next(true);
          this.authenticationService.groupOptionsSubject.next(true);
          this.api.addUser(user).subscribe(() => {
            this.router.navigate([this.returnUrl]);
            if (this.cookieService.check('group')) {
              this.cookieService.delete('additionalModules');
              this.dialogService.hideDialog();
            }
          });
        }
      });
  }
}
