import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { loadMessages, locale } from 'devextreme/localization';
import { DwApiService } from '../../../services/dw-mart-api.service';
import { FieldNode } from '../../../models/fieldnode';
import messagesIT from "devextreme/localization/messages/it.json";
import messagesEN from "devextreme/localization/messages/en.json";
import { DxFilterBuilderComponent, DxTagBoxComponent } from 'devextreme-angular';
import { FieldType, FunctionType, InterrogationType } from '../../../models/enums.model';
import { Validator } from '../../../utils/validator';

@Component({
  selector: 'app-custom-filter-builder',
  templateUrl: './custom-filter-builder.component.html',
  styleUrls: ['./custom-filter-builder.component.scss']
})
export class CustomFilterBuilderComponent implements OnInit {

  @Input() items: FieldNode[];
  @Output() error: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filtersAdded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(DxFilterBuilderComponent, { static: false }) filterBuilder: DxFilterBuilderComponent;
  @ViewChild(DxTagBoxComponent, { static: false }) tagbox: DxTagBoxComponent;
  public filterString: string = '';
  private fact: string;
  private factTitle: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  filterText: any;
  dataSourceText: any;
  fields: Array<any> = [];
  previousFields: Array<any> = [];
  customOperations: Array<any>;
  filter: any = '';
  groupOperations: string[] = ["and", "or"/*, "notAnd", "notOr"*/];
  distinctAvailables: any[] = [];
  betweenOperation: any;
  anyOfOperation: any;
  notAnyOfOperation: any;
  isNullOperation: any;
  isNotNullOperation: any;

  todayOperation: any;
  yesterdayOperation: any;
  thisWeekOperation: any;
  lastWeekOperation: any;
  thisMonthOperation: any;
  lastMonthOperation: any;
  thisYearOperation: any;
  lastYearOperation: any;
  lastSevenDays: any;


  query: any;
  realQuery: any;
  //prevFilters: any;
  //prevRealQuery: any;
  years: string[] = [];
  historicizedYearsAvailables: any[] = [];

  type: InterrogationType;

  constructor(
    private api: DwApiService,
    public translate: TranslateService,
    private ref: ChangeDetectorRef) {
      this.betweenOperation = {
        name: "betweenCustom",
        caption: this.translate.instant('filter.between'),
        icon: "check",
        editorTemplate: "tagBoxTemplate",
        dataTypes: "number, string",
        /*calculateFilterExpression(filterValue: any, field: any) {
          return filterValue && filterValue.length
              && Array.prototype.concat.apply([], filterValue.map(function(value) {
                  return [[field.dataField, "=", value], "or"];
              })).slice(0, -1);
        }*/
        calculateFilterExpression: function (filterValue, field) {
          if (filterValue && filterValue.length) {
            var lastIndex = filterValue.length - 1;
            var values = '(';
            filterValue.forEach((v, index) => {
              values += "'" + v + "'";
              if (index !== lastIndex)
                values += ", ";
            });
            values += ")";
            return [field.dataField, "in", values];
          } else return '';
        }
        /*customizeText: function(fieldInfo) {
            return fieldInfo.value && fieldInfo.value.length ? fieldInfo.value.join(", ") : "";
        },
        hasValue: true*/
      };

    this.anyOfOperation = {
      name: "anyof",
      caption: this.translate.instant('filter.anyof'),
      icon: "check",
      editorTemplate: "tagBoxTemplate",
      dataTypes: "number, string",
      /*calculateFilterExpression(filterValue: any, field: any) {
        return filterValue && filterValue.length
            && Array.prototype.concat.apply([], filterValue.map(function(value) {
                return [[field.dataField, "=", value], "or"];
            })).slice(0, -1);
      }*/
      calculateFilterExpression: function (filterValue, field) {
        if (filterValue && filterValue.length) {
          var lastIndex = filterValue.length - 1;
          var values = '(';
          filterValue.forEach((v, index) => {
            values += "'" + v + "'";
            if (index !== lastIndex)
              values += ", ";
          });
          values += ")";
          return [field.dataField, "in", values];
        } else return '';
      }
      /*customizeText: function(fieldInfo) {
          return fieldInfo.value && fieldInfo.value.length ? fieldInfo.value.join(", ") : "";
      },
      hasValue: true*/
    };

    this.notAnyOfOperation = {
      name: "notanyof",
      caption: this.translate.instant('filter.notanyof'),
      icon: "minus",
      editorTemplate: "tagBoxTemplate",
      dataTypes: "number, string",
      calculateFilterExpression: function (filterValue, field) {
        if (filterValue && filterValue.length) {
          var lastIndex = filterValue.length - 1;
          var values = '(';
          filterValue.forEach((v, index) => {
            values += "'" + v + "'";
            if (index !== lastIndex)
              values += ", ";
          });
          values += ")";
          return [field.dataField, "not in", values];
        } else return '';
      }
    };
    this.isNullOperation = {
      name: "isnull",
      caption: this.translate.instant('filter.isnull'),
      icon: "isblank",
      hasValue: false,
      dataTypes: "number, string",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is null"];

      }
    };
    this.isNotNullOperation = {
      name: "isnotnull",
      caption: this.translate.instant('filter.isnotnull'),
      icon: "isnotblank",
      hasValue: false,
      dataTypes: "number, string",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is not null"];

      }
    };
    this.todayOperation = {
      name: "today",
      caption: this.translate.instant('filter.today'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is today"];
      }
    };
    this.yesterdayOperation = {
      name: "yesterday",
      caption: this.translate.instant('filter.yesterday'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is yesterday"];
      }
    };
    this.thisMonthOperation = {
      name: "thismonth",
      caption: this.translate.instant('filter.this-month'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is this month"];
      }
    };
    this.lastMonthOperation = {
      name: "lastmonth",
      caption: this.translate.instant('filter.last-month'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is last month"];
      }
    };
    this.thisWeekOperation = {
      name: "thisweek",
      caption: this.translate.instant('filter.this-week'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is this week"];
      }
    };
    this.lastWeekOperation = {
      name: "lastweek",
      caption: this.translate.instant('filter.last-week'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is last week"];
      }
    };
    this.thisYearOperation = {
      name: "thisyear",
      caption: this.translate.instant('filter.this-year'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is this year"];
      }
    };
    this.lastYearOperation = {
      name: "lastyear",
      caption: this.translate.instant('filter.last-year'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is last year"];
      }
    };
    this.lastSevenDays = {
      name: "lastsevendays",
      caption: this.translate.instant('filter.last-seven-days'),
      icon: "minus",
      hasValue: false,
      dataTypes: "date",
      calculateFilterExpression: function (filterValue, field) {
        return [field.dataField, "is last seven days"];
      }
    };
    let currentLang = sessionStorage.getItem('lang');//this.cookieService.get('currentLang');
    if (currentLang == 'eng') {
      loadMessages(messagesEN);
      locale('en');
    }
    else if (currentLang == 'ita') {
      loadMessages(messagesIT);
      locale('it');
    }
  }

  ngOnInit(): void {
    console.log('on init');
  }

  valueChangedTagBox(e) {
    //condition.setValue($event.value)
  }

  configure(type: InterrogationType, items, fact, setMew?, historicizedYearsAvailables?, query?) {
    this.type = type;
    this.filterBuilder.visible = false;
    this.filterBuilder.value = '';
    let instance: any = this.filterBuilder.instance;
    instance._model = [];
    this.filterBuilder.resetOptions('value');
    this.filterBuilder.resetOptions('fields');
    this.filterBuilder.fields = [];
    this.fields = [];
    this.customOperations = [
      this.anyOfOperation,
      this.notAnyOfOperation,
      this.isNullOperation,
      this.isNotNullOperation,
      this.todayOperation,
      this.yesterdayOperation,
      this.thisWeekOperation,
      this.lastWeekOperation,
      this.thisMonthOperation,
      this.lastMonthOperation,
      this.thisYearOperation,
      this.lastYearOperation,
      this.lastSevenDays];
    //salvo i fields che avevo per confrontare i filtri => se non ho più un elemento ma è presente nei filtri devo eliminare la condizione
    //if (this.fields.length > 0) this.previousFields = this.fields;
    //this.fields = [];
    //this.prevFilters = this.filter;
    //this.prevRealQuery = this.realQuery;
    this.filter = '';
    this.query = '';
    this.realQuery = '';
    if (historicizedYearsAvailables) this.historicizedYearsAvailables = historicizedYearsAvailables;
    this.fact = fact;
    //this.getFactResources();
    this.items = items;
    this.mapItems(this.items, query);
    /*let newCondition: any[] = [];
    if(this.previousFields.length > 0) this.checkFilterCondition(undefined, this.realQuery, [], 0);*/

    //this.filtersAdded.emit(false);
    //this.ref.detectChanges();
  }

  async mapItems(items, query?) {
    /**
     * name: string;
      title: string;
      parentTitle?: string;
      parentName?: string;
      level: number;
      isMeasure: boolean;
      type: string;
      expandable: boolean;
      functionType?: FunctionType;
      fk?: string;
     */
    let params: HttpParams = new HttpParams();
    params = params.append('fact', this.fact);
    await this.api.getFTResources(params).subscribe((data: any) => {
      this.factTitle = data.Title ? data.Title : data.PhisicalName;
      let i = 0;
      items.forEach(async e => {
        //if (e.fieldType != FieldType.Calculated) {
        if (e.isEnum) {
          let pDistinct: HttpParams = new HttpParams();
          pDistinct = pDistinct.append('table', e.parentName);
          pDistinct = pDistinct.append('column', e.name);
           await this.api.getDistinct(pDistinct).subscribe((resDistinct: any) => {
            let arr: any = { 'name': e.title + ' (' + e.parentTitle + ')', 'items': [] };
            resDistinct.forEach(r => {
              //arr.items.push(r[e.name]);
              arr.items.push(r);
            });
            if (e.name == 'Year') {
              this.historicizedYearsAvailables.forEach(y => {
                let index = arr.items.indexOf(y);
                if (index > -1) arr.items.splice(index, 1);
              });
            }
            this.distinctAvailables.push(arr);

            let title = e.parentTitle == this.translate.instant('dw.descriptive-fields') ||
              e.parentTitle == this.translate.instant('dw.metrics') ? this.factTitle : e.parentTitle;
            if (arr.items.length > 0) {
              if(e.type == 'datetime') {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime' ?
                    ["=", "<>", "<", ">", "<=", ">=", "between", "today", "yesterday", "thisweek", "lastweek", "thismonth", "lastmonth", "thisyear", "lastyear", "lastsevendays"] :
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  lookup: {
                    dataSource: arr.items
                  },
                  format: "yyyy-MM-dd",
                  editorOptions: {
                    displayFormat: "yyyy-MM-dd",
                    dateSerializationFormat: "yyyy-MM-dd"
                  },
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    if(selectedFilterOperation == 'between' && filterValue.length == 2) {
                      return  [
                        ['[' + title + ']' + '.' + '[' + e.name + ']', ">=", "'"+filterValue[0]+"'"],
                        "and",
                        ['[' + title + ']' + '.' + '[' + e.name + ']', "<=", "'"+filterValue[1]+"'"]
                      ];
                    } else
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'"];
                  }
                });
              } else if(e.type == 'bool') {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: 'boolean',
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations:
                    ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  lookup: {
                    dataSource:['true', 'false']
                  },
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, filterValue == 'false'? 0 : 1];
                  }
                });
              } else if(e.type == 'string' && !e.isEnum) {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: 'string',
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations:
                    ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    if(filterValue == null) {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "''" ];
                    } else return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'" ];
                  }
                });
              } else if(e.functionType != undefined && this.type == InterrogationType.Smart){
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime'?
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    var f = '';
                      switch(e.functionType ) {
                        case FunctionType.Avg:
                          f = 'Avg';
                          break;
                        case FunctionType.Count:
                          f = 'Count';
                          break;
                        case FunctionType.Sum:
                          f = 'Sum';
                          break;
                        default:
                          f = '';
                          break;
                      }
                      return [f+'([' + title + ']' + '.' + '[' + e.name + '])', selectedFilterOperation, filterValue];
                  }
                });
              }  else {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime'?
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  lookup: {
                    dataSource: arr.items
                  },
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    if(filterValue == null) {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "''" ];
                    } else return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'" ];
                }
                });
              }
            } else {
              if(e.type == 'datetime') {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime'?
                    ["=", "<>", "<", ">", "<=", ">=", "between", "today", "yesterday", "thisweek", "lastweek", "thismonth", "lastmonth", "thisyear", "lastyear", "lastsevendays"] :
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  format: "yyyy-MM-dd",
                  editorOptions: {
                    displayFormat: "yyyy-MM-dd",
                    dateSerializationFormat: "yyyy-MM-dd"
                  },
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    if(selectedFilterOperation == 'between' && filterValue.length == 2) {
                      return  [
                        ['[' + title + ']' + '.' + '[' + e.name + ']', ">=", "'"+filterValue[0]+"'"],
                        "and",
                        ['[' + title + ']' + '.' + '[' + e.name + ']', "<=", "'"+filterValue[1]+"'"]
                      ];
                    } else
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'"];
                  }
                });
              } else if(e.type == 'bool') {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: 'boolean',
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations:
                    ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  lookup: {
                    dataSource:['true', 'false']
                  },
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, filterValue == 'false'? 0 : 1];
                  }
                });
              } else if(e.type == 'string' && !e.isEnum) {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: 'string',
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations:
                    ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    if(filterValue == null) {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "''" ];
                    } else return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'" ];
                  }
                });
              } else if(e.functionType != undefined && this.type == InterrogationType.Smart){
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime'?
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    var f = '';
                      switch(e.functionType ) {
                        case FunctionType.Avg:
                          f = 'Avg';
                          break;
                        case FunctionType.Count:
                          f = 'Count';
                          break;
                        case FunctionType.Sum:
                          f = 'Sum';
                          break;
                        default:
                          f = '';
                          break;
                      }
                      return [f+'([' + title + ']' + '.' + '[' + e.name + '])', selectedFilterOperation, filterValue];
                  }
                });
              }  else {
                this.fields.push({
                  dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                  dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                  //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                  filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime'?
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                    ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                  caption: e.title + ' (' + e.parentTitle + ')',
                  calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    if(filterValue == null) {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "''" ];
                    } else return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'" ];
                  }
                });
              }
            }


            if (i == items.length - 1) {

              if (query) {
                this.checkFilterCondition3(query, 0);
                this.filter = query;
                this.filtersAdded.emit(false);
                this.ref.detectChanges();
              }
              else this.filter = '';
              this.filterBuilder.visible = true;
            }
            i++;
          });
        } else {
          let arr: any = { 'name': e.title + ' (' + e.parentTitle + ')', 'items': [] };

          if (e.name == 'Year') {
            this.historicizedYearsAvailables.forEach(y => {
              let index = arr.items.indexOf(y);
              if (index > -1) arr.items.splice(index, 1);
            });
          }
          this.distinctAvailables.push(arr);

          let title = e.parentTitle == this.translate.instant('dw.descriptive-fields') ||
            e.parentTitle == this.translate.instant('dw.metrics') ? this.factTitle : e.parentTitle;
          if (arr.items.length > 0) {

            if(e.type == 'datetime') {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations: e.fieldType == FieldType.Calculated  || e.type == 'datetime'?
                  ["=", "<>", "<", ">", "<=", ">=", "between", "today", "yesterday", "thisweek", "lastweek", "thismonth", "lastmonth", "thisyear", "lastyear", "lastsevendays"] :
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                lookup: {
                  dataSource: arr.items
                },
                format: "yyyy-MM-dd",
                editorOptions: {
                  displayFormat: "yyyy-MM-dd",
                  dateSerializationFormat: "yyyy-MM-dd"
                },
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                  if(selectedFilterOperation == 'between' && filterValue.length == 2) {
                    return  [
                      ['[' + title + ']' + '.' + '[' + e.name + ']', ">=", "'"+filterValue[0]+"'"],
                      "and",
                      ['[' + title + ']' + '.' + '[' + e.name + ']', "<=", "'"+filterValue[1]+"'"]
                    ];
                  } else
                    return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'"];
                }
              });
            } else if(e.type == 'bool') {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: e.type == 'boolean',
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations:
                  ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                lookup: {
                  dataSource:['true', 'false']
                },
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, filterValue == 'false'? 0 : 1];
                }
              });
            } else if(e.type == 'string' && !e.isEnum) {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: 'string',
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations:
                  ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    //return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'"];
                    if(filterValue == null) {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "''" ];
                    } else return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'" ];
                }
              });
            } else if(e.functionType != undefined && this.type == InterrogationType.Smart){
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime'?
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                  var f = '';
                    switch(e.functionType ) {
                      case FunctionType.Avg:
                        f = 'Avg';
                        break;
                      case FunctionType.Count:
                        f = 'Count';
                        break;
                      case FunctionType.Sum:
                        f = 'Sum';
                        break;
                      default:
                        f = '';
                        break;
                    }
                    return [f+'([' + title + ']' + '.' + '[' + e.name + '])', selectedFilterOperation, filterValue];
                }
              });
            }   else {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations: e.fieldType == FieldType.Calculated  || e.type == 'datetime'?
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                lookup: {
                  dataSource: arr.items
                },
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                  return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'" ];
                }
              });
            }
          } else {
            if(e.type == 'datetime') {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations: e.fieldType == FieldType.Calculated  || e.type == 'datetime'?
                  ["=", "<>", "<", ">", "<=", ">=", "between", "today", "yesterday", "thisweek", "lastweek", "thismonth", "lastmonth", "thisyear", "lastyear", "lastsevendays"] :
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                format: "yyyy-MM-dd",
                editorOptions: {
                  displayFormat: "yyyy-MM-dd",
                  dateSerializationFormat: "yyyy-MM-dd"
                },
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                  if(selectedFilterOperation == 'between' && filterValue.length == 2) {
                    return  [
                      ['[' + title + ']' + '.' + '[' + e.name + ']', ">=", "'"+filterValue[0]+"'"],
                      "and",
                      ['[' + title + ']' + '.' + '[' + e.name + ']', "<=", "'"+filterValue[1]+"'"]
                    ];
                  } else
                    return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'"];
                }
              });
            } else if(e.type == 'bool') {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: 'boolean',
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations:
                  ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                lookup: {
                  dataSource:['true', 'false']
                },
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, filterValue == 'false'? 0 : 1];
                }
              });
            } else if(e.type == 'string' && !e.isEnum) {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: 'string',
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations:
                  ["=", "<>", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                    //return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'"];
                    if(filterValue == null) {
                      return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "''" ];
                    } else return ['[' + title + ']' + '.' + '[' + e.name + ']', selectedFilterOperation, "'"+filterValue+"'" ];
                }
              });
            } else if(e.functionType != undefined && this.type == InterrogationType.Smart){
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations: e.fieldType == FieldType.Calculated || e.type == 'datetime'?
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')',
                calculateFilterExpression: (filterValue, selectedFilterOperation) => {
                  var f = '';
                    switch(e.functionType ) {
                      case FunctionType.Avg:
                        f = 'Avg';
                        break;
                      case FunctionType.Count:
                        f = 'Count';
                        break;
                      case FunctionType.Sum:
                        f = 'Sum';
                        break;
                      default:
                        f = '';
                        break;
                    }
                    return [f+'([' + title + ']' + '.' + '[' + e.name + '])', selectedFilterOperation, filterValue];
                }
              });
            }   else {
              this.fields.push({
                dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
                dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : (e.type == 'datetime' ? 'date' : 'string'),
                //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
                filterOperations: e.fieldType == FieldType.Calculated ?
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank"] :
                  ["=", "<>", "<", ">", "<=", ">=", "between", "isblank", "isnotblank", "anyof", "notanyof", "isnull", "isnotnull"],
                caption: e.title + ' (' + e.parentTitle + ')'
              });
            }
          }


          if (i == items.length - 1) {

            if (query) {
              this.checkFilterCondition3(query, 0);
              this.filter = query;
              this.filtersAdded.emit(false);
              this.ref.detectChanges();
            }
            else this.filter = '';
            this.filterBuilder.visible = true;
          }
          i++;
        }
        /*} else {
          let title = e.parentTitle == this.translate.instant('dw.descriptive-fields') ||
            e.parentTitle == this.translate.instant('dw.metrics') ? this.factTitle : e.parentTitle;
          this.fields.push({
            dataField: '[' + title + ']' + '.' + '[' + e.name + ']',
            dataType: e.type == 'decimal' || e.type == 'integer' || e.type == 'int' ? 'number' : 'string',
            //caption: '[' + e.parentTitle + ']' + '.' + '[' + e.title + ']'
            caption: e.title + ' (' + e.parentTitle + ')'
          });
        }*/


      });
      /*if (query) this.filter = query;
      else this.filter = '';*/
      this.filtersAdded.emit(false);
      //this.ref.detectChanges();
      this.ref.detectChanges();
    });

  }

  calculateFilterExpression(filterValue, selectedFilterOperation) {
    //return [this.dataField, selectedFilterOperation, filterValue];
 }

  updateTexts(e) {
    this.query = e.component.option("value");
    this.realQuery = e.component.getFilterExpression();
    //console.log(this.query);
    //console.log(this.realQuery);
    //if (this.realQuery) this.findYearsInFilterCondition(this.query, 0);
    //console.log(this.years);
    this.ref.detectChanges();
    if (this.query && this.realQuery) this.error.emit(false);
    else this.error.emit(true);
  }

  getFactResources() {
    let params: HttpParams = new HttpParams();
    params = params.append('fact', this.fact);
    this.api.getFTResources(params).subscribe((data: any) => {
      this.factTitle = data.Title ? data.Title : data.PhisicalName;
    });
  }

  getItemsTagBox(condition) {
    //console.log(condition);
    return this.distinctAvailables.find(d => d.name == condition.field.caption) != undefined ?
      this.distinctAvailables.find(d => d.name == condition.field.caption).items : [];
  }

  checkIfAreYears(rules) {
    let ret = true;
    rules.forEach(r => { if (!Validator.isYearValue(r)) ret = false; });
    return ret;
  }

  checkFilterCondition3(rules, i) {
    if (rules.length > 0) {
      let i = rules.length - 1;
      while (i >= 0) {
        if (Array.isArray(rules[i])) {
          this.checkFilterCondition3(rules[i], i);
          if (rules[i].length == 0) {
            rules.splice(i, 1);
          } else if (rules[i - 1] == 'anyof' || rules[i - 1] == 'notanyof') {
            //caso in cui ho ciclato gli anni tipo:
            /**
              * [
                  2020,
                  2017,
                  2013
                ]
            che è comune alla condizione di filto any of e not any of.*/
            //può essere qualsiasi valore perchè anyof e notanyof li uso anche con le stringhe, quindi non mi basta il controllo solo sugli anni
            //this.checkIfAreYears(rules);
            if (typeof rules[0] === 'string' && rules[0].startsWith('[') && rules[0].endsWith(']') && rules[0].includes('].[') &&
              this.fields.find(f => f.dataField == rules[0]) == undefined) {
              //tolgo anche gli altri (i-1 e 0)? penso di no sennò salta tutto
              rules.splice(i, 1);
            }
          }
        } else if (typeof rules[i] === 'string') {
          if (rules[i] != 'and' && rules[i] != 'or') {
            if (rules[i].startsWith('[') && rules[i].endsWith(']') && rules[i].includes('].[') &&
              this.fields.find(f => f.dataField == rules[i]) == undefined) {
              //rules[i] = '';
              rules.splice(i, 1);
            } else if (typeof rules[0] === 'string' && rules[0].startsWith('[') && rules[0].endsWith(']') && rules[0].includes('].[') &&
              this.fields.find(f => f.dataField == rules[0]) == undefined) {
              rules.splice(i, 1);
            } else if (rules[i] == 'anyof' || rules[i] == 'notanyof') {
              //vuol dire che ho eliminato i valori di confronto quindi devo eliminare anche questo operatore
              if (i == rules.length - 1) {
                rules.splice(i, 1);
              }
            } else if(typeof rules[0] === 'string' && rules[0].startsWith('[') && rules[0].endsWith(']') && rules[0].includes('].[') &&
                      rules[i].endsWith('00:00Z')) {
              rules[i] = new Date(rules[i]);
            }
          }
        } else if (typeof rules[i] === 'number') {
          if (rules[Number(i) - 1] == '') {
            rules.splice(i, 1);
          } else if (typeof rules[0] === 'string' && rules[0].startsWith('[') && rules[0].endsWith(']') && rules[0].includes('].[') &&
            this.fields.find(f => f.dataField == rules[0]) == undefined) {
            rules.splice(i, 1);
          }
        }
        i--;
      }
    }
  }

  findYearsInFilterCondition(rules, i) {
    if (rules.length > 0) {
      let i = rules.length - 1;
      while (i >= 0) {
        if (Array.isArray(rules[i])) {
          this.findYearsInFilterCondition(rules[i], i);
          if (this.checkIfAreYears(rules[i]) && (rules[i - 1] == 'anyof' || rules[i - 1] == 'notanyof')) {
            if (rules[0] === 'string' && rules[0].startsWith('[') && rules[0].endsWith(']') && rules[0].includes('].[') &&
              rules[0] == '[' + this.translate.instant('filter.date') + '].[Year]') {
              if (!this.years.includes(rules[i])) this.years.push(rules[i]);
            }
          }
        } else if (typeof rules[i] === 'number' && Validator.isValidYear(rules[i])) {
          if (rules[0] === 'string' && rules[0].startsWith('[') && rules[0].endsWith(']') && rules[0].includes('].[') &&
            rules[0] == '[' + this.translate.instant('filter.date') + '].[Year]') {
            if (!this.years.includes(rules[i])) this.years.push(rules[i]);
          }
        }
        i--;
      }
    }
  }

}
