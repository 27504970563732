<mat-dialog-content class="mat-typography dialog-content">
  <div class="flex-fill flex-column">
    <div fxFlex>
      <div class="tab-container flex-align-center flex-place-content">
        <div class="drop-zone-container flex-column flex-align-items-center flex-place-content" appFilePicker
          #dropZonePicker="appFilePicker" (filesChanged)="onFilesChanged($event)" (filesReset)="onReset()">
          <mat-icon class="import-icon">add_circle</mat-icon>
          <span>Drag & Drop</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div>
        <app-selected-files-table [files]="selectedFiles"></app-selected-files-table>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close class="btn-light">{{'dialog.close' | translate}}</button>
  @if (isSelected()) {
    <button mat-raised-button [mat-dialog-close]="{file: selectedFiles}" class="btn-dark"
    >{{'dialog.ok' | translate}}</button>
  }
</mat-dialog-actions>