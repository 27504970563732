import {AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {FieldNode} from "../../models/fieldnode";
import {DwApiService} from "../../services/dw-mart-api.service";
import {DialogService} from "../dialog.service";
import {FiltersOnRequestComponent} from "./filters-on-request/filters-on-request.component";
import {firstValueFrom} from "rxjs";
import {DataSource, Fact, Filter} from "../../models/dialogs-model";

@Component({
  selector: 'app-request-filter-dialog',
  templateUrl: './request-filter-dialog.component.html',
  styleUrls: ['./request-filter-dialog.component.scss']
})
export class RequestFilterDialogComponent implements AfterViewInit {

  filters: any;
  filterError: boolean = true;
  @Input() interrogationId: string = '';
  historicizedYear: string = undefined;
  histYears: boolean = false;
  historicizedYearsAvailables: any[] = [];
  @Input() fact: Fact;
  @Input() selected: FieldNode[] = [];
  datasource: DataSource = null;
  isVisible: boolean = true;
  @Input() requestedFilter: string;
  @ViewChild('filterBuilder', { static: false }) filterBuilder: FiltersOnRequestComponent;

  constructor(private api: DwApiService,
    /*@Inject(MAT_DIALOG_DATA) data,*/
    private cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private dialogService: DialogService,
    private ref: ChangeDetectorRef) {
    /*this.interrogationId = data.id;
    this.fact = data.fact;
    if (data.selected) this.initSelected(data.selected);
    if (data.requestedFilter) this.initFilters(data.requestedFilter);*/
    /*if (data.historicizedYears && data.historicizedYears.length > 0) {
      this.histYears = true;
      this.historicizedYearsAvailables.push(this.translate.instant('dialog.delete-selection'));
      data.historicizedYears.forEach(y => {
        this.historicizedYearsAvailables.push(y);
      });
    }
    /*if (data.year && data.year != 0) {
      this.historicizedYear = data.year;
    } else this.historicizedYear = undefined;*/
  }
  ngAfterViewInit(): void {
    this.ref.detectChanges();
    this.filterBuilder.filterBuilder.value = '';
    let selected = this.historicizedYear ? this.selected.filter(i => i.parentName != undefined && i.name != "Year") :
    this.selected.filter(i => i.parentName != undefined);
    this.filters ? this.filterBuilder.configure(selected, this.fact.Name, false, this.historicizedYearsAvailables, this.filters) :
        this.filterBuilder.configure(selected, this.fact.Name, true, this.historicizedYearsAvailables);
        this.ref.detectChanges();
  }

  initFilters(filters) {
    this.filters = JSON.parse(filters);
  }

  initSelected(selected) {
    this.selected = [];
    if (selected instanceof Object) {
      selected.forEach(selectedNode => {
        this.selected.push(selectedNode);
      });
    } else {
      var sel: any = JSON.parse(selected);
      sel.forEach(selectedNode => {
        this.selected.push(selectedNode);
      });
    }
  }

  setFilterError(event) {
    this.filterError = event;
  }

  close() {
    let filter: Filter = new Filter();
    if(this.filterBuilder && this.filterBuilder.query && this.filterBuilder.realQuery) {
      filter.Dw = this.filterBuilder.query;
      filter.Query = this.filterBuilder.realQuery;
    }
    return filter;
  }
}
