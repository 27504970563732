import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { InterrogationType } from 'src/app/models/enums.model';
import { InterrogationInfo } from 'src/app/models/interrogation-info';
import { GroupPermissionDTO } from 'src/app/models/permission';
import { GetGroupPermissionsRS, GetInterrogationsInfoRS } from 'src/app/models/web-api-models';
import { ConfigService } from 'src/app/services/config.service';
import { DwApiService } from 'src/app/services/dw-mart-api.service';

@Component({
  selector: 'app-all-permission-dialog',
  templateUrl: './all-permission-dialog.component.html',
  styleUrl: './all-permission-dialog.component.scss'
})
export class AllPermissionDialogComponent implements OnInit {

  @Input() interrogationId: number;

  @Output() onSaved: EventEmitter<void> = new EventEmitter();

  protected dashboards: InterrogationInfo[] = [];
  protected reports: InterrogationInfo[] = [];
  protected surveys: InterrogationInfo[] = [];
  protected groupsPermissions: GroupPermissionDTO[] = [];

  protected textFilter: string;
  protected displayedColumns: string[] = ['interrogation', 'read', 'write'];

  private interrogationsInfoInitial: GetInterrogationsInfoRS;

  constructor(
    private dialogRef: MatDialogRef<AllPermissionDialogComponent>,
    private api: DwApiService,
    private dialogService: DialogService,
    protected configService: ConfigService
  ) { }

  async ngOnInit() {
    this.dialogService.openLoadingDialog(false);
    this.interrogationsInfoInitial = await firstValueFrom(this.api.GetInterrogationsInfo(this.interrogationId));
    this.dashboards = this.interrogationsInfoInitial.InterrogationsInfo.filter(x => x.InterrogationType == InterrogationType.Dashboard);
    this.reports = this.interrogationsInfoInitial.InterrogationsInfo.filter(x => x.InterrogationType == InterrogationType.Report);
    this.surveys = this.interrogationsInfoInitial.InterrogationsInfo.filter(x => x.InterrogationType == InterrogationType.Smart);
    let ret2 = await firstValueFrom(this.api.getGroupsPermissions());
    this.groupsPermissions = ret2.GroupPermissions;
    this.dialogService.hideDialog();
  }

  getCheckboxValue(group: GroupPermissionDTO, interrogation: InterrogationInfo, isWrite: boolean) {
    if (isWrite)
      return group.WriteIds.includes(interrogation.Id);
    else
      return group.ReadIds.includes(interrogation.Id);
  }

  setCheckboxValue(group: GroupPermissionDTO, interrogation: InterrogationInfo, isWrite: boolean, isChecked: boolean) {
    if (isWrite) {
      if (isChecked)
        group.WriteIds.includes(interrogation.Id) ? null : group.WriteIds.push(interrogation.Id);
      else
        group.WriteIds.includes(interrogation.Id) ? group.WriteIds.splice(group.WriteIds.indexOf(interrogation.Id), 1) : null;
    } else {
      if (isChecked)
        group.ReadIds.includes(interrogation.Id) ? null : group.ReadIds.push(interrogation.Id);
      else
        group.ReadIds.includes(interrogation.Id) ? group.ReadIds.splice(group.ReadIds.indexOf(interrogation.Id), 1) : null;
    }
  }

  getGroupCheckbox(group: GroupPermissionDTO, interrogations: InterrogationInfo[], isWrite: boolean, isIndeterminate: boolean) {
    let count = 0;
    if (isWrite) {
      interrogations.forEach(int => group.WriteIds.includes(int.Id) ? count = ++count : null)
    } else {
      interrogations.forEach(int => group.ReadIds.includes(int.Id) ? count = ++count : null)
    }

    if (isIndeterminate) {
      if (count != interrogations?.length && count != 0)
        return true;
      else
        return false;
    } else {
      if (count == interrogations?.length)
        return true;
      else if (count == 0)
        return false;
      else
        return null;
    }
  }

  setGroupCheckbox(group: GroupPermissionDTO, interrogations: InterrogationInfo[], isWrite: boolean, isCheked: boolean) {
    if (isWrite) {
      if (isCheked) {
        let set = new Set(group.WriteIds);
        interrogations.map(x => x.Id).forEach(x => {
          if (!set.has(x)) {
            group.WriteIds.push(x);
            set.add(x);
          }
        });
      } else {
        let set = new Set(interrogations.map(x => x.Id));
        group.WriteIds = group.WriteIds.filter(x => !set.has(x));
      }
    } else {
      if (isCheked) {
        let set = new Set(group.ReadIds);
        interrogations.map(x => x.Id).forEach(x => {
          if (!set.has(x)) {
            group.ReadIds.push(x);
            set.add(x);
          }
        });
      } else {
        let set = new Set(interrogations.map(x => x.Id));
        group.ReadIds = group.ReadIds.filter(x => !set.has(x));
      }
    }
  }

  filterChange() {
    this.dashboards = this.interrogationsInfoInitial.InterrogationsInfo
      .filter(x => x.InterrogationType == InterrogationType.Dashboard && (this.textFilter ? x.Name.toLowerCase().includes(this.textFilter.toLowerCase()) : true));
    this.reports = this.interrogationsInfoInitial.InterrogationsInfo
      .filter(x => x.InterrogationType == InterrogationType.Report && (this.textFilter ? x.Name.toLowerCase().includes(this.textFilter.toLowerCase()) : true));
    this.surveys = this.interrogationsInfoInitial.InterrogationsInfo
      .filter(x => x.InterrogationType == InterrogationType.Smart && (this.textFilter ? x.Name.toLowerCase().includes(this.textFilter.toLowerCase()) : true));
  }

  save() {
    let rq = new GetGroupPermissionsRS();
    rq.GroupPermissions = this.groupsPermissions;
    this.api.modifyGroupPermissions(rq).subscribe(() => {
      this.onSaved.emit();
      this.dialogRef.close();
    });
  }
}
