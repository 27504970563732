import { HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DwApiService } from '../../services/dw-mart-api.service';
import {InterrogationType, ProductTypeStyle} from '../../models/enums.model';
import {Validator, ValidatorService} from '../../utils/validator';
import {Fact, InterrogationName} from "../../models/dialogs-model";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-modify-interrogation-title',
  templateUrl: './modify-interrogation-title.component.html',
  styleUrls: ['./modify-interrogation-title.component.scss']
})
export class ModifyInterrogationTitleComponent implements OnInit {

  formGroup: FormGroup;
  facts: Fact[] = [];
  @Input() interrogationType: InterrogationType;
  @Input() name: string = '';

  constructor(
    private formBuilder: FormBuilder,
    /*@Inject(MAT_DIALOG_DATA) data,*/
    private api: DwApiService,
    public validatorService: ValidatorService) {
    /*this.name = data.name;
    this.interrogationType = data.interrogationType;*/
  }

  async ngOnInit() {
    this.createForm();
    this.facts = await firstValueFrom(this.api.getAvailableFTS(this.interrogationType));
  }

  isMenu() {
    return Number(sessionStorage.getItem('productType')) == ProductTypeStyle.Menu;
  }

  isRetail() {
    return Number(sessionStorage.getItem('productType')) == ProductTypeStyle.Retail;
  }

  isWelcome() {
    return Number(sessionStorage.getItem('productType')) == ProductTypeStyle.Welcome;
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'name': this.name.length > 0 ? [this.name, Validators.required] : [null, null]
    });
  }

  close(): InterrogationName {
    let ret = new InterrogationName();
    ret.Name =  this.formGroup.get('name').value;
    return ret;
  }
}
