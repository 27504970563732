import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToInstance } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { BtnDialogConfig } from 'src/app/models/dialog-box.model';
import { DaysOfWeek, DaysOfWeekMapping, DocumentType, EmailServerType, ProductTypeStyle, ScheduledOperationType, ScheduledOperationTypeMapping, ScheduleMonthlyPeriod, ScheduleMonthlyPeriodMapping, SchedulerRecurrenceType } from 'src/app/models/enums.model';
import {DaInterrogation, EmailServerConfig, ScheduledOperation} from 'src/app/models/schedule.model';
import {CheckSchedulerOperationNameRQ, GroupEmailServerConfig, SendTestEmailRQ} from 'src/app/models/web-api-models';
import { ConfigService } from 'src/app/services/config.service';
import { DwApiService } from 'src/app/services/dw-mart-api.service';
import { UpdateMenuService } from 'src/app/services/update-menu.service';

@Component({
  selector: 'app-scheduled-operation',
  templateUrl: './scheduled-operation.component.html',
  styleUrl: './scheduled-operation.component.scss'
})
export class ScheduledOperationComponent implements OnInit {

  @ViewChild('ngForm', { static: true }) ngForm: NgForm;

  public model: ScheduledOperation = new ScheduledOperation();
  public interrogations: DaInterrogation[];
  public interrogationsLoading: boolean = false;
  public isGoogleTokenOk: boolean;

  public scheduledOperationType = Object.values(ScheduledOperationType).filter(value => typeof value === 'number');
  public scheduledOperationTypeMap = ScheduledOperationTypeMapping;
  public daysOfWeek = Object.values(DaysOfWeek).filter(value => typeof value === 'number');
  public daysOfWeekMap = DaysOfWeekMapping;
  public scheduleMonthlyPeriod = Object.values(ScheduleMonthlyPeriod).filter(value => typeof value === 'number');
  public scheduleMonthlyPeriodMap = ScheduleMonthlyPeriodMapping;
  public documentType = DocumentType;
  public recurrenceType = SchedulerRecurrenceType;
  public emailServerType = EmailServerType;

  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private api: DwApiService,
    private configService: ConfigService,
    private router: Router,
    private updateMenu: UpdateMenuService
  ) {

  }
  
  get productTypeName() {
    return ProductTypeStyle[this.configService.configInstances.getProductType()].toLowerCase();
  }

  get canEdit() {
    return this.configService.configInstances.getEtlWrite();
  }

  async ngOnInit() {
    let params = await firstValueFrom(this.route.params);

    if (params['id']) {
      let res = await firstValueFrom(this.api.getScheduledOperation(+params['id']));
      this.interrogations = await firstValueFrom(this.api.getInterrogationsToSchedule(res.ScheduledOperation.InterrogationType))
      this.model = plainToInstance(ScheduledOperation, res.ScheduledOperation);
    } else {
      let emailConfig = await firstValueFrom(this.api.getServerEmailConfigByName(this.configService.configInstances.getGroupName()));
      this.setServerFields(emailConfig.ServerEmailConfig);
      this.isGoogleTokenOk = !!emailConfig.GoogleAuthToken;
    }

    this.dialogService.hideDialog();
  }

  interrogationTypeChange(event: MatSelectChange) {
    this.model.IdInterrogation = null;
    this.interrogations = [];
    this.interrogationsLoading = true;
    this.api.getInterrogationsToSchedule(event.value).subscribe(res => {
      this.interrogations = res;
      this.interrogationsLoading = false;
    });
  }

  openGoogleLogin() {
    this.api.googleAuthenticate().subscribe(res => {
      window.open(res.Url, '_blank');
    });
  }

  showError = () => this.dialogService.openMessageDialog(null, null, this.model.LastError, true);

  isFormValid = () => this.ngForm.form.valid && this.model.Recipient?.length > 0;

  sendTestEmail() {
    let rq = new SendTestEmailRQ(this.model);
    this.api.sendTestEmail(rq).subscribe(() => this.dialogService.openSnackbar('schedule.testSent'))
  }

  checkIfNameUnique() {
    let rq = new CheckSchedulerOperationNameRQ(this.model.Id, this.model.Name);
    this.api.checkScheduleOperationName(rq).subscribe(res => {
      if (res.IsNameOk)
        this.ngForm.form.controls["Name"].setErrors(null);
      else
        this.ngForm.form.controls["Name"].setErrors({'invalid': true});
    });
  }

  save() {
    if (!this.isFormValid()) {
      this.validateAllFormFields(this.ngForm.form);
      return;
    }

    if (this.model.ServerType == EmailServerType.Google)
      this.cleanSmtpFields();

    switch (this.model.RecurrenceType) {
      case SchedulerRecurrenceType.EveryHourMinute:
        this.cleanDailyFields();
        this.cleanWeeklyFields();
        this.cleanMonthlyFields();
        break;

      case SchedulerRecurrenceType.Daily:
        this.cleanEveryHourFields();
        this.cleanWeeklyFields();
        this.cleanMonthlyFields();
        break;

      case SchedulerRecurrenceType.Weekly:
        this.cleanDailyFields();
        this.cleanEveryHourFields();
        this.cleanMonthlyFields();
        break;

      case SchedulerRecurrenceType.Monthly:
        this.cleanDailyFields();
        this.cleanWeeklyFields();
        this.cleanEveryHourFields();
        break;
    }

    this.api.addScheduledOperation(this.model).subscribe(res => {
      this.dialogService.openSnackbar('schedule.saveComplete');
      this.updateMenu.onItemAdded$.next('new-sch');
    })
  }

  async delete() {
    let btnYes = new BtnDialogConfig('dialog.yes', () => true);
    let btnNo = new BtnDialogConfig('dialog.no', () => false);
    let res: boolean = await firstValueFrom(this.dialogService.openMessageDialog(null, null, 'schedule.deleteText', false, btnYes, btnNo).afterClosed());

    if (res) {
      this.api.deleteScheduledOperation(this.model.Id).subscribe(() => {
        this.updateMenu.onItemAdded$.next('deleted-sch');
        this.router.navigate(['/schedule']);
      });
    }
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl)
        control.markAsTouched({ onlySelf: true });
      else if (control instanceof UntypedFormGroup)
        this.validateAllFormFields(control);
    });
  }

  private cleanSmtpFields() {
    this.model.Sender = null;
    this.model.Timeout = null;
    this.model.ServerSmtp = null;
    this.model.Ssl = null;
    this.model.DefaultCredentials = null;
    this.model.User = null;
    this.model.Password = null;
  }

  private cleanEveryHourFields() {
    this.model.HourTick = null;
    this.model.MinuteTick = null;
    this.model.FromHour = null;
    this.model.ToHour = null;
  }

  private cleanDailyFields() {
    this.model.DaysTick = null;
  }

  private cleanWeeklyFields() {
    this.model.WeekTick = null;
    this.model.DayOfWeek = null;
  }

  private cleanMonthlyFields() {
    this.model.MonthPeriod = null;
  }

  private setServerFields(serverEmailConfig: GroupEmailServerConfig) {
    if (!serverEmailConfig)
      return;

    this.model.Sender = serverEmailConfig.Sender;
    this.model.Timeout = serverEmailConfig.Timeout;
    this.model.ServerSmtp = serverEmailConfig.ServerSmtp;
    this.model.Ssl = serverEmailConfig.Ssl;
    this.model.DefaultCredentials = serverEmailConfig.DefaultCredentials;
    this.model.User = serverEmailConfig.User;
    this.model.Password = serverEmailConfig.Password; //! non dovrebbero tornate le password a frontend
  }
}
