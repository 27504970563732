<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content class="mat-typography">
  @if (data.type != messageType.Confirm) {
    <pre class="message"> {{data?.message}} </pre>
  }
  @if (data.type == messageType.Confirm) {
    <p>
      {{data?.message}}
    </p>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  @if (data.type == messageType.Confirm) {
    <button mat-raised-button [mat-dialog-close]="false" class="btn-light">{{'dialog.no' | translate}}</button>
  }
  <button mat-raised-button [mat-dialog-close]="true" class="btn-dark">{{'dialog.yes' | translate}}</button>
</mat-dialog-actions>