import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DwApiService } from '../../../services/dw-mart-api.service';

@Component({
  selector: 'app-historicized-years-dialog',
  templateUrl: './historicized-years-dialog.component.html',
  styleUrls: ['../../../utils/shared-form-layout.scss']
})
export class HistoricizeYearsDialogComponent implements OnInit {

  formGroup: FormGroup;
  years: any = {};
  selected: any;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data, private api: DwApiService, private translate: TranslateService) {
    this.years = data.years;
    this.selected = data.selected != undefined? data.selected: this.translate.instant('dialog.delete-selection');
    if(this.years.length == 1) this.selected = this.years[0];
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'year': this.selected? [this.selected, Validators.required] : [null, Validators.required]
    });
  }
}
