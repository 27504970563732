import { ViewContainerRef } from '@angular/core';
import { DialogPosition } from '@angular/material/dialog';

export interface IPromiseDialogResult {
    readonly result: Promise<DialogResult>;
}

export class DialogResult {
    public customData: any;
}

export class DialogOptions {
    constructor() {
        this.title = "globals.app.title";
        this.okButtonText = "interfaces.dialog.ok";
        this.cancelButtonText = "interfaces.dialog.cancel";
        this.isShowCancelButton = false;
        this.width = "";
        this.height = "";
        this.maxHeight = "";
        this.panelClass = "";
        this.backdropClass = "";
        this.isErrore = false;
        this.isLoading = false;
        this.disableClose = false;
        this.autoFocus = false;
    }

    id?: string;
    title?: string;
    message: string;
    messageParams: any;
    params?: any;
    okButtonText?: string;
    cancelButtonText?: string;
    isShowCancelButton: boolean;
    dialogPosition: DialogPosition;
    width: string;
    height: string;
    maxHeight: string;
    minHeight: string;
    minWidth: string;
    panelClass: string;
    backdropClass: string;
    isErrore: boolean;
    isLoading: boolean;
    disableClose: boolean;
    viewContainerRef: ViewContainerRef;
    autoFocus: boolean;
}

export class BtnDialogConfig {
    Text: string;
    Action: () => any;

    constructor(text: string, action: () => any) {
        this.Text = text;
        this.Action = action;
    }
}