import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHandlerFn, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../app/services/authentication.service';
import { environment } from "@environments/environment";
import {ConfigService} from "../app/services/config.service";

function getNewHeaders(token, id, session, headers: HttpHeaders, hasSession: boolean) {
  let newHeaders = headers;
  if (token)
    newHeaders = newHeaders.set('Authorization', `Bearer ${token}`);
  if (id)
    newHeaders = newHeaders.set('User-Id', id);
  if (hasSession && session)
    newHeaders = newHeaders.set('Session', session);
  return newHeaders;
}

export function xhrAuthInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  // add auth header with jwt if user is logged in and request is to the api url
  let authenticationService = inject(AuthenticationService);
  let configService= inject(ConfigService);
  const user = authenticationService.userValue;
  let token = !configService.getToken() ? null : configService.getToken().toString();
  let id = !configService.getUserId()? null : configService.getUserId().toString();
  let session = configService.configInstances.getSession()? configService.configInstances.getSession().toString(): '';
  const isLoggedIn = user && user.Token;
  const isApiUrl = request.url.startsWith(environment.urlApi);

  if ((isLoggedIn && isApiUrl)) {
    if (session) {
      request = request.clone({
        headers: getNewHeaders(user.Token, id, session, request.headers, true)
      });
    } else {
      request = request.clone({
        headers: getNewHeaders(user.Token, id, session, request.headers, false)
      });
    }
  } else if (request.url.endsWith('refresh-token') && token) {
    request = request.clone({
      headers: getNewHeaders(token, id, session, request.headers, true)
    });
  }

  return next(request);
}
