import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-loading-dialog",
  templateUrl: "./loading-dialog.component.html",
  styleUrls: ["./loading-dialog.component.scss"]
})
export class LoadingDialogComponent {
  message: string = '';
  subtitle: string;
  canStop: boolean = false;
  hoCapito: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string, canStop: boolean, hoCapito: boolean, subtitle?: string },
    private translate: TranslateService
  ) {
    this.message = data.message;
    this.canStop = data.canStop;
    this.hoCapito = data.hoCapito;
    if(data.subtitle) this.subtitle = data.subtitle;
  }
}
