import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CFFunction } from "./cffunction";
import { Field } from "./field";

@Injectable({
  providedIn: 'root'
})
export class ChecklistFunctionDatabase {
  dataChange = new BehaviorSubject<CFFunction[]>([]);
  treeData: CFFunction[];
  get data(): CFFunction[] { return this.dataChange.value; }

  constructor() {
    //this.initialize();
  }

  initialize(treeData: CFFunction[]) {
    this.treeData = treeData;
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = treeData;//this.buildFileTree(TREE_DATA, '0');
    // Notify the change.
    this.dataChange.next(data);
  }

  public filter(filterText: string) {
    let filteredTreeData: CFFunction[] = [];
    filterText = filterText.toLocaleLowerCase();
    if (filterText) {
      //console.log(this.treeData);
      //filteredTreeData = this.treeData.filter(d => d.title.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
      this.treeData.forEach(f => {
        if (f.children) {
          var childrensMatched = f.children.filter(c => c.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
          /**
           * new Field(title: string, name: string, type: string, isTable?: boolean, isMeasure?: boolean,
           * parentTitle?: string, parentName?: string, children?: Field[], fk?: string): Field
           */
          if (childrensMatched.length > 0) {
            filteredTreeData.push(new CFFunction(f.name, f.baseValue, f.preview, f.sqlServerValue, f.mySqlValue, f.description, f.acceptedTypes, f.functionType, childrensMatched));
          }
        }
      });
    } else {
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = filteredTreeData;//this.buildFileTree(filteredTreeData, '0');
    // Notify the change.
    this.dataChange.next(data);
  }
}
