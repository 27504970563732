import { Field } from "../models/field";
import { FieldNode } from "../models/fieldnode";
import {TranslateService} from "@ngx-translate/core";
import {HttpParams} from "@angular/common/http";
import {FormGroup} from "@angular/forms";
import {DwApiService} from "../services/dw-mart-api.service";
import {inject, Injectable} from "@angular/core";
import {ApiService} from "../services/api.service";

export class Validator {

    static isColumnName(col: string[], tables: any) {
        for(let e in tables) {
            if(tables[e].parentRef == col[0] && tables[e].item == col[1]) return tables[e];
            for(let i in tables[e].children) {
                if(tables[e].children[i].parentRef == col[0] && tables[e].children[i].item == col[1]) return tables[e].children[i];
            }
        }
    }
    static isNumericField(field: Field) { return ['int', 'float', 'decimal'].indexOf(field.type) > -1;}
    static isLiteralField(field: Field) { return ['varchar', 'char'].indexOf(field.type) > -1;}
    static isNumericValue(value: string) {return !isNaN(Number(value));}
    static isStringValue(value: string) {return typeof value === 'string';}
    static isYearValue(value: string) {return !isNaN(Number(value)) && value.length == 4;}
    static isNumericColumn(type: string) { return ['int', 'float', 'decimal'].indexOf(type) > -1;}
    static isDateTimeValue(value: string) {
        //let regExp = "/^\d{4}([./-])\d{2}\1\d{2}$/";

        return (/^\d{4}([./-])\d{2}\1\d{2}$/).test(value);

    }
    static isDateTimeField(field: Field) { return ['datetime'].indexOf(field.type) > -1;}
    static isSpecialString(value: any, items: FieldNode[], factTitle: string) {
        var f = value.field.split('].[');// [  ([e.parentTitle), (e.title])   ]
        var tableTitle = f[0].substring(1);
        var columnTitle = f[1].substring(0, f[1].length - 1);
        let ifn = items.find(i => i.parentTitle == tableTitle && i.title == columnTitle) == undefined?
         items.find(i => i.parentTitle == factTitle && i.title == columnTitle) : items.find(i => i.parentTitle == tableTitle && i.title == columnTitle);
        return ifn.name == 'PostalCode';
    }
    static isValidDate(d) { return d instanceof Date && !isNaN(d.getTime());}
    static isValidYear(y) { return (/^\d{4}$/).test(y); }

    static transform(value) : any {
        let arr = [];
        for (let key in value) {
          arr.push({key: key, value: value[key]});
        }
        return arr;
    }
}

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor(public api: DwApiService, public translate: TranslateService) {

  }


  validateName(name: string, formGroup: FormGroup) {
    if (name == '' || name == null || name.startsWith("-") || name.includes("-") || Validator.isNumericValue(name) || (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(name))
      return formGroup.controls['name'].setErrors({ message: this.translate.instant('dialog.not-valid-name')});
    else {
      this.api.isValidInterrogationName(name).subscribe((res) => {
        return res ? formGroup.controls['name'].setErrors(null) : formGroup.controls['name'].setErrors({message: this.translate.instant('dialog.not-valid-name')});
      });
    }
  }
}
