import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selected-files-table',
  templateUrl: './selected-files-table.component.html',
  styleUrls: ['./selected-files-table.component.scss']
})
export class SelectedFilesTableComponent {
  //_displayedColumns = ['name', 'type', 'size', 'lastModified'];
  _displayedColumns = ['name', 'lastModified', 'delete'];

  @Input()
  files: File[] = []; 

  constructor(public translate: TranslateService) {
   }

   delete() {
     this.files.pop();
   }

}