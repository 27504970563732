<mat-dialog-content class="mat-typography" [formGroup]="formGroup">
  <mat-form-field class="form-element">
    <mat-label>{{'dialog.interested-year' | translate}}</mat-label>
    <mat-select placeholder="{{'dialog.interested-year' | translate}}" formControlName="year" required [(value)]="selected">
      @for (y of years; track y) {
        <mat-option [value]="y">
          {{y}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button class="btn-light" mat-dialog-close>{{'dialog.close' | translate}}</button>
  <button mat-raised-button class="btn-dark" [mat-dialog-close]="{year: formGroup.get('year').value}"
  [disabled]="!formGroup.valid" >{{'dialog.proceed' | translate}}</button>
</mat-dialog-actions>