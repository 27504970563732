import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-etl-error-dialog",
  templateUrl: "./etl-error-dialog.component.html",
   styleUrls: ["./etl-error-dialog.component.scss"]
})
export class EtlErrorDialogComponent {
  message: string = '';
  subtitle: string;
  error: string;
  showError: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string, error: string, subtitle?: string },
    private translate: TranslateService
  ) {
    this.message = data.message;
    if(data.error) this.error = data.error;
    if(data.subtitle) this.subtitle = data.subtitle;
  }

  showDetails() {
    this.showError = !this.showError;
  }
}
