export class User {
    Id: number;
    Username: string;
    Password: string;
    Name: string;
    Group: string;
    Company: string;
    Lang: string;
    Token?: string;
    RefreshToken?: string;
}
