import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { DialogService } from "../app/dialogs/dialog.service";
import * as Sentry from "@sentry/angular";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private sentry: Sentry.SentryErrorHandler;

  constructor(
    private dialogService: DialogService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private zone: NgZone
  ) { 
    this.sentry = Sentry.createErrorHandler({ showDialog: false });
  }

  handleError(error: Error) {
    this.zone.run(() => {
      this.dialogService.hideDialog();

      if (error instanceof HttpErrorResponse) {
        if (![0, 400].includes(error.status)) {
          this.convertErrorToString(error);
          this.sentry.handleError(error);
        }

        if ([0].includes(error.status)) {
          this.dialogService.openErrorDialog("Server non attivo!", true, error.status);
          if (error.url.endsWith('UpdateUser')) {
            this.authenticationService.logout();
          }
        } else if ([401, 403].includes(error.status)) {
          sessionStorage.clear();
          this.cookieService.deleteAll('/');

          if (this.authenticationService.userValue)
            this.authenticationService.logout();
          else 
            this.authenticationService.goToLogin();

          this.dialogService.openErrorDialog(error.message, true, error.status);
        } else if ([400].includes(error.status) && error.url.endsWith('authenticate')) {
          this.dialogService.openErrorDialog(error.message ?? "Utente o password non corretti!", true, error.status);
        } else if ([406].includes(error.status) && error.url.endsWith('ReportPreview')) {
          this.dialogService.openErrorDialog(error.message ?? "Report non disponibile.", true, error.status);
        } else {
          this.dialogService.openErrorDialog(error.message ?? JSON.stringify(error), true, error.status);
        }
      } else {
        this.dialogService.openErrorDialog(
          error.message || "Undefined client error", true
        );
        this.convertErrorToString(error);
        this.sentry.handleError(error);
      }
    });
  }

  private convertErrorToString(error: any) {
    if (error?.error != null && typeof error.error === 'object') {
      error.error = JSON.stringify(error.error);
    }
  }
}
