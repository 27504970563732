import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilePickerDirective } from './file-picker-directive';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {

  selectedFiles = [];

  //@ViewChild('buttonPicker', { static: true })
  buttonPicker: FilePickerDirective;

  @ViewChild('dropZonePicker', { static: true })
  dropZonePicker: FilePickerDirective;

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private zone: NgZone
  ) { }

  onFilesChanged(files: FileList) {
    this.selectedFiles = [];
    for (let i = 0; i < 1; i++) {
      if(files[i].type == 'application/json'/*files[i].type == 'text/xml'*/)
        this.selectedFiles.push(files[i]);
      else {
        /*let message: string = "Devi selezionare solo file xml!"
        let type: MessageType = MessageType.Error;
        const dialogRef = this.dialog.open(EtlDialogComponent, {
          data: { message, type},
          maxHeight: "100%",
          //width: "540px",
          maxWidth: "100%",
          disableClose: true,
          hasBackdrop: true
        });*/
        this.zone.run(() =>
          this.dialogService.openErrorDialog(
            "Devi selezionare solo file json!", true
        ));
      }
    }
  }

  onReset() {
    this.selectedFiles = [];
  }

  reset() {
    this.buttonPicker.reset();
    this.dropZonePicker.reset();
  }

  isSelected = () => { return this.selectedFiles.length > 0 };

}
