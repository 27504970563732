<dx-filter-builder class="request-filter"
    [fields]="fields"
    [(value)]="filter"
    [customOperations]="customOperations"
    [groupOperations]="groupOperations"
    (onInitialized)="updateTexts($event)"
    (onValueChanged)="updateTexts($event)"
    (onOptionChanged)="onOptionChanged($event)">
    <dx-tag-box *dxTemplate="let condition of 'tagBoxTemplate'"
        [value]="condition.value"
        [items]="getItemsTagBox(condition)"
        (onValueChanged)="condition.setValue($event.value)"
        width="auto">
    </dx-tag-box>
</dx-filter-builder>
