import {InterrogationInfo} from "./interrogation-info";
import {GroupOptions} from "./groupoptions";
import { CompanyInfo } from "./usergroupoptionsetl";

export class GroupOptions2 {
  GroupOptions: GroupOptions;
  Dashboards: InterrogationInfo[] = [];
  Reports: InterrogationInfo[] = [];
  Surveys: InterrogationInfo[] = [];
  CompanyNames: CompanyInfo[] = [];

  constructor(
    GroupOptions: GroupOptions,
    Dashboards: InterrogationInfo[],
    Reports: InterrogationInfo[],
    Surveys: InterrogationInfo[],
    CompanyNames: CompanyInfo[]
  ) {
    this.GroupOptions = GroupOptions;
    this.Dashboards = Dashboards;
    this.Reports = Reports;
    this.Surveys = Surveys;
    this.CompanyNames = CompanyNames;
  }

  updateOptions(groupOptions: GroupOptions) {
    this.GroupOptions.CanAccessEmailConfig = groupOptions.CanAccessEmailConfig;
    this.GroupOptions.CanAccessSchOp = groupOptions.CanAccessSchOp;
    this.GroupOptions.CanCreate = groupOptions.CanCreate;
    this.GroupOptions.EtlRead = groupOptions.EtlRead;
    this.GroupOptions.EtlWrite = groupOptions.EtlWrite;
    this.GroupOptions.FilterCompany = groupOptions.FilterCompany;
    this.GroupOptions.Homepage = groupOptions.Homepage;
    this.GroupOptions.TabletMode = groupOptions.TabletMode;
    this.GroupOptions.TypeFilterActive = groupOptions.TypeFilterActive;
  }
}
