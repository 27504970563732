import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NavItem} from '../nav-item';
import {Router} from '@angular/router';
import {NavService} from '../nav.service';
import { TranslateService } from '@ngx-translate/core';
import {DwApiService} from '../../services/dw-mart-api.service';
import {animateText, onSideNavChange, onMainContentChange} from '../../../animations/animation';
import {Subscription} from 'rxjs';
import {UpdateMenuService} from '../../services/update-menu.service';
import {FilteredMenuService} from '../../services/filtered-menu.service';
import {FormBuilder, FormGroup } from '@angular/forms';
import devices from 'devextreme/core/devices';
import {AdditionalModules, LicenseType, ProductType} from '../../models/enums.model';
import {ChecklistmenuService} from "../../services/checklistmenu.service";
import {AuthenticationService} from "../../services/authentication.service";
import {DialogService} from "../../dialogs/dialog.service";
import {ConfigService} from "../../services/config.service";
import { User } from 'src/app/models/user';
import {GetUserInterrogationsRQ} from "../../models/web-api-models";

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
  animations: [onSideNavChange, animateText, onMainContentChange]
})
export class MenuListComponent implements OnInit, OnDestroy {

  onItemAdded: Subscription;
  @Output() logoutEvent: EventEmitter<any> = new EventEmitter<any>();
  public sideNavState: boolean = false;
  name: string = " Analytics";

  navItems: NavItem[] = [
    {
      id: -1,
      displayName: this.translate.instant('dashboard.route-title'),
      iconName: 'dashboard',
      canAdd: true,
      route: 'dashboard',
      children: [],
      addString: this.translate.instant('menu.new-dashboard'),
      order: 1,
      productType: this.configService.configInstances.getProductType()
    },
    {
      id: -1,
      displayName: this.translate.instant('report.route-title'),
      iconName: 'table_chart',
      canAdd: true,
      route: 'report',
      children: [],
      addString: this.translate.instant('menu.new-report'),
      order: 2,
      productType: this.configService.configInstances.getProductType()
    },
    {
      id: -1,
      displayName: this.translate.instant('survey.route-title'),
      iconName: 'manage_search',
      canAdd: true,
      route: 'survey',
      children: [],
      addString: this.translate.instant('menu.new-survey'),
      order: 3,
      productType: this.configService.configInstances.getProductType()
    },
    {
      id: -1,
      displayName: this.translate.instant('controlloGestione.titolo'),
      iconName: 'payments',
      canAdd: false,
      route: 'controlloGestione',
      children: [],
      order: 4,
      productType: this.configService.configInstances.getProductType()
    },
    {
      id: -1,
      displayName: this.translate.instant('etl.route-title'),
      iconName: 'settings',
      canAdd: false,
      route: 'etl',
      order: 5,
      productType: this.configService.configInstances.getProductType()
    },
    {
      id: -1,
      displayName: this.translate.instant('user-panel.route-title'),
      iconName: 'people_outline',
      canAdd: false,
      route: 'user-panel',
      order: 6,
      productType: this.configService.configInstances.getProductType()
    },
    {
      id: -1,
      displayName: 'Operazioni pianificate',
      iconName: 'schedule_send',
      canAdd: false,
      route: 'schedule',
      order: 7,
      productType: this.configService.configInstances.getProductType()
    },
  ];

  isReady: boolean = false;
  isMobile: boolean = false;
  readonly: boolean = true;
  formGroup: FormGroup;

  constructor(public navService: NavService,
              public router: Router,
              public translate: TranslateService,
              private api: DwApiService,
              private updateMenuService: UpdateMenuService,
              private authService: AuthenticationService,
              private database: ChecklistmenuService,
              public filteredService: FilteredMenuService,
              private formBuilder: FormBuilder,
              public dialogService: DialogService,
              private configService: ConfigService) {
    if (devices.current().deviceType === 'desktop') {
      this.sideNavState = true;
      this.navService.sideNavState$.next(this.sideNavState);
    }
    this.isMobile = devices.current().deviceType === 'phone';
    this.onItemAdded = this.updateMenuService.onItemAdded$.subscribe(e => {
      this.updateList(e);
    })
    this.navService.sideNavState$.subscribe(res => {
      this.sideNavState = res;
      this.readonly = true;
      this.formGroup.controls['search'].disable();
    })
    database.dataChange.subscribe(data => {
      this.navItems = data;
    });
    this.formGroup = this.formBuilder.group({
      search: [{value: '', disabled: true}]
    });
    this.formGroup.controls['search'].disable();
  }

  ngOnDestroy(): void {
    this.onItemAdded.unsubscribe();
  }

  toggleEdit() {
    this.readonly = false;
    this.formGroup.controls['search'].enable();
  }

  filterChanged(filterText: any) {
    let _filterText = filterText.target.value;
    this.database.filter(_filterText);
    if (_filterText) {
      this.filteredService.onFilterApplied$.next(true);
      this.filteredService.setState(true);
    } else {
      this.filteredService.onFilterApplied$.next(false);
      this.filteredService.setState(false);
    }
  }

  goToHomepage() {
    if (this.configService.configInstances.getHomepage() != null) this.router.navigate([this.configService.configInstances.getHomepage()]);
  }

  isMenu() {
    return this.configService.configInstances.getProductType() == ProductType.Menu;
  }

  isRetail() {
    return this.configService.configInstances.getProductType() == ProductType.Retail;
  }

  isPro() {
    return this.configService.configInstances.getProductType() == 1;
  }

  isWelcome() {
    return this.configService.configInstances.getProductType() == ProductType.Welcome;
  }

  onSidenavToggle() {
    this.sideNavState = !this.sideNavState;
    this.readonly = true;
    this.navService.sideNavState$.next(this.sideNavState);
    this.formGroup.controls['search'].disable();
  }

  setNavItems() {
    this.navItems = [
      {
        id: -1,
        displayName: this.translate.instant('dashboard.route-title'),
        iconName: 'dashboard',
        canAdd: true,
        route: 'dashboard',
        children: [],
        addString: this.translate.instant('menu.new-dashboard'),
        order: 1,
        productType: this.configService.configInstances.getProductType()
      },
      {
        id: -1,
        displayName: this.translate.instant('report.route-title'),
        iconName: 'table_chart',
        canAdd: true,
        route: 'report',
        children: [],
        addString: this.translate.instant('menu.new-report'),
        order: 2,
        productType: this.configService.configInstances.getProductType()
      },
      {
        id: -1,
        displayName: this.translate.instant('survey.route-title'),
        iconName: 'manage_search',
        canAdd: true,
        route: 'survey',
        children: [],
        addString: this.translate.instant('menu.new-survey'),
        order: 3,
        productType: this.configService.configInstances.getProductType()
      },
      {
        id: -1,
        displayName: this.translate.instant('controlloGestione.titolo'),
        iconName: 'payments',
        canAdd: false,
        route: '',
        children: [],
        order: 4,
        productType: this.configService.configInstances.getProductType()
      },
      {
        id: -1,
        displayName: this.translate.instant('etl.route-title'),
        iconName: 'settings',
        canAdd: false,
        route: 'etl',
        order: 5,
        productType: this.configService.configInstances.getProductType()
      },
      {
        id: -1,
        displayName: this.translate.instant('user-panel.route-title'),
        iconName: 'people',
        canAdd: false,
        route: 'user-panel',
        order: 6,
        productType: this.configService.configInstances.getProductType()
      },
      {
        id: -1,
        displayName: 'Operazioni pianificate',
        iconName: 'schedule_send',
        canAdd: true,
        route: 'schedule',
        children: [],
        order: 5,
        productType: this.configService.configInstances.getProductType()
      }
    ];
  }

  updateList(e?: string) {
    this.setNavItems();
    this.navItems.find(n => n.displayName == this.translate.instant('dashboard.route-title')).children = [];
    this.navItems.find(n => n.displayName == this.translate.instant('report.route-title')).children = [];
    this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title')).children = [];
    let group = this.configService.configInstances.getGroupName();//this.cookieService.get('group');
    let licenseInfo: LicenseType = this.configService.configInstances.getLicense();
    let canCreate: boolean = this.configService.configInstances.getCanCreate();
    let canAccessSchOp: boolean = this.configService.configInstances.getCanAccessSchOp();
    let etlRead: boolean = this.configService.configInstances.getEtlRead();
    let additionalModules: AdditionalModules = this.configService.configInstances.getAdditionalModules();
    var body: GetUserInterrogationsRQ = {Group: group}
    this.api.getUserInterrogations(body).subscribe((data) => {

      this.navItems.find(n => n.displayName == this.translate.instant('dashboard.route-title')).disabled = licenseInfo ? !canCreate : false;// !data.LicenseInfo || !canCreate;
      this.navItems.find(n => n.displayName == this.translate.instant('report.route-title')).disabled = licenseInfo ? !canCreate : false;//!licenseInfo || !canCreate;
      this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title')).disabled = licenseInfo ? !canCreate : false;//!licenseInfo || !canCreate;

      for (let d in data.Dashboards) {
        var itemDashboard = {
          id: Number(data.Dashboards[d].Id),
          displayName: data.Dashboards[d].Name.length > 20 ? data.Dashboards[d].Name.slice(0, 20) + '...' : data.Dashboards[d].Name,
          hintName: data.Dashboards[d].Name,
          iconName: 'brightness_1',
          canAdd: false,
          order: data.Dashboards[d].Order,
          route: 'dashboard/' + data.Dashboards[d].Id,
          //secondaryRoute: 'dashboard/' + encodeURIComponent(data.Dashboards[d].Name),
          productType: data.Dashboards[d].ProductType
        }
        this.navItems.find(n => n.displayName == this.translate.instant('dashboard.route-title')).children.push(itemDashboard);
      }

      var itemConfrontoCostiRicavi = {
        id: Number(1),
        displayName: this.translate.instant('controlloGestione.confrontoCostiRicavi.titolo'),//data.Dashboards[d].Name.length > 20 ? data.Dashboards[d].Name.slice(0, 20) + '...' : data.Dashboards[d].Name,
        //disabled?: boolean; può servire?
        hintName: this.translate.instant('controlloGestione.confrontoCostiRicavi.titolo'),//data.Dashboards[d].Name,
        iconName: 'brightness_1',
        canAdd: false,
        order: 1,
        route: 'controllo-gestione/conto-economico-budget',
        //secondaryRoute: 'controllo-gestione/conto-economico-budget',
        productType: this.configService.configInstances.getProductType()
      }

      this.navItems.find(n => n.displayName == this.translate.instant('controlloGestione.titolo')).children.push(itemConfrontoCostiRicavi);

      var itemRiepilogoCosti = {
        id: Number(2),
        displayName: this.translate.instant('controlloGestione.riepilogoCosti.titolo'),
        //disabled?: boolean; può servire?
        hintName: this.translate.instant('controlloGestione.riepilogoCosti.titolo'),
        iconName: 'brightness_1',
        canAdd: false,
        order: 1,
        route: 'controllo-gestione/riepilogo-mensile',
        //secondaryRoute: 'controllo-gestione/riepilogo-mensile',
        productType: this.configService.configInstances.getProductType()
      }

      if (this.configService.configInstances.getProductType() == ProductType.Welcome) {
        this.navItems.find(n => n.displayName == this.translate.instant('controlloGestione.titolo')).children.push(itemRiepilogoCosti);
      }


      var itemContoEconomicoPerReparto = {
        id: Number(3),
        displayName: this.translate.instant('controlloGestione.contoEconomicoPerReparto.titolo'),
        hintName: this.translate.instant('controlloGestione.contoEconomicoPerReparto.titolo'),
        iconName: 'brightness_1',
        canAdd: false,
        order: 1,
        route: 'controllo-gestione/conto-economico-per-reparto',
        //secondaryRoute: 'controllo-gestione/conto-economico-per-reparto',
        productType: this.configService.configInstances.getProductType()
      }

      this.navItems.find(n => n.displayName == this.translate.instant('controlloGestione.titolo')).children.push(itemContoEconomicoPerReparto);

      if (licenseInfo && canAccessSchOp) {
        for (let d in data.ScheduledOperations) {
          /**
           * Name = "rfrf"
           * Id = "1"
           * Write = true
           */
          var schOperations = {
            id: Number(data.ScheduledOperations[d].Id),
            displayName: data.ScheduledOperations[d].Name.length > 20 ? data.ScheduledOperations[d].Name.slice(0, 20) + '...' : data.ScheduledOperations[d].Name,
            //disabled?: boolean; può servire?
            hintName: data.ScheduledOperations[d].Name,
            iconName: 'brightness_1',
            canAdd: false,
            order: data.ScheduledOperations[d].Order,
            route: 'schedule/' + data.ScheduledOperations[d].Id,
            //secondaryRoute: 'schedule/' + encodeURIComponent(data.ScheduledOperations[d].Name),
            productType: data.ScheduledOperations[d].ProductType
          }
          this.navItems.find(n => n.displayName == this.translate.instant('Operazioni pianificate')).children.push(schOperations);
        }
      }
      for (let d in data.Reports) {
        var itemReport = {
          id: Number(data.Reports[d].Id),
          displayName: data.Reports[d].Name.length > 20 ? data.Reports[d].Name.slice(0, 20) + '...' : data.Reports[d].Name,
          //disabled?: boolean; può servire?
          hintName: data.Reports[d].Name,
          iconName: 'brightness_1',
          canAdd: false,
          order: data.Reports[d].Order,
          route: 'viewer/' + data.Reports[d].Id,
          //secondaryRoute: 'viewer/' + encodeURIComponent(data.Reports[d].Name),
          thirdRoute: 'report/' + encodeURIComponent(data.Reports[d].Name),
          productType: data.Reports[d].ProductType
        }
        this.navItems.find(n => n.displayName == this.translate.instant('report.route-title')).children.push(itemReport);
      }
      if (devices.current().deviceType !== 'phone') {
        for (let d in data.Surveys) {
          var item = {
            id: Number(data.Surveys[d].Id),
            displayName: data.Surveys[d].Name.length > 20 ? data.Surveys[d].Name.slice(0, 20) + '...' : data.Surveys[d].Name,
            //disabled?: boolean; può servire?
            hintName: data.Surveys[d].Name,
            iconName: 'brightness_1',
            canAdd: false,
            order: data.Surveys[d].Order,
            route: 'survey/' + encodeURIComponent(data.Surveys[d].Id),
            //route: 'survey/' + data.Surveys[d].Name,
            //secondaryRoute: 'survey/' + encodeURIComponent(data.Surveys[d].Id),
            productType: data.Surveys[d].ProductType
          }
          this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title')).children.push(item);
        }
      } else {
        this.navItems.splice(this.navItems.indexOf(this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title'))), 1);
      }

      if (!etlRead) {
        let etlIndex = this.navItems.indexOf(this.navItems.find(n => n.displayName == this.translate.instant('etl.route-title')));
        this.navItems.splice(etlIndex, 1);
      }
      if (additionalModules != undefined) {
        if (additionalModules == AdditionalModules.None) {
          let cdgIndex = this.navItems.indexOf(this.navItems.find(n => n.displayName == this.translate.instant('controlloGestione.titolo')));
          this.navItems.splice(cdgIndex, 1);
        }
      }
      if (!canAccessSchOp) {
        let schOpIndex = this.navItems.indexOf(this.navItems.find(n => n.displayName == 'Operazioni pianificate'));
        this.navItems.splice(schOpIndex, 1);
      }
      this.reorderNavItems();
      this.database.initialize(this.navItems);
      this.isReady = true;
    }, (err => {
      throw err;
    }));
  }

  reorderNavItems() {
    if (this.navItems.find(n => n.displayName == this.translate.instant('dashboard.route-title')) &&
      !this.navItems.find(n => n.displayName == this.translate.instant('dashboard.route-title')).disabled &&
      this.navItems.find(n => n.displayName == this.translate.instant('dashboard.route-title')).children.length > 0) {
      this.navItems.find(n => n.displayName == this.translate.instant('dashboard.route-title')).children.sort((a, b) => {
        if (a.order > b.order) {
          if (b.order == 0) return -1;
          else return 1;
        } else if (a.order < b.order) {
          if (a.order == 0) return 1;
          else return -1;
        } else return 0;
      });
    }
    if (this.navItems.find(n => n.displayName == this.translate.instant('report.route-title')) &&
      !this.navItems.find(n => n.displayName == this.translate.instant('report.route-title')).disabled &&
      this.navItems.find(n => n.displayName == this.translate.instant('report.route-title')).children.length > 0) {
      this.navItems.find(n => n.displayName == this.translate.instant('report.route-title')).children.sort((a, b) => {
        if (a.order > b.order) {
          if (b.order == 0) return -1;
          else return 1;
        } else if (a.order < b.order) {
          if (a.order == 0) return 1;
          else return -1;
        } else return 0;
      });
    }
    if (this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title')) &&
      !this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title')).disabled &&
      this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title')).children.length > 0) {
      this.navItems.find(n => n.displayName == this.translate.instant('survey.route-title')).children.sort((a, b) => {
        if (a.order > b.order) {
          if (b.order == 0) return -1;
          else return 1;
        } else if (a.order < b.order) {
          if (a.order == 0) return 1;
          else return -1;
        } else return 0;
      });
    }
  }


  ngOnInit() {
    this.updateList();
  }

  logout() {
    let ref = this.getDialogRef();
    ref.afterClosed().subscribe((res) => {
      if (res) {
        let user = new User();
        user.Id = this.authService.userValue.Id;
        user.Name = this.authService.userValue.Name;
        user.Group = this.authService.userValue.Group;

        this.api.removeUser(user).subscribe(
          {
            next: () => {
              this.authService.logout();
              this.logoutEvent.emit();
            },
            error: err => {
              throw err;
            }
          });
      }
    });
  }

  getDialogRef() {
    return this.dialogService.openMessageDialog("dialog.confirm", null, "login.logout-confirm");
  }
}
