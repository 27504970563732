import {ProductType} from "../models/enums.model";

export class NavItem {
    id: number;
    displayName: string;
    hintName?: string;
    disabled?: boolean;
    iconName: string;
    canAdd: boolean;
    route?: string;
    secondaryRoute?: string;
    thirdRoute?: string;
    children?: NavItem[];
    interrogationId?: string;
    addString?: string;
    order: number;
    productType: ProductType;

    constructor (
        id: number,
        displayName: string,
        iconName: string,
        canAdd: boolean,
        order: number,
        productType: ProductType,
        hintName?: string,
        disabled?: boolean,
        route?: string,
        secondaryRoute?: string,
        thirdRoute?: string,
        children?: NavItem[],
        interrogationId?: string,
        addString?: string)
    {
       this.id = id;
       this.displayName = displayName;
       this.iconName = iconName;
       this.canAdd = canAdd;
       this.order = order;
       this.productType = productType;
       if(hintName) this.hintName = hintName;
       if(disabled) this.disabled = disabled;
       if(route) this.route = route;
       if(secondaryRoute) this.secondaryRoute = secondaryRoute;
       if(thirdRoute) this.thirdRoute = thirdRoute;
       if(children) this.children = children;
       if(interrogationId) this.interrogationId = interrogationId;
       if(addString) this.addString = addString;

    }
}

