import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {DwApiService} from "../../services/dw-mart-api.service";
import {DateRangeDialogResType, TimeLimitType, TimeLimitTypeMapping} from "../../models/enums.model";
import {firstValueFrom} from "rxjs";
import {DateRange} from "../../models/web-api-models";
import {DateRangeDialogRes} from "../../models/dialogs-model";

@Component({
  selector: 'app-date-range-dialog',
  templateUrl: './date-range-dialog.component.html',
  styleUrls: ['./date-range-dialog.component.scss']
})
export class DateRangeDialogComponent implements OnInit {

  formGroup: FormGroup;
  dateRange: DateRange;
  periods: string[] = [];
  @Input() id: string;
  isReady: boolean = false;
  public timeLimitTypeList = Object.values(TimeLimitType).filter(value => typeof value === 'number' && value != 0);
  public timeLimitTypeMapList = TimeLimitTypeMapping;

  constructor(private formBuilder: FormBuilder, /*@Inject(MAT_DIALOG_DATA) data,*/ private api: DwApiService, private translate: TranslateService) {
    this.periods.push(this.translate.instant('date-range.days'));
    this.periods.push(this.translate.instant('date-range.months'));
    this.periods.push(this.translate.instant('date-range.years'));
    //this.id = data.id;
  }

  async ngOnInit() {
    this.dateRange = await firstValueFrom(this.api.getDateRange(this.id));
    this.createForm();
    this.isReady = true;
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'number': [this.dateRange.TimeLimit, Validators.required],
      'period': [this.dateRange.TimeLimitType, Validators.required]
    });
  }

  dateRangeValidator(number: number, period: TimeLimitType, formGroup: FormGroup) {
      let n = number;
      let m = period;
      if((n > 2 && m == TimeLimitType.Year) || (n > 24 && m == TimeLimitType.Month) || (n > 730 && m == TimeLimitType.Day)) {
        return formGroup.controls['period'].setErrors({message: this.translate.instant('date-range.error-msg')});
      } else {
        return this.formGroup.controls['period'].setErrors(null);
      }
  }

  close(): DateRangeDialogRes {
    //{action: 'update', limit: formGroup.get('number').value, period: formGroup.get('period').value}
    let dateRange: DateRange = new DateRange();
    dateRange.TimeLimit = this.formGroup.controls['number'].value;
    dateRange.TimeLimitType = this.formGroup.controls['period'].value;
    let res: DateRangeDialogRes = {
      Type: DateRangeDialogResType.Update,
      DateRange: dateRange
    }
    return res;
  }
}
