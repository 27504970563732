import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {FieldType, InterrogationType, ScheduledOperationType} from "../models/enums.model";
import {
  AcknowledgeRS,
  AddDashboardDatasourceRQ, AddInterrogationRQ, AddMonthLimitRQ, ApplyHistoricizedFiltersRQ, ApplyRequestedFiltersRQ,
  CheckSchedulerOperationNameRQ,
  CheckSchedulerOperationNameRS,
  CopyInterrogationRQ, DateRange, ExportDashboardDataRQ, ExportPdfDashboardRQ, ExpressionRQ, FtYearsCountRS,
  GetConfigDataRQ,
  GetConfrontoCostiRicaviRQ,
  GetContoEconomicoDettaglioRQ,
  GetContoEconomicoPerRepartoRQ,
  GetGroupNameRQ,
  GetGroupPermissionsRS,
  GetInterrogationsInfoRS,
  GetOrderListRQ,
  GetRiepilogoCostiRQ,
  GetScheduledOperationRS,
  GetServerEmailConfigRS, GetUserInterrogationsRQ,
  GoogleAuthUrl,
  GroupOptionsSingleRS, InitializeRS,
  InterrogationExportConfig, InterrogationId,
  LoginRQ,
  ModifyDataSourceRQ,
  ModifyInterrogationDescriptionRQ,
  ModifyInterrogationNameRQ,
  ModifyRecipientServerEmailRQ,
  SendTestEmailRQ, SetDateRangeRQ,
  UpdateInterrogationVisibilityRQ,
  UpdateUserRQ,
  ValidateFormulaRQ,
  WebApiResponse
} from '../models/web-api-models';
import { Field } from '../models/field';
import {environment} from "@environments/environment";
import {Permission} from "../models/permission";
import { ApiService } from './api.service';
import {DataSource, Fact, TableInfo, ViewInfo} from "../models/dialogs-model";
import { DaInterrogation, EmailServerConfig, ScheduledOperation } from '../models/schedule.model';
import { instanceToPlain } from 'class-transformer';
import { LogService } from './log.service';
import {GroupSettings, UserGroupOptionsRestricted, UserInterrogations} from '../models/usergroupoptionsetl';
import { User } from '../models/user';

const EMPTY_STRING = '';
@Injectable({
  providedIn: 'root'
})
export class DwApiService extends ApiService {
  url: string;
  private readonly VERSION = ''; //'/v1'

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    logService: LogService
  ) {
    super(httpClient, logService);
    this.url = `${environment.urlApi}api`; //api/v1
  }

  getProductInfo = () => this.get(this.VERSION + "/DW/GetProductInfo");
  getAvailableFTS(type: InterrogationType) {
    let params = new HttpParams();
    params = params.append('type', type.toString());
    return this.get<TableInfo[]>(this.VERSION + "/DW/GetAvailableFTS", params);
  }
  getAvailableDS(id: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.get<DataSource[]>(this.VERSION + "/DW/GetAvailableDS", params);
  }
  getDSInfo = (params: HttpParams) => this.httpClient.get(this.url + "/DW/GetDSInfo", {params: params});
  getFT(fact: string) {
    let params = new HttpParams();
    params = params.append('fact', fact);
    return this.get<ViewInfo[]>(this.VERSION + "/Fact/GetFT", params);
  }
  getFTResources = (params: HttpParams) => this.httpClient.get(this.url + "/Fact/GetFTResources", {params: params});
  getInterrogationDescr = (params: HttpParams) => this.httpClient.get(this.url + "/DW/GetInterrogationDescription", {params: params});
  deleteInterrogation(id: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.delete<AcknowledgeRS>(this.VERSION + "/DW/DeleteInterrogation", params);
  }
  deleteDataSource(idInterrogation: string, idDataSource: string, type: InterrogationType) {
    let params = new HttpParams();
    params = params.append('idInterrogation', idInterrogation);
    params = params.append('idDataSource', idDataSource);
    params = params.append('type', type);
    return this.delete<AcknowledgeRS>(this.VERSION + "/DW/DeleteDataSource", params);
  }
  isValidInterrogationName(name: string) {
    let params = new HttpParams();
    params = params.append('name', name);
    return this.get<boolean>(this.VERSION + "/DW/IsValidInterrogationName", params);
  }
  isValidDataSourceName = (params: HttpParams) => this.httpClient.get(this.url + "/DW/IsValidDataSourceName", {params: params});
  getDataSourceName = (params: HttpParams) => this.httpClient.get(this.url + "/DW/GetDataSourceName", {params: params});
  addNewReport = (body: AddInterrogationRQ) => this.post<InterrogationId>(this.VERSION + "/Report/AddNewReport", body);
  //(body: AddDashboardRQ) => this.post<InterrogationId>("/Dashboard/AddNewDashboard", body);
  //addDashboardDatasource = (body) => this.httpClient.post(this.url + "/Dashboard/AddDashboardDatasource", body);
  addDashboardDatasource = (body: AddDashboardDatasourceRQ) => this.post<AcknowledgeRS>(this.VERSION + "/Dashboard/AddDashboardDatasource", body);
  addNewDashboard = (body: AddInterrogationRQ) => this.post<InterrogationId>(this.VERSION + "/Dashboard/AddNewDashboard", body);
  //modifyDataSource = (body) => this.httpClient.post(this.url + "/DW/ModifyDataSource", body);
  modifyDataSource(body: ModifyDataSourceRQ) {
    return this.post<InterrogationId>(this.VERSION + "/DW/ModifyDataSource", body);
  }
  modifyDescription(id: number, body: ModifyInterrogationDescriptionRQ) {
    return this.post<InterrogationId>(this.VERSION + `/DW/ModifyDescription/${id}`, body);
  }
  //getReportDesignerModel = (body) => this.http.post(this.url + "/ReportDesigner/GetReportDesignerModel", body);
  getETLInfo = () => this.get(this.VERSION + "/Etl/GetETLInfo");
  //getUsersGroup = () => this.http.get(this.url + "/DW/GetUsersGroup");
  getGroupSettings = () => this.get<GroupSettings[]>(this.VERSION + "/DW/GetGroupSettings");
  //getGroupSettings22 = () => this.get<GroupSettings[]>(this.VERSION + "/DW/GetGroupSettings");
  getGroupOptionsEtl = (body) => this.post(this.VERSION + "/DW/GetGroupOptionsEtl", body);
  getUserInterrogations = (body: GetUserInterrogationsRQ) => this.post<UserInterrogations>(this.VERSION + "/DW/GetUserInterrogations", body);
  getGroupOptionsSingle(id: string, group: string, type: InterrogationType) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('group', group);
    params = params.append('interrogationType', type);
    return this.get<GroupOptionsSingleRS>(this.VERSION + "/DW/GetGroupOptionsSingle", params);
  }
  getInterrogationPermission = (params: HttpParams) => this.httpClient.get(this.url + "/DW/GetInterrogationPermission", {params: params});
  getGroupPermission = (params: HttpParams) => this.httpClient.get(this.url + "/DW/GetGroupPermissions", {params: params});
  getGroups = () => this.get(this.VERSION + "/DW/GetGroups");
  getGroupsPermissions(interrogationId?: number) {
    let params = new HttpParams();
    if (interrogationId)
      params = params.append('interrogationId', interrogationId);
    return this.get<GetGroupPermissionsRS>(this.VERSION + "/DW/GetGroupsPermissions", params);
  }
  GetInterrogationsInfo(interrogationId?: number)  {
    let params = new HttpParams();
    if (interrogationId)
      params = params.append('interrogationId', interrogationId);
    return this.get<GetInterrogationsInfoRS>(this.VERSION + "/DW/GetInterrogationsInfo", params);
  }
  //addPermissions = (body) => this.httpClient.post(this.url + "/DW/AddPermissions", body);
  getFactList = (params: HttpParams) => this.httpClient.get(this.url + "/Etl/GetFactList", {params: params});
  getFTInfo = (params: HttpParams) => this.httpClient.get(this.url + "/Etl/GetFTInfo", {params: params});
  getStoricalYear = (params: HttpParams) => this.httpClient.get(this.url + "/Etl/GetStoricalYear", {params: params});
  resetDW = (body) => this.httpClient.post(this.url + "/Etl/ResetDW", body);
  ftMaintenance = (body) => this.httpClient.post(this.url + "/Etl/FTMaintenance", body);
  startETLExecution = (body) => this.httpClient.post(this.url + "/Etl/StartETLExecution", body);
  updateETL = (body) => this.httpClient.post(this.url + "/Etl/UpdateETL", body);
  getETLState = (params: HttpParams) => this.httpClient.get(this.url + "/Etl/GetETLState", {params: params});
  stopETLExecution = (body) => this.httpClient.post(this.url + "/Etl/StopETLExecution", body);
  //addGroupOptions = (body) => this.httpClient.post(this.url + "/DW/AddGroupOptions", body);
  //addAllGroupOptions = (body) => this.httpClient.post(this.url + "/DW/AddAllGroupOptions", body);

  addGroup = (body: GetGroupNameRQ) => this.httpClient.post(this.url + "/Group/AddGroup", body);
  deleteGroup = (id: number) => this.delete(this.VERSION + `/Group/DeleteGroup/${id}`);

  updateOptions = (body) => this.httpClient.post(this.url + "/DW/UpdateOptions", instanceToPlain(body));
  //getUserGroupOptions = (body) => this.http.post(this.url + "/DW/GetUserGroupOptions", body);
  exportDashboard = (body, options) => this.httpClient.post(this.url + "/data/PerformExportAction", body, options);
  exportInterrogation = (body) => this.httpClient.post(this.url + "/DW/ExportInterrogation", body, {responseType: 'blob'});
  exportInterrogationJson = (body: CopyInterrogationRQ) => this.post(this.VERSION + "/DW/ExportInterrogationJson", body);
  importDashboard = (body, headers) => this.httpClient.post(this.url + "/Dashboard/ImportInterrogation", body, headers);
  importInterrogationJson = (body) => this.httpClient.post(this.url + "/DW/ImportInterrogationJson", body);
  importSurveyJson = (body) => this.post(this.VERSION + "/DW/ImportSurveyJson", body);
  importReport = (body, headers) => this.httpClient.post(this.url + "/Report/ImportInterrogation", body, headers);
  //checkDBConnection = (body) => this.httpClient.post(this.url + "/Etl/CheckDBConnection", body);
  checkETLName = (params: HttpParams) => this.httpClient.get(this.url + "/Etl/CheckETLName", { params: params});
  checkSourceName = (params: HttpParams) => this.httpClient.get(this.url + "/Etl/CheckSourceName", { params: params});
  addETL = (body) => this.httpClient.post(this.url + "/Etl/AddEtl", body);
  addNewSurvey = (body) => this.post(this.VERSION + "/Smart/AddNewSurvey", body, null, true);
  addNewSurvey_ = (params: HttpParams) => this.httpClient.get(this.url + "/Smart/AddNewSurvey", {params: params});
  addNewPivot_ = (params: HttpParams) => this.httpClient.get(this.url + "/Smart/AddNewPivot", {params: params});
  addNewPivot = (body) => this.httpClient.post(this.url + "/Smart/AddNewPivot", body);
  getTotalCountPivot_ = (params: HttpParams) => this.httpClient.get(this.url + "/Smart/GetTotalCount", {params: params});
  getTotalCountPivot = (body) => this.httpClient.post(this.url + "/Smart/GetTotalCount", body);
  getDistinct = (params: HttpParams) => this.httpClient.get(this.url + "/DW/GetDistinct", {params: params});
  getFTDatasources(fact: string) {
    let params = new HttpParams();
    params = params.append('fact', fact);
    return this.get<DataSource[]>(this.VERSION + "/DW/GetFTDatasources", params);
  }
  modifyInterrogationName (body: ModifyInterrogationNameRQ) {
    return this.post<InterrogationId>(this.VERSION + "/DW/ModifyInterrogationName", body);
  }
  //previewReport = (body) => this.httpClient.post(this.url + "/Report/ReportPreview", body, {responseType: 'arraybuffer'});
  getEtlLastError = () => this.httpClient.get(this.url + "/Etl/GetLastError");
  getHistoricizedYears(fact: string) {
    let params = new HttpParams();
    params = params.append('fact', fact);
    return this.get<number[]>(this.VERSION + "/Etl/GetHistoricizedYears", params);
  }
  getAvailableLanguage = () => this.httpClient.get(this.url + "/DW/GetAvailableLanguage");
  copyInterrogation = (body: CopyInterrogationRQ) => this.post<InterrogationId>(this.VERSION + "/DW/CopyInterrogation", body);
  getDefaultToImport(interrogationType: InterrogationType) {
    let params = new HttpParams();
    params = params.append('fact', interrogationType);
    return this.get<InterrogationExportConfig[]>(this.VERSION + "/DW/GetDefaultInterrogationsToImport", params);
  }
  updateVisibility = (body: UpdateInterrogationVisibilityRQ) => this.post<InterrogationId>(this.VERSION + "/DW/UpdateVisibility", body);
  updateDefaultInterrogations = (body) => this.httpClient.post(this.url + "/DW/UpdateDefaultInterrogations", body);
  updateOrder = (body: GetOrderListRQ[]) => this.httpClient.post<GetOrderListRQ[]>(this.url + "/DW/ReorderInterrogations", body);
  getUpdatedLog = (body) => this.httpClient.post(this.url + "/Etl/GetUpdatedLog", body);
  getDateRange (id: string) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.get<DateRange>(this.VERSION + "/DW/GetDateRange", params);
  }
  setDateRange = (body: SetDateRangeRQ) => this.post<InterrogationId>(this.VERSION + "/DW/SetDateRange", body);
  applyCompanyFilter = (body) => this.httpClient.post(this.url + "/Dashboard/ApplyCompanyFilter", body);
  applyDateFilter = (body) => this.httpClient.post(this.url + "/Dashboard/ApplyDateFilter", body);
  applyHistoricizedFilter = (body: ApplyHistoricizedFiltersRQ) => this.post<InterrogationId>(this.VERSION +"/Dashboard/ApplyHistoricizedFilter", body);
  resetHistoricizedFilter = (body: InterrogationId) => this.post<InterrogationId>(this.VERSION +"/Dashboard/ResetHistoricizedFilter", body);
  applyMonthLimit = (body: AddMonthLimitRQ) => this.post(this.VERSION +"/Dashboard/ApplyMonthLimit", body);
  resetMonthLimit = (body: InterrogationId) => this.post( this.VERSION +"/Dashboard/ResetMonthLimit", body);
  applyTodayLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyTodayLimit", body);
  applyYesterdayLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyYesterdayLimit", body);
  applyThisWeekLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyThisWeekLimit", body);
  applyLastWeekLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyLastWeekLimit", body);
  applyThisMonthLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyThisMonthLimit", body);
  applyLastMonthLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyLastMonthLimit", body);
  applyThisYearLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyThisYearLimit", body);
  applyLastYearLimit = (body: InterrogationId) => this.post(this.VERSION +"/Dashboard/ApplyLastYearLimit", body);
  applyLastSevenDaysLimit = (body: InterrogationId) => this.post(this.VERSION + "/Dashboard/ApplyLastSevenDaysLimit", body);
  clearCache = (body) => this.httpClient.post(this.url + "/Cache/Clear", body);
  getLog = () => this.httpClient.get(this.url + '/DW/GetLog', {responseType: 'text'});
  addUser = (body) => this.httpClient.post(this.url + "/UsersLogin/LogUser", body);
  removeUser = (body: User) => this.post(this.VERSION + "/UsersLogin/LogOutUser", body);
  updateUser = (body: UpdateUserRQ) => this.post(this.VERSION + "/UsersLogin/UpdateUser", body);
  login = (body: LoginRQ) => this.post<User>(this.VERSION + "/UsersLogin/Authenticate", body);
  refreshToken = (body) => this.post<String>(this.VERSION + "Users/refresh-token", body);
  applyRequestedFilters = (body: ApplyRequestedFiltersRQ) => this.post<InterrogationId>(this.VERSION + "/Report/ApplyRequestedFilters", body);
  resetRequestedFilters = (body: InterrogationId) => this.post<InterrogationId>(this.VERSION + "/Report/ResetRequestedFilters", body);
  modifyGroupPermissions = (body: GetGroupPermissionsRS) => this.post(this.VERSION + "/DW/ModifyGroupPermissions", body);
  saveSurvey = (body) => this.post<InterrogationId>(this.VERSION + "/Smart/SaveSurvey", body);
  deleteSurvey = (body) => this.httpClient.post(this.url + "/Smart/DeleteSurvey", body);
  importConfig = (body: GetConfigDataRQ) => this.httpClient.post<GetConfigDataRQ>(this.url + "/DW/ImportConfig", body);
  isValidAliasName = (params: HttpParams) => this.httpClient.get(this.url + "/CalculatedField/IsValidAliasName", { params: params });
  validateExpression = (body: ExpressionRQ) => this.httpClient.post<ExpressionRQ>(this.url + "/CalculatedField/ValidateExpression", body);
  validateFormula = (body: ValidateFormulaRQ) => this.httpClient.post<ValidateFormulaRQ>(this.url + "/CalculatedField/ValidateFormula", body);
  exportSurvey = (params: HttpParams) => this.httpClient.get(this.url + "/Scheduler/ExportSurvey", { params: params });
  getInterrogationsToSchedule(type: ScheduledOperationType) {
    let params = new HttpParams();
    params = params.append('type', type);
    return this.get<DaInterrogation[]>(this.VERSION + "/Scheduler/GetInterrogationsToSchedule", params);
  }
  sendTestEmail = (body: SendTestEmailRQ) => this.post(this.VERSION + "/Scheduler/SendTestEmail", body);
  checkScheduleOperationName = (body: CheckSchedulerOperationNameRQ) => this.post<CheckSchedulerOperationNameRS>(this.VERSION + "/Scheduler/CheckScheduleName", body);
  addScheduledOperation = (body: ScheduledOperation) => this.post<Number>(this.VERSION + "/Scheduler/AddScheduledOperation", instanceToPlain(body));
  deleteScheduledOperation = (id: number) => this.delete(this.VERSION + `/Scheduler/DeleteScheduledOperation/${id}`);
  getScheduledOperation = (id: number) => this.get<GetScheduledOperationRS>(this.VERSION + `/Scheduler/GetScheduledOperation/${id}`);
  getScheduledOperationPermissions = (params: HttpParams) => this.httpClient.get(this.url + "/Scheduler/GetScheduledOperationPermissions", {params: params});
  modifyScheduledOperationPermissions = (params: HttpParams) => this.httpClient.get(this.url + "/Scheduler/GetScheduledOperationPermissions", {params: params});
  addScheduledOperationPermissions = (body) => this.httpClient.post(this.url + "/Scheduler/AddPermissions", body);
  getGroupPermissionsSchOperations = () => this.httpClient.get(this.url + "/Scheduler/GetGroupPermissions" );
  hasScheduledOperations = (params: HttpParams) => this.httpClient.get(this.url + "/Scheduler/HasScheduledOperation", { params: params } );
  getFtYearsCount = () => this.get<FtYearsCountRS>(this.VERSION + "/DW/GetFtYearsCount");
  exportPdfDashboard = (body: ExportDashboardDataRQ) => this.httpClient.post(this.url + "/Dashboard/ExportDashboard", body, {responseType: 'blob'}); /*, {responseType: 'blob'});*/

  initialize = () => this.post<InitializeRS>(this.VERSION + "/DW/Initialize");

  //ControlloGestione
  getConfrontoCostiRicaviV2 = (body: GetConfrontoCostiRicaviRQ) => this.httpClient.post<WebApiResponse>(this.url + "/AccountUsali/GetConfrontoCostiRicaviV2", body);
  getRiepilogoCosti = (body: GetRiepilogoCostiRQ) => this.httpClient.post<WebApiResponse>(this.url + "/AccountUsali/GetRiepilogoCosti", body);
  getContoEconomicoPerReparto = (body: GetContoEconomicoPerRepartoRQ) => this.httpClient.post<WebApiResponse>(this.url + "/AccountUsali/GetContoEconomicoPerReparto", body);
  getContoEconomicoDettaglio = (body: GetContoEconomicoDettaglioRQ) => this.httpClient.post<WebApiResponse>(this.url + "/AccountUsali/GetContoEconomicoDettaglio", body);
  getLocations = () => this.httpClient.get(this.url + "/AccountUsali/GetLocations");
  sendEmailExportConfig = (params: HttpParams) => this.httpClient.get(this.url + "/Scheduler/SendEmailExport", { params: params } );
  getServerEmailConfig = (id: number) => this.get<GetServerEmailConfigRS>(this.VERSION + `/ServerEmailConfig/GetConfigByGroup/${id}`);
  addServerEmailConfig = (body: EmailServerConfig) => this.post(this.VERSION + "/ServerEmailConfig/AddGroupConfig", instanceToPlain(body));
  //addGroupConfigByName = (body) => this.httpClient.post(this.url + "/ServerEmailConfig/AddGroupConfigByName", body );
  //deleteServerEmailConfig = (body) => this.httpClient.post(this.url + "/ServerEmailConfig/DeleteConfig", body );
  getServerEmailConfigByName(groupName: string) {
    let params = new HttpParams();
    params = params.append('groupName', groupName);
    return this.get<GetServerEmailConfigRS>(this.VERSION + "/ServerEmailConfig/GetConfigByGroupName", params);
  };
  getServerEmailSurveyConfigByName(groupName: string, idInterrogation: string) {
    let params: HttpParams = new HttpParams();
    params = params.append('groupName', groupName);
    params = params.append('idInterrogation', idInterrogation);
    return this.get<GetServerEmailConfigRS>(this.VERSION + "/ServerEmailConfig/GetSurveyConfigByGroupName", params);
  }
  sendEmailExport = (params: HttpParams) => this.httpClient.get(this.url + "/ServerEmailConfig/SendEmailExport", { params: params } );
  modifyRecipient = (body: ModifyRecipientServerEmailRQ) => this.httpClient.post<ModifyRecipientServerEmailRQ>(this.url + "/ServerEmailConfig/ModifyRecipient", body);
  //addLog = (body) => this.httpClient.post(this.url + "/Dashboard/AddLog", body);
  getData = (params: HttpParams) => this.httpClient.get(this.url + "/Api/GetData", {params: params});
  customExportSurvey = (params: HttpParams) => this.httpClient.get(this.url + "/Smart/ExportSurvey", {params: params, responseType: 'blob'});

  googleAuthenticate = () => this.get<GoogleAuthUrl>(this.VERSION + "/GoogleApi/Authenticate");



  private contains(obj: Fact[], term: string): boolean {
    for (let key in obj){
        if(obj[key].Name.indexOf(term) != -1) return true;
    }
    return false;
  }

  /*
    - factSchema è lo schema del fatto
      es.  {tableName: "DIMHCompany", tableTitle: "Azienda",…}
              columnNames: {Id: {int: 1}, SourceId: {int: 4}, CompanyName: {string: 0}, PostalCode: {string: 0},…}
              columnTitles: ["Id", "Id gestionale", "Ragione sociale", "CAP", "Città", "Provincia", "Regione", "Nazione"]
              tableName: "DIMHCompany"
              tableTitle: "Azienda"

    - factList è la lista dei fatti disponibili
      es. {tableName: "FTHBill", tableTitle: "Conto"}

  */
  parseFactSchema(factSchema: ViewInfo[], factList: Fact[]): Field[] {
    let arr: Field[] = [];
    factSchema.forEach(e => {
      let items: Field[] = [];
      //k è il nome della tabella
      //v è il tipo della colonna -> v (decimal|int) ? misura : dimensione
      let i = 0;
      for(let columnName in e.Names) {
        var columnType = e.Names[columnName][0].Type;
        var fieldType = e.Names[columnName][0].FieldType;
        var functionType = e.Names[columnName][0].FunctionType;
        var format = e.Names[columnName][0].Format;
        var columnTitle = e.Titles[i];
        var isEnum = e.Names[columnName][0].IsEnum;
        var grouped = false;

          if(fieldType != FieldType.ForeignKey) {

            if(this.contains(factList, e.Name)) {
              if(fieldType == FieldType.Simple || fieldType == FieldType.Key || fieldType == FieldType.CompanyKey)
                items.push(new Field(columnTitle, columnName, columnType, isEnum/*, grouped*/, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING, false, false, this.translate.instant('dw.descriptive-fields'), e.Name, functionType, fieldType));
              else if(fieldType == FieldType.Metric || fieldType == FieldType.Calculated)
                items.push(new Field(columnTitle, columnName, columnType, isEnum/*, false*/, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING, false, true, this.translate.instant('dw.metrics'), e.Name, functionType, fieldType));
              else arr.push(new Field(columnTitle, columnName, columnType, isEnum, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING, false, true, e.Title, e.Name, functionType, fieldType));
            }
            else e.Fk != null? items.push(new Field(columnTitle, columnName, columnType, isEnum, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING, false, false, e.Title, e.Name, functionType, fieldType, undefined, e.Fk)):
            items.push(new Field(columnTitle, columnName, columnType, isEnum, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING, false, false, e.Title, e.Name, functionType, fieldType));
        }
        i++;
      }
      if(items.length > 0){
        if(items.filter(i => i.parentTitle == this.translate.instant('dw.descriptive-fields')).length > 0)
          arr.push(new Field(this.translate.instant('dw.descriptive-fields'), e.Name, undefined, isEnum/*, false*/, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING,true, false, undefined, undefined, fieldType, undefined,
          items.filter(i => i.parentTitle == this.translate.instant('dw.descriptive-fields')).sort((a, b) => (a.title < b.title) ? -1 : 1)));
        if(items.filter(i => i.parentTitle == this.translate.instant('dw.metrics')).length > 0)
          arr.push(new Field(this.translate.instant('dw.metrics'), e.Name, undefined, isEnum/*, false*/, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING,true, false, undefined, undefined,  fieldType, undefined,
          items.filter(i => i.parentTitle == this.translate.instant('dw.metrics')).sort((a, b) => (a.title < b.title) ? -1 : 1)));
        if(items.filter(i => i.parentTitle == this.translate.instant('dw.descriptive-fields')).length == 0 &&
        items.filter(i => i.parentTitle == this.translate.instant('dw.metrics')).length == 0)
          arr.push(new Field(e.Title, e.Name, undefined, isEnum/*, false*/, format, e.Title, false, EMPTY_STRING, EMPTY_STRING, EMPTY_STRING, true, false, undefined, undefined, fieldType, undefined, items.sort((a, b) => (a.title < b.title) ? -1 : 1)));
      }
    });
    return arr;
  }

  parseGroups(groups): Permission[] {
    let arr: Permission[] =[];
    groups.forEach(g => {
      g.startsWith("ad")? arr.push(new Permission(g, true, true, true, true)) : arr.push(new Permission(g, false, false, false, false));
    });
    return arr;
  }
}
