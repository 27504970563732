import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Field } from "./field";

@Injectable({
  providedIn: 'root'
})
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<Field[]>([]);
  treeData: Field[];
  get data(): Field[] { return this.dataChange.value; }

  constructor() {
    //this.initialize();
  }

  initialize(treeData: Field[]) {
    this.treeData = treeData;
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = treeData;//this.buildFileTree(TREE_DATA, '0');
    // Notify the change.
    this.dataChange.next(data);
  }

  public filter(filterText: string) {
    let filteredTreeData: Field[] = [];
    filterText = filterText.toLocaleLowerCase();
    if (filterText) {
      //console.log(this.treeData);
      //filteredTreeData = this.treeData.filter(d => d.title.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
      this.treeData.forEach(f => {
        if (f.children) {
          var childrensMatched = f.children.filter(c => c.title.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
          /**
           * new Field(title: string, name: string, type: string, isTable?: boolean, isMeasure?: boolean,
           * parentTitle?: string, parentName?: string, children?: Field[], fk?: string): Field
           */
          if (childrensMatched.length > 0) {
            filteredTreeData.push(new Field(f.title, f.name, f.type, f.isEnum, f.format, f.tableTitle,
              f.isCalculatedField, f.sqlExpression, f.sqlServerExpression, f.mySqlExpression, f.isTable,
              f.isMeasure, f.parentTitle, f.parentName, f.functionType, f.fieldType, childrensMatched, f.fk));
          }
        }
      });
    } else {
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = filteredTreeData;//this.buildFileTree(filteredTreeData, '0');
    // Notify the change.
    this.dataChange.next(data);
  }
}
