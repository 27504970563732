import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { camelCase, startCase } from 'lodash';
import { LogService } from './log.service';
import { LogType } from '../models/enums.model';

export type type_options = {
  body?: any,
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'response';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  transferCache?: boolean;
};

export class ApiService {
  public static readonly DE_KEY_PARAMS = ['filter', 'group', 'groupSummary', 'parentIds', 'requireGroupCount', 'requireTotalCount', 'searchExpr', 'searchOperation', 'searchValue', 'select', 'sort', 'skip', 'take', 'totalSummary', 'userData'];
  public static readonly INTERVAL_30m: number = 1800000; //30m
  public static readonly INTERVAL_30s: number = 30000; //30s
  public static readonly INTERVAL_60s: number = 60000; //60s
  public static readonly INTERVAL_10s: number = 10000; //10s
  public static readonly INTERVAL_2s: number = 2000; //2s

  protected timeOut: number = ApiService.INTERVAL_30m;

  constructor(
    private http: HttpClient,
    private logService: LogService
  ) {

  }

  /**
 * HTTP `PATCH` request wrapper.
 * @param endpoint Endpoint to call.
 * @param body
 * @returns The response of the request.
 */
  protected patch<T>(endpoint: string, body: any, skipPascalize?: boolean, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json'): Observable<T> {//To fix, look method down
    return this.request<T>("PATCH", endpoint, { body: body, responseType: responseType }, skipPascalize);
  }

  /**
   * HTTP `PUT` request wrapper.
   * @param endpoint Endpoint to call.
   * @param body
   * @param responseType Type of the response.
   * @returns The response of the request.
   */
  protected put<T>(endpoint: string, body: any, skipPascalize?: boolean, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json'): Observable<T> {//To fix, look method down
    return this.request<T>("PUT", endpoint, { body: body, responseType: responseType }, skipPascalize);
  }

  /**
   * HTTP `GET` request wrapper.
   * @param endpoint Endpoint to call.
   * @param parameters
   * @param responseType Type of the response.
   * @returns The response of the request.
   */
  protected get<T>(endpoint: string, parameters?: HttpParams, skipPascalize?: boolean, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json'): Observable<T> {//To fix, look method down
    return this.request<T>("GET", endpoint, { params: parameters, responseType: responseType, transferCache: true }, skipPascalize);
  }
  /**
   * HTTP `DELETE` request wrapper.
   * @param endpoint Endpoint to call.
   * @param parameters
   * @param responseType Type of the response.
   * @returns The response of the request.
   */
  protected delete<T>(endpoint: string, parameters?: HttpParams, skipPascalize?: boolean, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json'): Observable<T> {//To fix, look method down
    return this.request<T>("DELETE", endpoint, { params: parameters, responseType: responseType }, skipPascalize);
  }
  /**
   * HTTP `POST` request wrapper.
   * @param endpoint Endpoint to call.
   * @param body Body of the request.
   * @param responseType Type of the response.
   * @returns The response of the request.
   */
  protected post<T>(endpoint: string, body?: any, parameters?: HttpParams, skipPascalize?: boolean, responseType: 'arraybuffer' | 'blob' | 'json' | 'text' = 'json'): Observable<T> {
    return this.request<T>("POST", endpoint, { params: parameters, body: body, responseType: responseType }, skipPascalize)
  }

  /**
   * HTTP Request wrapper.
   * @param method "POST" or "GET".
   * @param endpoint Endpoint to call
   * @param options
   * @param interval If a response is not received after this interval, an error will be thrown.
   * @returns The response of the request.
   */
  private request<T>(method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE", endpoint: string, options: type_options, skipPascalize: boolean = false): Observable<T> {
    options = this.getCompleteHeaders(options);
    let url = `${environment.urlApi}${environment.branch}${endpoint}`;
    return this.http.request(method, url, options).pipe(
      tap(x => this.logService.log(x, LogType.Api)),
      timeout(this.timeOut),
      map((x: HttpResponse<any>) => {
        if (skipPascalize)
          return <T>(x.body);
        else
          return pascalize<T>(x.body);
      }));
  }


  /**
   * Utility method to add the observe: 'response' header.
   * @param options the Object containing the headers
   * @returns The object passed with the modified headers.
   */
  private getCompleteHeaders(options: type_options): type_options {
    return { observe: 'response', ...options };
  }

  private sleep(ms: number) {
    return new Promise(resolve => setInterval(resolve, ms));
  }
}

const pascalize = <T>(obj) => <T>pascalizeKeys(obj);

const pascalizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => pascalizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [toPascalCase(key)]: pascalizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const toPascalCase = (str) => startCase(camelCase(str)).replace(/ /g, '')
