import {FieldType, FunctionType} from "./enums.model";

export class Field {
    title: string;
    name: string;
    tableTitle: string = undefined;
    isTable?: boolean;
    isMeasure?: boolean = false;
    parentTitle?: string = undefined;
    parentName?: string = undefined;
    functionType?: FunctionType;
    fieldType?: FieldType;
    children?: Field[] = [];
    type: string;
    fk: string;
    format: string;
    //grouped: boolean;
    isEnum?: boolean;
    isCalculatedField: boolean;
    sqlExpression: string;
    sqlServerExpression: string;
    mySqlExpression: string;


    constructor(title: string, name: string, type: string, isEnum: boolean, format: string, tableTitle: string, isCalculatedField: boolean, sqlExpression: string, sqlServerExpression: string,
        mySqlExpression: string, isTable?:boolean, isMeasure?: boolean, parentTitle?: string, parentName?: string, functionType?: FunctionType,  fieldType?: FieldType, children?: Field[], fk?: string) {
        this.title = title;
        this.name = name;
        this.isEnum = isEnum;
        this.format = format;
        this.tableTitle = tableTitle;
        //this.grouped = grouped;
        this.isCalculatedField = isCalculatedField;
        this.sqlExpression = sqlExpression;
        this.sqlServerExpression = sqlServerExpression;
        this.mySqlExpression = mySqlExpression;
        if(isTable) this.isTable = isTable;
        if(isMeasure) this.isMeasure = isMeasure;
        if(parentName) this.parentName = parentName;
        if(parentTitle) this.parentTitle = parentTitle;
        if(children) this.children = children;
        if(type) this.type = type;
        if(functionType != null) this.functionType = functionType;
        if(fieldType != null) this.fieldType = fieldType;
        if(fk) this.fk = fk;
    }
}

export class Dimension {
    isDimension?: boolean = false;
    parentRef?: string = undefined;

    constructor(isDimension?: boolean, parentRef?: string) {
        if(isDimension) this.isDimension = isDimension;
        if(parentRef) this.parentRef = parentRef;
    }

}
