import { Component, Inject } from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import { TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"]
})
export class ErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; error: boolean, status?: number, title?: string }, public translate: TranslateService
  ) {
    //this.translate.use('it');
  }
}
