import {Transform} from "class-transformer";

export class GroupOptions {
    Id: number;
    Name: string;
    EtlRead: boolean;
    EtlWrite: boolean;
    TypeFilterActive: boolean;
    FilterCompany: string;
    @Transform(value => <number>value.value, { toClassOnly: true })
    @Transform(value => <string>value.value, { toPlainOnly: true })
    Homepage: number;
    CanCreate: boolean;
    CanAccessSchOp: boolean;
    CanAccessEmailConfig: boolean;
    GoogleAuthToken: string;
    TabletMode: boolean;

    constructor(
      id?: number,
      name?: string,
      etlRead?: boolean,
      etlWrite?: boolean,
      typeFilterActive?: boolean,
      filterCompany?: string,
      homepageId?: number,
      canCreate?: boolean,
      canAccessSchOp?: boolean,
      canAccessEmailConfig?: boolean,
      googleAuthToken?: string
    ) {
        this.Id = id;
        this.Name = name;
        this.EtlRead = etlRead;
        this.EtlWrite = etlWrite;
        this.TypeFilterActive = typeFilterActive;
        this.FilterCompany = filterCompany;
        this.Homepage = homepageId;
        this.CanCreate = canCreate;
        this.CanAccessSchOp = canAccessSchOp;
        this.CanAccessEmailConfig = canAccessEmailConfig;
        this.GoogleAuthToken = googleAuthToken
    }
}

