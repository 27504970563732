import {BehaviorSubject} from "rxjs";
import {NavItem} from "../menu/nav-item";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ChecklistmenuService {

  dataChange = new BehaviorSubject<NavItem[]>([]);
  treeData: NavItem[];
  get data(): NavItem[] { return this.dataChange.value; }

  constructor() {
    //this.initialize();
  }

  initialize(treeData: NavItem[]) {
    this.treeData = treeData;
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = treeData;//this.buildFileTree(TREE_DATA, '0');
    // Notify the change.
    this.dataChange.next(data);
  }

  public filter(filterText: string) {
    let filteredTreeData: NavItem[] = [];
    filterText = filterText.toLocaleLowerCase();
    if (filterText) {
      //console.log(this.treeData);
      //filteredTreeData = this.treeData.filter(d => d.title.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
      this.treeData.forEach(f => {
        if (f.children) {
          var childrensMatched = f.children.filter(c => c.displayName.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
          /**
           * new Field(title: string, name: string, type: string, isTable?: boolean, isMeasure?: boolean,
           * parentTitle?: string, parentName?: string, children?: Field[], fk?: string): Field
           */
          if (childrensMatched.length > 0) filteredTreeData.push(
            new NavItem(f.id, f.displayName, f.iconName, f.canAdd, f.order, f.productType, f.hintName, f.disabled, f.route, f.secondaryRoute, f.thirdRoute, childrensMatched, f.interrogationId, f.addString));
        }
      });
    } else {
      filteredTreeData = this.treeData;
    }

    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = filteredTreeData;//this.buildFileTree(filteredTreeData, '0');
    // Notify the change.
    this.dataChange.next(data);
  }
}
