import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import devices from 'devextreme/core/devices';
import {firstValueFrom, map} from 'rxjs';
import {InterrogationType, LicenseType} from '../../models/enums.model';
import {GroupOptions} from '../../models/groupoptions';
import {AuthenticationService} from '../../services/authentication.service';
import {DwApiService} from '../../services/dw-mart-api.service';
import {UpdateMenuService} from '../../services/update-menu.service';
import {DialogService} from '../../dialogs/dialog.service';
import {AllPermissionDialogComponent} from '../all-permission-dialog/all-permission-dialog.component';
import {GroupOptions2} from "../../models/group-options2";
import {ConfigService} from "../../services/config.service";
import {EmailServerConfigComponent} from '../email-server-config/email-server-config.component';
import {EmailServerConfig} from 'src/app/models/schedule.model';
import {CompanyInfo, GroupSettings, UserGroupOptionsRestricted} from 'src/app/models/usergroupoptionsetl';
import { HttpHeaders } from '@angular/common/http';
import { GetGroupNameRQ } from 'src/app/models/web-api-models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BtnDialogConfig } from 'src/app/models/dialog-box.model';

interface Homepage {
  displayName: string;
  id: number;
}

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrl: './user-panel.component.scss'
})
export class UserPanelComponent implements OnInit {

  isReady: boolean = false;
  hasChanges: boolean = false;
  onSaving: boolean = false;
  groups: GroupOptions2[] = [];
  selectedGroup: number;
  selectedCompany: number[];
  selectedHomepage: Homepage;
  etlRead: boolean = false;
  etlWrite: boolean = false;
  typeFilter: boolean = false;
  canCreate: boolean = false;
  canAccessSchOp: boolean = false;
  canAccessEmailConfig: boolean = false;
  availableInterrogations: Homepage[] = [];
  availableCompanies: any[] = [];
  hasActiveChanged: boolean = false;
  canAccess : boolean = false;
  userGroup: string = '';
  isBasic: boolean = false;
  canModifyPermissions: boolean = this.configService.configInstances.getGroupName() == 'administrator';
  tabletMode: boolean = sessionStorage.getItem('device') == 'tablet';
  isDesktop: boolean = devices.current().deviceType === 'desktop';
  currentModel: GroupOptions = new GroupOptions();
  groupName: string = '';
  isDeleteDisabled: boolean = false;

  constructor(
    private api: DwApiService,
    private dialogService: DialogService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
    private updateMenu: UpdateMenuService,
    private authenticationService: AuthenticationService,
    public configService: ConfigService,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.dialogService.openLoadingDialog(false);
    this.api.getGroupSettings().subscribe((data) => {
      this.init(data);
    });
  }

  canDeactivate() {
    if (this.hasChanges  && this.authenticationService.userValue) {
      return this.dialogService.getDialogRefDeactivate(this.translate.instant('dialog.go-away')).pipe(map(result => {
        if(!result) this.dialogService.hideDialog();
        return result === true;
      }));
    } else return true;
  }

  getGroupsInfo() {
    this.groups = [];
    this.availableCompanies = [];
    this.dialogService.openLoadingDialog(false);
    this.api.getGroupSettings().subscribe((userGroupInfo) => {
      this.init(userGroupInfo);
    });
  }

  init(userGroupInfo: GroupSettings[]) {
    this.isBasic = this.configService.configInstances.getLicense() == LicenseType.Basic;
    this.userGroup = this.configService.configInstances.getGroupName();
    this.canAccess = this.configService.isAdministratorGroup();
    for (let ugi of userGroupInfo) {
      if((this.userGroup != 'administrator' && ugi.GroupOptions.Name == this.userGroup) ||
      this.userGroup == 'administrator') {
        this.groups.push(new GroupOptions2(
          new GroupOptions(
            ugi.GroupOptions.Id,
            ugi.GroupOptions.Name,
            ugi.GroupOptions.EtlRead,
            ugi.GroupOptions.EtlWrite,
            ugi.GroupOptions.TypeFilterActive,
            ugi.GroupOptions.FilterCompany,
            Number(ugi.GroupOptions.Homepage),
            ugi.GroupOptions.CanCreate,
            ugi.GroupOptions.CanAccessSchOp,
            ugi.GroupOptions.CanAccessEmailConfig,
            ugi.GroupOptions.GoogleAuthToken
          ),
          ugi.Dashboards,
          ugi.Reports,
          ugi.Surveys,
          ugi.CompanyNames
        ));
      }
    }
    let group = this.selectedGroup ? this.selectedGroup : this.configService.configInstances.getGroupId();//this.cookieService.get('group');
    let userGroup = this.groups.find(gr => gr.GroupOptions.Id == group);
    this.selectedGroup = this.groups.map(g => g.GroupOptions.Id).find(gr => gr == group);
    this.currentModel = userGroup.GroupOptions;
    if (this.currentModel.Name == "administrator") {
      this.isDeleteDisabled = true;
    } else {
      this.isDeleteDisabled = false;
    }
    this.setAvailableInterrogations(userGroup);
    this.availableCompanies.push({Name: 'dialog.delete-selection', Value: ''});
    userGroup.CompanyNames.forEach((c: CompanyInfo) => this.availableCompanies.push({Name: c.Name, Value: c.Id}));
    this.setSelectedCompany(userGroup);
    this.dialogService.hideDialog();
   this.ref.detectChanges();
  }

  setAvailableInterrogations(group: GroupOptions2) {
    this.availableInterrogations = [];
    const addAvailableInterrogations = (items, type) =>{
      items.forEach(item =>{
        this.availableInterrogations.push({
          displayName: item.Name + type,
          id: +item.Id
        });
      })
    }
    addAvailableInterrogations(group.Dashboards, ' ( Dashboard )');
    addAvailableInterrogations(group.Reports, ' ( Report )');
    addAvailableInterrogations(group.Surveys, ' ( Indagine )');

    if(group.GroupOptions.EtlRead) {
      this.availableInterrogations.push({
        displayName: 'etl.opt-title',
        id: -1
      })
    }
    this.isReady = true;
  }

  findInterrogationType(group: GroupOptions2, id: number) {
    return group.Dashboards.some(dashboard => dashboard.Id == id) ? InterrogationType.Dashboard :
            group.Reports.some(report => report.Id == id) ? InterrogationType.Report :
            group.Surveys.some(survey => survey.Id == id) ? InterrogationType.Smart :
            InterrogationType.NotDefined;
  }

  findInterrogationName(group: GroupOptions2, id: number) {
    return group.Dashboards.find(dashboard => dashboard.Id == id)?.Name ||
          group.Reports.find(report => report.Id == id)?.Name ||
          group.Surveys.find(survey => survey.Id == id)?.Name ||
          '';
  }

  private setSelectedCompany(group: GroupOptions2){
    this.selectedCompany = group.GroupOptions.FilterCompany.split(';').map(x=>+x);
  }

  onGroupSelection(e, v) {
    this.selectedGroup = v[0].value.GroupOptions.Id;
    let group = this.groups.find(g => g.GroupOptions.Id == this.selectedGroup);
    this.setSelectedCompany(group);
    this.currentModel = group.GroupOptions;

    if (group.GroupOptions.Name == "administrator") {
      this.isDeleteDisabled = true;
    } else {
      this.isDeleteDisabled = false;
    }

    let interrogationType: InterrogationType = this.findInterrogationType(group, group.GroupOptions.Homepage);
    let interrogationName: string = this.findInterrogationName(group, group.GroupOptions.Homepage);
    /*console.log('typeInt: '+interrogationType);
    console.log('nameInt: '+interrogationName);*/
    let displayName = interrogationType  == InterrogationType.Dashboard? interrogationName +' ( Dashboard )' :
      interrogationType  == InterrogationType.Report? interrogationName +' ( Report )' : interrogationName +' ( Indagine )';
    this.selectedHomepage = {displayName: displayName, id: group.GroupOptions.Homepage};
    this.etlRead = group.GroupOptions.EtlRead;
    this.etlWrite = group.GroupOptions.EtlWrite;
    this.typeFilter = group.GroupOptions.TypeFilterActive;
    this.canCreate = group.GroupOptions.CanCreate;
    this.canAccessSchOp = group.GroupOptions.CanAccessSchOp;
    this.canAccessEmailConfig = group.GroupOptions.CanAccessEmailConfig;
    this.setAvailableInterrogations(group);
  }

  companyChanged(e) {
      this.currentModel.FilterCompany = (<Array<string>> e.value).join(';');
      this.updateGroups()
  }

  homepageChanged(e) {
    let group = this.groups.find(g => g.GroupOptions.Id == this.selectedGroup);
    let interrogationType: InterrogationType = this.findInterrogationType(group, group.GroupOptions.Homepage);
    let interrogationName: string = this.findInterrogationName(group, group.GroupOptions.Homepage);
    let displayName = interrogationType  == InterrogationType.Dashboard? interrogationName +' ( Dashboard )' :
      interrogationType  == InterrogationType.Report? interrogationName +' ( Report )' : interrogationName +' ( Indagine )';
    this.selectedHomepage = {displayName: displayName, id: group.GroupOptions.Homepage};
    this.currentModel = this.groups.filter(g => g.GroupOptions.Id == this.selectedGroup).map(g => { g.GroupOptions.Homepage = this.selectedHomepage.id; return g.GroupOptions })[0];
    this.updateGroups();
  }

  checkBoxClickActivate(e) {
    this.hasActiveChanged = true;
    //controllo modalità tablet
    if(this.tabletMode) devices.current().deviceType = 'tablet';
    else devices.current().deviceType = devices.current().deviceType;
    this.updateGroups();
  }

  checkBoxClick(e) {
    this.updateGroups();
  }

  updateGroups(){
    let groupToEdit = this.groups.find(x => x.GroupOptions.Id == this.currentModel.Id);
    groupToEdit.updateOptions(this.currentModel);
    this.hasChanges = true;
  }

  saveGroupOptions(reload: boolean) {
    this.hasChanges = false;
    this.onSaving = true;
    var body = this.groups;

    this.api.updateOptions(body).subscribe({next: () => {
      if (reload) {
        this.getGroupsInfo();
        this.onSaving = false;
        //console.log(this.configService.configInstances.getGroupName());
        let temp = body.find(x => x.GroupOptions.Id == this.configService.configInstances.getGroupId());
        this.configService.configInstances.setHomepage(temp.GroupOptions.Homepage);
      }
      if(this.tabletMode) sessionStorage.setItem('device', devices.current().deviceType);
    }, error: (err) => { this.onSaving = false; this.hasChanges = true; throw err; }});
  }

  async deleteGroup() {
    let group = this.groups.find(g => g.GroupOptions.Id == this.selectedGroup).GroupOptions;

    if (group && group.Name != "administrator") {
      let btnYes = new BtnDialogConfig('dialog.yes', () => true);
      let btnNo = new BtnDialogConfig('dialog.no', () => false);
      let confirmDelete: boolean = await firstValueFrom(this.dialogService.openMessageDialog(null, null, 'user-panel.deleteGroup', false, btnYes, btnNo).afterClosed());
      
      if (confirmDelete) {
        this.dialogService.openLoadingDialog(false);
          this.api.deleteGroup(group.Id).subscribe(() => {
            this.selectedGroup = null;
            this.getGroupsInfo();
          });
        } else{
          console.error('Gruppo non eliminato');
        }
    }
    
  }
  
  addGroup() {
    var body= new GetGroupNameRQ;
    body.groupName = this.groupName;
    this.dialogService.openLoadingDialog(false);
    this.api.addGroup(body).subscribe(() => {
        this.dialogService.openSnackbar(this.translate.instant('user-panel.createGroup'), this.translate.instant('snackbar.close'));
        this.groupName = '';
        this.getGroupsInfo();
      }
    );
  }
  
  openPermissionDialog() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = '80vh';
    const dialogRef = this.dialogService.openModalComponent(AllPermissionDialogComponent, dialogConfig);
    let componentInstance = dialogRef.componentInstance as AllPermissionDialogComponent;
    componentInstance.onSaved.subscribe(x => this.getGroupsInfo());
  }

  openEmailConfig() {
    var goid = this.groups.find(gr => gr.GroupOptions.Id == this.selectedGroup)?.GroupOptions?.Id;

    if (goid) {
      this.api.getServerEmailConfig(goid).subscribe(res => {
        const dialogRef = this.dialogService.openModalComponent(EmailServerConfigComponent);
        const componentInstance = dialogRef.componentInstance as EmailServerConfigComponent;
        componentInstance.model = new EmailServerConfig(res.ServerEmailConfig, goid);
        componentInstance.groupId = goid;
      });
    }
  }
}
