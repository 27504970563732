<div class="container">
  <span mat-dialog-title class="title" [class]="'product-' + configService.productTypeName">
    {{ 'permission.title' | translate }}
  </span>
  <div mat-dialog-content class="content">
    @if (!interrogationId) {
      <mat-form-field class="w-100">
        <input type="text" matInput [placeholder]="'permission.filterPlaceholder' | translate" [(ngModel)]="textFilter" (input)="filterChange()">
      </mat-form-field>
    }

    <mat-accordion class="accordion">
      @for (group of groupsPermissions; track group.Id) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>{{ group.Name }}</mat-panel-title>
            <mat-panel-description>
              @if (interrogationId) {
                {{ 'permission.read' | translate }}: {{group.ReadIds.includes(interrogationId) ? 1 : 0}} |
                {{ 'permission.write' | translate }}: {{group.WriteIds.includes(interrogationId) ? 1 : 0}}
              } @else {
                {{ 'permission.read' | translate }}: {{group.ReadIds.length}} |
                {{ 'permission.write' | translate }}: {{group.WriteIds.length}}
              }
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            @if (dashboards?.length > 0) {
              <ng-template
                [ngTemplateOutlet]="table"
                [ngTemplateOutletContext]="{ interrogations: dashboards, group: group, type: 'dashboards'}">
              </ng-template>
            }
            @if (reports?.length > 0) {
              <ng-template
                [ngTemplateOutlet]="table"
                [ngTemplateOutletContext]="{ interrogations: reports, group: group, type: 'reports'}">
              </ng-template>
            }
            @if (surveys?.length > 0) {
              <ng-template
                [ngTemplateOutlet]="table"
                [ngTemplateOutletContext]="{ interrogations: surveys, group: group, type: 'surveys'}">
              </ng-template>
            }
          </ng-template>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
  <div mat-dialog-actions class="actions" align="end">
    <button mat-raised-button mat-dialog-close class="btn-light">{{ 'dialog.close' | translate }}</button>
    <button mat-raised-button class="btn-dark" (click)="save()">{{ 'dialog.save' | translate }}</button>
  </div>
</div>

<ng-template #table let-group="group" let-interrogations="interrogations" let-type="type">
  <br/>
  <table mat-table [dataSource]="interrogations">
    <!-- Interrogation Column -->
    <ng-container matColumnDef="interrogation">
      <th mat-header-cell *matHeaderCellDef>{{ ('permission.' + type) | translate }}</th>
      <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
    </ng-container>

    <!-- Read Column -->
    <ng-container matColumnDef="read">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'permission.read' | translate }}
        @if (!interrogationId) {
          <mat-checkbox
            [indeterminate]="getGroupCheckbox(group, interrogations, false, true)"
            [checked]="getGroupCheckbox(group, interrogations, false, false)"
            [disabled]="configService.configInstances.getGroupName() == group.Name || group.Name == 'administrator'"
            (change)="setGroupCheckbox(group, interrogations, false, $event.checked)">
          </mat-checkbox>
        }
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          [checked]="getCheckboxValue(group, element, false)"
          [disabled]="configService.configInstances.getGroupName() == group.Name || group.Name == 'administrator'"
          (change)="setCheckboxValue(group, element, false, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Write Column -->
    <ng-container matColumnDef="write">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'permission.write' | translate }}
        @if (!interrogationId) {
          <mat-checkbox
            [indeterminate]="getGroupCheckbox(group, interrogations, true, true)"
            [checked]="getGroupCheckbox(group, interrogations, true, false)"
            [disabled]="configService.configInstances.getGroupName() == group.Name || group.Name == 'administrator'"
            (change)="setGroupCheckbox(group, interrogations, true, $event.checked)">
          </mat-checkbox>
        }
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          [checked]="getCheckboxValue(group, element, true)"
          [disabled]="configService.configInstances.getGroupName() == group.Name || group.Name == 'administrator'"
          (change)="setCheckboxValue(group, element, true, $event.checked)">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>