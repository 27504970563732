import { NgModule } from "@angular/core";
import { RecipientInputComponent } from './recipient-input/recipient-input.component';
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import {CustomSnackbarComponent} from "./custom-snackbar/custom.snackbar.component";

const sharedComponents = [
  RecipientInputComponent,
  CustomSnackbarComponent
];

@NgModule({
  declarations: [...sharedComponents],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [...sharedComponents]
})
export class SharedModule {}
