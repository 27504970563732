<h1 mat-dialog-title style="color: red;">
  <mat-icon style="vertical-align:  -5px; color: red;">error</mat-icon>
  {{message}}
</h1>
@if (subtitle) {
  <p>{{subtitle}}</p>
}
<div mat-dialog-content class="mat-typography">
  @if (showError) {
    <pre class="message"> {{error}} </pre>
  }
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close class="btn-light">{{'dialog.close' | translate}}</button>
  <button mat-raised-button class="btn-dark" (click)="showDetails()">Dettagli</button>
</div>