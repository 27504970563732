import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DocumentType, ProductTypeStyle} from '../../models/enums.model';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import DevExpress from "devextreme";
import {firstValueFrom, GroupByOptionsWithElement} from "rxjs";
import {DwApiService} from "../../services/dw-mart-api.service";
import {ConfigService} from "../../services/config.service";
import {DialogService} from "../dialog.service";
import {Router} from "@angular/router";
import {ServerConfigRecipient} from "../../models/dialogs-model";
import {GroupEmailServerConfig} from "../../models/web-api-models";

@Component({
  selector: 'app-email-dialog',
  templateUrl: './recipient-dialog.component.html',
  styleUrls: ['./recipient-dialog.component.scss']
})
export class RecipientDialogComponent implements OnInit {

  formGroupConf: FormGroup;
  documentType: DocumentType = DocumentType.PDF;
  @Input() interrogationId: string;
  @Input() recipient: string = undefined;

  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    /*@Inject(MAT_DIALOG_DATA) data,*/
    private api: DwApiService,
    public configService: ConfigService
  ) {
    //this.dataConfig = data;
    /*if (data.interrogationId) this.interrogationId = data.interrogationId;
    if (data.recipient) this.recipient = data.recipient;*/
  }

  createForm(recipient: string) {
    this.formGroupConf = this.formBuilder.group({
      recipient: [recipient ? recipient : '', Validators.required]
    });
  }

  public get documentTypeEn(): typeof DocumentType {
    return DocumentType;
  }

  async ngOnInit() {
    let serverEmailConfig: GroupEmailServerConfig;
    if (!this.recipient) {
      serverEmailConfig = (await firstValueFrom(this.api.getServerEmailSurveyConfigByName(this.configService.configInstances.getGroupName(), this.interrogationId))).ServerEmailConfig;
    }
    this.recipient = serverEmailConfig.Recipient;
    this.createForm(this.recipient);
  }

  close() {
    let recipient: ServerConfigRecipient = new ServerConfigRecipient();
    recipient.Recipient = this.formGroupConf.controls['recipient'].value;
    return recipient;
  }
}
