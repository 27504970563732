@if (isETLInfoLoaded) {
  <div class="container">
    <div class="internal-container flex-row flex-align-center">
      <div class="sub-internal-container flex-row flex-align-center">
        <div class="flex-row flex-align-center gap-10" style="flex-direction: column; box-sizing: border-box; display: flex; place-content: stretch center; align-items: stretch;">
          <!--box-shadow: 0px 0px 10px 5px #cecccc;-->
          <mat-card class="selection">
            <mat-card-title>
              <div class="flex-column">
                <div
                  [ngClass]="isMenu() ? 'option-title-menu' : isRetail() ? 'option-title-retail': isWelcome()?'option-title-welcome':''">
                  {{'etl.opt-title' | translate}}
                </div>
                <div>
                  {{'etl.select-etl' | translate}}
                </div>
              </div>
            </mat-card-title>
            <mat-card-content>
              <div class="flex-column flex-align-center">
                <mat-form-field class="no-fill etl-selection">
                  <mat-label>{{'etl.title' | translate}}</mat-label>
                  <mat-select #etl [(ngModel)]="selectedEtl" [hideSingleSelectionIndicator]="true" (selectionChange)="etlChanged($event)">
                    <mat-select-trigger>
                      <span class="material-icons"
                      [ngClass]="{'active' : checked, 'not-active': !checked }">brightness_1</span>
                      {{selectedEtl}}
                    </mat-select-trigger>
                    @for (e of etlList; track e) {
                      <mat-option [value]="e">
                        <!--<span id="active" class="material-icons" *ngIf="checked">brightness_1</span>-->
                        <span [ngClass]="{'active' : isEtlActive(e), 'not-active': !isEtlActive(e) }"
                        class="material-icons">brightness_1</span> {{e}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <div class="flex-row">
                  <p class="toggle-label">{{'etl.off' | translate}}</p>
                  <mat-slide-toggle class="flex-align-center" class="example-margin" [color]="color"
                    [checked]="checked" [disabled]="!canDoMaintenance" (change)="toggleActive($event)">
                  </mat-slide-toggle>
                  <p class="toggle-label">{{'etl.on' | translate}}</p>
                </div>
                <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="NotShareDT"
                  (change)="DTSharedClick($event)" matTooltip="{{'dialog.not-share-descr' | translate}}">
                  <!--[disabled]="!canAccess"-->
                  {{'dialog.not-share-dt' | translate}}
                </mat-checkbox>
                @if (selectedSource) {
                  <mat-form-field appearance="fill" class="white-back">
                    <mat-label>{{'etl.source' | translate}}</mat-label>
                    <input matInput [disabled]="true" [(ngModel)]="selectedSource">
                  </mat-form-field>
                }
              </div>
              <div class="flex-column flex-align-center">
                @if (selectedDataMarts) {
                  <mat-form-field appearance="fill">
                    <mat-label>{{'etl.datamart' | translate}}</mat-label>
                    <textarea cdkTextareaAutosize matInput [disabled]="true"
                    [(ngModel)]="selectedDataMarts"></textarea>
                  </mat-form-field>
                }
                @if (isWelcome()) {
                  <mat-checkbox class="custom-checkbox checkbox-opt" [(ngModel)]="IsMenuAvailable"
                    (change)="IsMenuAvailableClick($event)" matTooltip="{{'dialog.menu-available' | translate}}">
                    <!--[disabled]="!canAccess"-->
                    {{'dialog.menu-available' | translate}}
                  </mat-checkbox>
                }
              </div>
              <div class="flex-column flex-align-center">
                <div class="mat-card-title">{{'language.title' | translate}}</div>
                <mat-form-field class="no-fill etl-selection">
                  <mat-label>{{'language.title' | translate}}</mat-label>
                  <mat-select [(ngModel)]="selectedLanguage.value" [hideSingleSelectionIndicator]="true"
                    (selectionChange)="languageChanged($event)">
                    @for (e of availableLanguages; track e.value) {
                      <mat-option [value]="e.value">{{e.displayName}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex-column flex-align-center">
                <div class="mat-card-title">{{'license.title' | translate}}</div>
                @if (licenseType) {
                  <mat-form-field appearance="fill" class="white-back">
                    <mat-label>{{'license.type' | translate}}</mat-label>
                    <!--<input matInput [disabled]="true" [(ngModel)]="licenseType">-->
                    <textarea cdkTextareaAutosize matInput [disabled]="true" [(ngModel)]="licenseType"></textarea>
                  </mat-form-field>
                }
              </div>
              <div class="flex-column flex-end" id="version">
                <p>{{'dialog.version' | translate}}: {{version}}</p>
              </div>
            </mat-card-content>
            <mat-card-actions style="display: inline-flex;">
              <button mat-raised-button class="btn-etl btn-dark" (click)="saveEtl(true)"
              [disabled]="!canDoMaintenance || !hasChanges">{{'dialog.save' | translate}}</button>
              <!--<button mat-raised-button class="btn-etl btn-light" (click)="addNewEtl()" *ngIf="isNewAvailable"
            [disabled]="!canDoMaintenance">{{'dialog.add-etl' | translate}}</button>-->
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="right-container flex-row gap-12 flex-align-center">
        <div class="sub-right-container flex-column gap-10 flex-align-start">
          <mat-card class="right-card" style="border: 1px solid #dedede; ">
            <mat-card-title>{{'etl.planning' | translate}}</mat-card-title>
            <mat-card-content class="flex-row flex-space-between">
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="recurrenceType" [disabled]="!canDoMaintenance">
                @for (o of planningOptions; track o) {
                  <mat-radio-button (change)="planningRadioChange($event)" class="example-radio-button"
                    [value]="o">
                    {{o}}
                  </mat-radio-button>
                }
              </mat-radio-group>
              @if (hoursSelectionVisible && canDoMaintenance) {
                <div style="display: grid;">
                  <ngx-timepicker-field [format]="24" style="display: inline-flex;" [(ngModel)]="hour1"
                    (timeChanged)="hour1Changed($event)">
                  </ngx-timepicker-field>
                  <ngx-timepicker-field [format]="24" style="display: inline-flex;" [(ngModel)]="hour2"
                    (timeChanged)="hour2Changed($event)">
                  </ngx-timepicker-field>
                  <ngx-timepicker-field [format]="24"  style="display: inline-flex;" [(ngModel)]="hour3"
                    (timeChanged)="hour3Changed($event)">
                  </ngx-timepicker-field>
                </div>
              }
            </mat-card-content>
          </mat-card>
          <mat-card class="right-card" style="border: 1px solid #dedede;">
            <mat-card-title class="state-card-title">{{'etl.state' | translate}}</mat-card-title>
            <mat-card-content class="flex-column">
              @if (onLineYears) {
                <mat-form-field appearance="fill">
                  <mat-label>{{'etl.on-line-years' | translate}}: </mat-label>
                  <textarea cdkTextareaAutosize matInput [(ngModel)]="onLineYears" [disabled]="true"></textarea>
                </mat-form-field>
              }
              @if (ftCount) {
                <mat-form-field appearance="fill">
                  <mat-label>{{'etl.dim-on-line-years' | translate}}: </mat-label>
                  <input matInput [(ngModel)]="ftCount" [disabled]="true">
                </mat-form-field>
              }
              @if (storicalYears) {
                <mat-form-field appearance="fill">
                  <mat-label>{{'etl.historical-years' | translate}}: </mat-label>
                  <!--input matInput [(ngModel)]="storicalYears" [disabled]="true">-->
                  <textarea cdkTextareaAutosize matInput [disabled]="true" [(ngModel)]="storicalYears"></textarea>
                </mat-form-field>
              }
              @if (lastExecution) {
                <mat-form-field appearance="fill">
                  <mat-label>{{'etl.last-execution' | translate}}: </mat-label>
                  <input matInput [(ngModel)]="lastExecution" [disabled]="true">
                </mat-form-field>
              }
              @if (nextExecution) {
                <mat-form-field appearance="fill">
                  <mat-label>{{'etl.next-execution' | translate}}: </mat-label>
                  <input matInput [(ngModel)]="nextExecution" [disabled]="true">
                </mat-form-field>
              }
              @if (state) {
                <mat-form-field appearance="fill">
                  <mat-label>{{'etl.state' | translate}}: </mat-label>
                  <input matInput [(ngModel)]="state" [disabled]="true">
                </mat-form-field>
              }
            </mat-card-content>
            <mat-card-actions class="etl-status-buttons">
              @if (hasLog) {
                <button mat-raised-button class="btn-dark" (click)="openEtlDialog(messageType.Log)"
                >{{'dialog.log-etl' | translate}}</button>
              }
              @if (hasError) {
                <button mat-raised-button class="btn-dark" (click)="openEtlDialog(messageType.Error)"
                >{{'dialog.error' | translate}}</button>
                }<!-- -->
              </mat-card-actions>
            </mat-card>
            <div fxFlex style="flex: 1 1 auto;"></div>
            <div >
              <button mat-raised-button class="btn-light" (click)="getLog()">{{'dialog.log-server' |
              translate}}</button>
            </div>
          </div>
          <div class="last-col-container flex-column flex-align-start gap-10">
            <mat-card class="right-card" style="border: 1px solid #dedede;">
              <mat-card-title>{{'etl.manual-start' | translate}}</mat-card-title>
              <mat-card-content>
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="manualStart">
                  @for (o of manualStartOptions; track o) {
                    <mat-radio-button (change)="manualStartRadioChange($event)" class="example-radio-button"
                      [value]="o">
                      {{o}}
                    </mat-radio-button>
                  }
                </mat-radio-group>
                @if (manualStartTimeVisible) {
                  <div class="manual-start-time" class="flex-row">
                    <mat-form-field appearance="fill" class="fill no-padding" style="width: 40%;">
                      <!--s-->
                      <mat-label>{{'etl.date-choice' | translate}}</mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="manualStartDate">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <div>
                      <!--style="display: grid; float: right;"-->
                      <ngx-timepicker-field [format]="24" style="display: inline-flex;"
                        [(ngModel)]="manualStartTime">
                      </ngx-timepicker-field>
                    </div>
                  </div>
                }
              </mat-card-content>
              <mat-card-actions style="display: inline-flex;">
                <!--[class.spinner]="manualStartRunning"-->
                <button mat-raised-button class="btn-dark" (click)="manualStartClick()"
                  [disabled]="!manualStart || manualStartRunning">{{'etl.start' |
                translate}}</button>
                <!--<button mat-raised-button class="btn-dark" (click)="stopEtl(runningType.ManualStart)"
              *ngIf="manualStartRunning">{{'dialog.stop' | translate}}</button>-->
            </mat-card-actions>
          </mat-card>
          <mat-card class="right-card" style="border: 1px solid #dedede;">
            <mat-card-title>{{'etl.maintenance' | translate}}</mat-card-title>
            <mat-card-content>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="maintenance" [disabled]="!canDoMaintenance">
                @for (o of maintenanceOptions; track o) {
                  <mat-radio-button (change)="maintenanceRadioChange($event)" class="example-radio-button"
                    [value]="o">
                    {{o}}
                  </mat-radio-button>
                }
              </mat-radio-group>
              @if (maintenanceParamsVisible) {
                <div class="maintenance-params">
                  <mat-form-field appearance="fill" class="fill">
                    <mat-label>Fatto</mat-label>
                    <mat-select [disabled]="!canDoMaintenance" [hideSingleSelectionIndicator]="true"
                      (selectionChange)="changeFactMaintenance($event.value)">
                      @for (e of factList; track e.Name) {
                        <mat-option [value]="e.Name">
                          {{e.Title}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="fill">
                    <mat-label>{{'etl.year' | translate}}: </mat-label>
                    <input matInput [(ngModel)]="selectedYear" [disabled]="!canDoMaintenance">
                  </mat-form-field>
                </div>
              }
            </mat-card-content>
            <mat-card-actions style="display: inline-flex;">
              <!--[class.spinner]="maintenanceRunning"-->
              <button mat-raised-button class="btn-dark" (click)="maintenanceStart()"
                [disabled]="!maintenance || maintenanceRunning">{{'etl.start' |
              translate}}</button>
              <!--<button mat-raised-button class="btn-dark" (click)="stopEtl(runningType.Maintenance)"
            *ngIf="maintenanceRunning">{{'dialog.stop' | translate}}</button>-->
          </mat-card-actions>
        </mat-card>
        <!--<mat-card class="right-card" style="border: 1px solid #dedede;">
        <mat-card-title>{{'etl.import-export' | translate}}</mat-card-title>
        <mat-card-content>
        </mat-card-content>
        <mat-card-actions align="center" style="display: inline-flex;">
        </mat-card-actions>
      </mat-card>-->
      <div fxFlex style="flex: 1 1 auto;"></div>
      <div class="btn-container flex-row flex-align-center gap-10">
        <button mat-raised-button class="btn-light"
        (click)="importConfig()">{{'dashboard.import' | translate}}</button>
        <button mat-raised-button class="btn-light"
        (click)="exportConfig()">{{'dashboard.export' | translate}}</button>
      </div>
    </div>
  </div>
</div>
</div>
</div>
}
