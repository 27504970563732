import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogService } from 'src/app/dialogs/dialog.service';
import { EmailServerType, ProductTypeStyle } from 'src/app/models/enums.model';
import { EmailServerConfig } from 'src/app/models/schedule.model';
import { SendTestEmailRQ } from 'src/app/models/web-api-models';
import { ConfigService } from 'src/app/services/config.service';
import { DwApiService } from 'src/app/services/dw-mart-api.service';

@Component({
  selector: 'app-email-server-config',
  templateUrl: './email-server-config.component.html',
  styleUrl: './email-server-config.component.scss'
})
export class EmailServerConfigComponent {

  @Input() model: EmailServerConfig = new EmailServerConfig();
  @Input() groupId: number;

  @ViewChild('ngForm', { static: true }) ngForm: NgForm;

  public emailServerType = EmailServerType;
  public isGoogleTokenOk: boolean;

  constructor(
    private configService: ConfigService,
    private api: DwApiService,
    private dialogService: DialogService
  ) { }

  get productTypeName() {
    return ProductTypeStyle[this.configService.configInstances.getProductType()].toLowerCase();
  }

  openGoogleLogin() {
    this.api.googleAuthenticate().subscribe(res => {
      window.open(res.Url, '_blank');
    });
  }

  isFormValid = () => this.ngForm.form.valid && this.model.Recipient?.length > 0;

  sendTestEmail() {
    let rq = new SendTestEmailRQ(this.model);
    this.dialogService.openLoadingDialog(false);
    this.api.sendTestEmail(rq).subscribe(() => {
      this.dialogService.hideDialog();
      this.dialogService.openSnackbar('schedule.testSent')
    });
  }

  save() {
    this.model.IdGroup = this.groupId;
    this.api.addServerEmailConfig(this.model).subscribe(() => {
      this.dialogService.hideDialog();
    });
  }
}
