import { Injectable } from '@angular/core';
import { LogType } from '../models/enums.model';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private logTypes: LogType[] = [];

  constructor() {
    //this.logTypes = [LogType.Api]
  }

  log(msg: any, logType: LogType = LogType.Generic) {
    if (this.logTypes.includes(logType))
      console.log(msg);
  }
}
