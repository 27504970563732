import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DwApiService } from '../../services/dw-mart-api.service';
import { ProductTypeStyle, InterrogationType, ProductType } from '../../models/enums.model';
import { ValidatorService } from '../../utils/validator';
import { firstValueFrom } from "rxjs";
import { Fact, FactDialogModel, TableInfo } from "../../models/dialogs-model";

@Component({
  selector: 'app-fact-selection-dialog',
  templateUrl: './fact-selection-dialog.component.html',
  styleUrls: ['./fact-selection-dialog.component.scss']
})
export class FactSelectionDialogComponent implements OnInit {

  formGroup: FormGroup;
  facts: TableInfo[] = [];
  @Input() newInterrogation: boolean = false;
  @Input() interrogationType: InterrogationType;

  constructor(private formBuilder: FormBuilder, /*@Inject(MAT_DIALOG_DATA) data,*/ private api: DwApiService, public validatorService: ValidatorService) {
    /*this.interrogationType = data.interrogationType;
    this.newInterrogation = data.newInterrogation;*/
  }

  async ngOnInit() {
    this.createForm();
    this.facts = await firstValueFrom(this.api.getAvailableFTS(this.interrogationType));
    this.setFacts();
  }

  setFacts() {
    for (let i in this.facts) {
      if (Number(sessionStorage.getItem('productType')) == ProductTypeStyle.Welcome && sessionStorage.getItem('isMenuAvailable') === 'true') {
        if (this.facts[i].ProductTypes.includes(ProductType.Menu)) {
          this.facts[i].Preview = this.facts[i].Title + ' (Menu) ';
        } else if (this.facts[i].ProductTypes.includes(ProductType.Welcome)) {
          this.facts[i].Preview = this.facts[i].Title + ' (Welcome) ';
        }
      } else {
        this.facts[i].Preview = this.facts[i].Title;
      }
    }
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'fact': [null, Validators.required],
      'name': this.newInterrogation ? ['', Validators.required] : [null, null],
      'description': this.newInterrogation ? ['', null] : [null, null],
    });
  }

  close(isImport: boolean) {
    let factDialog: FactDialogModel = new FactDialogModel();
    if (isImport) {
      factDialog.Import = true;
    } else {
      factDialog.Fact = this.formGroup.get('fact').value;
      let facts: Fact[] = [];
      this.facts.forEach(f => facts.push(new Fact(f)));
      factDialog.Facts = facts;
      factDialog.Name = this.formGroup.get('name').value;
      factDialog.Description = this.formGroup.get('description').value;
    }
    return factDialog;
  }

}
