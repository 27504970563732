import {DateRangeDialogResType, FieldType, FunctionType} from "./enums.model";
import {ProductType} from "./enums.model";
import {FieldNode} from "./fieldnode";
import {FactInfo} from "./factInfo";
import {DimensionOption} from "./dimensionoption";
import {DateRange} from './web-api-models';

export class TableInfo {
  Name: string;
  Title: string;
  Preview: string;
  ProductTypes: ProductType[];
  HistoricizedYears: number[];
}
export class ViewInfo {
  Name: string;
  Title: string;
  Names: Record<any, ColumnInfo>;
  Titles: string[];
  Fk: string;
}
export class ColumnInfo {
  Type: string;
  Format: string;
  IsEnum: boolean;
  FieldType: FieldType;
  FunctionType: FunctionType;
}
export class DataSource {
  Id: number;
  Name: string;
  Fact: Fact;
  Fields: string;
  DimensionOptions: string;
  Filter: string;
  SqlFilter: string;
  RequestFilter: string;
  RequestSqlFilter: string;
  IdInterrogation: number;
  HistoricizedYear: number;
}
export class Fact {
  Name: string;
  Title: string;

  constructor(tableInfo: TableInfo) {
    this.Name = tableInfo.Name;
    this.Title = tableInfo.Title;
  }
}

export class FactDialogModel {
  Fact: Fact;
  Facts: Fact[];
  Description: string;
  Name: string;
  Import: boolean;
}

export class InterrogationName {
  Name: string;
}

export class InterrogationDescription {
  Description: string;
}

export class ServerConfigRecipient {
  Recipient: string;
}
export class Filter {
  Dw: any = null;
  Query: any = null;
}
export class DataSourceCreationDialogModel {
  Name: string;
  Selected: FieldNode[];
  Fact: FactInfo;
  Filters: Filter;
  RequestedFilters: Filter;
  HistoricizedYear: number;
  DimensionOptions: DimensionOption;

  constructor(Name: string, Selected: FieldNode[], Fact: FactInfo, HistoricizedYear?: number, Filters?: Filter, RequestedFilter?: Filter, DimensionOptions?: DimensionOption) {
    this.Name = Name;
    this.Selected = Selected;
    this.Fact = Fact;
    if(Filters)
      this.Filters = Filters;
    if(RequestedFilter)
      this.RequestedFilters = RequestedFilter;
    if(HistoricizedYear)
      this.HistoricizedYear = HistoricizedYear;
    if(DimensionOptions)
      this.DimensionOptions = DimensionOptions;
  }
}

export class DateRangeDialogRes {
  Type: DateRangeDialogResType;
  DateRange: DateRange;
}
