import { ProductType } from "./enums.model";

export interface DataMartInfo {
  Name: string;
  Title: string;
  HistoricizedYears?: number[];
  ProductTypes?: ProductType[];
}

export class EtlInfo {
    name: string;
    active: boolean;
    lastDimAnalyze: number;
    lastError: string;
    lastExecution: string;
    lastLog: string;
    nextExecution: string;
    source: string;
    state: string;
    factList: DataMartInfo[];
    /*maxYearOnline: number;
    minYearOnline: number;*/
    onlineYears: string;
    ftCount: number;
    storicalYears: number[];
    recurrenceType: string;
    hour1: string;
    hour2: string;
    hour3: string;
    language: string;
    NotShareDT: boolean;
    IsMenuAvailable: boolean;
    AreInterrogationsAvailable: boolean;

    constructor(
        name: string,
        active: boolean,
        lastDimAnalyze: number,
        lastError: string,
        lastExecution: string,
        lastLog: string,
        nextExecution: string,
        source: string,
        state: string,
        factList: DataMartInfo[],
        /*maxYearOnline: number,
        minYearOnline: number,*/
        onlineYears: string,
        ftCount: number,
        storicalYears: number[],
        recurrenceType: string,
        hour1: string,
        hour2: string,
        hour3: string,
        language: string,
        NotShareDT: boolean,
        IsMenuAvailable: boolean,
        AreInterrogationsAvailable: boolean

    ) {
        this.name = name;
        this.active = active;
        this.lastDimAnalyze = lastDimAnalyze;
        this.lastError = lastError;
        this.lastExecution = lastExecution;
        this.lastLog = lastLog;
        this.nextExecution = nextExecution;
        this.source = source;
        this.state = state;
        this.factList = factList;
        /*this.maxYearOnline = maxYearOnline;
        this.minYearOnline = minYearOnline;*/
        this.onlineYears = onlineYears;
        this.ftCount = ftCount;
        this.storicalYears = storicalYears;
        this.recurrenceType = recurrenceType;
        this.hour1 = hour1;
        this.hour2 = hour2;
        this.hour3 = hour3;
        this.language = language;
        this.NotShareDT = NotShareDT;
        this.IsMenuAvailable = IsMenuAvailable;
        this.AreInterrogationsAvailable = AreInterrogationsAvailable
    }
}
