import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA}  from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MessageType} from "../../models/enums.model";

@Component({
  selector: 'app-deactivate-dialog',
  templateUrl: './deactivate-dialog.component.html',
  styleUrls: ['./deactivate-dialog.component.scss']
})
export class DeactivateDialogComponent {

  public get messageType(): typeof MessageType {
    return MessageType;
  }
  title: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string, type: MessageType},
    public translate: TranslateService
  ) {
    //this.translate.use('it');
    this.title = data.type == MessageType.Error? this.translate.instant("dialog.error") : (data.type == MessageType.Log? this.translate.instant("dialog.log"): this.translate.instant("dialog.confirm"));
  }


}
