<mat-dialog-content class="mat-typography dialog-content">
  <div class="flex-fill flex-column">
    <div fxFlex>
      <div class="tab-container flex-place-content flex-align-center">
        @if (!empty) {
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Radio Buttom Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-radio-button
                  [checked]="row == selected">
                </mat-radio-button>
              </td>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> {{'upload.name' | translate}} </th>
              <td mat-cell *matCellDef="let element" > {{element.value.Name}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> {{'dialog.description' | translate}} </th>
              <td mat-cell *matCellDef="let element" > {{element.value.Description}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onChange($event, row)">
            </tr>
          </table>
        }
        @if (empty) {
          <p>{{'upload.warning-empty' | translate}}</p>
        }
      </div>
      <mat-divider></mat-divider>
      <!--<div>
      <app-selected-files-table [files]="selectedFiles"></app-selected-files-table>
    </div>-->
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close class="btn-light">{{'dialog.close' | translate}}</button>
  @if (selected) {
    <button mat-raised-button [mat-dialog-close]="close()" class="btn-dark"
    >{{'dialog.ok' | translate}}</button>
  }
</mat-dialog-actions>
