<div>
<mat-dialog-content class="mat-typography" style="overflow: hidden;" fxFill>
<mat-tab-group #matGroup mat-stretch-tabs class="request-filters">
    <mat-tab label="{{'dialog.request-filters' | translate}}">
        <app-filters-on-request #filterBuilder [items]="selected"
        (error)="setFilterError($event)"></app-filters-on-request>
    </mat-tab>
</mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end" style="width: 100%;">
    <button mat-raised-button
        [mat-dialog-close]="close()"
        type="button" [disabled]="filterError" class="btn-dark" >{{'dialog.proceed' |
        translate}}</button>
</mat-dialog-actions>
</div>
