import { Transform } from "class-transformer"
import { DocumentType, EmailServerType, ScheduleMonthlyPeriod, SchedulerRecurrenceType } from "./enums.model"
import dayjs from "dayjs"
import {GroupEmailServerConfig} from "./web-api-models"

export class ScheduledOperation {
    Id: number
    Name: string
    InterrogationType: number
    LastExecution: string
    RecurrenceType: SchedulerRecurrenceType
    ServerSmtp: string
    Sender: string
    @Transform(value => (<string>value.value).split(";"), { toClassOnly: true })
    @Transform(value => (<string[]>value.value).join(";"), { toPlainOnly: true })
    Recipient: string[]
    Timeout: number
    DefaultCredentials: boolean
    Ssl: boolean
    User: string
    Password: string
    HourTick: number
    MinuteTick: number
    FromHour: string
    ToHour: string
    DaysTick: number
    WeekTick: number
    MonthPeriod: ScheduleMonthlyPeriod[]
    LastError: string
    DayOfWeek: number
    DocumentType: number
    @Transform(value => dayjs(value.value).format('YYYY-MM-DD'), { toPlainOnly: true })
    FromDateExecution: string
    FromHourExecution: string
    IdInterrogation: number
    ProductType: number
    ServerType: EmailServerType

    constructor() {
        this.DocumentType = DocumentType.PDF;
        this.RecurrenceType = SchedulerRecurrenceType.One;
        this.ServerType = EmailServerType.Smtp;
        this.Recipient = [];
        this.DefaultCredentials = true;
        this.Timeout = 30000;
    }
}

export class DaInterrogation {
    Name: string
    Description: string
    Type: number
    Composition: string
    Default: boolean
    Modified: boolean
    Visible: boolean
    Order: number
    TimeLimit: number
    ProductType: number
    TimeLimitType: number
    DataSources: any[]
    Permissions: any[]
    SurveyOptions: any[]
    Id: number
    TimeStamp: number
    TimeStampOri: number
    CreatedBy: number
    UpdatedBy: number
    CreatedDate: string
    UpdatedDate: string
    IsNew: boolean
    IsModified: boolean
}

export class EmailServerConfig {
    IdGroup: number
    ServerSmtp: string
    Sender: string
    @Transform(value => (<string>value.value).split(";"), { toClassOnly: true })
    @Transform(value => (<string[]>value.value).join(";"), { toPlainOnly: true })
    Recipient: string[]
    Timeout: number
    DefaultCredentials: boolean
    Ssl: boolean
    User: string
    Password: string
    ServerType: EmailServerType;
    DocumentType: DocumentType;

    constructor(config?: GroupEmailServerConfig, idGroup?: number) {
        if (config && idGroup) {
            idGroup = idGroup;
            this.ServerSmtp = config.ServerSmtp;
            this.Sender = config.Sender;
            this.Recipient = config.Recipient.split(";");
            this.Timeout = config.Timeout;
            this.DefaultCredentials = config.DefaultCredentials;
            this.Ssl = config.Ssl;
            this.User = config.User;
            this.Password = config.Password;
            this.ServerType = config.ServerType;
            this.DocumentType = config.DocumentType;
        } else {
            this.ServerType = EmailServerType.Smtp;
            this.Recipient = [];
            this.DefaultCredentials = true;
            this.Timeout = 30000;
        }
    }
}
