import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {DwApiService} from '../../services/dw-mart-api.service';
import {FieldNode} from '../../models/fieldnode';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {HttpParams} from '@angular/common/http';
import {MatMenuTrigger} from '@angular/material/menu';
import {Field} from '../../models/field';
import {TranslateService} from '@ngx-translate/core';
import {CustomFilterBuilderComponent} from './custom-filter-builder/custom-filter-builder.component';
import {HistoricizeYearsDialogComponent} from './historicized-years-dialog/historicized-years-dialog.component';
import {CdkDragDrop, CdkDragRelease, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatTabGroup} from '@angular/material/tabs';
import {ChecklistDatabase} from '../../models/checklistdatabase';
import {CalculatedFieldFormComponent} from './calculated-field-form/calculated-field-form.component';
import {DimensionOption} from '../../models/dimensionoption';
import {InterrogationType, JoinType, LicenseType, SourceType} from '../../models/enums.model';
import {ConfigService} from "../../services/config.service";
import {DataSource, DataSourceCreationDialogModel, Fact, Filter, ViewInfo} from "../../models/dialogs-model";
import {firstValueFrom} from "rxjs";
import {FactInfo} from "../../models/factInfo";
import { forEach } from 'lodash';

@Component({
  selector: 'app-datasource-creation-dialog',
  templateUrl: './datasource-creation-dialog.component.html',
  styleUrls: ['./datasource-creation-dialog.component.scss']
})

export class DatasourceCreationDialogComponent implements OnInit {

  @Input() loaded: boolean = false;
  @Input() selectedFactI: string;
  @Output() setDataSource: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild('filterBuilder', {static: false}) filterBuilder: CustomFilterBuilderComponent;
  @ViewChild('filterRequest', {static: false}) filterRequest: CustomFilterBuilderComponent;
  @ViewChild('matGroup') matGroup: MatTabGroup;
  @Input() dataSource: MatTreeFlatDataSource<Field, FieldNode>;

  @Input() dsName: string = null;
  @Input() fact: Fact;
  @Input() facts: Fact[];
  @Input() filters: any;
  @Input() historicizedYear: string = undefined;
  @Input() sourceType: SourceType;
  @Input() canReorder: boolean = false;
  @Input() type: InterrogationType;
  @Input() alterDatasource: boolean = false;
  @Input() selected: FieldNode[] = [];
  @Input() year: number;
  @Input() requestedFilters: any;

  filterError: boolean = true;
  filterRequestError: boolean = true;
  contextMenuPosition = {x: '0px', y: '0px'};
  items: any;
  view: string = 'tree';
  filterOpened: boolean = false;
  requestFilters: any;
  dsAvailables: DataSource[] = [];
  historicizedYearsAvailables: number[] = [];
  isPointerOverContainer: boolean = false;
  flatNodeMap = new Map<FieldNode, Field>();
  nestedNodeMap = new Map<Field, FieldNode>();
  treeControl: FlatTreeControl<FieldNode>;
  treeFlattener: MatTreeFlattener<Field, FieldNode>;
  checklistSelection = new SelectionModel<FieldNode>(true /* multiple */);
  @Input() dimensionOptions: DimensionOption[] = [];
  histYears: boolean = false;
  selectedToReorder: FieldNode[] = [];
  isNotReport: boolean;
  isSurvey: boolean;
  //alterDatasource: boolean = false;
  proVersion: boolean = false;
  datasource: Field[] = [];
  factSchema: ViewInfo[] = [];
  
  componentInstance: DimensionOption;

  constructor(
    private api: DwApiService,
    /*@Inject(MAT_DIALOG_DATA) public data: { 
      alterDatasource: boolean, 
      type: InterrogationType, 
      fact: Fact,
      facts: Fact[], 
      selected: FieldNode[],
      sourceType: SourceType,
      filters: any,
      requestedFilters: any,
      dsName: string,
      canReorder: boolean,
      year: number,
      historicizedYear: string,
    },*/
    private cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private database: ChecklistDatabase,
    public dialog: MatDialog,
    public configService: ConfigService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FieldNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.items = [{text: this.translate.instant('dialog.add-filter')}];
    /*this.alterDatasource = data.alterDatasource;
    this.type = data.type;
    this.isNotReport = this.type == InterrogationType.Smart || this.type == InterrogationType.Dashboard;
    this.isSurvey = this.type == InterrogationType.Smart;
    this.fact = data.fact;
    this.facts = data.facts;
    if (data.sourceType != undefined) this.sourceType = data.sourceType == 0 ? SourceType.MySQL : data.sourceType == 1 ? SourceType.SqlServer : SourceType.SQLite;
    if (data.selected) this.selected = data.selected;
    this.initDimensionOptions(data.dimensionOptions);
    if (data.filters) this.initFilters(data.filters);
    if (data.requestedFilters) this.initRequestedFilters(data.requestedFilters);
    if (data.dsName) this.initDsName(data.dsName);
    if (data.canReorder) this.canReorder = data.canReorder;
    if (data.year && data.year != 0) {
      this.historicizedYear = data.year.toString();
    } else this.historicizedYear = undefined;
    this.dsName = data.dsName ? data.dsName : null;*/
    this.filterRequestError = this.canReorder;
    let licenseInfo = this.configService.configInstances.getLicense();
    this.proVersion = licenseInfo == LicenseType.Full || licenseInfo == LicenseType.FullDemo;
    console.log(this.proVersion);
  }

  async ngOnInit() {
    /*if(this.dsName){
      this.initDsName(this.dsName);
    }*/
    this.isNotReport = this.type == InterrogationType.Smart || this.type == InterrogationType.Dashboard;
    this.isSurvey = this.type == InterrogationType.Smart;

    if (this.sourceType != undefined) this.sourceType = this.sourceType == 0 ? SourceType.MySQL : this.sourceType == 1 ? SourceType.SqlServer : SourceType.SQLite;
    if (this.selected) this.selected = this.selected;
    this.initDimensionOptions(this.dimensionOptions);
    if (this.filters) this.initFilters(this.filters);
    if (this.requestedFilters) this.initRequestedFilters(this.requestedFilters);
    if (this.dsName) this.initDsName(this.dsName);
    if (this.canReorder) this.canReorder = this.canReorder;
    if (this.year && this.year != 0) {
      this.historicizedYear = this.year.toString();
    } else this.historicizedYear = undefined;
    this.dsName = this.dsName ? this.dsName : null;

    this.cdRef.detectChanges();
    this.factSchema = await firstValueFrom(this.api.getFT(this.fact.Name));
    this.datasource = this.api.parseFactSchema(this.factSchema, this.facts)
      .sort((a, b) => (a.children.length > 0) && (a.title < b.title) ? -1 : 1);
    this.database.initialize(this.datasource);
    this.database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    //this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    let historicizedYears = await firstValueFrom(this.api.getHistoricizedYears(this.fact.Name))
    if (historicizedYears && historicizedYears.length > 0) {
      this.histYears = true;
      this.historicizedYearsAvailables.push(this.translate.instant('dialog.delete-selection'));
      historicizedYears.forEach(y => {
        this.historicizedYearsAvailables.push(y);
      });
    }
    this.initSelected(this.selected);
    let dsAvailables: DataSource[] = await firstValueFrom(this.api.getFTDatasources(this.fact.Name));
    if (dsAvailables) {
      let deleteDs: DataSource = new DataSource();
      deleteDs.Name = this.translate.instant('dialog.delete-selection');
      this.dsAvailables.push(deleteDs);
      dsAvailables.forEach(ds => {
        this.dsAvailables.push(ds);
      });
    }
  }

  onContextMenu(event, node) {
    if (this.type == InterrogationType.Smart) {
      event.preventDefault();
      let index = this.dimensionOptions.findIndex(d => d.name == node.name && d.title == node.tableTitle && d.fk == node.fk);
      let factField = node.parentTitle == this.translate.instant('dw.descriptive-fields') || node.parentTitle == this.translate.instant('dw.metrics');
      if (/*index != -1 &&*/ !factField) {
        this.contextMenuPosition.x = Number(event.clientX + 20) + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        //this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.menuData = node;
        this.contextMenu.openMenu();
      }
    }
  }

  isRightJoin() {
    let node = this.contextMenu.menuData;
    let n = this.dimensionOptions.find(d => d.name == node.name && d.title == node.tableTitle && d.fk == node.fk);
    if (n != undefined) return n.joinType == JoinType.Right;
    else return false;
  }

  changeJoin(event) {
    let node = this.contextMenu.menuData;
    let n = this.dimensionOptions.find(d => d.name == node.name && d.title == node.tableTitle && d.fk == node.fk);
    if (n != undefined) {
      if (n.joinType == JoinType.Left) n.joinType = JoinType.Right;
      else if (n.joinType == JoinType.Right) n.joinType = JoinType.Left;
    } else {
      this.dimensionOptions.push(new DimensionOption(node.tableTitle, node.name, node.fk, event.checked ? JoinType.Right : JoinType.Left));
    }
    //event.stopPropagation();
  }

  onContextMenuAction2(item) {
    alert(`Click on Action 2 for ${item.name}`);
  }

  isNotSmart() {
    return this.type != InterrogationType.Smart;
  }

  dsChanged(e) {
    //console.log(e);
    if (e.value.Name == this.translate.instant('dialog.delete-selection')) {
      this.resetSelected();
      this.resetFilters();
      this.historicizedYear = undefined;
    } else {
      if (e.value.Fields) this.initSelected(e.value.Fields);
      if (e.value.Filter) this.initFilters(e.value.Filter);
      if (e.value.HistoricizedYear && e.value.HistoricizedYear) this.historicizedYear = e.value.HistoricizedYear;
      else this.historicizedYear = undefined;
    }
  }

  resetSelected() {
    this.checklistSelection.clear();
    this.dimensionOptions = [];
    this.selectedToReorder = [];
  }

  initSelected(selected) {
    this.checklistSelection.clear();
    if (selected instanceof Object) {
      let cfFields = selected.filter(i => i.isCalculatedField);
      let n: FieldNode;
      selected.forEach(selectedNode => {
        //if(selectedNode.parentName != null){
          this.treeControl.dataNodes.forEach(node => {
            if(node.parentName == selectedNode.parentName
              && node.name == selectedNode.name 
              && node.parentTitle == selectedNode.parentTitle){
                n = node;
            }
          });
          if(n == null) n = undefined;
        /*let n: FieldNode = this.treeControl.dataNodes ?
          this.treeControl.dataNodes.find(node => { 
            node.parentName == selectedNode.parentName
            && node.name == selectedNode.name 
            && node.parentTitle == selectedNode.parentTitle}) :
          undefined;*/
        if (n != undefined) {
          this.checklistSelection.select(n);
        } else if (selectedNode.isCalculatedField && cfFields.includes(selectedNode)) {
          this.checklistSelection.select(selectedNode);
        }
      //}
      });
      //cfFields.forEach(cf => this.checklistSelection.select(cf));
    } else {
      console.log(JSON.parse(selected));
      var sel: any = JSON.parse(selected);
      sel.forEach(selectedNode => {
        console.log(selectedNode);
        let n: FieldNode = undefined;
        /*let n: FieldNode = this.treeControl.dataNodes.find(node => {
          if(node.parentName.toLocaleLowerCase() == selectedNode.parentName.toLocaleLowerCase() &&
          node.name.toLocaleLowerCase() == selectedNode.name.toLocaleLowerCase()) {
            if(selectedNode.fk != undefined && selectedNode.fk != null) {
              if(node.fk != undefined && node.fk != null &&
                node.fk.toLocaleLowerCase() == selectedNode.fk.toLocaleLowerCase()) {
                  //n = node;
                  return node;
                }
            } else return node;//n = node;
          }

          //node.parentTitle == selectedNode.parentTitle &&

        });*/
        for (let i in this.treeControl.dataNodes) {
          if (selectedNode.parentName && selectedNode.name) {
            if (this.treeControl.dataNodes[i].name.toLocaleLowerCase() == selectedNode.name.toLocaleLowerCase() &&
              ((this.treeControl.dataNodes[i].parentName != undefined && this.treeControl.dataNodes[i].parentName != null &&
                  this.treeControl.dataNodes[i].parentName.toLocaleLowerCase() == selectedNode.parentName.toLocaleLowerCase()) ||
                this.treeControl.dataNodes[i].parentName == undefined)) {
              if (selectedNode.parentName.toLocaleLowerCase() == 'dimdate') {
                if (this.treeControl.dataNodes[i].title.toLocaleLowerCase() == selectedNode.title.toLocaleLowerCase() &&
                  this.treeControl.dataNodes[i].parentTitle.toLocaleLowerCase() == selectedNode.parentTitle.toLocaleLowerCase()) {
                  if (selectedNode.fk != undefined && selectedNode.fk != null) {
                    if (this.treeControl.dataNodes[i].fk != undefined && this.treeControl.dataNodes[i].fk != null &&
                      this.treeControl.dataNodes[i].fk.toLocaleLowerCase() == selectedNode.fk.toLocaleLowerCase()) {
                      n = this.treeControl.dataNodes[i];
                    }
                  } else n = this.treeControl.dataNodes[i];
                }
              } else {
                if (selectedNode.fk != undefined && selectedNode.fk != null) {
                  if (this.treeControl.dataNodes[i].fk != undefined && this.treeControl.dataNodes[i].fk != null &&
                    this.treeControl.dataNodes[i].fk.toLocaleLowerCase() == selectedNode.fk.toLocaleLowerCase()) {
                    n = this.treeControl.dataNodes[i];
                  }
                } else n = this.treeControl.dataNodes[i];
              }
            }
          }
        }
        if (n != undefined) {
          n.grouped = selectedNode.grouped == undefined ? /*(selectedNode.isMeasure? false : true)*/true : selectedNode.grouped;
          this.checklistSelection.select(n);
        }
      });
    }
    this.selectedToReorder = this.checklistSelection.selected.filter(i => i.parentName != undefined || i.parentName == '' && i.isCalculatedField);
  }

  initDimensionOptions(dimensionOptions) {
    this.dimensionOptions = [];
    if (dimensionOptions) {
      if (dimensionOptions instanceof Object) {
        dimensionOptions.forEach(dim => {
          let n: FieldNode = this.treeControl.dataNodes.find(node => node.name == dim.name && node.tableTitle == dim.title && node.fk == dim.fk);
          if (n != undefined) {
            let factField = n.title == this.translate.instant('dw.descriptive-fields') || n.title == this.translate.instant('dw.metrics');
            if (!factField) this.dimensionOptions.push(new DimensionOption(n.tableTitle, n.name, n.fk, dim.joinType));
          }
        });
        //cfFields.forEach(cf => this.checklistSelection.select(cf));
      } else {
        var dimensions: any = JSON.parse(dimensionOptions);
        if (dimensions != null && dimensions != undefined) {
          dimensions.forEach(dim => {
            console.log(dim);
            this.dimensionOptions.push(new DimensionOption(dim.title, dim.name, dim.fk, dim.joinType));
          });
        }
      }
    } else {
      for (let i in this.checklistSelection.selected) {
        let tmp = this.dimensionOptions.find(d => d.name == this.checklistSelection.selected[i].parentName && d.title == this.checklistSelection.selected[i].tableTitle && d.fk == this.checklistSelection.selected[i].fk);
        let factField =
          tmp != undefined ? tmp.title == this.translate.instant('dw.descriptive-fields') || tmp.title == this.translate.instant('dw.metrics') :
            this.checklistSelection.selected[i].parentTitle == this.translate.instant('dw.descriptive-fields') || this.checklistSelection.selected[i].parentTitle == this.translate.instant('dw.metrics');
        if (tmp == undefined && !factField) this.dimensionOptions.push(new DimensionOption(this.checklistSelection.selected[i].tableTitle, this.checklistSelection.selected[i].parentName, this.checklistSelection.selected[i].fk, JoinType.Left));
      }
    }
  }

  resetFilters() {
    this.filters = undefined;
    this.requestFilters = undefined;
    this.filterOpened = false;
  }

  initFilters(filters) {
    this.filters = JSON.parse(filters);
  }

  initRequestedFilters(requestedFilters) {
    this.requestFilters = JSON.parse(requestedFilters);
  }

  initDsName(dsName) {
    if (!this.alterDatasource) {
      let params: HttpParams = new HttpParams();
      params = params.append('name', dsName);

      //var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if ((/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(dsName)) {
        dsName = dsName.replace(/[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/);
      }
      this.api.isValidDataSourceName(params).subscribe((res) => {
        if (res) {
          this.dsName = dsName;
          //return null;
        } else {
          this.api.getDataSourceName(params).subscribe((res: any) => {
            if (res) {
              this.dsName = res;
            }
          })
        }
      });
    } else {
      this.dsName = dsName;
    }
  }

  filterChanged(filterEvent: EventTarget) {
    let filterText = (filterEvent as HTMLInputElement).value
    this.database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }

  getLevel = (node: FieldNode) => node.level;

  isExpandable = (node: FieldNode) => node.expandable;

  getChildren = (node: Field): Field[] => node.children;

  hasChild = (_: number, _nodeData: FieldNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FieldNode) => _nodeData.title === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: Field, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.title === node.title
      ? existingNode
      : new FieldNode();
    flatNode.name = node.name;
    flatNode.title = node.title
    flatNode.level = level;
    flatNode.tableTitle = node.tableTitle;
    flatNode.parentName = node.parentName;
    flatNode.parentTitle = node.parentTitle;
    flatNode.expandable = !!node.children?.length;
    flatNode.isMeasure = node.isMeasure;
    flatNode.isEnum = node.isEnum;
    flatNode.preview = flatNode.title + ' (' + flatNode.parentTitle + ')';
    if (node.functionType != null) flatNode.functionType = node.functionType;
    flatNode.fieldType = node.fieldType;
    flatNode.type = node.type;
    flatNode.grouped = /*!node.isMeasure*/true;
    flatNode.format = node.format;
    flatNode.isCalculatedField = node.isCalculatedField;
    flatNode.sqlExpression = node.sqlExpression;
    flatNode.sqlServerExpression = node.sqlServerExpression;
    flatNode.mySqlExpression = node.mySqlExpression;
    if (node.fk) flatNode.fk = node.fk;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FieldNode): boolean {
    if (this.treeControl.dataNodes) {
      const descendants = this.treeControl.getDescendants(node);
      const descAllSelected = descendants.length > 0 && descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
      return descAllSelected;
    } else return false;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FieldNode): boolean {
    if (this.treeControl.dataNodes) {
      const descendants = this.treeControl.getDescendants(node);
      const result = descendants.some(child => this.checklistSelection.isSelected(child));
      return result && !this.descendantsAllSelected(node);
    } else return false;
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FieldNode): void {
    this.filterOpened = false;
    //this.filters = undefined;
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (this.checklistSelection.isSelected(node)) {
      let factField = node.parentTitle == this.translate.instant('dw.descriptive-fields') || node.parentTitle == this.translate.instant('dw.metrics');
      if (!factField) this.dimensionOptions.push(new DimensionOption(node.tableTitle, node.name, node.fk, JoinType.Left));
      this.checklistSelection.select(...descendants);
      for (let i in descendants) {
        if (this.selectedToReorder.find(it => it.name == descendants[i].name && it.parentName == descendants[i].parentName &&
          it.title.toLowerCase() == descendants[i].title.toLowerCase() && it.parentTitle.toLowerCase() == descendants[i].parentTitle.toLowerCase()) == undefined) {
          this.selectedToReorder.push(descendants[i]);
        }
      }
    } else {
      let index = this.dimensionOptions.findIndex(d => d.name == node.name && d.title == node.tableTitle && d.fk == node.fk);
      if (index != -1) this.dimensionOptions.splice(index, 1);
      this.checklistSelection.deselect(...descendants);
      for (let i in descendants) {
        let index = this.selectedToReorder.indexOf(descendants[i]);
        this.selectedToReorder.splice(index, 1);
      }
    }

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    //this.selectedToReorder = this.checklistSelection.selected.filter(i => i.parentName != undefined);
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(e, node: FieldNode): void {

    this.filterOpened = false;
    //this.filters = undefined;
    /*let title = node.parentTitle == this.translate.instant('dw.descriptive-fields') ||
      node.parentTitle == this.translate.instant('dw.metrics') ? this.selectedFact : node.parentTitle;
      let name = '['+title+'].['+node.name+']';
    if(this.filterBuilder.checkIfFieldIsFiltered(this.filters, name)) {
      e.preventDefault();
      this.dialogService.openDialog('questo campo è presente nei filtri, eliminalo prima!');

    } else {
      this.checklistSelection.toggle(node);
      this.checkAllParentsSelection(node);
    }*/
    let tmp = this.checklistSelection.selected.filter(s => s.parentName == node.parentName && s.tableTitle == node.tableTitle && s.fk == node.fk);
    if (this.checklistSelection.isSelected(node)) {
      let index = this.selectedToReorder.indexOf(node);
      this.selectedToReorder.splice(index, 1);
      if (tmp.length <= 1) {
        let index = this.dimensionOptions.findIndex(d => d.name == node.name && d.title == node.tableTitle && d.fk == node.fk);
        if (index != -1) this.dimensionOptions.splice(index, 1);
      }
    } else {
      this.selectedToReorder.push(node);
      if (tmp.length <= 0) {
        let factField = node.parentTitle == this.translate.instant('dw.descriptive-fields') || node.parentTitle == this.translate.instant('dw.metrics');
        if (!factField) this.dimensionOptions.push(new DimensionOption(node.tableTitle, node.parentName, node.fk, JoinType.Left));
      }
    }
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  isChecked(node) {
    return this.checklistSelection.isSelected(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FieldNode): void {
    let parent: FieldNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FieldNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FieldNode): FieldNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  isValidDataSource() {
    //es. FieldNode {name: 'Amount', title: 'Importo', level: 1, parentName: 'FTHBill', parentTitle: 'Metriche', …}
    if (this.checklistSelection.selected.length > 0) {
      let notUndefined = new Set(this.checklistSelection.selected.filter(i => i.parentTitle != undefined && !i.isCalculatedField));
      if (notUndefined.size > 1) { //ho almeno un campo
        //controllo che ci siano campi di una sola dimensione oppure di più dimensioni e il fatto
        let parents = this.checklistSelection.selected.filter(i => i.parentTitle != undefined && !i.isCalculatedField);
        let distinctParentsTitle = new Set(parents.map(i => i.parentTitle));
        let distinctParentsName = new Set(parents.map(i => i.parentName));
        let ret: boolean = false;
        if (distinctParentsTitle.size > 1) {
          distinctParentsName.forEach(s => {
            if (this.facts.some(x => x.Name == s)) {
              ret = true;
            }
          });
        } else {
          //console.log(distinctParents);
          if (this.isNotSmart())
            ret = true;
          else {
            distinctParentsName.forEach(s => {
              if (this.facts.some(x => x.Name == s)) {
                ret = true;
              }
            });
          }
        }
        return ret && this.dsName != null;
      }
      return this.dsName != null && this.isNotSmart();
    }
    return false;
    //return this.checklistSelection.selected.length > 0 && this.dsName != null;
  }

  changeDataSourceName(e) {
    if (e.target.value.startsWith("-") || (/[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/).test(e.target.value)) {
      this.dsName = null;
    } else {
      let params: HttpParams = new HttpParams();
      params = params.append('name', e.target.value);

      this.api.isValidDataSourceName(params).subscribe((res) => {
        if (res) {
          this.dsName = e.target.value
          //return null;
        } else this.dsName = null;//return { message: 'nome non valido!' };
      });
    }
  }

  setFilterError(event) {
    this.filterError = event;
    if (this.filterError == false) {
      //console.log("filtri ok");
      //this.filterBuilder.realQuery = JSON.parse(JSON.stringify(this.filterBuilder.query));
      //this.filterBuilder.map(this.filterBuilder.realQuery.rules, this.filterBuilder.realQuery.condition)
      //console.log(this.filterBuilder.realQuery);
    }
  }

  setFilterRequestError(event) {
    this.filterRequestError = event;
    if (this.filterRequestError == false) {
      //console.log("filtri ok");
      //this.filterBuilder.realQuery = JSON.parse(JSON.stringify(this.filterBuilder.query));
      //this.filterBuilder.map(this.filterBuilder.realQuery.rules, this.filterBuilder.realQuery.condition)
      //console.log(this.filterBuilder.realQuery);
    }
  }

  setSelectedDataSource() {
    this.setDataSource.emit();
    this.cancel.emit();
  }

  close() {
    this.loaded = false;
    this.checklistSelection.clear();

  }

  goToPanel(panel: string) {
    if (panel == 'filter' && this.filterOpened == false) {
      this.matGroup.realignInkBar();
      this.filterOpened = true;
      //this.filterBuilder.filterBuilder.instance.resetOption();
      //this.filterBuilder.query = '';
      //this.filterBuilder.realQuery = '';
      //this.filterBuilder.filter = '';
      //var model: any = this.filterBuilder.filterBuilder.instance;
      //model._model = [];
      /*this.filterBuilder.query = '';
      this.filterBuilder.realQuery = '';
      this.filterBuilder.filter = '';
      this.filterBuilder.fields = [];
      this.filterBuilder.items = [];*/
      this.filterBuilder.filterBuilder.value = '';
      let selected = this.historicizedYear ? this.checklistSelection.selected.filter(i => i.parentName != undefined /*&& i.parentName != "DIMDate" */ && i.name != "Year") :
        this.checklistSelection.selected.filter(i => i.parentName != undefined);
      this.filters ? this.filterBuilder.configure(this.type, selected, this.fact.Name, false, this.historicizedYearsAvailables, this.filters) :
        this.filterBuilder.configure(this.type, selected, this.fact.Name, true, this.historicizedYearsAvailables);

      if (this.canReorder) {
        this.filterRequest.filterBuilder.value = '';
        /*let selected = this.historicizedYear? this.checklistSelection.selected.filter(i => i.parentName != undefined && i.name != "Year"):
        this.checklistSelection.selected.filter(i => i.parentName != undefined);*/
        this.requestFilters ? this.filterRequest.configure(this.type, selected, this.fact.Name, false, this.historicizedYearsAvailables, this.requestFilters) :
          this.filterRequest.configure(this.type, selected, this.fact.Name, true, this.historicizedYearsAvailables);
      }

    } else if (panel == 'filter' && this.filterOpened == true) {
      /*this.filterBuilder.query = '';
      this.filterBuilder.realQuery = '';
      thi s.filterBuilder.filter = '';*/
    } else if (panel == 'tree' && this.filterOpened) {
      this.filters = this.filterBuilder.realQuery;
      if (this.canReorder) {
        this.requestFilters = this.filterRequest.realQuery;
      }
      /*this.filterBuilder.query = '';
      this.filterBuilder.realQuery = '';
      this.filterBuilder.filter = '';
      //this.filterBuilder.fields = [];
      this.filterBuilder.items = [];*/
    }
    this.view = panel;
    this.cdRef.detectChanges();
  }

  openHistoricizedYears() {
    //this.api.getHistoricizedYears().subscribe((res: any) => {
    //console.log(res);
    const dialogConfig = new MatDialogConfig();
    /*let years : any[] = [];
    years.push(this.translate.instant('dialog.delete-selection'));
    res.forEach(y => {
      years.push(y);
    });*/
    dialogConfig.data = {
      years: this.historicizedYearsAvailables,//chiamata api per lista dei fatti
      selected: this.historicizedYear
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(HistoricizeYearsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(yearsRes => {
      if (yearsRes) {
        //console.log(yearsRes);
        if (yearsRes.year == this.translate.instant('dialog.delete-selection')) {
          /*if(this.dsName.includes('-'+this.historicizedYear) && this.dsName.endsWith('-'+this.historicizedYear)) {
            let index = this.dsName.indexOf('-'+this.historicizedYear);
            this.dsName = this.dsName.substring(0, index);
          }*/
          this.historicizedYear = undefined;
        } else {
          this.historicizedYear = yearsRes.year;
          //this.dsName = this.dsName+'-'+this.historicizedYear;
        }
      }
    });
    //}, (error) => {throw error});

  }


  drop(event: CdkDragDrop<string[]>) {
    if (this.isPointerOverContainer) {
      moveItemInArray(this.selectedToReorder, event.previousIndex, event.currentIndex);
    } else {
      let element = this.selectedToReorder[event.previousIndex];
      this.checklistSelection.deselect(element);
      this.selectedToReorder.splice(event.previousIndex, 1);
      this.filterOpened = false;
    }

  }

  dragDropped(event: CdkDragDrop<string[]>) {
    this.isPointerOverContainer = event.isPointerOverContainer || event.distance.x > -250;
    if (!this.isPointerOverContainer) {
      event.item.element.nativeElement.style.display = 'none';

    }
  }

  dragReleased(event: CdkDragRelease<string[]>) {
    const preview = new ElementRef<HTMLElement>(document.querySelector(".cdk-drag.cdk-drag-preview"));
    preview.nativeElement.style.visibility = 'hidden';
    preview.nativeElement.style.opacity = '1';
    preview.nativeElement.style.transition = 'opacity 2s linear';
  }

  changeGrouped(s: FieldNode) {
    s.grouped = !s.grouped;
  }

  openCalculatedFieldForm() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      datasource: this.datasource,
      fact: this.fact,
      cfName: '',
      sourceType: this.sourceType,
      cfNames: this.checklistSelection.selected.filter(cf => cf.isCalculatedField).map(cf => cf.name)
    };
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'full-width-dialog';
    const dialogRef = this.dialog.open(CalculatedFieldFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dsCreationDialogRes => {
      if (dsCreationDialogRes) {
        console.log(dsCreationDialogRes);
        //qui devo andare ad inserire il campo calcolato che ho creato nei selected e selecttoreorder
        /*
        flatNode.name = node.name;
        flatNode.title = node.title
        flatNode.level = level;
        flatNode.tableTitle = node.tableTitle;
        flatNode.parentName = node.parentName;
        flatNode.parentTitle = node.parentTitle;
        flatNode.expandable = !!node.children?.length;
        flatNode.isMeasure = node.isMeasure;
        flatNode.isEnum = node.isEnum;
        flatNode.preview = flatNode.title + ' (' + flatNode.parentTitle + ')';
        if (node.functionType != null) flatNode.functionType = node.functionType;
        flatNode.fieldType = node.fieldType;
        flatNode.type = node.type;
        flatNode.grouped = true;
        flatNode.format = node.format;
        flatNode.isCalculatedField = node.isCalculatedField;
        flatNode.sqlExpression = node.sqlExpression;
        flatNode.sqlServerExpression = node.sqlServerExpression;
        flatNode.mySqlExpression = node.mySqlExpression;
        if (node.fk) flatNode.fk = node.fk;
        */
        //{name: cfName, expression: expression, sqlServerExpression: sqlServerExpression, mySqlExpression: mySqlExpression}
        let cfField = FieldNode.setCFFieldNode(dsCreationDialogRes.name, dsCreationDialogRes.expression, dsCreationDialogRes.sqlServerExpression, dsCreationDialogRes.mySqlExpression, dsCreationDialogRes.functionType.type);
        //this.checklistSelection.select(cfField);
        //this.selectedToReorder.push(cfField);
        this.initSelected(this.checklistSelection.selected);
        this.checklistSelection.select(cfField);
        this.selectedToReorder.push(cfField);
      } else {

      }
    });

  }

  modifyCF(cfField) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      datasource: this.datasource,
      fact: this.fact,
      cfName: cfField.name,
      expression: cfField.sqlExpression,
      sqlServerExpression: cfField.sqlServerExpression,
      mySqlExpression: cfField.mySqlExpression,
      functionType: cfField.functionType,
      sourceType: this.sourceType,
      cfNames: this.checklistSelection.selected.filter(cf => cf.isCalculatedField).map(cf => cf.name)
    };
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'full-width-dialog';
    const dialogRef = this.dialog.open(CalculatedFieldFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(dsCreationDialogRes => {
      if (dsCreationDialogRes) {
        console.log(dsCreationDialogRes);
        let cfFieldMod = FieldNode.setCFFieldNode(dsCreationDialogRes.name, dsCreationDialogRes.expression, dsCreationDialogRes.sqlServerExpression, dsCreationDialogRes.mySqlExpression, dsCreationDialogRes.functionType.type);
        //this.checklistSelection.select(cfField);
        //this.selectedToReorder.push(cfField);
        this.initSelected(this.checklistSelection.selected);
        this.checklistSelection.deselect(cfField);
        this.checklistSelection.select(cfFieldMod);
        let index = this.selectedToReorder.indexOf(cfField);
        this.selectedToReorder.splice(index, 1);
        this.selectedToReorder.push(cfFieldMod);
      } else {

      }
    });
  }

  closeDialog(dsName: string): DataSourceCreationDialogModel {
    //canReorder attivo se si tratta di report o indagine
    //isNotSmart è true se non è un'indagine
    let res: DataSourceCreationDialogModel;
    let filter: Filter = new Filter();
    if(this.filterBuilder) {
      filter.Dw = this.filterBuilder.query;
      filter.Query = this.filterBuilder.realQuery;
    }
    let requestedFilters: Filter = new Filter();
    if(this.filterRequest) {
      requestedFilters.Dw = this.filterRequest.query;
      requestedFilters.Query = this.filterRequest.realQuery;
    }
    let factInfo: FactInfo = new FactInfo(this.fact.Name, this.fact.Title);
    let histocizedYear: number = this.historicizedYear != null? Number(this.historicizedYear) : null;
    if(this.type == InterrogationType.Dashboard/*!this.canReorder && this.filterBuilder && this.isNotSmart()*/) {
      //{name: dataSourceName.value, selected: checklistSelection.selected, fact: fact.Name, filters: {dw: filterBuilder.query, query: filterBuilder.realQuery}, historicizedYear: historicizedYear, dimensionOptions: dimensionOptions}"
      res = new DataSourceCreationDialogModel(dsName, this.checklistSelection.selected, factInfo,
        histocizedYear, filter)
    } else if(this.type == InterrogationType.Report/*this.canReorder && this.filterBuilder && this.filterRequest && this.isNotSmart()*/) {
      //{name: dataSourceName.value, selected: selectedToReorder, fact: fact.Name, filters: {dw: filterBuilder.query, query: filterBuilder.realQuery}, requestedFilters: {dw: filterRequest.query, query: filterRequest.realQuery}, historicizedYear: historicizedYear, dimensionOptions: dimensionOptions}"
      res = new DataSourceCreationDialogModel(dsName, this.selectedToReorder, factInfo,
        histocizedYear, filter, requestedFilters);
    } else if(this.type == InterrogationType.Smart/*this.canReorder && !this.isNotSmart()*/) {
      //{name: dataSourceName.value, selected: selectedToReorder, fact: fact.Name, filters: { dw: '', query: ''}, historicizedYear: historicizedYear, dimensionOptions: dimensionOptions}
      res = new DataSourceCreationDialogModel(dsName, this.selectedToReorder, factInfo, histocizedYear);
    }
    return res;
  }

}


