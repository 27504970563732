<h1 mat-dialog-title >
  @if (canStop) {
    <mat-icon style="vertical-align:  -5px;">settings</mat-icon>
  }
  {{message | translate}}
</h1>
<div mat-dialog-content>
  @if (subtitle) {
    <p>{{subtitle}}</p>
  }
  <div class="loading-spinner">
    <!--<mat-spinner [diameter]="40" strokeWidth="2"></mat-spinner>-->
    <app-spinner class="spnr"></app-spinner>
  </div>
</div>
@if (canStop) {
  <div mat-dialog-actions align="end">
    <button mat-raised-button class="btn-dark" [mat-dialog-close]="true">{{'dialog.stop' | translate}}</button>
  </div>
}
@if (hoCapito) {
  <div mat-dialog-actions align="end">
    <button mat-raised-button class="btn-dark" [mat-dialog-close]="true">Ok</button>
  </div>
}
