import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {take} from 'rxjs/operators';
import {DwApiService} from "../app/services/dw-mart-api.service";
import {Validator} from "../app/utils/validator";
import {ConfigService} from "../app/services/config.service";
import {InterrogationType} from "../app/models/enums.model";

@Injectable({
  providedIn: 'root'
})
export class SmartSurveysResolver implements Resolve<any> {
  constructor(
    private api: DwApiService,
    private configService: ConfigService) {}

  resolve(route: ActivatedRouteSnapshot) {
      let group = this.configService.configInstances.getGroupName();
      let id = '';
    if(route.url.length >= 2)
    {
      let urlId: any = route.url[1].toString();
      if (urlId != '25' && !Validator.isNumericValue(urlId)) {
        urlId = urlId.replaceAll('25', '');
        urlId = urlId.replaceAll('%20', ' ');
      }
      id = urlId;
    }
      let e = this.api.getGroupOptionsSingle(id, group, InterrogationType.Smart);
      return e.pipe(take(1));
  }
}
