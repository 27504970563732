<h1 mat-dialog-title>{{'dialog.refresh' | translate}}</h1>
<div mat-dialog-content>
	<p>
		{{'dialog.refresh-msg' | translate}}
	</p>
	<p class="error-message">
		{{data?.message}}
	</p>
</div>
<div mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close class="btn-light">{{'dialog.close' | translate}}</button>
	<button mat-raised-button [mat-dialog-close]="true" class="btn-dark">{{'dialog.ok' | translate}}</button>
</div>