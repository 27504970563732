import { TemplateRef } from "@angular/core";
import {
  AdditionalModules,
  DateRangeType,
  DocumentType,
  EmailServerType,
  FilterValueType,
  FunctionType,
  InterrogationType, LicenseType, ProductType, SortingType, SourceType,
  TimeLimitType
} from "./enums.model";
import { UtilityService } from "../services/utility.service";
import { EmailServerConfig, ScheduledOperation } from "./schedule.model";
import { GroupPermissionDTO } from "./permission";
import { InterrogationInfo } from "./interrogation-info";
import dayjs from "dayjs";
import { DataSource, Fact, Filter } from './dialogs-model';
import { DimensionOption } from "./dimensionoption";
import { FactInfo } from "./factInfo";
import { FieldNode } from "./fieldnode";
import { GroupOptions} from "./groupoptions";

export class WebApiResponse {
  data: any;
  errors: string[];
}

export class GetContoEconomicoPerRepartoRQ {
  anno?: number;
  mesi: number[];
  isUsaLordo: boolean;
}

export class GetContoEconomicoDettaglioRQ extends GetContoEconomicoPerRepartoRQ {
  IdReparto: number;
}

export class Location {
  Id: number;
  Location: string;
}

export class GetRiepilogoCostiRQ {
  anno?: number;
  mesi: number[];
  oidRepartoUsali?: number;
  oidAccountUsali?: number;
  isUsaLordo: boolean;
  locations: Location[];
}

export class GetConfrontoCostiRicaviRQ {
  anno?: number;
  annoRiferimento?: number;
  mese?: number;
  useBudget: boolean;
  isUsaLordo: boolean;
  tassaSoggiorno: boolean;
  locations: Location[];
}



export class BandedGridColumn {
  name: string;
  displayName: string;
  columns: GridColumn[];
}

export class GridColumn {
  name: string;
  displayName: string;
  displayText: any;
  displayValue: any;
  calculateValue: any;
  sortOrder: SortingType;
  dataType: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | 'time' | 'currency' | 'percentual' | 'color' | 'anagrafica' | 'password';
  isCurrency: boolean;
  isPercentual: boolean;
  format: string;
  showSearch: boolean;
  showFilter: boolean;
  allowEditing: boolean;
  allowSorting: boolean;
  hidden: boolean;
  values: any;
  objDisplayProperty: string;
  objValueExpr: string;
  allowClearing: boolean;
  customButtonIcon: string;
  customButtonCssClass: string;
  customButtonFixedToRight: boolean;
  customButtonHint: string;
  onCustomButtonClick: (e: any) => void;
  buttonVisibleCheck: (e: any) => boolean;
  buttonText: (e: any) => string;
  buttonColumnCaption: string;
  enum: any;
  enumName: string;
  filterProperty: string;
  calculateFilterExpression: any;
  summary: boolean;
  summaryType: string;
  required: boolean;
  minValue: number;
  maxValue: number;
  minLength: number;
  maxLength: number;
  validationMessage: string;
  editorType: string;
  colorColumn: boolean;
  anagraficaColumn: boolean;
  passwordColumn: boolean;
  groupIndex: number;
  isHideGroupName: boolean;
  dbPropertyName: string;
  displayFormat: string;
  isHyperlinkColumn: boolean;
  isTrendColumn: boolean;
  isInvertedTrendColumn: boolean;
  isCustomTrendColumn: boolean;
  onHyperlinkClick: (e: any) => void;
  isDecimal: boolean;
  customCellTemplate: TemplateRef<any>;
  customEditCellTemplate: TemplateRef<any>;
  showEditorAlways: boolean;
  setCellValue: (newData: any, value: any, currentRowData: any) => void;
  width: number;
  fixedWidth: number;
  headerFilterItems: any[];
  icon: (e: any) => string;
  hint: (e: any) => string;
  cssClass: string;
  fixedPosition: 'left' | 'right';

  constructor(name: string, displayName?: string, dataType: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | 'time' | 'currency' | 'percentual' | 'color' | 'anagrafica' | 'password' = 'string', displayText?: any, sortOrder?: SortingType, showSearch: boolean = true, showFilter: boolean = false, allowEditing: boolean = true, allowSorting: boolean = true, cssClass?: string, customCellTemplate?: TemplateRef<any>) {
    this.name = name;

    this.dataType = dataType || 'string';

    this.format = this.dataType == 'date' ? 'dd/MM/yyyy' : null;

    this.displayName = displayName;
    this.displayText = displayText;
    this.sortOrder = sortOrder;
    this.showSearch = showSearch;
    this.showFilter = showFilter;
    this.allowEditing = allowEditing;
    this.allowSorting = allowSorting;
    this.cssClass = cssClass;

    if (this.dataType == 'currency' || this.dataType == 'percentual') {
      let symbol: string = this.dataType == 'currency' ? ' €' : ' %';
      this.displayFormat = this.dataType == 'currency' ? "{0} €" : "{0} %";
      this.isCurrency = this.dataType == 'currency' ? true : false;
      this.isPercentual = this.dataType == 'percentual' ? true : false;

      this.dataType = 'number';

      if (!this.displayText) {
        this.displayText = (cellInfo: any) => {
          let value = !!cellInfo.valueText ? cellInfo.valueText : cellInfo.value;

          if (!!value && !isNaN(+value)) {
            if (this.isCurrency)
              return (Math.round((+value) * 100) / 100).toLocaleString("it-IT", { style: "currency", currency: "EUR" });
            else
              return (+value).toLocaleString("it-IT", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + symbol;

          } else {
            return value;
          }
        };
      }

      this.isDecimal = true;
    }

    if (this.dataType == 'time') {
      this.dataType = 'datetime';
      this.format = 'HH:mm:ss';
    }

    if (this.dataType == 'color') {
      this.colorColumn = true;
      this.dataType = 'string';
      this.editorType = 'dxColorBox';
    }

    if (this.dataType == 'anagrafica') {
      this.anagraficaColumn = true;
      this.dataType = 'string';
      this.filterProperty = 'ragioneSociale';
    }

    if (this.dataType == 'password') {
      this.passwordColumn = true;
      this.dataType = 'string';
    }
  }

  public static buttonColumn(name: string, icon: string, onClick?: (e: any) => void, cssClass?: string, buttonVisibleCheck?: (e: any) => boolean, fixedToRight: boolean = false, hint?: string, isAlwaysVisible: boolean = false): GridColumn {
    let column = new GridColumn(name);
    column.customButtonIcon = icon;
    column.customButtonCssClass = cssClass;
    column.customButtonFixedToRight = fixedToRight;
    column.customButtonHint = hint;
    column.onCustomButtonClick = onClick;

    column.buttonVisibleCheck = (e: any) => {
      if (isAlwaysVisible) {
        return true;
      } else {
        let visible = e.row != null && !e.row.isNewRow && !e.row.isEditing;

        if (buttonVisibleCheck != null && visible) {
          visible = buttonVisibleCheck(e);
        }

        return visible;
      }
    };

    return column;
  }

  public static iconColumn(name: string, icon: (e: any) => string, hint?: (e: any) => string): GridColumn {
    let column = new GridColumn(name);
    column.icon = icon;
    column.hint = hint;
    return column;
  }

  public static textButtonColumn(name: string, text: string, onClick: (data: any) => void, buttonVisibleCheck?: (data: any) => boolean, caption?: string, buttonText?: (data: any) => string): GridColumn {
    let column = new GridColumn(name, text);
    column.onCustomButtonClick = onClick;
    column.buttonVisibleCheck = buttonVisibleCheck;
    column.buttonText = buttonText;
    column.buttonColumnCaption = caption;
    return column;
  }

  public static hyperlinkColumn(column: GridColumn, onHyperlinkClick: (data: any) => void): GridColumn {
    column.isHyperlinkColumn = true;
    column.onHyperlinkClick = onHyperlinkClick;
    return column;
  }

  public hyperlinkColumn(onHyperlinkClick: (data: any) => void): GridColumn {
    return GridColumn.hyperlinkColumn(this, onHyperlinkClick);
  }

  public static trendColumn(column: GridColumn, inverted: boolean = false): GridColumn {
    if (!inverted)
      column.isTrendColumn = true;
    else
      column.isInvertedTrendColumn = true;

    return column;
  }

  public trendColumn(inverted: boolean = false): GridColumn {
    return GridColumn.trendColumn(this, inverted);
  }

  public static customTrendColumn(column: GridColumn): GridColumn {
    column.isCustomTrendColumn = true;
    return column;
  }

  public customTrendColumn(): GridColumn {
    return GridColumn.customTrendColumn(this);
  }

  public static thousandSeparatorColumn(column: GridColumn): GridColumn {
    column.displayText = (cellInfo: any) => {
      let value = !!cellInfo.valueText ? cellInfo.valueText : cellInfo.value;

      if (!!value && !isNaN(+value)) {
        return (+value).toLocaleString("it-IT");
      } else {
        return value;
      }
    };

    return column;
  }

  public thousandSeparatorColumn(): GridColumn {
    return GridColumn.thousandSeparatorColumn(this);
  }

  public static enumColumn(name: string, displayName: string, en: any, enumName: string, utility?: UtilityService): GridColumn {
    let column = new GridColumn(name, displayName);
    column.enum = en;
    column.enumName = enumName;
    column.showSearch = false;
    column.showFilter = true;

    if (utility != null) {
      column.headerFilterItems = utility.getEnumLookup(en, enumName, null, 'value', 'text');
    }

    return column;
  }

  public static objColumn(name: string, displayName: string, filterProperty: string): GridColumn {
    let column = new GridColumn(name, displayName);
    column.filterProperty = filterProperty;
    return column;
  }

  public static lookupColumn(name: string, displayName: string, values: any, objDisplayProperty: string, allowClearing: boolean = true, setCellValue?: (newData: any, value: any, currentRowData: any) => void, objValueExpr: string = 'oid'): GridColumn {
    let column = new GridColumn(name, displayName);
    column.values = values;
    column.objDisplayProperty = objDisplayProperty;
    column.allowClearing = allowClearing;
    column.objValueExpr = objValueExpr;
    column.setCellValue = setCellValue;
    return column;
  }

  public static enumLookupColumn(name: string, displayName: string, values: any[], allowClearing: boolean = false, setCellValue?: (newData: any, value: any, currentRowData: any) => void): GridColumn {
    let column = new GridColumn(name, displayName);
    column.values = values;
    column.objDisplayProperty = 'name';
    column.allowClearing = allowClearing;
    column.objValueExpr = 'key';
    column.setCellValue = setCellValue;
    return column;
  }

  public static summaryColumn(column: GridColumn, summaryType: 'sum' | 'avg' | 'custom' = 'sum'): GridColumn {
    column.summary = true;
    column.summaryType = summaryType;
    return column;
  }

  public summaryColumn(summaryType: 'sum' | 'avg' | 'custom' = 'sum'): GridColumn {
    return GridColumn.summaryColumn(this, summaryType);
  }

  public static hiddenColumn(column: GridColumn): GridColumn {
    column.hidden = true;
    return column;
  }

  public hiddenColumn(): GridColumn {
    return GridColumn.hiddenColumn(this);
  }

  public static notEditableColumn(column: GridColumn): GridColumn {
    column.allowEditing = false;
    return column;
  }

  public notEditableColumn(): GridColumn {
    return GridColumn.notEditableColumn(this);
  }

  public static groupedColum(column: GridColumn, index: number = 0, isHideGroupName: boolean = false): GridColumn {
    column.groupIndex = index;
    column.isHideGroupName = isHideGroupName;
    return column;
  }

  public groupedColum(index: number = 0, isHideGroupName: boolean = false): GridColumn {
    return GridColumn.groupedColum(this, index, isHideGroupName);
  }

  public static requiredColumn(column: GridColumn): GridColumn {
    column.required = true;
    return column;
  }

  public requiredColumn(): GridColumn {
    return GridColumn.requiredColumn(this);
  }

  public static rangeColumn(column: GridColumn, minValue: number, maxValue?: number, validationMessage?: string): GridColumn {
    column.minValue = minValue;
    column.maxValue = maxValue;
    column.validationMessage = validationMessage;
    return column;
  }

  public rangeColumn(minValue: number, maxValue?: number, validationMessage?: string): GridColumn {
    return GridColumn.rangeColumn(this, minValue, maxValue, validationMessage);
  }

  public static lengthColumn(column: GridColumn, minLength: number, maxLength?: number, validationMessage?: string): GridColumn {
    column.minLength = minLength;
    column.maxLength = maxLength;
    column.validationMessage = validationMessage;
    return column;
  }

  public lengthColumn(minLength: number, maxLength?: number, validationMessage?: string): GridColumn {
    return GridColumn.lengthColumn(this, minLength, maxLength, validationMessage);
  }

  public static customTextColumn(column: GridColumn, fields?: string[], displayValue?: any): GridColumn {
    if (fields != null && fields.length > 0) {
      column.displayValue = (rowData: any) => fields.map(x => this.getPropertyValue(rowData, x)).join(' ');
    } else {
      column.displayValue = displayValue;
    }

    return column;
  }

  public customTextColumn(fields?: string[], displayValue?: any): GridColumn {
    return GridColumn.customTextColumn(this, fields, displayValue);
  }

  public static customTextColumnFilter(column: GridColumn, fields?: string[], calculateValue?: any): GridColumn {
    if (fields != null && fields.length > 0) {
      column.calculateValue = (rowData: any) => fields.map(x => this.getPropertyValue(rowData, x)).join(' ');
    } else {
      column.calculateValue = calculateValue;
    }

    return column;
  }

  public static unboundColumn(column: GridColumn, dbPropertyName: string): GridColumn {
    column.dbPropertyName = dbPropertyName;
    return column;
  }

  public unboundColumn(dbPropertyName: string): GridColumn {
    return GridColumn.unboundColumn(this, dbPropertyName);
  }

  public static headerFilterColumn(column: GridColumn, items: any[]): GridColumn {
    column.showSearch = false;
    column.showFilter = true;
    column.headerFilterItems = items;
    return column;
  }

  public headerFilterColumn(items: any[]): GridColumn {
    return GridColumn.headerFilterColumn(this, items);
  }

  public static customWidthColumn(column: GridColumn, width: number): GridColumn {
    column.width = width;
    return column;
  }

  public customWidthColumn(width: number): GridColumn {
    return GridColumn.customWidthColumn(this, width);
  }

  public static customTemplateColumn(column: GridColumn, customCellTemplate: TemplateRef<any>, customEditCellTemplate?: TemplateRef<any>, showEditorAlways?: boolean): GridColumn {
    column.customCellTemplate = customCellTemplate;
    column.customEditCellTemplate = customEditCellTemplate;
    column.showEditorAlways = showEditorAlways;
    return column;
  }

  public customTemplateColumn(customCellTemplate: TemplateRef<any>, customEditCellTemplate?: TemplateRef<any>, showEditorAlways?: boolean): GridColumn {
    return GridColumn.customTemplateColumn(this, customCellTemplate, customEditCellTemplate, showEditorAlways);
  }

  public static fixedColumn(column: GridColumn, fixedPosition: 'left' | 'right', width?: number, cssClass?: string): GridColumn {
    column.fixedPosition = fixedPosition;
    column.fixedWidth = width;
    column.cssClass = cssClass;
    return column;
  }

  public fixedColumn(fixedPosition: 'left' | 'right', width?: number, cssClass?: string): GridColumn {
    return GridColumn.fixedColumn(this, fixedPosition, width, cssClass);
  }

  public static selectColumn(): GridColumn {
    return new GridColumn('select', null);
  }

  private static getPropertyValue(obj: any, name: string): string {
    let propertySplit = name.split('.');

    let value: any = obj;

    for (let property of propertySplit) {
      if (value != null) {
        value = value[property];
      } else {
        return '';
      }
    }

    return value;
  }
}

export class FilterGrid {
  field: string;
  fields: string[];
  operator: string;
  invertedOperator: string;
  value: string;
  stringValue: boolean;
  dateValue: boolean;
  active: boolean;
  inverted: boolean;

  constructor(field: string | string[], operator?: string, value?: string, filterValueType?: FilterValueType, active: boolean = false, invertedOperator?: string, inverted: boolean = false) {
    if (field != null) {
      if (Array.isArray(field)) {
        this.fields = field;
      } else {
        this.field = field;
      }
    }

    this.operator = operator;
    this.invertedOperator = invertedOperator;
    this.value = value;

    if (filterValueType != null) {
      switch (filterValueType) {
        case FilterValueType.Number:
          this.stringValue = false;
          this.dateValue = false;
          break;

        case FilterValueType.String:
          this.stringValue = true;
          this.dateValue = false;
          break;

        case FilterValueType.Date:
          this.stringValue = false;
          this.dateValue = true;
          break;
      }
    }

    this.active = active;
    this.inverted = inverted;
  }
}

export class Grouping {
  field: string;
  type: SortingType;

  constructor(field?: string, type?: SortingType) {
    this.field = field;
    this.type = type;
  }
}

export class WebApiRequestViewSelect {
  filterRequest: WebApiRequestFilter;
  addEmptyItem: boolean;

  constructor(filterRequest: WebApiRequestFilter, addEmptyItem: boolean) {
    this.filterRequest = filterRequest;
    this.addEmptyItem = addEmptyItem;
  }
}

export class WebApiRequestFilter {
  criteria: string;
  sorting: Sorting[];
  grouping: Grouping[];
  take: number;
  skip: number;

  constructor(criteria?: string, sorting?: Sorting) {
    this.criteria = criteria;

    if (sorting != null) {
      this.sorting = [sorting];
    }
  }
}

export class WebApiResponseFilter extends WebApiResponse {
  totalCount: number;

  constructor() {
    super();
  }
}

export class WebApiResponseUpdate extends WebApiResponse {
  override data: { obj: any }
}

export class WebApiResponseUpdateDetails extends WebApiResponse {
  override data: { obj: any, details: any[] }
}

export class GetScheduledOperationRS {
  GoogleAuthToken: string;
  ScheduledOperation: ScheduledOperation;
}

export class Sorting {
  field: string;
  type: SortingType;

  constructor(field?: string, type?: SortingType) {
    this.field = field;
    this.type = type;
  }
}

export class PieOptions {
  title: string;
  palette: string;
  dataSource: PieData[];
}

export class PieData {
  description: string;
  value: number;
}

export class GetServerEmailConfigRS {
  GoogleAuthToken: string;
  ServerEmailConfig: GroupEmailServerConfig;
}

export class SendTestEmailRQ {
  Sender: string;
  Recipient: string;
  Timeout: number;
  ServerSmtp: string;
  UseDefaultCredentials: boolean;
  UseSsl: boolean;
  User: string;
  Password: string;

  constructor(scheduledOperation?: ScheduledOperation | EmailServerConfig) {
    if (scheduledOperation) {
      this.Sender = scheduledOperation.Sender;
      this.Recipient = scheduledOperation.Recipient.join(';');
      this.Timeout = scheduledOperation.Timeout;
      this.ServerSmtp = scheduledOperation.ServerSmtp;
      this.UseDefaultCredentials = scheduledOperation.DefaultCredentials;
      this.UseSsl = scheduledOperation.Ssl;
      this.User = scheduledOperation.User;
      this.Password = scheduledOperation.Password;
    }
  }
}

export class CheckSchedulerOperationNameRQ {
  Id: number;
  Name: string;

  constructor(id: number, name: string) {
    this.Id = id;
    this.Name = name;
  }
}

export class CheckSchedulerOperationNameRS {
  IsNameOk: boolean;
}

export class GetGroupPermissionsRS {
  GroupPermissions: GroupPermissionDTO[];
}

export class GetInterrogationsInfoRS {
  InterrogationsInfo: InterrogationInfo[];
}

export class UpdateUserRQ {
  Id: number;
  Group: string;
  Name: string;

  constructor(id: number, group: string, name: string) {
    this.Id = id;
    this.Group = group;
    this.Name = name;
  }
}

export class GroupOptionsSingleRQ {
  Id: string;
  Group: string;
  Type: InterrogationType;

  constructor(id: string, group: string, type: InterrogationType) {
    this.Id = id;
    this.Group = group;
    this.Type = type;
  }
}

/*export class GroupOptions {
  Name: string;
  EtlRead: boolean;
  EtlWrite: boolean;
  CanAccessSchOp: boolean;
  CanAccessEmailConfig: boolean;
  TypeFilterActive: boolean;
  FilterCompany: string;
  Homepage: string;
  CanCreate: boolean;
  GoogleAuthToken: string;
}*/

export class ApplyRequestedFiltersRQ {
  Id: string;
  RequestedFilters: Filter;
}

export class InterrogationOptions {
  Name: string;
  Id: string;
  Description: string;
  Write: boolean;
  Default: boolean;
  Modified: boolean;
  Visible: boolean;
  Order: number;
  ProductType: ProductType;
  TimeLimit: number;
  TimeLimitType: TimeLimitType;
  RequestedFilters: string;
  HistoricizedYears: number[];
  Datasource: DataSource[];
}

export class TableInfo {
  Name: string;
  Title: string;
  HistoricizedYears: number[];
  ProductTypes: ProductType[];
}

export class SurveyOptions {
  Columns: string;
  PivotColumns: string;
  ChartType: string;
  ChartMeasures: string;
  PivotGridColumn: string;
  RowChartEnabled: boolean;
  GridChartEnabled: boolean;
  PivotGridEnabled: boolean;
  SecondPeriodEnabled: boolean;
  FirstStartDate: dayjs.Dayjs;
  FirstEndDate: dayjs.Dayjs;
  SecondStartDate: dayjs.Dayjs;
  SecondEndDate: dayjs.Dayjs;
  PageSize: number;
  PageIndex: number;
  FirstRange: number;
  SecondRange: number;
  FilterTS: string;
  HavingFilter: string;
  WhereFilter: string;
  Sorting: string;
  TotalSummary: string;
  IsFYHistoricized: boolean;
  IsSYHistoricized: boolean;
  Group: string;
  GroupSummary: string;
  Recipient: string;
  IdInterrogation: number;
}

export class GroupOptionsSingleRS {
  GroupOptions: GroupOptions;
  Interrogations: InterrogationOptions[] = [];
  LicenseInfo: LicenseType;
  Fact: FactInfo;
  Facts: TableInfo[];
  MaxOnlineYears: number;
  MaxFtCount: number;
  BacthEnabled: boolean;
  CustomDataSourceEnabled: boolean;
  ADUserLog: boolean;
  StartDate: string;
  EndDate: string;
  HistoricizedYear: string;
  TimeLimit: string;
  TimeLimitType: TimeLimitType;
  RequestedFilters: string;
  HasHistoricizedYears: boolean;
  SurveyOptions: SurveyOptions;
  DWType: SourceType;
}

export class DatasourceExportInfo {
  Name: string;
  Fact: string;
  Fields: string;
  Filter: string;
  SqlFilter: string;
  RequestFilter: string;
  RequestSqlFilter: string;
}
export class InterrogationExportInfo {
  Name: string;
  Description: string;
  Composition: string;
  Datasources: DatasourceExportInfo;
  Type: InterrogationType;
  ProductType: ProductType;
  MonthLimit: number;
  TimeLimit: number;
  TimeLimitType: TimeLimitType;
  SurveyOptions: SurveyOptions;
}

export class InterrogationExportConfig {
  Id: string;
  InterrogationInfo: InterrogationExportInfo;
}

export class InterrogationName {
  Name: string;
}

export class InterrogationId {
  Id: string;
}

export class ApplyHistoricizedFiltersRQ {
  Id: string;
  Year: number;
}

export class AddMonthLimitRQ {
  Id: string;
  Limit: number;
  LimitType: TimeLimitType;
}

export class GoogleAuthUrl {
  Url: string;
}

export class UpdateInterrogationVisibilityRQ {
  InterrogationId: string;
  Json: string;
}

export class ModifyInterrogationNameRQ {
  OldName: string;
  NewName: string;
  Type: InterrogationType;
}

export class ModifyInterrogationDescriptionRQ {
  Description: string;

  constructor(description: string) {
    this.Description = description;
  }
}

export class CopyInterrogationRQ {
  Id: string;
  Type: InterrogationType;
}

export class GroupEmailServerConfig {
  IdGroup: number;
  ServerSmtp: string;
  Sender: string;
  Recipient: string;
  Timeout: number;
  DefaultCredentials: boolean;
  Ssl: boolean;
  User: string;
  Password: string;
  ServerType: EmailServerType;
  DocumentType: DocumentType;
}

export class ModifyDataSourceRQ {
  InterrogationId: string;
  InterrogationType: InterrogationType;
  DataSourceName: string;
  Fields: FieldNode[];
  Filters: Filter;
  RequestedFilters: Filter;
  Fact: Fact;
  DataSourceId: string;
  HistoricizedYears: number;
  Facts: FactInfo[];
  DimensionOptions: DimensionOption;

  constructor(InterrogationId: string, InterrogationType: InterrogationType, DataSourceName: string, Fields: FieldNode[], Filters: Filter, RequestedFilters: Filter,
    Fact: Fact, DataSourceId: string, HistoricizedYears: number, Facts: FactInfo[], DimensionOptions: DimensionOption) {
    this.InterrogationId = InterrogationId;
    this.InterrogationType = InterrogationType;
    this.DataSourceName = DataSourceName;
    this.Fields = Fields;
    this.Filters = Filters;
    this.RequestedFilters = RequestedFilters;
    this.Fact = Fact;
    this.DataSourceId = DataSourceId;
    this.HistoricizedYears = HistoricizedYears;
    this.Facts = Facts;
    this.DimensionOptions = DimensionOptions;
  }
}

export class AddDashboardDatasourceRQ {
  DataSourceName: string;
  Fields: FieldNode[];
  Filters: Filter;
  RequestedFilters: Filter;
  Fact: FactInfo;
  DatashboardId: string;
  HistoricizedYears: number;
}

export class AcknowledgeRS {
  Success: boolean;
}

export class AddInterrogationRQ {
  InterrogationData: string;
  DataSourceName: string;
  Fields: FieldNode[];
  Filters: Filter;
  RequestedFilters: Filter;
  Fact: FactInfo;
  Name: string;
  Description: string;
  HistoricizedYears: number;
}

export class DateRange {
  TimeLimit: number;
  TimeLimitType: TimeLimitType;
}

export class SetDateRangeRQ {
  Id: string;
  DateRange: DateRange;
}

export class AddNewSurveyRQ {
  dataSourceName: string;
  queryFields: string;
  filters: string;
  fact: string;
  name: string;
  description: string;
  firstStart: string;
  firstEnd: string;
  secondStart: string;
  secondEnd: string;
  isFYHistoricized: boolean;
  isSYHistoricized: boolean;
  rowChart: boolean;
  filterTS: string;
  bodyParams: SurveyBodyParams;
}

export class SurveyBodyParams {
  skip: number;
  take: number;
  requireTotalCount: boolean;
  requireGroupCount: boolean;
  sort: string;
  filter: string;
  whereFilter: string;
  havingFilter: string;
  totalSummary: string;
  group: string;
  groupSummary: string;
  isLoadingAll: boolean;

  constructor() {
    this.skip = undefined;
    this.take = undefined;
    this.requireTotalCount = undefined;
    this.requireGroupCount = undefined;
    this.sort = undefined;
    this.filter = undefined;
    this.whereFilter = undefined;
    this.havingFilter = undefined;
    this.totalSummary = undefined;
    this.group = undefined;
    this.groupSummary = undefined;
    this.isLoadingAll = undefined;
  }
}

export class SaveSurveyRQ {
  Id: string;
  DataSourceName: string;
  Fields: string;
  Fact: string;
  Name: string;
  Description: string;
  Columns: string;
  RowChartEnabled: boolean;
  GridChartEnabled: boolean;
  SecondPeriodEnabled: boolean;
  ChartType: string;
  ChartMeasures: string;
  FirstStartDate: dayjs.Dayjs;
  FirstEndDate: dayjs.Dayjs;
  SecondStartDate: dayjs.Dayjs;
  SecondEndDate: dayjs.Dayjs;
  PageIndex: number;
  PageSize: number;
  PivotGridEnabled: boolean;
  PivotGridColumn: string;
  PivotColumns: string;
  FirstRange: number;
  SecondRange: number;
  //DimensionOptions: string;
  Summary: string;
  FilterTS: string;
  WhereFilter: string;
  HavingFilter: string;
  Sorting: string;
  TotalSummary: string;
  GroupSummary: string;
  IsFYHistoricized: boolean;
  IsSYHistoricized: boolean;
  Group: string;
}

export class LoginRQ {
  Username: string;
  Password: string;

  constructor(username?: string, password?: string) {
    this.Username = username;
    this.Password = password;
  }
}

export class InitializeRS {
  FtCount: number;
  OnlineYears: number;
  License: LicenseType;
  ProductType: ProductType;
  Session: number;
  IsMenuAvailable: boolean;
  AdditionalModules: AdditionalModules;
  GroupOptions: GroupOptions;
  Facts: FactInfo[];
}

export class FtYearsCountRS {
  FtCount: number;
  OnlineYears: number;
  IsMenuAvailable: boolean;
}

export class GetUserInterrogationsRQ {
  Group: string;
}

export class GetOrderListRQ {
  id: number; 
  order: number;
}

export class GetConfigDataRQ {
  dashboards: string;
  reports: string;
  surveys: string;
  deleteOnImport: boolean;
}

export class ExportPdfDashboardRQ {
  interrogationId: string;
  state: string;
}
export class ExpressionRQ {
  expression: string;
}
export class ValidateFormulaRQ {
  expression: string; 
  fact: string; 
  factAlias: string; 
  aggrFunction: FunctionType;
}

export class ModifyRecipientServerEmailRQ {
  idInterrogation: string;
  receiver: string;
}

export class ExportDashboardDataRQ {
  interrogationId: string;
  state: string;
}

export class GetGroupNameRQ{
  groupName: string;
}