@if (files.length > 0) {
  <table mat-table [dataSource]="files" class="selected-files-table">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'upload.name' |translate}} </th>
      <td mat-cell *matCellDef="let file"> {{file.name}} </td>
    </ng-container>
    <!-- Type Column -->
    <!--<ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef> Type </th>
    <td mat-cell *matCellDef="let file"> {{file.type}} </td>
  </ng-container>-->
  <!-- Size Column -->
  <!--<ng-container matColumnDef="size">
  <th mat-header-cell *matHeaderCellDef> Size (bytes) </th>
  <td mat-cell *matCellDef="let file"> {{file.size}} </td>
</ng-container>-->
<!-- Last Modified Column -->
<ng-container matColumnDef="lastModified">
  <th mat-header-cell *matHeaderCellDef> {{'upload.modified' |translate}}</th>
  <td mat-cell *matCellDef="let file"> {{file.lastModified | date }} </td>
</ng-container>
<ng-container matColumnDef="delete">
  <th mat-header-cell *matHeaderCellDef> </th>
  <td mat-cell *matCellDef="let file">
    <button mat-icon-button (click)="delete()">
      <span class="material-icons-outlined">delete</span>
    </button>
  </td>
</ng-container>
<tr mat-header-row *matHeaderRowDef="_displayedColumns"></tr>
<tr mat-row *matRowDef="let row; columns: _displayedColumns;"></tr>
</table>
}