import {FlatTreeControl} from '@angular/cdk/tree';
import {HttpParams} from '@angular/common/http';
import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {TranslateService} from '@ngx-translate/core';
import {DwApiService} from '../../../services/dw-mart-api.service';
import {CFFunction} from '../../../models/cffunction';
import {CFFunctionNode} from '../../../models/cffunctionnode';
import {ChecklistDatabase} from '../../../models/checklistdatabase';
import {ChecklistFunctionDatabase} from '../../../models/checklistfunctiondatabase';
import {Field} from '../../../models/field';
import {FieldNode} from '../../../models/fieldnode';
import {CFFunctionType, FunctionType, ProductTypeStyle, SourceType} from '../../../models/enums.model';
import {DialogService} from '../../dialog.service';
import {Validator} from '../../../utils/validator';
import { ExpressionRQ, ValidateFormulaRQ } from 'src/app/models/web-api-models';

@Component({
  selector: 'app-calculated-field-form',
  templateUrl: './calculated-field-form.component.html',
  styleUrls: ['./calculated-field-form.component.scss']
})
export class CalculatedFieldFormComponent implements OnInit {

  sourceType: SourceType;
  cfName: string = undefined;
  fact: { tableName: undefined, tableTitle: undefined };
  flatNodeMap = new Map<FieldNode, Field>();
  nestedNodeMap = new Map<Field, FieldNode>();
  treeControl: FlatTreeControl<FieldNode>;
  treeFlattener: MatTreeFlattener<Field, FieldNode>;

  functionsFlatNodeMap = new Map<CFFunctionNode, CFFunction>();
  functionsNestedNodeMap = new Map<CFFunction, CFFunctionNode>();
  functionsTreeControl: FlatTreeControl<CFFunctionNode>;
  functionsTreeFlattener: MatTreeFlattener<CFFunction, CFFunctionNode>;

  @Input() dataSource: MatTreeFlatDataSource<Field, FieldNode>;
  @Input() functionsDataSource: MatTreeFlatDataSource<CFFunction, CFFunctionNode>;

  fields: FieldNode[] = [];
  functions: CFFunctionNode[] = [];
  cfNames: string[] = [];

  expressionError: boolean = false;
  expression: string = '';
  sqlServerExpression: string = '';
  mySqlExpression: string = '';

  functionTypes: { name: string, type: FunctionType }[] = [];
  aggrFuntionSelected: { name: string, type: FunctionType } = undefined;

  cfFunctionErr: string = this.translate.instant('calculated-fields.function-err');

  constructor(
    private database: ChecklistDatabase,
    private functionDatabase: ChecklistFunctionDatabase,
    private api: DwApiService,
    @Inject(MAT_DIALOG_DATA) data,
    private cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private dialogService: DialogService,
    public dialog: MatDialog
  ) {

    this.cfName = data.cfName ? data.cfName : '';
    this.fact = data.fact;
    this.sourceType = data.sourceType;
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FieldNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    //this.dataSource.data = data.datasource;
    this.database.initialize(data.datasource);
    database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    this.functionsTreeFlattener = new MatTreeFlattener(this.functionTransformer, this.getLevelFunction,
      this.isExpandableFunction, this.getChildrenFunction);
    this.functionsTreeControl = new FlatTreeControl<CFFunctionNode>(this.getLevelFunction, this.isExpandableFunction);
    this.functionsDataSource = new MatTreeFlatDataSource(this.functionsTreeControl, this.functionsTreeFlattener);
    this.functionDatabase.initialize(CFFunction.getDefaultFunctions(this.translate));
    this.functionDatabase.dataChange.subscribe(data => {
      this.functionsDataSource.data = data;
    });
    this.cfNames = data.cfNames;
    if (data.expression) this.expression = data.expression;
    if (data.sqlServerExpression) this.sqlServerExpression = data.sqlServerExpression;
    if (data.mySqlExpression) this.mySqlExpression = data.mySqlExpression;
    this.functionTypes.push({ name: this.translate.instant('dialog.delete-selection'), type: FunctionType.None });
    this.functionTypes.push({ name: this.translate.instant('calculated-fields.functions.aggr.sum-abbr'), type: FunctionType.Sum });
    this.functionTypes.push({ name: this.translate.instant('calculated-fields.functions.aggr.avg-abbr'), type: FunctionType.Avg });
    this.functionTypes.push({ name: this.translate.instant('calculated-fields.functions.aggr.count-abbr'), type: FunctionType.Count });
    this.functionTypes.push({ name: this.translate.instant('calculated-fields.functions.aggr.max-abbr'), type: FunctionType.Max });
    this.functionTypes.push({ name: this.translate.instant('calculated-fields.functions.aggr.min-abbr'), type: FunctionType.Min });
    this.functionTypes.push({ name: this.translate.instant('calculated-fields.functions.aggr.countdistinct-abbr'), type: FunctionType.CountDistinct });
    this.aggrFuntionSelected = data.functionType != undefined ? this.functionTypes.find(t => t.type == data.functionType) : this.functionTypes.find(t => t.type == FunctionType.None)
  }

  ngOnInit(): void {
  }

  aggrFunctionChange(e) {
    console.log(e);
    this.aggrFuntionSelected = e.value;
    this.checkExpression(this.expression);
  }

  getLevel = (node: FieldNode) => node.level;

  isExpandable = (node: FieldNode) => node.expandable;

  getChildren = (node: Field): Field[] => node.children;

  hasChild = (_: number, _nodeData: FieldNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FieldNode) => _nodeData.title === '';

  getLevelFunction = (node: CFFunctionNode) => node.level;

  isExpandableFunction = (node: CFFunctionNode) => node.expandable;

  getChildrenFunction = (node: CFFunction): CFFunction[] => node.children;

  hasChildFunction = (_: number, _nodeData: CFFunctionNode) => _nodeData.expandable;

  hasNoContentFunction = (_: number, _nodeData: CFFunctionNode) => _nodeData.name === '';

  transformer = (node: Field, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.title === node.title
      ? existingNode
      : new FieldNode();
    flatNode.name = node.name;
    flatNode.title = node.title
    flatNode.level = level;
    flatNode.tableTitle = node.tableTitle;
    flatNode.parentName = node.parentName;
    flatNode.parentTitle = node.parentTitle;
    flatNode.expandable = !!node.children?.length;
    flatNode.isMeasure = node.isMeasure;
    flatNode.isEnum = node.isEnum;
    flatNode.preview = flatNode.title + ' (' + flatNode.parentTitle + ')';
    if (node.functionType != null) flatNode.functionType = node.functionType;
    flatNode.fieldType = node.fieldType;
    flatNode.type = node.type;
    flatNode.grouped = /*!node.isMeasure*/true;
    flatNode.format = node.format;
    if (node.fk) flatNode.fk = node.fk;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  functionTransformer = (node: CFFunction, level: number) => {
    const existingNode = this.functionsNestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new CFFunctionNode();
    flatNode.name = node.name;
    flatNode.baseValue = node.baseValue;
    flatNode.preview = node.preview;
    flatNode.level = level;
    flatNode.description = node.description;
    flatNode.sqlServerValue = node.sqlServerValue;
    flatNode.expandable = !!node.children?.length;
    flatNode.mySqlValue = node.mySqlValue;
    flatNode.acceptedTypes = node.acceptedTypes;
    flatNode.functionType = node.functionType;
    this.functionsFlatNodeMap.set(flatNode, node);
    this.functionsNestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  changeCalculatedFieldName(e) {
    if (!this.cfNames.includes(e.target.value)) {
      if (e.target.value.startsWith("-") || (/[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/).test(e.target.value)) {
        this.cfName = null;
      } else {
        let params: HttpParams = new HttpParams();
        params = params.append('name', e.target.value);
        params = params.append('factName', this.fact.tableName);

        this.api.isValidAliasName(params).subscribe((res) => {
          if (res) {
            this.cfName = e.target.value
            //return null;
          } else this.cfName = null;//return { message: 'nome non valido!' };
        }, err => { console.log(err); throw err; });
      }
    } else {
      this.cfName = null;
    }
  }

  filterChanged(filter: EventTarget) {
    let filterText = (filter as HTMLInputElement).value;
    this.database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }

  functionsFilterChanged(filter: EventTarget) {
    let filterText = (filter as HTMLInputElement).value;
    this.functionDatabase.filter(filterText);
    if (filterText) {
      this.functionsTreeControl.expandAll();
    } else {
      this.functionsTreeControl.collapseAll();
      // this.treeControl.expandAll();
    }
  }

  insertField(node: FieldNode) {
    //se dimensione [table title].[col name]
    //se metrica aggr([table title].[col name])
    console.log(node);
    if (this.expression.length > 0) {
      if (node.isMeasure) {
        switch (node.functionType) {
          case FunctionType.Avg:
            this.expression = this.expression + ' Avg([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Count:
            this.expression = this.expression + ' Count([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Max:
            this.expression = this.expression + ' Max([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Min:
            this.expression = this.expression + ' Min([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Sum:
            this.expression = this.expression + ' Sum([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.CountDistinct:
            this.expression = 'Count(distinct [' + node.tableTitle + '].[' + node.name + '])';
            break;
        }
      } else this.expression = this.expression + ' [' + node.tableTitle + '].[' + node.name + ']';
    } else {
      if (node.isMeasure) {
        switch (node.functionType) {
          case FunctionType.Avg:
            this.expression = 'Avg([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Count:
            this.expression = 'Count([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Max:
            this.expression = 'Max([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Min:
            this.expression = 'Min([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.Sum:
            this.expression = 'Sum([' + node.tableTitle + '].[' + node.name + '])';
            break;
          case FunctionType.CountDistinct:
            this.expression = 'Count(distinct [' + node.tableTitle + '].[' + node.name + '])';
            break;
        }
      } else this.expression = '[' + node.tableTitle + '].[' + node.name + ']';
    }
    if (!this.fields.includes(node)) this.fields.push(node);
    this.onChangeExpression(this.expression);
  }

  insertFunction(node: CFFunctionNode) {
    //inserisce il baseValue
    console.log(node);
    this.expression = this.expression.length > 0 ? this.expression + ' ' + node.preview : node.preview;
    if (!this.functions.includes(node)) this.functions.push(node);
    this.onChangeExpression(this.expression);
  }

  onChangeExpression(expr: EventTarget | string) {
    let exprStr = (expr instanceof  EventTarget)  ? (expr as HTMLInputElement).value : expr;
    console.log(exprStr);
    console.log(this.expression);
    this.expression = exprStr;
    this.checkExpression(exprStr);
  }

  findField(tableTitle, colName) {
    //this.dataSource.data.find(el => el.parentTitle == tableTitle && el.name == colName)
    let field = undefined;
    for (let i in this.dataSource.data) {
      if (this.dataSource.data[i].children) {
        field = this.dataSource.data[i].children.find(el => el.tableTitle == tableTitle && el.name == colName);
        if (field != undefined && field != null) return field;
      }
    }
    return field;
  }

  findOccurence(expression, find, occurrence) {
    let count = 0;
    for (let i in expression) {
      if (expression[i] == find && count == occurrence - 1) {
        return i;
      } else if (expression[i] == find) {
        count = count + 1;
      }
    }
    return -1;
  }

  checkExpression(expr: string) {
    let body = new ExpressionRQ;
    console.log("Validate expression");
    console.log(body);
    body.expression= expr;
    let sqlExpression = expr;
    this.sqlServerExpression = expr;
    this.mySqlExpression = expr;
    this.api.validateExpression(body).subscribe({next: res => {
      if (res) {
        console.log('ok');
        this.expressionError = false;
        for (let i in this.functionsDataSource.data) {
          if (this.functionsDataSource.data[i].children) {
            if (this.functionsDataSource.data[i].functionType == CFFunctionType.Aggregate) {
              //sezione sum
              if (expr.includes("Sum") || expr.includes("sum")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Sum", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Sum", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("sum", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("sum", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 3) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let finalIndex = sub.indexOf(')');
                      sub = sub.substring(3, finalIndex);
                      sub = sub.replaceAll('(', '');
                      sub = sub.replaceAll(')', '');
                      if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                        sub = sub.substring(1, sub.length - 1);
                        if (sub.includes('].[')) {
                          let split = sub.split('].[');
                          let tableTitle = split[0];
                          let colName = split[1];
                          let field = this.findField(tableTitle, colName);
                          if (field == undefined || !field.isMeasure) {
                            //return true;
                          //} else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Avg") || expr.includes("avg")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Avg", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Avg", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("avg", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("avg", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 3) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let finalIndex = sub.indexOf(')');
                      sub = sub.substring(3, finalIndex);
                      sub = sub.replaceAll('(', '');
                      sub = sub.replaceAll(')', '');
                      if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                        sub = sub.substring(1, sub.length - 1);
                        if (sub.includes('].[')) {
                          let split = sub.split('].[');
                          let tableTitle = split[0];
                          let colName = split[1];
                          let field = this.findField(tableTitle, colName);
                          if (field == undefined || !field.isMeasure) {
                            //return true;
                          //} else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("CountDistinct") || expr.includes("countdistinct")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("CountDistinct", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("CountDistinct", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("countdistinct", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("countdistinct", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 13) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let finalIndex = sub.indexOf(')');
                      sub = sub.substring(13, finalIndex);
                      sub = sub.replaceAll('(', '');
                      sub = sub.replaceAll(')', '');
                      if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                        sub = sub.substring(1, sub.length - 1);
                        if (sub.includes('].[')) {
                          let split = sub.split('].[');
                          let tableTitle = split[0];
                          let colName = split[1];
                          let field = this.findField(tableTitle, colName);;
                          if (field != undefined /*&& field.isMeasure*/) {
                            //return true;
                            let sub = <any>expr.substring(startingIndices[i], expr.length);
                            let stringToRep = sub.substring(0, finalIndex + 1);
                            sqlExpression = sqlExpression.replace(stringToRep, `Count( distinct [${tableTitle}].[${colName}])`);
                            this.sqlServerExpression = sqlExpression;
                            this.mySqlExpression = sqlExpression;
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Count") || expr.includes("count")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Count", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Count", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("count", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("count", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 5) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let finalIndex = sub.indexOf(')');
                      sub = sub.substring(5, finalIndex);
                      sub = sub.replaceAll('(', '');
                      sub = sub.replaceAll(')', '');
                      if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                        sub = sub.substring(1, sub.length - 1);
                        if (sub.includes('].[')) {
                          let split = sub.split('].[');
                          let tableTitle = split[0];
                          let colName = split[1];
                          let field = this.findField(tableTitle, colName);;
                          if (field != undefined && field != null /*&& field.isMeasure*/) {
                            //return true;
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      if(expr.charAt(Number(startingIndices[i]) + 5) == 'D' || expr.charAt(Number(startingIndices[i]) + 5) == 'd') {
                        //distinct
                        let sub = expr.substring(Number(startingIndices[i]) + 5, Number(startingIndices[i] + 13));
                        sub = sub.toLowerCase();
                        if(sub != 'distinct') {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    }
                  }
                }
              }
              if (expr.includes("Max") || expr.includes("max")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Max", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Max", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("max", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("max", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 3) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let finalIndex = sub.indexOf(')');
                      sub = sub.substring(3, finalIndex);
                      sub = sub.replaceAll('(', '');
                      sub = sub.replaceAll(')', '');
                      if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                        sub = sub.substring(1, sub.length - 1);
                        if (sub.includes('].[')) {
                          let split = sub.split('].[');
                          let tableTitle = split[0];
                          let colName = split[1];
                          let field = this.findField(tableTitle, colName);;
                          if (field != undefined && field != null && (field.isMeasure || field.type == 'decimal' || field.type == 'integer' || field.type == 'int')) {
                            //return true;
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Min") || expr.includes("min")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Min", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Min", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("min", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("min", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 3) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let finalIndex = sub.indexOf(')');
                      sub = sub.substring(3, finalIndex);
                      sub = sub.replaceAll('(', '');
                      sub = sub.replaceAll(')', '');
                      if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                        sub = sub.substring(1, sub.length - 1);
                        if (sub.includes('].[')) {
                          let split = sub.split('].[');
                          let tableTitle = split[0];
                          let colName = split[1];
                          let field = this.findField(tableTitle, colName);;
                          if (field != undefined && field != null && (field.isMeasure || field.type == 'decimal' || field.type == 'integer' || field.type == 'int')) {
                            //return true;
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
            } else if (this.functionsDataSource.data[i].functionType == CFFunctionType.Logic) {
              if (expr.includes("Iif") || expr.includes("Iif")) {

                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Iif", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Iif", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("iif", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("iif", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 3) == '(') {
                      if (this.sourceType == SourceType.MySQL) {
                        sqlExpression = sqlExpression.replace("Iif", "If");
                        sqlExpression = sqlExpression.replace("iif", "If");
                        this.mySqlExpression = sqlExpression;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("IsNull") || expr.includes("isnull")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("IsNull", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("IsNull", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("isnull", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("isnull", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 6) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 7) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(6, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure) {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `[${tableTitle}].[${colName}] is null`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 7, Number(startingIndices[i]) + 13);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg")) {
                            if (test.charAt(3) == '(') {
                              //analizzare se tra parentesi ho una metrica
                              let sub1 = sub.substring(7, sub.length);
                              let finalIndex = sub1.indexOf(')');
                              sub1 = sub1.substring(3, finalIndex);
                              sub1 = sub1.replaceAll('(', '');
                              sub1 = sub1.replaceAll(')', '');
                              if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                                sub1 = sub1.substring(1, sub1.length - 1);
                                if (sub1.includes('].[')) {
                                  let split = sub1.split('].[');
                                  let tableTitle = split[0];
                                  let colName = split[1];
                                  let field = this.findField(tableTitle, colName);;
                                  if (field != undefined && field != null && field.isMeasure) {
                                    //return true;
                                    let sub = <any>expr.substring(startingIndices[i], expr.length);
                                    //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                    let fIndex = this.findOccurence(sub, ")", 2);
                                    let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                    let func = sub.substring(7, sub.length);
                                    func = func.substring(0, func.indexOf('('));
                                    sqlExpression = sqlExpression.replace(stringToRep, `${func}([${tableTitle}].[${colName}]) is null`);
                                    this.sqlServerExpression = sqlExpression;
                                    this.mySqlExpression = sqlExpression;
                                    //console.log(sqlExpression);
                                  } else {
                                    this.expressionError = true;
                                    //return false;
                                  }
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else if(test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                            if (test.charAt(3) == '(') {
                              //analizzare se tra parentesi ho una metrica
                              let sub1 = sub.substring(7, sub.length);
                              let finalIndex = sub1.indexOf(')');
                              sub1 = sub1.substring(3, finalIndex);
                              sub1 = sub1.replaceAll('(', '');
                              sub1 = sub1.replaceAll(')', '');
                              if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                                sub1 = sub1.substring(1, sub1.length - 1);
                                if (sub1.includes('].[')) {
                                  let split = sub1.split('].[');
                                  let tableTitle = split[0];
                                  let colName = split[1];
                                  let field = this.findField(tableTitle, colName);;
                                  if (field != undefined && field != null && field.isMeasure) {
                                    //return true;
                                    let sub = <any>expr.substring(startingIndices[i], expr.length);
                                    //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                    let fIndex = this.findOccurence(sub, ")", 2);
                                    let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                    let func = sub.substring(7, sub.length);
                                    func = func.substring(0, func.indexOf('('));
                                    sqlExpression = sqlExpression.replace(stringToRep, `${func}([${tableTitle}].[${colName}]) is null`);
                                    this.sqlServerExpression = sqlExpression;
                                    this.mySqlExpression = sqlExpression;
                                    //console.log(sqlExpression);
                                  } else if(!(field.type == 'decimal' || field.type == 'integer' || field.type == 'int')){
                                    this.expressionError = true;
                                    //return false;
                                  }
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else if (test.includes("Count") || test.includes("count")) {
                            if (test.charAt(5) == '(') {
                              //analizzare se tra parentesi ho una metrica
                              let sub1 = sub.substring(7, sub.length);
                              let finalIndex = sub1.indexOf(')');
                              sub1 = sub1.substring(5, finalIndex);
                              sub1 = sub1.replaceAll('(', '');
                              sub1 = sub1.replaceAll(')', '');
                              if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                                sub1 = sub1.substring(1, sub1.length - 1);
                                if (sub1.includes('].[')) {
                                  let split = sub1.split('].[');
                                  let tableTitle = split[0];
                                  let colName = split[1];
                                  let field = this.findField(tableTitle, colName);;
                                  if (field != undefined && field != null/* && field.isMeasure*/) {
                                    let sub = <any>expr.substring(startingIndices[i], expr.length);
                                    //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                    let fIndex = this.findOccurence(sub, ")", 2);
                                    let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                    let func = sub.substring(7, sub.length);
                                    func = func.substring(0, func.indexOf('('));
                                    sqlExpression = sqlExpression.replace(stringToRep, `${func}([${tableTitle}].[${colName}]) is null`);
                                    this.sqlServerExpression = sqlExpression;
                                    this.mySqlExpression = sqlExpression;
                                    //console.log(sqlExpression);
                                  } else {
                                    this.expressionError = true;
                                    //return false;
                                  }
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      if (expr.substring(startingIndices[i], Number(startingIndices[i]) + 13) == "IsNullOrEmpty" ||
                        expr.substring(startingIndices[i], Number(startingIndices[i]) + 13) == "isnullorempty") {

                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    }
                  }
                }
              }
              if (expr.includes("IsNullOrEmpty") || expr.includes("isnullorempty")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("IsNullOrEmpty", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("IsNullOrEmpty", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("isnullorempty", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("isnullorempty", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 13) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 14) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(13, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure) {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `[${tableTitle}].[${colName}] is null or [${tableTitle}].[${colName}] = ''`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 14, Number(startingIndices[i]) + 20);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                            if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg")) {
                              if (test.charAt(3) == '(') {
                                //analizzare se tra parentesi ho una metrica
                                let sub1 = sub.substring(7, sub.length);
                                let finalIndex = sub1.indexOf(')');
                                sub1 = sub1.substring(3, finalIndex);
                                sub1 = sub1.replaceAll('(', '');
                                sub1 = sub1.replaceAll(')', '');
                                if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                                  sub1 = sub1.substring(1, sub1.length - 1);
                                  if (sub1.includes('].[')) {
                                    let split = sub1.split('].[');
                                    let tableTitle = split[0];
                                    let colName = split[1];
                                    let field = this.findField(tableTitle, colName);;
                                    if (field != undefined && field != null && field.isMeasure) {
                                      //return true;
                                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                                      //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                      let fIndex = this.findOccurence(sub, ")", 2);
                                      let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                      let func = sub.substring(7, sub.length);
                                      func = func.substring(0, func.indexOf('('));
                                      sqlExpression = sqlExpression.replace(stringToRep, `${func}([${tableTitle}].[${colName}]) is null`);
                                      this.sqlServerExpression = sqlExpression;
                                      this.mySqlExpression = sqlExpression;
                                      //console.log(sqlExpression);
                                    } else {
                                      this.expressionError = true;
                                      //return false;
                                    }
                                  } else {
                                    this.expressionError = true;
                                    //return false;
                                  }
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else if(test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                              if (test.charAt(3) == '(') {
                                //analizzare se tra parentesi ho una metrica
                                let sub1 = sub.substring(7, sub.length);
                                let finalIndex = sub1.indexOf(')');
                                sub1 = sub1.substring(3, finalIndex);
                                sub1 = sub1.replaceAll('(', '');
                                sub1 = sub1.replaceAll(')', '');
                                if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                                  sub1 = sub1.substring(1, sub1.length - 1);
                                  if (sub1.includes('].[')) {
                                    let split = sub1.split('].[');
                                    let tableTitle = split[0];
                                    let colName = split[1];
                                    let field = this.findField(tableTitle, colName);;
                                    if (field != undefined && field != null && field.isMeasure) {
                                      //return true;
                                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                                      //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                      let fIndex = this.findOccurence(sub, ")", 2);
                                      let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                      let func = sub.substring(7, sub.length);
                                      func = func.substring(0, func.indexOf('('));
                                      sqlExpression = sqlExpression.replace(stringToRep, `${func}([${tableTitle}].[${colName}]) is null`);
                                      this.sqlServerExpression = sqlExpression;
                                      this.mySqlExpression = sqlExpression;
                                      //console.log(sqlExpression);
                                    } else if(!(field.type == 'decimal' || field.type == 'integer' || field.type == 'int')){
                                      this.expressionError = true;
                                      //return false;
                                    }
                                  } else {
                                    this.expressionError = true;
                                    //return false;
                                  }
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else if (test.includes("Count") || test.includes("count")) {
                              if (test.charAt(5) == '(') {
                                //analizzare se tra parentesi ho una metrica
                                let sub1 = sub.substring(7, sub.length);
                                let finalIndex = sub1.indexOf(')');
                                sub1 = sub1.substring(5, finalIndex);
                                sub1 = sub1.replaceAll('(', '');
                                sub1 = sub1.replaceAll(')', '');
                                if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                                  sub1 = sub1.substring(1, sub1.length - 1);
                                  if (sub1.includes('].[')) {
                                    let split = sub1.split('].[');
                                    let tableTitle = split[0];
                                    let colName = split[1];
                                    let field = this.findField(tableTitle, colName);;
                                    if (field != undefined && field != null/* && field.isMeasure*/) {
                                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                                      //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                      let fIndex = this.findOccurence(sub, ")", 2);
                                      let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                      let func = sub.substring(7, sub.length);
                                      func = func.substring(0, func.indexOf('('));
                                      sqlExpression = sqlExpression.replace(stringToRep, `${func}([${tableTitle}].[${colName}]) is null`);
                                      this.sqlServerExpression = sqlExpression;
                                      this.mySqlExpression = sqlExpression;
                                      //console.log(sqlExpression);
                                    } else {
                                      this.expressionError = true;
                                      //return false;
                                    }
                                  } else {
                                    this.expressionError = true;
                                    //return false;
                                  }
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
            } else if (this.functionsDataSource.data[i].functionType == CFFunctionType.Math) {
              if (expr.includes("Abs") || expr.includes("abs")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Abs", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Abs", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("abs", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("abs", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 3) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let test = expr.substring(Number(startingIndices[i]) + 4, Number(startingIndices[i]) + 10);
                      if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                        test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          if (test.charAt(3) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(4, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            sub1 = sub1.substring(3, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null && field.isMeasure) {
                                  //return true;
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(4, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = sqlExpression.replace(stringToRep, `ABS(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else if (test.includes("Count") || test.includes("count")) {
                          if (test.charAt(5) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(4, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            sub1 = sub1.substring(5, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null/* && field.isMeasure*/) {
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(4, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = sqlExpression.replace(stringToRep, `ABS(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Ceiling") || expr.includes("ceiling")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Ceiling", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Ceiling", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("ceiling", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("ceiling", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 7) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                      if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                        test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          if (test.charAt(3) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(8, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            sub1 = sub1.substring(3, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null && field.isMeasure) {
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(8, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = sqlExpression.replace(stringToRep, `CEILING(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else if (test.includes("Count") || test.includes("count")) {
                          if (test.charAt(5) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(8, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            sub1 = sub1.substring(5, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null /*&& field.isMeasure*/) {
                                  //return true;
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(8, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = sqlExpression.replace(stringToRep, `CEILING(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Floor") || expr.includes("floor")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Floor", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Floor", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("floor", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("floor", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 5) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let test = expr.substring(Number(startingIndices[i]) + 6, Number(startingIndices[i]) + 12);
                      if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                        test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          if (test.charAt(3) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(6, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            sub1 = sub1.substring(3, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null && field.isMeasure) {
                                  //return true;
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(6, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = sqlExpression.replace(stringToRep, `FLOOR(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else if (test.includes("Count") || test.includes("count")) {
                          if (test.charAt(5) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(6, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            sub1 = sub1.substring(5, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null /*&& field.isMeasure*/) {
                                  //return true;
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(6, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = sqlExpression.replace(stringToRep, `FLOOR(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }

              }
              if (expr.includes("Round") || expr.includes("round")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Round", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Round", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("round", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("round", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 5) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      let test = expr.substring(Number(startingIndices[i]) + 6, Number(startingIndices[i]) + 12);
                      if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                        test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          if (test.charAt(3) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(6, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            let decimal = undefined;
                            //questa è la parentesi che chiude la funzione di aggregazione, se sono indicati i decimali la virgola sarebbe accanto
                            if (sub1.charAt(Number(finalIndex) + 1) == ',') {
                              decimal = sub1.substring(Number(finalIndex) + 2, sub1.length);
                              decimal = decimal.trim();
                              decimal = decimal.substring(0, decimal.indexOf(')'));
                              decimal = decimal.replaceAll(')', '');

                              if (Validator.isNumericValue(decimal)) {
                                //return true;
                                console.log('is decimal');

                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            }
                            sub1 = sub1.substring(3, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null && field.isMeasure) {
                                  //return true;
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(6, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = decimal != undefined ? sqlExpression.replace(stringToRep, `ROUND(${func}([${tableTitle}].[${colName}]), ${decimal})`) :
                                    sqlExpression.replace(stringToRep, `ROUND(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else if (test.includes("Count") || test.includes("count")) {
                          if (test.charAt(5) == '(') {
                            //analizzare se tra parentesi ho una metrica
                            let sub1 = sub.substring(6, sub.length);
                            let finalIndex = sub1.indexOf(')');
                            let decimal = undefined;
                            if (sub1.charAt(Number(finalIndex) + 1) == ',') {
                              decimal = sub1.substring(Number(finalIndex) + 2, sub1.length);
                              decimal = decimal.replaceAll(')', '');
                              decimal = decimal.trim();
                              if (Validator.isNumericValue(decimal)) {
                                //return true;
                                console.log('is decimal');
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            }
                            sub1 = sub1.substring(5, finalIndex);
                            sub1 = sub1.replaceAll('(', '');
                            sub1 = sub1.replaceAll(')', '');
                            if (sub1.charAt(0) == '[' && sub1.charAt(sub1.length - 1) == ']') {
                              sub1 = sub1.substring(1, sub1.length - 1);
                              if (sub1.includes('].[')) {
                                let split = sub1.split('].[');
                                let tableTitle = split[0];
                                let colName = split[1];
                                let field = this.findField(tableTitle, colName);;
                                if (field != undefined && field != null/* && field.isMeasure*/) {
                                  //return true;
                                  let sub = <any>expr.substring(startingIndices[i], expr.length);
                                  //let sub1 = sub.substring(Number(startingIndices[i]) + 7, sub.length);
                                  let fIndex = this.findOccurence(sub, ")", 2);
                                  let stringToRep = sub.substring(0, Number(fIndex) + 1);
                                  let func = sub.substring(6, sub.length);
                                  func = func.substring(0, func.indexOf('('));
                                  sqlExpression = decimal != undefined ? sqlExpression.replace(stringToRep, `ROUND(${func}([${tableTitle}].[${colName}]), ${decimal})`) :
                                    sqlExpression.replace(stringToRep, `ROUND(${func}([${tableTitle}].[${colName}]))`);
                                  this.sqlServerExpression = sqlExpression;
                                  this.mySqlExpression = sqlExpression;
                                } else {
                                  this.expressionError = true;
                                  //return false;
                                }
                              } else {
                                this.expressionError = true;
                                //return false;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          //return false;
                        }
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
            } else if (this.functionsDataSource.data[i].functionType == CFFunctionType.String) {
              if (expr.includes("Trim") || expr.includes("trim")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Trim", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Trim", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("trim", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("trim", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 4) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 5) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(4, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'string') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `TRIM([${tableTitle}].[${colName}])`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 5, Number(startingIndices[i]) + 11);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Len") || expr.includes("len")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Len", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Len", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("len", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("len", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 3) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 4) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(3, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && (field.type == 'string' || field.type == 'int' )) {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `LEN([${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `LENGTH([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 4, Number(startingIndices[i]) + 10);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Upper") || expr.includes("upper")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Upper", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Upper", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("upper", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("upper", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 5) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 6) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(5, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'string') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `UPPER([${tableTitle}].[${colName}])`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 6, Number(startingIndices[i]) + 12);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Lower") || expr.includes("lower")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Lower", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Lower", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("lower", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("lower", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 5) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 6) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(5, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'string') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `LOWER([${tableTitle}].[${colName}])`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 6, Number(startingIndices[i]) + 12);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }

                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Ascii") || expr.includes("ascii")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Ascii", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Ascii", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("ascii", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("ascii", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 5) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 6) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(5, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'string') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `ASCII([${tableTitle}].[${colName}])`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 6, Number(startingIndices[i]) + 12);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Reverse") || expr.includes("reverse")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Reverse", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Reverse", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("reverse", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("reverse", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 7) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 8) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(8, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'string') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `REVERSE([${tableTitle}].[${colName}])`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Substring") || expr.includes("substring")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Substring", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Substring", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("substring", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("substring", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 9) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 10) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(9, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        //sub case1: [Conto].[Company], 4
                        //sub case2: [Conto].[Company], 4, 5
                        let firstC = sub.indexOf(',');
                        let initialPos = undefined;
                        let secondPos = undefined;
                        if (firstC >= 0) {
                          initialPos = sub.substring(Number(firstC) + 1, sub.length);
                          let secondC = initialPos.indexOf(',');
                          if (secondC >= 0) {
                            secondPos = initialPos.substring(Number(secondC) + 1, initialPos.length);
                            secondPos = secondPos.trim();
                            initialPos = initialPos.substring(0, Number(secondC));
                            initialPos = initialPos.trim();
                            if (Validator.isNumericValue(initialPos) && Validator.isNumericValue(secondPos)) {
                              //ok
                              sub = sub.substring(0, Number(firstC));
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            /*initialPos = initialPos.trim();
                            if (Validator.isNumericValue(initialPos)) {
                              //ok
                              sub = sub.substring(0, Number(firstC));
                            } else {*/
                            this.expressionError = true;
                            //return false;
                            //}
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && (field.type == 'string' || field.type == 'int')) {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `SUBSTRING([${tableTitle}].[${colName}], ${initialPos}, ${secondPos})`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("Replace") || expr.includes("replace")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("Replace", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("Replace", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("replace", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("replace", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 7) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 8) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(7, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        //sub case1: [Conto].[Company], 4
                        //sub case2: [Conto].[Company], 4, 5
                        let firstC = sub.indexOf(',');
                        let initialPos = undefined;
                        let secondPos = undefined;
                        if (firstC >= 0) {
                          initialPos = sub.substring(Number(firstC) + 1, sub.length);
                          let secondC = initialPos.indexOf(',');
                          if (secondC >= 0) {
                            secondPos = initialPos.substring(Number(secondC) + 1, initialPos.length);
                            secondPos = secondPos.trim();
                            initialPos = initialPos.substring(0, Number(secondC));
                            initialPos = initialPos.trim();
                            if (Validator.isStringValue(initialPos) && Validator.isStringValue(secondPos)) {
                              //ok
                              sub = sub.substring(0, Number(firstC));
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            /*initialPos = initialPos.trim();
                            if (Validator.isStringValue(initialPos)) {
                              //ok
                              sub = sub.substring(0, Number(firstC));
                            } else {*/
                            this.expressionError = true;
                            //return false;
                            //}
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'string') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              sqlExpression = sqlExpression.replace(stringToRep, `REPLACE([${tableTitle}].[${colName}], ${initialPos}, ${secondPos})`);
                              this.sqlServerExpression = sqlExpression;
                              this.mySqlExpression = sqlExpression;
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
            } else if (this.functionsDataSource.data[i].functionType == CFFunctionType.Date) {
              if (expr.includes("GetDate") || expr.includes("getdate")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetDate", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetDate", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getdate", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getdate", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 7) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 8) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(7, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `CONVERT([${tableTitle}].[${colName}], getdate(), 5)`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `GetDate([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              ////return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("GetSecond") || expr.includes("getsecond")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetSecond", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetSecond", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getsecond", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getsecond", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 9) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 10) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(9, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(second, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `SECOND([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 10, Number(startingIndices[i]) + 16);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("GetMinute") || expr.includes("getminute")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetMinute", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetMinute", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getminute", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getminute", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 9) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 10) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(9, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);;
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(minute, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `MINUTE([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 10, Number(startingIndices[i]) + 16);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("GetHour") || expr.includes("gethour")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetHour", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetHour", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("gethour", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("gethour", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 7) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 8) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(7, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(hour, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `HOUR([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("GetDay") || expr.includes("getday")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetDay", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetDay", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getday", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getday", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 7) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 8) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(7, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(day, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DAY([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      if (expr.substring(startingIndices[i], Number(startingIndices[i]) + 13) == "GetDayOfWeek" ||
                        expr.substring(startingIndices[i], Number(startingIndices[i]) + 13) == "getdayofweek" ||
                        expr.substring(startingIndices[i], Number(startingIndices[i]) + 12) == "GetDayOfYear" ||
                        expr.substring(startingIndices[i], Number(startingIndices[i]) + 12) == "getdayofyear") {

                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    }
                  }
                }
              }
              if (expr.includes("GetMonth") || expr.includes("getmonth")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetMonth", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetHour", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getmonth", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getmonth", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 8) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 9) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(8, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(month, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `MONTH([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 9, Number(startingIndices[i]) + 15);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }

                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("GetYear") || expr.includes("getyear")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetYear", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetYear", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getyear", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getyear", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 7) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 8) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(7, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(year, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `YEAR([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 8, Number(startingIndices[i]) + 14);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("GetDayOfWeek") || expr.includes("getdayofweek")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetDayOfWeek", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetDayOfWeek", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getdayofweek", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getdayofweek", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 12) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 13) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(12, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(DW, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DAYOFWEEK([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 13, Number(startingIndices[i]) + 26);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
              if (expr.includes("GetDayOfYear") || expr.includes("getdayofyear")) {
                let startingIndices = [];

                let indexOccurenceUpper = expr.indexOf("GetDayOfYear", 0);

                while (indexOccurenceUpper >= 0) {
                  startingIndices.push(indexOccurenceUpper);
                  indexOccurenceUpper = expr.indexOf("GetDayOfYear", indexOccurenceUpper + 1);
                }

                let indexOccurenceLower = expr.indexOf("getdayofyear", 0);

                while (indexOccurenceLower >= 0) {
                  startingIndices.push(indexOccurenceLower);
                  indexOccurenceLower = expr.indexOf("getdayofyear", indexOccurenceLower + 1);
                }

                for (let i in startingIndices) {
                  if (Number(startingIndices[i]) > 1 && (/[a-zA-Z]/).test(expr.charAt(Number(startingIndices[i] - 1)))) {

                  } else {
                    if (expr.charAt(Number(startingIndices[i]) + 12) == '(') {
                      //analizzare se tra parentesi ho una metrica
                      let sub = <any>expr.substring(startingIndices[i], expr.length);
                      //qui non va bene, devo vedere se ci sono delle metriche con la funzione di aggregazione
                      if (expr.charAt(Number(startingIndices[i]) + 13) == '[') {
                        let finalIndex = sub.indexOf(')');
                        sub = sub.substring(12, finalIndex);
                        sub = sub.replaceAll('(', '');
                        sub = sub.replaceAll(')', '');
                        if (sub.charAt(0) == '[' && sub.charAt(sub.length - 1) == ']') {
                          sub = sub.substring(1, sub.length - 1);
                          if (sub.includes('].[')) {
                            let split = sub.split('].[');
                            let tableTitle = split[0];
                            let colName = split[1];
                            let field = this.findField(tableTitle, colName);
                            if (field != undefined && field != null && !field.isMeasure && field.type == 'datetime') {
                              //return true;
                              let sub = <any>expr.substring(startingIndices[i], expr.length);
                              let stringToRep = sub.substring(0, finalIndex + 1);
                              if (this.sourceType == SourceType.SqlServer) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DATEPART(DY, [${tableTitle}].[${colName}])`);
                                this.sqlServerExpression = sqlExpression;
                              } else if (this.sourceType == SourceType.MySQL) {
                                sqlExpression = sqlExpression.replace(stringToRep, `DAYOFYEAR([${tableTitle}].[${colName}])`);
                                this.mySqlExpression = sqlExpression;
                              }
                            } else {
                              this.expressionError = true;
                              //return false;
                            }
                          } else {
                            this.expressionError = true;
                            //return false;
                          }
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      } else {
                        //cerco le funzioni di aggregazione, quella più lunga è count
                        let test = expr.substring(Number(startingIndices[i]) + 13, Number(startingIndices[i]) + 26);
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                          test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                          this.expressionError = true;
                          //return false;
                        } else {
                          this.expressionError = true;
                          //return false;
                        }
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                }
              }
            }
          }
        }
        for (let i in this.dataSource.data) {
          if (this.dataSource.data[i].children) {
            for (let j in this.dataSource.data[i].children) {
              if (expr.includes('[' + this.dataSource.data[i].children[j].tableTitle + '].[' + this.dataSource.data[i].children[j].name + ']')) {
                let field = '[' + this.dataSource.data[i].children[j].tableTitle + '].[' + this.dataSource.data[i].children[j].name + ']';
                if (this.dataSource.data[i].children[j].isMeasure) {

                  let startingIndices = [];

                  let indexOccurenceUpper = expr.indexOf(field, 0);

                  while (indexOccurenceUpper >= 0) {
                    startingIndices.push(indexOccurenceUpper);
                    indexOccurenceUpper = expr.indexOf(field, indexOccurenceUpper + 1);
                  }

                  for (let i in startingIndices) {
                    if (Number(startingIndices[i]) - 6 >= 0 || Number(startingIndices[i]) - 4 >= 0) {
                      let test = Number(startingIndices[i]) - 6 >= 0 ?
                        expr.substring(Number(startingIndices[i]) - 6, Number(startingIndices[i]) + field.length) :
                        expr.substring(Number(startingIndices[i]) - 4, Number(startingIndices[i]) + field.length);
                      if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") || test.includes("Count") || test.includes("count") ||
                        test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")) {
                        console.log(test);
                      } else {
                        this.expressionError = true;
                        //return false;
                      }
                    } else {
                      this.expressionError = true;
                      //return false;
                    }
                  }
                } else {
                  let startingIndices = [];

                  let indexOccurenceUpper = expr.indexOf(field, 0);

                  while (indexOccurenceUpper >= 0) {
                    startingIndices.push(indexOccurenceUpper);
                    indexOccurenceUpper = expr.indexOf(field, indexOccurenceUpper + 1);
                  }

                  for (let i in startingIndices) {
                    if (Number(startingIndices[i]) - 6 >= 0 || Number(startingIndices[i]) - 4 >= 0) {
                      let test = Number(startingIndices[i]) - 6 >= 0 ?
                        expr.substring(Number(startingIndices[i]) - 6, Number(startingIndices[i]) + field.length) :
                        expr.substring(Number(startingIndices[i]) - 4, Number(startingIndices[i]) + field.length);
                      let fieldNode = this.dataSource.data[i].children[j];
                      if ((fieldNode && fieldNode.type && !(fieldNode.type == 'decimal' || fieldNode.type == 'integer' || fieldNode.type == 'int')) &&
                        (test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min"))) {
                        console.log(test);
                        this.expressionError = true;
                      } else
                        if (test.includes("Sum") || test.includes("sum") || test.includes("Avg") || test.includes("avg") /*|| test.includes("Count") || test.includes("count") ||*/
                        /*test.includes("Max") || test.includes("max") || test.includes("Min") || test.includes("min")*/) {
                          console.log(test);
                          this.expressionError = true;
                          //return false;
                        } //else //return false;
                    } //else //return false;
                  }
                }
              }
            }
          }
        }
        this.expression = expr;
        console.log(sqlExpression);
        let bodyRQ = new ValidateFormulaRQ;
        console.log("Validate Formula");
        console.log(bodyRQ);
        bodyRQ.expression = sqlExpression;
        bodyRQ.fact = this.fact.tableName;
        bodyRQ.factAlias = this.fact.tableTitle;
        bodyRQ.aggrFunction = this.aggrFuntionSelected.type;
        /*let body = { 
          expression: sqlExpression, 
          fact: this.fact.tableName, 
          factAlias: this.fact.tableTitle, 
          aggrFunction: this.aggrFuntionSelected.type 
        }*/
        this.api.validateFormula(bodyRQ).subscribe( (res) => {
          if (res) {
            //return true;
          } else {
            this.expressionError = true;
            this.cfFunctionErr = this.translate.instant('calculated-fields.not-valid-formula');
            //return false;
          }
        }, (err) => {
          this.expressionError = true;
          this.cfFunctionErr = this.translate.instant('calculated-fields.not-valid-formula');
          throw err;
          //return false;
        });
      } else {
        console.log('error');
        this.cfFunctionErr = this.translate.instant('calculated-fields.not-valid-expression');
        this.expressionError = true;
        //return false;
      }
      return !this.expressionError;
    }, error: err => { console.log(err); throw err; }});
  }

  isDisabled() {
    return this.cfName == null || this.cfName == '' || this.cfName == undefined || this.expressionError == true || this.expression == '';
  }

  validateExpression() {
    let body = new ExpressionRQ;
    console.log("Validate Expression");
    body.expression = this.expression;
    this.api.validateExpression(body).subscribe(res => {
      if (res) {
        console.log('ok');
        this.expressionError = false;
      } else {
        console.log('error');
        this.expressionError = true;
      }
    }, err => { console.log(err); throw err; })
  }

  isMenu() {
    return Number(sessionStorage.getItem('productType')) == ProductTypeStyle.Menu;
  }

  isRetail() {
    return Number(sessionStorage.getItem('productType')) == ProductTypeStyle.Retail;
  }

  isWelcome() {
    return Number(sessionStorage.getItem('productType')) == ProductTypeStyle.Welcome;
  }

}
