@if (isReady) {
  <div class="container">
    <mat-dialog-content class="mat-typography" [formGroup]="formGroup" style="display: grid;" >
      <mat-form-field class="form-element">
        <mat-label>{{'date-range.number' | translate}}</mat-label>
        <input matInput type="number" min="1" placeholder="{{'date-range.number' | translate}}" formControlName="number" required
          (blur)="dateRangeValidator(formGroup.controls['number'].value, formGroup.controls['period'].value, formGroup)">
          @if (!formGroup.controls['number'].valid || formGroup.controls['number'].errors) {
            <mat-error
              >
              {{ formGroup.controls['number'].errors['message'] }}
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field class="form-element">
          <mat-label>{{'date-range.period' | translate}}</mat-label>
          <mat-select placeholder="{{'date-range.period' | translate}}" formControlName="period" required
            (blur)="dateRangeValidator(formGroup.controls['number'].value, formGroup.controls['period'].value, formGroup)">
            @for (key of timeLimitTypeList; track key) {
              <mat-option [value]="key">
                {{timeLimitTypeMapList[key]}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-raised-button class="btn-light" mat-dialog-close>{{'dialog.close' | translate}}</button>
        <button mat-raised-button class="btn-dark"
          [mat-dialog-close]="close()"
        [disabled]="!formGroup.valid">{{'dialog.proceed' | translate}}</button>
      </mat-dialog-actions>
    </div>
  }
