import {Injectable} from '@angular/core';
import {jwtDecode} from "jwt-decode";
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {User} from "../models/user";
import {ConfigService} from "./config.service";

@Injectable({providedIn: 'root'})

export class JwtService {

  constructor(
    private authService: AuthenticationService,
    private configService: ConfigService) {
  }

  decodeToken(token: string): string {
    return jwtDecode(token);
  }

  setUser(token, currentLang): Observable<string> {
    let t: any;
    if (token) {
      t = this.decodeToken(token);
      return of(t).pipe(map((t) => {
        let user = new User();
        user.Id = t.nameid;
        user.Username = t.unique_name;
        user.Password = '';
        user.Name = t.unique_name;
        user.Group = t.Group;
        user.Company = t.Company;
        user.Lang = t.Lang;
        user.Token = token;
        this.configService.setUser(user);
        this.authService.userSubject.next(user);
        return t;
      }));
    } else return of('ok').pipe(map((t) => {
      let user = this.configService.getUser();
      this.authService.userSubject.next(user);
      return t;
    }));
  }

}